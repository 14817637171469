import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { AVATAR_SIZES } from '../../utils/componentUtils';
import { NEUTRAL_SHADES } from '../../utils/theme';

export const AvatarSizes = {
  [AVATAR_SIZES.XS]: {
    font: 12,
    size: 24,
  },
  [AVATAR_SIZES.SM]: {
    font: 14,
    size: 32,
  },
  [AVATAR_SIZES.MD]: {
    font: 16,
    size: 40,
  },
  [AVATAR_SIZES.LG]: {
    font: 24,
    size: 50,
  },
  [AVATAR_SIZES.XL]: {
    font: 32,
    size: 88,
  },
};

export const AvatarContainerStyles = css`
  background-color: black;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  word-wrap: normal;
  font-family: 'DIN2014-Regular';
  padding-top: 2px;
  cursor: default;
`;

export const AvatarContainerDiv = styled.div`
  ${AvatarContainerStyles}
  ${({ bgColor, size }) => css`
    background: ${bgColor};
    width: ${AvatarSizes[`${size}`].size}px;
    height: ${AvatarSizes[`${size}`].size}px;
    font-size: ${AvatarSizes[`${size}`].font}px;
    img {
      width: ${AvatarSizes[`${size}`].size}px;
      height: ${AvatarSizes[`${size}`].size}px;
    }
  `}
`;

export const AvatarContainerLink = styled(Link)`
  ${AvatarContainerStyles}
  ${({ bgColor, size }) => css`
    background: ${bgColor};
    width: ${AvatarSizes[`${size}`].size}px;
    height: ${AvatarSizes[`${size}`].size}px;
    font-size: ${AvatarSizes[`${size}`].font}px;
    img {
      width: ${AvatarSizes[`${size}`].size}px;
      height: ${AvatarSizes[`${size}`].size}px;
    }
  `}
`;

export const LetteredAvatar = styled.div`
  white-space: nowrap;
  overflow: hidden;
  // font-size: 12px;
  border-radius: 32px;
  color: ${NEUTRAL_SHADES.WHITE};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AvatarImg = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;
