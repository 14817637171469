import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

import { NEUTRAL_SHADES, SPACING } from './theme';

// ----- Page Header ----- //

export const PageHeaderContainer = styled.div`
  padding-inline: 3.5%;
  margin-top: 28px;
`;

export const PageHeader = styled.div`
  display: flex;
  flex: 1;
`;

export const StyledPageTitle = styled.span`
  font-size: 20px;
  color: ${NEUTRAL_SHADES.BLACK};
  align-self: flex-end;
`;

export const PageHeaderButtonContainer = styled.div`
  display: flex;
  gap: ${SPACING[3]};
  margin-left: auto;
`;

export const BackToContainer = styled.div`
  font-size: 14px;
  font-family: 'DIN2014-Regular';
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
  margin-bottom: ${SPACING[1]};
`;

// ----- Content View ----- //

export const ContentViewContainer = styled.div`
  width: 93%;
  margin: 0 auto;
`;

// ----- Table Global Styles ----- //

export const TableHeaderText = styled.span`
  font-size: 14px;
  font-family: DIN2014-DemiBold;
  color: ${NEUTRAL_SHADES.BLACK};
`;

export const TableBodyText = styled.span`
  font-size: 14px;
  font-family: 'DIN2014-Regular';
  color: ${NEUTRAL_SHADES.BLACK};
`;

// ----- Modals Global Styles ----- //

export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
`;

// ----- Global Styled Components ----- //

export const Divider = styled.hr`
  width: 100%;
  border-bottom: 1px solid ${NEUTRAL_SHADES[600]};
  margin: 0px;
  margin-bottom: ${SPACING[3]};
`;

export const StyledCard = styled.div`
  border: 1px solid ${NEUTRAL_SHADES[200]};
  background: ${NEUTRAL_SHADES.WHITE};
  font-family: 'DIN2014-Regular';
`;

export const FlexContainer = styled.div`
  display: flex;
  gap: ${SPACING[2]};
`;

// container for 2 elements in a row
export const DuoContainer = styled(FlexContainer)`
  width: 100%;
`;

export const CenteredContainer = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
`;

export const StyledScrollDiv = styled.div`
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: ${SPACING[1.5]};
  }

  &::-webkit-scrollbar-track {
    background: ${NEUTRAL_SHADES.WHITE};
  }

  &::-webkit-scrollbar-thumb {
    background: ${NEUTRAL_SHADES[200]};
    border-radius: ${SPACING[1]};
  }
`;
