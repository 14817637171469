import { Home } from '../../pages/Home/Home';
import { AcknowledgeReceiptPage } from '../../pages/AcknowledgeReceiptPage';
import { AdminAgreement } from '../../pages/Admin/AdminAgreement';
import { AdminAgreementEditView } from '../../pages/Admin/AdminAgreementEditView/AdminAgreementEditView';
import { AdminAssetEditView } from '../../pages/Admin/AdminAssetEditView';
import { AdminAssets } from '../../pages/Admin/AdminAssets';
import { AdminClientEditView } from '../../pages/Admin/AdminClientEditView';
import { AdminCourses } from '../../pages/Admin/AdminCourses';
import { AdminHome } from '../../pages/Admin/AdminHome';
import { AdminLawFirm } from '../../pages/Admin/AdminLawFirm';
import { AdminUserEditView } from '../../pages/Admin/AdminUserEditView';
import { AdminUsers } from '../../pages/Admin/AdminUsers';
import { Asset } from '../../pages/Asset/Asset';
import { ClientAssets } from '../../pages/ClientAssets';
import { ClientDashboard2V } from '../../pages/ClientDashboard';
import { Dashboard } from '../../pages/Dashboard';
import { Learn } from '../../pages/Learn';
import { Login } from '../../pages/Login';
import { People } from '../../pages/People';
import { PreviewAssetPage } from '../../pages/PreviewAssetPage';
import { Quiz } from '../../pages/Quiz';
import { ResetPassword } from '../../pages/ResetPassword';
import { AcceptInvitePage } from '../../pages/AcceptInvitePage/AcceptInvitePage';
import { OktaCallback } from '../../_components/OktaCallback/OktaCallback';
import Onboarding from '../../pages/Onboarding/Onboarding';
import { PartnershipPage } from '../../pages/PartnershipPage/PartnershipPage';
import { allPermissionsExceptLearn, superAdmin, adminsExceptLearn, admins, allPermissions, IRoles, specialAdmins } from '../roles';
import PatentMappingToolPage from 'pages/PatentTool/PatentMappingToolPage';
import ContractTool from 'pages/ContractTool/ContractToolPage';
import InventionDisclosure from '../../pages/InventionDisclosurePage/InventionDisclosure';
import PredictedPortfolio from 'pages/TradeSecretsAnalysis/PredictedPortfolio/PredictedPortfolio';
import CompetitiveAnalysis from 'pages/TradeSecretsAnalysis/CompetitiveAnalysis/CompetitiveAnalysis';
import Lobby from 'pages/Lobby/Lobby';
import PatentXRay from 'pages/PatentXRay/PatentXRay';
import EmailTrackerMainPage from 'pages/EmailTrackerMainPage/EmailTrackerMainPage';
import ContractAnalyzer from 'pages/ContractAnalyzer/ContractAnalyzer';
import InventionDisclosureMainPage from 'pages/InventionDisclosureMainPage/InventionDisclosureMainPage';
import ContinuationAnalysis from 'pages/ContinuationAnalysis/ContinuationAnalysis';
import { AppRoutes } from './consts';

export const superAdminRoutes = [
  {
    component: AdminHome,
    path: '/adminhome',
    exact: true,
    permissions: superAdmin,
  },
  {
    component: AdminClientEditView,
    path: '/admin/client/:type/:id',
    exact: true,
    permissions: superAdmin,
  },
  {
    component: AdminAssetEditView,
    path: '/admin/asset/:type/:id',
    exact: true,
    permissions: superAdmin,
  },
  {
    component: AdminLawFirm,
    path: '/admin/lawfirm',
    exact: true,
    permissions: superAdmin,
  },
  {
    component: AdminLawFirm,
    path: '/admin/lawfirm/:type/:firmid',
    exact: true,
    permissions: superAdmin,
  },
  {
    component: AdminHome,
    path: '/adminhome/client&type=addnew',
    exact: true,
    permissions: superAdmin,
  },
  {
    component: AdminUsers,
    path: '/admin/users',
    exact: true,
    permissions: superAdmin,
  },
  {
    component: AdminUsers,
    path: '/admin/users&type=addnew',
    exact: true,
    permissions: superAdmin,
  },
  {
    component: AdminUserEditView,
    path: '/admin/user/:type/:id',
    exact: true,
    permissions: superAdmin,
  },
  {
    component: AdminAgreementEditView,
    path: '/admin/agreement/:type/:accountId',
    exact: true,
    permissions: superAdmin,
  },
  {
    component: AdminAssets,
    path: '/admin/assets',
    exact: true,
    permissions: superAdmin,
  },
  {
    component: AdminAssets,
    path: '/admin/assets&type=addnew',
    exact: true,
    permissions: superAdmin,
  },
  {
    component: AdminAssets,
    path: '/admin/assets&type=type',
    exact: true,
    permissions: superAdmin,
  },
  {
    component: AdminAssets,
    path: '/admin/assets&type=type/:type/:firmid',
    exact: true,
    permissions: superAdmin,
  },
  {
    component: AdminAssets,
    path: '/admin/assets&type=status',
    exact: true,
    permissions: superAdmin,
  },
  {
    component: AdminAssets,
    path: '/admin/assets&type=tags',
    exact: true,
    permissions: superAdmin,
  },
  {
    component: AdminAssets,
    path: '/admin/assets&type=tags/:type/:firmid',
    exact: true,
    permissions: superAdmin,
  },
  {
    component: AdminAssets,
    path: '/admin/assets&type=bv',
    exact: true,
    permissions: superAdmin,
  },
  {
    component: AdminAssets,
    path: '/admin/assets&type=bv/:type/:firmid',
    exact: true,
    permissions: superAdmin,
  },
  {
    component: AdminAgreement,
    path: '/admin/agreement',
    exact: true,
    permissions: superAdmin,
  },
  {
    component: AdminAgreement,
    path: '/admin/agreement&type=addnew',
    exact: true,
    permissions: superAdmin,
  },
  {
    component: AdminAgreement,
    path: '/admin/agreement&type=type',
    exact: true,
    permissions: superAdmin,
  },
  {
    component: AdminAgreement,
    path: '/admin/agreement&type=type/:type/:firmid',
    exact: true,
    permissions: superAdmin,
  },
  {
    component: AdminCourses,
    path: '/admin/course',
    exact: true,
    permissions: superAdmin,
  },
  {
    component: AdminCourses,
    path: '/admin/course&type=addnew',
    exact: true,
    permissions: superAdmin,
  },
  {
    component: AdminCourses,
    path: '/admin/course&type=editcourse/:id',
    exact: true,
    permissions: superAdmin,
  },
  {
    component: AdminCourses,
    path: '/admin/course&type=tags',
    exact: true,
    permissions: superAdmin,
  },
  {
    component: AdminCourses,
    path: '/admin/course&type=category',
    exact: true,
    permissions: superAdmin,
  },
];

export const routes = [
  {
    component: Lobby,
    path: '/client/:clientId',
    exact: true,
    permissions: allPermissions,
  },
  {
    component: Onboarding,
    path: AppRoutes.FIRST_ONBOARDING,
    exact: true,
    permissions: admins,
  },
  {
    component: Onboarding,
    path: AppRoutes.SETTINGS,
    exact: true,
    permissions: admins,
  },
  {
    component: Dashboard,
    path: AppRoutes.DASHBOARD,
    exact: true,
    permissions: ['Lawyer - Admin', 'Lawyer'],
  },
  {
    component: ClientAssets,
    path: AppRoutes.ASSETS_MAIN_PAGE,
    exact: true,
    permissions: allPermissionsExceptLearn,
  },
  {
    component: Asset,
    path: AppRoutes.ASSET_INNER_PAGE,
    exact: true,
    permissions: allPermissionsExceptLearn,
  },
  {
    component: People,
    path: AppRoutes.PEOPLE,
    exact: true,
    permissions: [...admins, IRoles.EMPLOYEE_HR], // all admins + hr
  },
  {
    component: PartnershipPage,
    path: AppRoutes.PARTNERSHIP,
    exact: true,
    permissions: admins,
  },
  {
    component: ClientDashboard2V,
    path: AppRoutes.CLIENT_DASHBOARD,
    exact: true,
    permissions: adminsExceptLearn,
  },
  {
    component: PredictedPortfolio,
    path: AppRoutes.PREDICTED_PORTFOLIO,
    exact: true,
    permissions: allPermissions,
  },
  {
    component: CompetitiveAnalysis,
    path: AppRoutes.COMPETITIVE_ANALYSIS,
    exact: true,
    permissions: allPermissions,
  },
  {
    component: Learn,
    path: AppRoutes.TRAINING,
    exact: true,
    permissions: allPermissions,
  },
  {
    component: Quiz,
    path: AppRoutes.QUIZ,
    exact: true,
    permissions: allPermissions,
  },
  {
    component: PatentXRay,
    path: AppRoutes.PATENT_X_RAY_MAIN_PAGE,
    exact: true,
    permissions: specialAdmins,
  },
  {
    component: ContractAnalyzer,
    path: AppRoutes.CONTRACT_ANALYZER_MAIN_PAGE,
    exact: true,
    permissions: specialAdmins,
  },
  {
    component: InventionDisclosureMainPage,
    path: AppRoutes.INVENTION_DISCLOSURE_MAIN_PAGE,
    exact: true,
    permissions: specialAdmins,
  },
  {
    component: EmailTrackerMainPage,
    path: AppRoutes.EMAIL_TRACKER_MARKETING_PAGE,
    exact: true,
    permissions: specialAdmins,
  },
  {
    component: PatentMappingToolPage,
    path: AppRoutes.PATENT_X_RAY_INNER_PAGE,
    exact: true,
    permissions: specialAdmins,
  },
  {
    component: ContractTool,
    path: AppRoutes.CONTRACT_ANALYZER_INNER_PAGE,
    exact: true,
    permissions: specialAdmins,
  },
  {
    component: InventionDisclosure,
    path: AppRoutes.INVENTION_DISCLOSURE_INNER_PAGE,
    exact: true,
    permissions: specialAdmins,
  },
  {
    component: ContinuationAnalysis,
    path: AppRoutes.CONTINUATION_ANALYSIS_MAIN_PAGE,
    exact: true,
    permissions: specialAdmins,
  },
  {
    component: ContinuationAnalysis,
    path: AppRoutes.CONTINUATION_ANALYSIS_MAIN_PAGE,
    exact: true,
    permissions: specialAdmins,
  },

  // ------- CURRENTLY NOT USED PAGES -------
  // {
  //   component: EmailFinder,
  //   path: AppRoutes.EMAIL_FINDER,
  //   exact: true,
  //   permissions: specialAdmins,
  // },
  // {
  //   component: EmailTracker,
  //   path: AppRoutes.EMAIL_TRACKER,
  //   exact: true,
  //   permissions: specialAdmins,
  // },

  // ------- OLD PAGES -------
  // {
  //   component: XRayLobby,
  //   path: AppRoutes.XRAY_LOBBY,
  //   exact: true,
  //   permissions: specialAdmins,
  // },
  // {
  //   component: DiscoveryJoin,
  //   path: AppRoutes.DISCOVERY_JOIN,
  //   exact: true,
  //   permissions: allPermissions,
  // },
];

export const publicRoutes = [
  {
    component: OktaCallback,
    path: AppRoutes.OKTA_CALLBACK,
    exact: false,
  },
  {
    component: Login,
    path: AppRoutes.LOGIN,
    exact: false,
  },
  {
    component: AcknowledgeReceiptPage,
    path: AppRoutes.ASSET_ACKNOWLEDGE,
    exact: true,
  },
  {
    component: PreviewAssetPage,
    path: AppRoutes.ASSET_PREVIEW,
    exact: true,
  },
  {
    component: Home,
    path: AppRoutes.HOME,
    exact: true,
  },
  {
    component: Home,
    path: AppRoutes.MAIN,
    exact: true,
  },
  {
    component: ResetPassword,
    path: AppRoutes.RESET_PASSWORD,
    exact: true,
  },
  {
    component: ResetPassword,
    path: AppRoutes.FORCE_RESET_PASSWORD,
    exact: true,
  },
  {
    component: AcceptInvitePage,
    path: AppRoutes.REGISTER,
    exact: true,
  },
];
