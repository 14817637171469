/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, FormCheckProps } from 'react-bootstrap';
import { Container } from './style';
import { UseFormRegister } from 'react-hook-form';

interface TangiCheckboxProps extends FormCheckProps {
  name: string;
  label?: string;
  register?: UseFormRegister<any>;
}

export const TangiCheckbox = ({ register, label, name, ...props }: TangiCheckboxProps) => {
  return (
    <Container>
      <Form.Check label={label} type="checkbox" {...register?.(name)} {...props} />
    </Container>
  );
};
