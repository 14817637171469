// ContractHeader.tsx
import React, { useMemo } from 'react';
import { HeaderContainer } from '../style';
import { TangiTypography } from '_components/TangiLibrary';
import { ContractChip } from './ContractChip';
import { t } from 'i18next';
import { getScoreColor, getTextByScore } from '../utils';

interface ContractHeaderProps {
  score: number;
  filename: string;
}

export const ContractHeader: React.FC<ContractHeaderProps> = ({ score, filename }) => {
  const scoreStatus = useMemo(() => getScoreColor(score), [score]);
  const textByScore = useMemo(() => getTextByScore(score), [score]);

  return (
    <HeaderContainer>
      <TangiTypography type="heading-lg">{filename}</TangiTypography>
      <ContractChip variant={scoreStatus} text={t(textByScore)} />
    </HeaderContainer>
  );
};
