/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import Pool from '_hocs/Cognito/Auth';
import { config } from '../config/config';
import { history } from '../_helpers/history';
import { authConstants } from '_constants';
import { store } from '_helpers/store';
import { mixpanelEvents } from './utils/MixPanel/mixpanelConfig';
import { loadStateFromLocalStorage, saveStateToLocalStorage } from 'utils/localStorage';
import { ERROR_MESSAGES } from 'utils/token/localStorage';
import { AppRoutes } from 'utils/routing/consts';

const baseURL = config.apiUrl;

export const HttpClientService = {
  async post<T>(url: string, data?: any, options: AxiosRequestConfig = {}): Promise<AxiosResponse<T, any>> {
    return HttpClientService.send<T>({
      ...options,
      url,
      baseURL,
      data,
      method: 'post',
    });
  },

  async put<T>(url: string, data: any, options: AxiosRequestConfig = {}): Promise<AxiosResponse<T, any>> {
    return HttpClientService.send<T>({
      ...options,
      url,
      baseURL,
      data,
      method: 'put',
    });
  },
  async get<T>(url: string, options: AxiosRequestConfig = {}): Promise<AxiosResponse<T, any>> {
    return HttpClientService.send<T>({
      ...options,
      url,
      baseURL,
      method: 'get',
    });
  },

  async delete<T>(url: string, options: AxiosRequestConfig = {}, data?: any): Promise<AxiosResponse<T, any>> {
    return HttpClientService.send<T>({
      ...options,
      url,
      baseURL,
      data,
      method: 'delete',
    });
  },

  async patch<T>(url: string, data: any, options: AxiosRequestConfig = {}): Promise<AxiosResponse<T, any>> {
    return HttpClientService.send<T>({
      ...options,
      url,
      baseURL,
      data,
      method: 'patch',
    });
  },

  logout: () => {
    const user = Pool.getCurrentUser();
    const firstTimeLoginFlag = loadStateFromLocalStorage(authConstants.FIRST_TIME_LOGIN);
    localStorage.clear();
    saveStateToLocalStorage(authConstants.FIRST_TIME_LOGIN, firstTimeLoginFlag);
    if (user) {
      user.signOut();
    }
  },

  setupInterceptors: (() => {
    axios.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: any) => {
        console.error(`HTTP error - status: ${error?.response?.status || 'Unknown'}, Message: ${error?.response?.data?.message || 'No message available'}`);

        if (error?.response?.data?.message === ERROR_MESSAGES.UNAUTHORIZED_ACCESS) {
          store.dispatch(mixpanelEvents.logoutAuto({ message: 'automatic logout' }));
          HttpClientService.logout();
          history.push(AppRoutes.LOGIN);
          window.location.reload();
        }
        throw new Error(error.response.data.message);
      },
    );
  })(),

  async send<T>(httpOptions: AxiosRequestConfig): Promise<AxiosResponse<T, any>> {
    // Make a copy of the options object, to avoid modifying the original
    const options = { ...httpOptions };

    // Make the request using Axios
    return axios(options);
  },
};
