import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';

import { TangiIconButton, TangiButton, TangiSvgIcon } from '../TangiLibrary';
import AcknowledgementModalSkeleton from '../AcknowledgementModalSkeleton/AcknowledgementModalSkeleton';
import useAssetAttachmentsInfo from '_hooks/useAssetAttachmentsInfo';
import { BUTTON_VARIANTS } from '../../utils/componentUtils';
import { formatDate } from '../../utils/dateUtils';
import { acknowledgementActions } from '../../_actions/acknowledgementActions';
import { mixpanelEvents } from '_services/utils/MixPanel/mixpanelConfig';
import { EventSource } from '_services/utils/MixPanel/const';
import { StyledModal, Divider } from '../../utils/globalStyles';
import { FileNameText, NoFilesText, AssetFileNum } from '../../pages/ClientAssets/styles';
import {
  ModalContentContainer,
  StyledModalBody,
  StyledModalHeader,
  TitleContainer,
  SubTitle,
  RequestContainer,
  AssetRequest,
  RequestBody,
  AssetName,
  AssetFileContainer,
  AssetFileIconAndName,
  SharedByContainer,
  StyledCheckbox,
  ConfirmationText,
  StyledInput,
  StyledModalFooter,
  ManageAllText,
} from './style';

const AcknowledgementModal = ({ isOpen, onClose, isSingleRequest, items, singleItem, handleManageAllRequests, loading }) => {
  const activeAccount = useSelector((state) => state.authentication.activeAccount);

  const acknowledgementLoading = useSelector((state) => state.acknowledgementReducer.acknowledgementLoading);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (isSingleRequest && singleItem?.id) {
      setSelectedAssets([singleItem.id]);
    }
    if (!isSingleRequest && items && items.length) {
      const arrayOfIds = items.map((item) => (item = item.id));
      setSelectedAssets(arrayOfIds);
    }
  }, [isSingleRequest, singleItem, items]);

  const handleClose = () => {
    setInputValue('');
    onClose();
  };

  const handleManageAllClick = () => {
    setInputValue('');
    handleManageAllRequests();
  };

  const renderConfirmationTextMultiple = (selectedAmount, totalAmount) => {
    return (
      <ConfirmationText>
        <Trans t={t} i18nKey="ASSET_PAGE.CONTENT.CONFIRMATION_TEXT" values={{ AMOUNTS: `${selectedAmount}/${totalAmount}` }} />
      </ConfirmationText>
    );
  };

  const updateAcknowledgement = () => {
    dispatch(acknowledgementActions.updateRecipientsAcknowledgment({ accountId: activeAccount._id, assets: selectedAssets }));
    setInputValue('');
    const source = EventSource.FromSystem;
    dispatch(
      mixpanelEvents.acknowledgementAsset({
        accountId: activeAccount._id,
        selectedAssets,
        source,
      }),
    );
  };

  return (
    <StyledModal transition="Fade" show={isOpen} onHide={handleClose} data-testid="acknowledgement-modal">
      <ModalContentContainer>
        <StyledModalHeader>
          <TitleContainer>
            <div>{t('ASSET_PAGE.TITLE.CONFIRMATION_IS_REQUIRED')}</div>
            <TangiIconButton variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={handleClose} icon="close" />
          </TitleContainer>
          <SubTitle>{t('ASSET_PAGE.TITLE.INFORMATION_IS_CONFIDENTIAL')}</SubTitle>
        </StyledModalHeader>
        <StyledModalBody>
          {isSingleRequest && !loading && (
            <>
              <SignatureRequest asset={singleItem} account={activeAccount} isSingleRequest={isSingleRequest} />
              <ConfirmationText>{t('ASSET_PAGE.CONTENT.SINGEL_REQUEST_CONFIRMATION_TEXT')}</ConfirmationText>
              <StyledInput data-testid="acknowledgement-modal-input" type="text" placeholder={t('ASSET_PAGE.INPUT.FULL_NAME')} value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
              <ManageAllText data-testid="manage-all-requests" onClick={handleManageAllClick}>
                {t('ASSET_PAGE.CONTENT.MANAGE_SIGNATURE_REQUESTS')}
              </ManageAllText>
            </>
          )}
          {!isSingleRequest && !loading && (
            <>
              <RequestContainer>
                {items?.map((item, index) => (
                  <SignatureRequest asset={item} key={index} selectedAssets={selectedAssets} setSelectedAssets={setSelectedAssets} account={activeAccount} isSingleRequest={isSingleRequest} />
                ))}
              </RequestContainer>
              {renderConfirmationTextMultiple(selectedAssets?.length, items?.length)}
              <StyledInput data-testid="acknowledgement-modal-input" type="text" placeholder={t('ASSET_PAGE.INPUT.FULL_NAME')} value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
            </>
          )}
          {loading && <AcknowledgementModalSkeleton />}
        </StyledModalBody>
        <StyledModalFooter>
          <Divider />
          <TangiButton variant="tertiary" text={t('GENERAL.BUTTONS_TEXT.CANCEL')} size="small" onClick={handleClose} disabled={isEmpty(items)} smallbtn={true} data-testid="cancel-signature-request" />
          <TangiButton
            text={t('GENERAL.BUTTONS_TEXT.CONFIRM')}
            size="small"
            loading={acknowledgementLoading}
            disabled={isEmpty(selectedAssets) || isEmpty(inputValue)}
            onClick={updateAcknowledgement}
            data-testid="confirm-signature-request"
            smallbtn={true}
          />
        </StyledModalFooter>
      </ModalContentContainer>
    </StyledModal>
  );
};

const SignatureRequest = ({ asset, selectedAssets, setSelectedAssets, account, isSingleRequest }) => {
  const { attachmentName, attachmentIconType, attachmentAdditionalAmount, isAttachmentFile, isAttachmentEmpty } = useAssetAttachmentsInfo({
    filesArr: asset?.files,
    urlsArr: asset?.urls,
  });
  const sharedWithMeSingleAsset = isSingleRequest ? asset?.recipients?.filter((recipient) => recipient?.account?._id === account._id)[0] : null;
  const { t } = useTranslation();

  const isItemSelected = (id) => {
    return !!selectedAssets.find((elem) => elem === id);
  };

  const handleSelect = (item) => {
    const isFound = isItemSelected(item.id);
    if (isFound) {
      setSelectedAssets((state) => state.filter((elem) => elem !== item.id));
    } else {
      setSelectedAssets((state) => [...state, item.id]);
    }
  };

  const renderSharedByInfo = () => {
    return (
      <SharedByContainer>
        {isSingleRequest ? (
          <>
            <span>
              {t('ASSET_PAGE.CONTENT.SHARED_ON')} {formatDate(sharedWithMeSingleAsset?.sharedOn ?? '', 'DD-MMM-YYYY HH:mm')}
            </span>
            <span>
              {t('ASSET_PAGE.CONTENT.BY', {
                SHARED_BY: sharedWithMeSingleAsset?.sharedBy?.isActive ? sharedWithMeSingleAsset?.sharedBy?.displayName || '' : sharedWithMeSingleAsset?.sharedBy?.user?.email || '',
              })}
            </span>
          </>
        ) : (
          <>
            <span>
              {t('ASSET_PAGE.CONTENT.SHARED_ON')} {formatDate(asset.recipient.sharedOn, 'DD-MMM-YYYY HH:mm')}
            </span>
            <span>{t('ASSET_PAGE.CONTENT.BY', { SHARED_BY: asset.recipient.sharedBy.isActive ? asset.recipient.sharedBy.displayName : asset.recipient.sharedBy.user.email })}</span>
          </>
        )}
      </SharedByContainer>
    );
  };

  return (
    <AssetRequest
      onClick={() => {
        if (isSingleRequest) return;
        handleSelect(asset);
      }}
      isWithCheckbox={!isSingleRequest}
      isSelected={isSingleRequest ? false : isItemSelected(asset.id)}
      data-testid="signature-request"
    >
      {!isSingleRequest && <StyledCheckbox checked={isItemSelected(asset.id)} type="checkbox" readOnly data-testid="acknowledgement-modal-checkbox" />}
      <RequestBody>
        <AssetName>{asset.name}</AssetName>
        <AssetFileContainer>
          <AssetFileIconAndName>
            {!isAttachmentEmpty && <TangiSvgIcon component={attachmentIconType} />}
            {isAttachmentEmpty ? <NoFilesText>{attachmentName}</NoFilesText> : <FileNameText className={isAttachmentFile && 'file-attachment-name'}>{attachmentName}</FileNameText>}
          </AssetFileIconAndName>
          {!!attachmentAdditionalAmount && <AssetFileNum>{`+ ${attachmentAdditionalAmount}`}</AssetFileNum>}
        </AssetFileContainer>
        {renderSharedByInfo()}
      </RequestBody>
    </AssetRequest>
  );
};

export default AcknowledgementModal;
