import styled, { css } from 'styled-components';
import { Tabs } from 'react-bootstrap';

import { NEUTRAL_SHADES, PRIMARY_SHADES, SPACING } from 'utils/theme';
import { watermark } from '../../assets/img';

export const TypeAndColor = styled.div`
  display: flex;
  gap: ${SPACING[3]};
  font-size: ${SPACING[3]};
  align-items: center;
  margin-top: ${SPACING[2]};
`;

export const TabsContainer = styled.div`
  margin-top: ${SPACING[4]};
  .active {
    &:hover {
      color: ${NEUTRAL_SHADES.BLACK} !important;
    }
  }
`;

export const StyledTabs = styled(Tabs)`
  border-bottom: 1px solid ${NEUTRAL_SHADES[500]};
  & .nav-item .nav-link {
    border-color: none;
    padding: ${SPACING[2]};
    color: ${NEUTRAL_SHADES[600]};
    font-family: 'DIN2014-Regular';
    width: 144px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      border: 1px solid ${NEUTRAL_SHADES[50]};
      border-bottom: 1px solid ${NEUTRAL_SHADES[500]};
      color: ${NEUTRAL_SHADES[800]};
    }
    &.active:hover {
      color: ${PRIMARY_SHADES[800]} !important;
    }
  }

  & .nav-item .nav-link.active {
    border-color: none;
    border: 1px solid ${NEUTRAL_SHADES[50]};
    border-bottom: 2px solid ${PRIMARY_SHADES[800]};
    color: ${PRIMARY_SHADES[800]};
    font-family: 'DIN2014-DemiBold';
    background: ${NEUTRAL_SHADES[50]};
  }
`;

export const WatermarkContainer = styled.div<{ clientName: string }>`
  ${({ clientName }) => css`
    position: relative;

    &::before {
      position: absolute;
      content: '';
      display: inline-block;
      background-image: url(${watermark});
      background-repeat: no-repeat;
      background-size: 100%;
      opacity: 0.4;
      width: 50%;
      height: 100%;
      z-index: 999;
      top: 5px;
      left: 10%;
      pointer-events: none;
    }

    &::after {
      font-family: 'BankGothic Md BT';
      position: absolute;
      display: flex;
      justify-content: center;
      content: 'PROPERTY OF ${clientName}';
      opacity: 0.4;
      font-size: 1em;
      top: 22px;
      left: 49%;
      width: 50%;
      height: 100%;
      z-index: 999;
      pointer-events: none;
      @media screen and (max-width: 1000px) {
        font-size: 0.9em;
        top: ${SPACING[3.5]};
      }
    }
  `}
`;
