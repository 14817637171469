import styled from 'styled-components';

export const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  font-family: 'DIN2014-Regular';
`;

export const ImgCourse = styled.img`
  border-radius: 2px;
  width: 15%;
  height: 12%;
`;

export const CourseName = styled.div`
  width: 78%;
  max-width: 260px;
`;

export const StatusIcon = styled.div`
  width: 7%;
`;
