import React from 'react';
import { Form } from 'react-bootstrap';

function Star() {
  return (
    <Form.Label className="text-danger" style={{marginLeft:'5px'}}>*</Form.Label>
  );
}

export { Star };
