export enum ERROR_MESSAGES {
  UNAUTHORIZED_ACCESS = 'Unauthorized access, please login',
  API_KEY_EXPIRED = 'API Key Expired',
}

export const LOCAL_STORAGE_KEYS = {
  API_KEY: 'apiKey',
  USER: 'user',
  JWT_TOKEN: 'jwtToken',
  ROLE: 'Role',
  PERMISSION: 'permission',
  OKTA_USER_TOKENS: 'oktaUserTokens',
};
