import { LOBBY_CARD_ID } from 'pages/Lobby/types';
import { generateRoute } from 'utils/routing/generateRoute';
import { AppRoutes } from 'utils/routing/consts';

export const getUrlByCardId = (cardId: LOBBY_CARD_ID, clientId: string): string => {
  const urlMapping = {
    [LOBBY_CARD_ID.PREDICTED_PORTFOLIO]: generateRoute(AppRoutes.PREDICTED_PORTFOLIO, { clientId }),
    [LOBBY_CARD_ID.COMPETITIVE_INTELLIGENCE]: generateRoute(AppRoutes.COMPETITIVE_ANALYSIS, { clientId }),
    [LOBBY_CARD_ID.CONTINUATION_ANALYSIS]: generateRoute(AppRoutes.CONTINUATION_ANALYSIS_MAIN_PAGE, { clientId }),
    [LOBBY_CARD_ID.PATENT_X_RAY]: generateRoute(AppRoutes.PATENT_X_RAY_MAIN_PAGE, { clientId }),
    [LOBBY_CARD_ID.INVENTION_DISCLOSURE]: generateRoute(AppRoutes.INVENTION_DISCLOSURE_MAIN_PAGE, { clientId }),
    [LOBBY_CARD_ID.ASSETS_CATALOG]: generateRoute(AppRoutes.ASSETS_MAIN_PAGE, { clientId }),
    [LOBBY_CARD_ID.ADD_EMPLOYEES]: generateRoute(AppRoutes.PEOPLE, { clientId }),
    [LOBBY_CARD_ID.TRACK_PROGRESS]: generateRoute(AppRoutes.PEOPLE, { clientId }),
    [LOBBY_CARD_ID.LEARN]: generateRoute(AppRoutes.TRAINING, { clientId }),
    [LOBBY_CARD_ID.SELECT_COURSES]: generateRoute(AppRoutes.TRAINING, { clientId }),
    [LOBBY_CARD_ID.COLLABORATE_WITH_PARTNERS]: generateRoute(AppRoutes.HOME_LOBBY, { clientId }),
    [LOBBY_CARD_ID.DISCOVER]: generateRoute(AppRoutes.HOME_LOBBY, { clientId }),
    [LOBBY_CARD_ID.MANAGE]: generateRoute(AppRoutes.HOME_LOBBY, { clientId }),
  };

  return urlMapping[cardId];
};
