export enum MixpanelEventNames {
  Login = 'Login',
  InviteAccount = 'Invite Account',
  CreateAsset = 'Create Asset',
  Logout = 'Logout',
  ExtractTradeSecret = 'Extract Trade Secret',
  XraySearch = 'Patent Search',
  DeleteAsset = 'Delete Asset',
  EditAsset = 'Edit Asset',
  AcknowledgementAsset = 'Asset Acknowledgement',
  CloseAsset = 'Closed Asset',
  PrivilegeLogDownload = 'Privilege log download',
  ShareAsset = 'Notify Asset',
  FirstTimeLogin = 'First Time Login',
  ContractXray = 'Contract Xray',
  CustomEvent = 'Custom Event',
  AssetFilePreview = 'Asset File Preview',
  QuizCompleted = 'Quiz Completed',
  QuizStarted = 'Quiz Started',
  LogoutActive = 'Active Log Out',
  LogoutAuto = 'Auto Log Out',
  DownloadPolicy = 'Policy Downloaded',
  InventionSearch = 'Invention Disclosure Search',
  ContinuationSearch = 'Continuation Analysis Search',
  RequestAccess = 'Request Access',
  EnterPredictedPage = 'Enter Predicted Portfolio Page',
  EnterCompetitivePage = 'Enter Competitive Page',
}

export const X_RAY_TOOLS = {
  MAPPING: 'Mapping Tool',
  EXTRACTION: 'Extraction Tool',
};

export enum EventSource {
  FromSystem = 'From System',
  FromEmail = 'From Email',
}
export const Environment = {
  DEVELOPMENT: 'develop',
  STAGE: 'stage',
  PRODUCTION: 'production',
};

export const TRACK_MIXPANEL_EVENT = 'TRACK_MIXPANEL_EVENT';

export const DEMO_ACCOUNT_NAMES = [
  'Matthews Demo',
  'Tangibly Demo',
  'Client Demo',
  'Calgon Carbon Demo',
  'Moveo_Demo',
  'MoveoLawFirm_Client1',
  'prodTestClient',
  'prodTestClient4',
  'test client',
  'prodTestClient9',
  'test client 1',
  'test client 3',
  'TimDemoCompany',
  'TangiblyDemo',
  'ClientTest1',
  'MoveoTest',
  'TangiblyOktaTest',
  'DemoLearnClient',
  'Client 2 Demo C',
  'LiatTest',
  'TestAug23',
  'Appolo',
];
