/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import { UpdateRequiredCoursesParams, JoinDiscoveryEnquiryParams, ClientRequestParams, UploadFilesParams, ClientLanguage, PolicyType, OptionalIpAuditData } from 'utils/types/client/client';
import { headers, headersmultipart } from '_services/utils/headers';
import { config } from '../../config/config';
import { HttpClientService } from '../../_services/HttpClientService';
import { LANGUAGE, LANGUAGES } from 'translations/enums';
import { UPLOAD_FILE_TYPES, ENTITY_TYPES } from 'utils/enums';
import { IpAuditData } from '_components/IpAudit/types';

class ClientAdapter {
  static clientEndpoint = `${config.apiUrl}${config.apiVersion}/client`;

  static baseEndPoint = `${config.apiUrl}${config.apiVersion}`;

  static fileEndpoint = `${config.apiUrl}${config.apiVersion}/file`;

  // Default Params
  static defaults = {
    PAGE: 1,
    LIMIT: 1000,
    SORTBY: 'createdAt:asc',
  };

  async getAgreementVersions({ client, page = ClientAdapter.defaults.PAGE, limit = ClientAdapter.defaults.LIMIT, sortBy = ClientAdapter.defaults.SORTBY }: any) {
    const res = await HttpClientService.get(`${ClientAdapter.baseEndPoint}/agreementVersions`, {
      headers: headers(),
      params: {
        client,
        page,
        limit,
        sortBy,
      },
    });
    return res.data;
  }

  async getClientById(clientId: string) {
    const res = await HttpClientService.get(`${ClientAdapter.clientEndpoint}/${clientId}`, { headers: headers() });
    return res.data;
  }

  async updateRequiredCourses({ clientId, updatedCourses }: UpdateRequiredCoursesParams) {
    const res = await HttpClientService.put(`${ClientAdapter.clientEndpoint}/${clientId}/requiredCourses`, updatedCourses, { headers: headers() });
    return res.data;
  }

  async createAgreementVersion(agreement: any) {
    const res = await HttpClientService.post(`${ClientAdapter.baseEndPoint}/agreementVersion`, agreement, { headers: headers() });
    return res.data;
  }

  async joinDiscoveryEnquiry({ name, email, clientName, role }: JoinDiscoveryEnquiryParams) {
    const res = await HttpClientService.post(`${ClientAdapter.baseEndPoint}/client/joinDiscovery`, { name, email, clientName, role }, { headers: headers() });
    return res.data;
  }

  async getInsights({ clientId }: ClientRequestParams) {
    const res = await HttpClientService.get(`${ClientAdapter.baseEndPoint}/client/get_insights/${clientId}`, { headers: headers() });
    return res.data;
  }

  async uploadFiles({ clientId, policyFiles, type, entityType }: Omit<UploadFilesParams, 'isLawyer' | 'accountId'>) {
    const formData: any = new FormData();
    formData.append('file', policyFiles[0]);
    formData.append(0, policyFiles[0].name);
    const res = await HttpClientService.post(`${ClientAdapter.fileEndpoint}/file_upload`, formData, {
      headers: headersmultipart(),
      params: { clientId: clientId, id: clientId, type: type, entityType },
    });
    return res.data;
  }

  async downloadPolicy(fileName: string) {
    const urlForFile = '/authenticated/path';
    const reqHeaders = headers();
    const res = await HttpClientService.get(`${ClientAdapter.fileEndpoint}${urlForFile}`, {
      headers: reqHeaders,
      params: { fileName, type: UPLOAD_FILE_TYPES.POLICY, entityType: ENTITY_TYPES.ACCOUNTS, id: reqHeaders.ClientId },
    });
    return res.data;
  }

  async deletePolicy(clientId: string, type: PolicyType) {
    const res = await HttpClientService.delete(`${ClientAdapter.fileEndpoint}/deletePolicy/${clientId}?type=${type}`, {
      headers: headers(),
    });
    return res.data;
  }

  async updateClientLanguage(data: ClientLanguage) {
    const res = await HttpClientService.patch(
      `${ClientAdapter.baseEndPoint}/client/${data.clientId}/updateLanguage`,
      { language: LANGUAGES.includes(data.language) ? data.language : LANGUAGE.ENGLISH },
      { headers: headers() },
    );
    return res.data;
  }

  async updateClientIpAudit(clientId: string, data: OptionalIpAuditData): Promise<{ ipAuditData: IpAuditData }> {
    const res = await HttpClientService.patch<{ ipAuditData: IpAuditData }>(`${ClientAdapter.baseEndPoint}/client/${clientId}/updateIpAudit`, data, { headers: headers() });
    return res.data;
  }
}

const clientAdapter = new ClientAdapter();
export default clientAdapter;
