import './Header.scss';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { MobileHeader } from './MobileHeader';
import { AuthContext } from '../../_hocs/Cognito/Auth';
import { history } from '../../_helpers';

function AdminHeader({ title }) {
  const { logout } = useContext(AuthContext);

  const auth = useSelector((state) => state.authentication);
  const dispatch = useDispatch();

  function handleLogout() {
    dispatch(logout());
    history.push('/login');
    window.location.reload();
  }

  return (
    <>
      <header>
        <Navbar collapseOnSelect expand="lg" bg="white" variant="light" className="navbar-expand-md navbar-top border-bottom" fixed="top">
          <Container className="pt-1 d-none d-sm-none d-md-block">
            <Navbar id="responsive-navbar-nav">
              <h3 className="h2 mb-0 text-dark">{title}</h3>
              <Nav className="me-auto" />
              <Nav>
                {/* <NavDropdown title={<i className="far fa-bell" aria-hidden="true" />} id="nav-dropdown">
                  <NavDropdown.Item eventKey="4.1">Action</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item eventKey="4.4">Separated link</NavDropdown.Item>
                </NavDropdown> */}
                <NavDropdown
                  className="profile-dropdown-toggle"
                  title={
                    <span style={{ fontWeight: '300', fontSize: '15px' }} className="ff-din-regular">
                      Welcome Back,{' '}
                      <span style={{ fontWeight: '300', fontSize: '15px' }} className="ff-din-bold">
                        {auth?.user?.username}
                      </span>
                    </span>
                  }
                  id="nav-dropdown1"
                >
                  {/* <NavDropdown.Item className="ff-din-regular-2 fw-bolder header-dropdown" href="/my-profile" eventKey="4.1">
                    My Profile
                  </NavDropdown.Item> */}
                  <NavDropdown.Item className="ff-din-regular fw-bolder text-muted header-dropdown" eventKey="4.2" onClick={handleLogout}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar>
          </Container>
          <Container fluid className="py-1 d-sm-none d-md-none d-lg-none d-xl-none" id="MobileHeaderView">
            <MobileHeader />
          </Container>
          <Container fluid className="py-1 d-none d-sm-block d-md-none d-lg-none d-xl-none" id="MobileHeaderView">
            <MobileHeader />
          </Container>
        </Navbar>
      </header>
    </>
  );
}

export { AdminHeader };
