import { TangiButton } from '_components/TangiLibrary/TangiButton/TangiButton';
import { Container } from './styles';
import { useTranslation } from 'react-i18next';
import { useHandleSteps } from '_components/TangiLibrary/TangiStepper/useHandleSteps';
import { useIpAudit } from '_components/IpAudit/useIpAudit';

interface StepFooterProps {
  onSubmit: () => void;
  isContinueBtnDisabled?: boolean;
  isContinueBtnLoading?: boolean;
  onContinueText?: string;
}

export const StepFooter = ({ onSubmit, onContinueText, isContinueBtnDisabled = false, isContinueBtnLoading = false }: StepFooterProps) => {
  const { t } = useTranslation();
  const { handleStepChange, currentStepKey } = useHandleSteps();
  const { getNextStepKey, isIpAuditStepKey } = useIpAudit();

  const isKeyValid = isIpAuditStepKey(currentStepKey);

  const onSkip = () => {
    moveToNextStep(true);
  };

  const moveToNextStep = (isMoveToFirstStep = false) => {
    const nextStepKey = isKeyValid ? getNextStepKey(currentStepKey, isMoveToFirstStep) : null;
    if (nextStepKey) {
      handleStepChange(nextStepKey);
    }
  };

  return (
    <Container>
      <TangiButton variant="tertiary-grey" text={t('GENERAL.BUTTONS_TEXT.SKIP')} onClick={onSkip} smallbtn />
      <TangiButton text={t(onContinueText ?? 'GENERAL.BUTTONS_TEXT.SAVE_AND_CONTINUE')} onClick={onSubmit} smallbtn disabled={isContinueBtnDisabled} loading={isContinueBtnLoading} />
    </Container>
  );
};
