import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en } from './translations/en';
import { ja } from './translations/ja';
import { useSelector } from 'react-redux';
import { RootState } from '_helpers';
import { LANGUAGE } from 'translations/enums';
import { IRoles } from 'utils/roles';
import { useEffect, useState } from 'react';

const resources = { en, ja };

function I18nConfig() {
  const userLanguage = useSelector((state: RootState) => state.authentication.user?.language);
  const activeAccount = useSelector((state: RootState) => state.authentication.activeAccount);
  const Role = useSelector((state: RootState) => state.authentication.Role);
  const activeClient = useSelector((state: RootState) => state.lawfirm.activeClient);

  const [selectedLanguage, setSelectedLanguage] = useState('');

  useEffect(() => {
    let languageByRole;

    if (Role === IRoles.LAWYER) {
      languageByRole = userLanguage ?? activeClient?.language ?? LANGUAGE.ENGLISH;
    } else {
      languageByRole = userLanguage ?? activeAccount?.client?.language ?? LANGUAGE.ENGLISH;
    }

    setSelectedLanguage(languageByRole);
  }, [userLanguage, activeAccount, Role, activeClient]);

  i18n.use(initReactI18next).init({
    resources,
    lng: selectedLanguage || LANGUAGE.ENGLISH,
    interpolation: {
      escapeValue: false,
    },
  });

  return null;
}

export default I18nConfig;
