import { SystemSecurityType } from '../types';
import { TangiSvgIcon } from '_components/TangiLibrary/TangiSvgIcon/TangiSvgIcon';
import { IconTypes, TangiCheckbox } from '_components/TangiLibrary';
import { CheckboxWithIconCard } from './styles';

interface Props {
  systemOption: SystemSecurityType;
  isSelected: boolean;
  toggleSelection: (option: SystemSecurityType) => void;
}

export const SecurityCard = ({ systemOption, isSelected, toggleSelection }: Props) => {
  return (
    <CheckboxWithIconCard onClick={() => toggleSelection(systemOption)}>
      <TangiCheckbox type="checkbox" data-cy={`checkbox-${systemOption}`} checked={isSelected} readOnly name='' />
      <TangiSvgIcon component={systemOption as IconTypes} color="" />
    </CheckboxWithIconCard>
  );
};
