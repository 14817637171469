import { Dispatch } from 'redux';
import { Document, Packer, Paragraph, TextRun, BookmarkStart, BookmarkEnd, InternalHyperlink } from 'docx';
import { saveAs } from 'file-saver';

import { patentActions } from 'redux-toolkit/slices/patentSlice';
import { ContinuationAnalysisResultsResponse, ETradeSecretCategory, Mapping, Subject, TradeSecretEntry } from './types/patent/patent';
import { XRAY_JOB_STATUS } from './types/xRay/xRay';

// Function to create a Word document
// TODO: change the any inside the Dispatch<any>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createWordDocument = (data: ContinuationAnalysisResultsResponse, patentNumber: string, dispatch: Dispatch<any>) => {
  dispatch(patentActions.setContinuationExportFileStatus({ fileType: 'word', status: XRAY_JOB_STATUS.STARTED }));
  const validTradeSecrets = data.subjects.flatMap((subject: Subject) =>
    subject.tradeSecrets.filter(
      (tradeSecret: TradeSecretEntry) => tradeSecret?.category === ETradeSecretCategory.ContinuationCandidates && tradeSecret?.suggestedClaims && tradeSecret?.suggestedClaims?.length > 0,
    ),
  );
  if (!validTradeSecrets.length) {
    // If there are no valid trade secrets, dispatch an action to update the export checkbox status to 'FAILED' and return early that doesn't download the word document
    dispatch(patentActions.setContinuationExportFileStatus({ fileType: 'word', status: XRAY_JOB_STATUS.FAILED }));
    return;
  }

  const doc = new Document({
    styles: {
      paragraphStyles: [
        {
          id: 'Title',
          name: 'Title',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            size: 42,
            color: '000000',
            font: 'Roboto',
          },
        },
        {
          id: 'Heading1',
          name: 'Heading 1',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            size: 36, // font size 18pt (36 half-points)
            font: 'Roboto',
          },
        },
        {
          id: 'Heading3',
          name: 'Heading 3',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            size: 28, // font size 14pt (28 half-points)
            font: 'Roboto',
            bold: true,
            color: '666666', // Grey color
          },
        },
        {
          id: 'Heading4',
          name: 'Heading 4',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            size: 24, // font size 12pt (24 half-points)
            font: 'Roboto',
            color: '666666', // Grey color
          },
        },
        {
          id: 'Normal',
          name: 'Normal',
          run: {
            font: 'Roboto',
          },
        },
        {
          id: 'Quote',
          name: 'Quote',
          basedOn: 'Normal',
          next: 'Normal',
          run: {
            italics: true,
            font: 'Roboto',
          },
        },
        {
          id: 'AppendixTitle',
          name: 'Appendix Title',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            size: 44,
            font: 'Arial',
          },
        },
      ],
    },
    sections: [
      {
        children: [
          new Paragraph({
            text: `Continuation Analysis - Suggested Claims for Patent ${patentNumber}`,
            heading: 'Title',
          }),
          new Paragraph({
            text: '',
            spacing: { after: 200 },
          }),
          ...validTradeSecrets.flatMap((tradeSecret: TradeSecretEntry, index: number) => {
            const candidateCounter = index + 1;
            const bookmarkName = `bookmark-${candidateCounter}`;
            return [
              new Paragraph({
                children: [
                  //   new BookmarkStart(bookmarkName, candidateCounter),
                  new TextRun({
                    text: `Potential Continuation Claim Candidate ${candidateCounter}`,
                  }),
                  //   new BookmarkEnd(candidateCounter),
                ],
                heading: 'Heading1',
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${tradeSecret.suggestedClaims}`,
                    break: 1,
                    font: 'Roboto',
                    size: 22,
                  }),
                  new TextRun({
                    text: '',
                    break: 2,
                  }),
                  new InternalHyperlink({
                    children: [
                      new TextRun({
                        text: 'Link to Spec Mapping',
                        font: 'Roboto',
                        size: 20,
                        color: '4F81BD', // Dark Cornflower Blue 2
                      }),
                    ],
                    anchor: bookmarkName,
                  }),
                ],
              }),
              new Paragraph({
                text: '',
                spacing: { after: 200 },
              }),
            ];
          }),
        ],
      },
      {
        children: [
          new Paragraph({
            text: 'Appendix - Spec Mapping',
            style: 'AppendixTitle',
          }),
          new Paragraph({
            text: '',
            spacing: { after: 200 },
          }),
          ...validTradeSecrets.flatMap((tradeSecret: TradeSecretEntry, index: number) => {
            const bookmarkName = `bookmark-${index + 1}`;
            return [
              new Paragraph({
                children: [
                  new BookmarkStart(bookmarkName, index + 1),
                  new TextRun({
                    text: `Potential Continuation Candidate ${index + 1}`,
                  }),
                  new BookmarkEnd(index + 1),
                ],
                heading: 'Heading1',
              }),
              new Paragraph({
                text: '',
                spacing: { after: 200 },
              }),
              new Paragraph({
                text: 'Trade Secret',
                style: 'Heading3',
              }),
              new Paragraph({
                text: '',
                spacing: { after: 200 },
              }),
              new Paragraph({
                text: tradeSecret.title,
                style: 'Normal',
                spacing: { after: 200 },
              }),
              ...tradeSecret.mappings.flatMap((mapping: Mapping, mappingIndex) => [
                new Paragraph({
                  text: `Spec Mapping ${mappingIndex + 1}`,
                  style: 'Heading4',
                }),
                new Paragraph({
                  text: '',
                  spacing: { after: 200 },
                }),
                new Paragraph({
                  text: mapping.text,
                  style: 'Normal',
                }),
                new Paragraph({
                  text: `Score: ${mapping.score !== null ? mapping.score.toFixed(2).toString() : 'N/A'}`,
                  style: 'Normal',
                  spacing: { after: 200 },
                }),
              ]),
            ];
          }),
        ],
      },
    ],
  });

  Packer.toBlob(doc)
    .then((blob: Blob) => {
      saveAs(blob, `ContinuationAnalysisWord ${patentNumber}.docx`);
      dispatch(patentActions.setContinuationExportFileStatus({ fileType: 'word', status: XRAY_JOB_STATUS.SUCCEEDED }));
    })
    .catch(() => {
      dispatch(patentActions.setContinuationExportFileStatus({ fileType: 'word', status: XRAY_JOB_STATUS.FAILED }));
    });
};
