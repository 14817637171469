export const ja = {
  translation: {
    LOBBY_PAGE: {
      TITLE: 'Tangiblyへようこそ',
      SUB_TITLE: '営業秘密の探索をどのように始めるか選ぶ。',
      DISCOVER_AND_PROTECT: '営業秘密の発見、文書化、保護',
      RECONNECT: 'システムへの接続が切断されました。再度接続が必要です。',
      GO_TO_HOMEPAGE: 'ホームへ行く',
      LOGIN: 'ログイン',
      CHIP: {
        NEW: '最新',
      },
      CARDS: {
        PREDICTED_PORTFOLIO: {
          TITLE: 'ポートフォリオ予測™',
          SUB_TITLE: '特許ポートフォリオの中に埋もれている知的財産を明らかにする。これらの営業秘密は管理すべき重要なアセットです。',
        },
        COMPETITIVE_INTELLIGENCE: {
          TITLE: '競合分析',
          SUB_TITLE: '競合他社の営業秘密を予測して優位性を獲得する。',
        },
        CONTINUATION_ANALYSIS: {
          TITLE: '継続解析',
          SUB_TITLE: '継続出願ドラフトを簡易に作成し、特許からさらなる価値を抽出する。',
        },
        PATENT_X_RAY: {
          TITLE: '特許Xレイ',
          SUB_TITLE: '特許に関連する潜在的な営業秘密をAIで予測する。',
        },
        INVENTION_DISCLOSURE: {
          TITLE: '発明の開示',
          SUB_TITLE: '特許の請求範囲と営業秘密を並べて比較し、情報に基づいて特許出願に関する意思決定を行う。',
        },
        MANAGE: {
          TITLE: '管理する。',
          SUB_TITLE: 'アセットの分類、アクセスの制限、契約内容の確認、従業員のトレーニングにより、ベストプラクティスの基準を活用する。',
        },
        ASSETS_CATALOG: {
          TITLE: 'アセットの分類',
          SUB_TITLE: '営業秘密をそれ自体価値があるアセットとして扱う。',
        },
        ADD_EMPLOYEES: {
          TITLE: '従業員を追加する。',
          SUB_TITLE: '従業員によるアクセス、コンプライアンス、ベストプラクティスのトレーニングを追跡する。',
        },
        COLLABORATE_WITH_PARTNERS: {
          TITLE: '取引先と連携する。',
          SUB_TITLE: '必要なのは、取引先とのセンシティブな情報のやり取りを管理することです。',
        },
        LEARN: {
          TITLE: '知る。',
          SUB_TITLE: '営業秘密とは何か、どのように適切に扱うかについて従業員をトレーニングする。',
        },
        DISCOVER: {
          TITLE: '発見する。',
          SUB_TITLE: 'Tangibly の AI TangiblyのAIを活用して特許出願ワークフローを効率化し、営業秘密に関する洞察を明らかにする。',
        },
        SELECT_COURSES: {
          TITLE: 'コースを選択する。',
          SUB_TITLE: '従業員に必須あるいは推奨されるトレーニングコースを選べます。',
        },
        TRACK_PROGRESS: {
          TITLE: '進捗状況を追跡する。',
          SUB_TITLE: '必須のトレーニングを完了した従業員と、1つ以上のトレーニングが残っている従業員を容易に追跡できます。',
        },
      },
      REQUEST_ACCESS_MODAL: {
        DISCOVER: {
          HEADER: '発見する。',
          TITLE: 'TangiblyのXレイTMのプラットフォーム',
          SUB_TITLE: 'XレイTMは社内の法務チームと法律事務所向けに設計された完全統合型のAI搭載ツールで、営業秘密を特定しリスクの測定と管理を支援します。',
          POINT1: '特許XレイTMで営業秘密を特定する。',
          POINT2: '契約XレイTMで営業秘密の保護状況を評価する。',
          POINT3: 'EメールXレイTMでリスクを特定し、管理する。',
        },
        PREDICTED_PORTFOLIO: {
          HEADER: '予測ポートフォリオ',
          TITLE: 'Tangiblyのポートフォリオ予測ツール',
          SUB_TITLE: 'TangiblyのSaaSプラットフォームは、秘密情報を法的拘束力のある営業秘密として保護します。',
          POINT1: 'これまで認識していなかった営業秘密を発見する。',
          POINT2: '営業秘密ポートフォリオの長所と短所を把握する。',
          POINT3: '自身の営業秘密が競合他社にどのように比肩するかについて学ぶ。',
        },
        MANAGE: {
          HEADER: '管理する。',
          TITLE: 'Tangiblyの管理プラットフォーム',
          SUB_TITLE:
            'Tangiblyは間違いなく知的財産の最も重要なアセットクラスである営業秘密に特化した唯一のSaaSプラットフォームです。TangiblyのAIはあなたの補佐として、営業秘密の特定、リスクの測定・管理、特許プロセスへの新しい機能の追加を支援します。',
          POINT1: '営業秘密にラベルを付ける。',
          POINT2: '営業秘密へのアクセスを通知する／承認する。',
          POINT3: '従業員および取引先をトレーニングする。',
          POINT4: '取引先と安全に連携する。',
        },
        BUTTON_TEXT: 'アクセス権をリクエストする。',
      },
    },
    PARTNERSHIP_PAGE: {
      HEADERS: {
        PEOPLE: 'ピープル',
        ASSETS_SHARED_WITH_PARTNERS: 'パートナーと共有されたアセット',
        ASSETS_CREATED: '作成されたアセット',
        SUMMARY: '概要',
        DOCUMENTS: 'ドキュメント',
      },
      SUMMARY: {
        ONBOARDED_ON: '登録日:',
        CREATED_BY: '作成者',
        NUMBER_OF_EMPLOYEES: '従業員数：',
      },
      TABLE: {
        MISSING: '見つかりません',
        NO_ASSETS: 'アセットなし',
        NO_DOCUMENTS: 'ドキュメントなし',
        NO_PEOPLE_YET: 'まだピープルはいません',
      },
      TOOLTIP: {
        EDIT: '編集',
        DOWNLOAD_AGREEMENT: '契約書をダウンロードする',
      },
      DROPDOWN_BUTTON: {
        DEACTIVATE: '非アクティブ化を確認',
      },
      TOAST: {
        PARTNERSHIP_CREATED: 'パートナーが正常に作成されました',
        PARTNERSHIP_UPDATED: 'パートナーが正常に更新されました',
        PARTNERSHIP_DEACTIVATED: 'パートナーが正常に非アクティブ化されました',
      },
      TABLE_HEADERS: {
        TITLE: 'タイトル',
        SIGNED: '署名済み',
        VERSION: 'バージョン',
        EXECUTED_ON: '実行日',
        EXPIRES_ON: '有効期限',
        NAME: '名前',
        STATUS: 'ステータス',
        ASSETS: 'アセット',
        CREATION_DATE: '作成日',
        ACTIONS: 'アクション',
        ASSET_NAME: 'アセット名',
        TYPE: 'タイプ',
        SHARED_WITH: '共有先',
        CONTRIBUTORS: 'コントリビュータ',
      },
      DEACTIVATION_POPUP_BODY: {
        WILL_LOSE_ACCESS: 'このパートナーを非アクティブ化すると、Tangiblyへのアクセスが失われます。アセットの確認と表示は電子メール経由でのみ可能となります。',
        CAN_ACTIVATE_LATER: '非アクティブ化されたアカウントを再度アクティブ化することができます。',
        CAN_FIND_IN_SIDEMENU: '非アクティブ化されたパートナーは、サイドメニューのパートナーセクションで検索できます。',
        NOTE: '注',
      },
      DEACTIVATION_POPUP_TITLE: {
        DEACTIVATE: '非アクティブ化',
      },
    },
    PARTNERSHIP_ADD_EDIT_PARTNER: {
      INPUT_LABEL: {
        EMAIL: 'メール',
        DISPLAY_NAME: '表示名',
        PARTNER_NAME: 'パートナー名',
        ADDRESS: '住所',
      },
      SELECT_LABEL: {
        COUNTRY: '国',
      },
      ERROR_MESSAGE: {
        INVALID_EMAIL_MESSAGE: '有効なメールアドレスを入力してください',
      },
      TITLE: {
        EDIT: '<strong>{{NAME}}</strong>編集 ',
        ADD_USER_TO: '<strong>{{NAME}}</strong>にユーザーを追加',
        ADD_USERS_TO: '<strong>{{PARTNER_NAME}}</strong>にユーザーを追加',
        EDIT_PARTNER: 'パートナーを編集',
        CREATE_NEW_PARTNER_IN: '新しいパートナーを作成',
        ALL_SET: 'すべて設定されました！',
        ADD_PARTNER_LOGO: 'パートナーロゴを追加',
        ADD_RELEVANT_DOCUMENTS: '関連ドキュメントを追加',
      },
      DESCRIPTION: {
        SHARE_ASSETS: '新しいパートナーのユーザーとアセットを共有できるようになりました',
      },
      TOGGLE: {
        STATUS: 'ステータス：',
        ACTIVE: 'アクティブ',
      },
      PARTNER_TOOLTIP: {
        ACTIVE: 'アクティブアカウントはアセットを作成し、割り当てられたすべてのコースを閲覧できます。',
        NONACTIVE: '非アクティブアカウントは管理者によって共有された特定のアセットについて一時的な閲覧者となります。',
        EDIT_DISABLED: 'パートナーが無効化された場合、ピープルを編集することはできません',
        PARTNERSHIP_DISABLED: 'パートナーが無効化された場合、パートナーを編集することはできません',
      },
      CHECKBOX: {
        OKTA_MESSAGE: 'このユーザーはSSO（シングルサインオン）にOktaを使用しています',
        ACTIVE: 'アクティブ',
      },
      TOAST: {
        USER_ADDED_SUCCESSFULLY: 'ユーザーが正常に追加されました',
      },
    },
    PREDICTED_PORTFOLIO: {
      HEADER: {
        TITLE: '予測ポートフォリオ™',
        CHIP: 'ベータ',
        UPDATE: '更新',
        CHIP_TOOLTIP: '本機能はベータ版です。データに基づいて継続的に学習と改良を行い、最善の成果をご提供します。',
      },
      BANNER: {
        TITLE: 'Tangiblyのサンプル・プロジェクトを表示しています。',
        INFO: '貴社のポートフォリオ予測と戦略分析をご希望ですか？ぜひご連絡ください。',
        LEARN_MORE: '詳しく見る。',
        REQUEST_ACCESS: 'アクセス権をリクエストする。',
      },
      WIDGET: {
        PATENT: '特許',
        POTENTIAL_TREDE_SECRETS: '潜在的な営業秘密',
        SUBJECTS: 'テーマ',
      },
      CARD: {
        TITLE: '潜在的な営業秘密（上位テーマ別)',
      },
      PORTFOLIO_TABLE: {
        TITLE: 'ポートフォリオデータ',
        POTENTIAL_TRADE_SECRET: '潜在的な営業秘密',
        PATENT: '特許',
        YEAR: '年',
        SUBJECTS: 'テーマ',
        EXPORT_DATA: 'データのエクスポート',
        TOTAL_ITEMS: '項目{{NUMBER}} 個',
      },
      COMPETITIVE_BOX_TEXT: '会社の競合分析をチェック',
      CHARTS: {
        SUBJECT: 'テーマ',
        SUBJECTS: 'テーマ',
        POTENTIAL_TRADE_SECRETS: '潜在的な営業秘密',
        PATENTS: '特許',
        PATENT_NUMBER: '特許番号',
      },
      FILTERS: {
        COMPENY: '企業',
        PATENT: '特許',
        YEAR: '年',
        SUBJECT: 'テーマ',
        SEARCH: '検索',
        CLEAR_ALL: 'すべて消去',
      },
    },
    COMPETITIVE_ANALYSIS: {
      HEADER: {
        TITLE: '競合分析',
      },
      CHART: {
        COMPETITORS: '競合',
      },
      WIDGET: {
        COMPANIES: '企業',
      },
      CARDS: {
        PERCENTAGE_CHART: {
          TITLE: '潜在的な営業秘密（企業別およびテーマ別)',
        },
        BY_COMPANIES_CHART: {
          TITLE: '潜在的な営業秘密（企業別)',
          TOTAL_TEXT: '潜在的な\n営業秘密\nの合計',
        },
        BY_SUBJECT_CHART: {
          TITLE: '潜在的な営業秘密（上位テーマ別）',
          TOOLTIP_COMPANY_TITLE: '会社',
          TOOLTIP_POTENTIAL_TRADE_SECRETS_TITLE: '潜在的な営業秘密',
        },
      },
      DATA_TABLE: {
        TITLE: 'ポートフォリオデータ',
        POTENTIAL_TRADE_SECRET: '潜在的な営業秘密',
        COMPANY: '企業',
        PATENT: '特許',
        YEAR: '年',
        SUBJECTS: 'テーマ',
        EXPORT_DATA: 'データのエクスポート',
      },
    },
    ASSET_PAGE: {
      RECIPIENT_TABLE: {
        RECIPIENTS: '受領者',
        ACK_DATE: '承認日',
        ACK_STATUS: '承認ステータス',
        ACTIONS: 'アクション',
        COMPLIANT: '適合',
        PENDING: '保留',
      },
      WIDGET: {
        USERS_WERE_CREATED_BY_FINDER: 'ユーザーはFinderによって作成されました',
        NO_USERS_CREATED_BY_FINDER: 'ファインダーによって作成されたユーザーはいません',
        USERS_WERE_CREATED_BY_TRACKER: 'ユーザーはTrackerによって作成されました',
        NO_USERS_CREATED_BY_TRACKER: 'トラッカーによって作成されたユーザーはいません',
      },
      TAB: {
        OVERVIEW: '概要',
        ACTIVITY: 'アクティビティ',
        RECIPIENTS: '受信者',
      },
      CONTENT: {
        ASSET_REQUIRES_ACKNOWLEDGEMENT: 'このアセットは承認が必要です',
        CONTACT_PARTNER_TO_GAIN_ACCESS: 'サードパーティの資産にアクセスするには、パートナーに連絡してください。',
        NO_RECIPIENTS_YET: 'まだ受信者がいません',
        CONFIRMATION_TEXT:
          'こちらに名前を入力して、この情報は営業秘密として扱われ、機密保持義務、雇用義務、および／またはサービス義務のすべてを遵守することに同意したことを確認してください。<strong>{{AMOUNTS}}</strong>を承認したことを確認するには、',
        SINGEL_REQUEST_CONFIRMATION_TEXT:
          'こちらに名前を入力して、この情報は営業秘密として扱われ、機密保持義務、雇用義務、および／またはサービス義務のすべてを遵守することに同意したことを確認してください。',
        ENTER_NAME: 'アセット　以下に名前を入力してください',
        MANAGE_SIGNATURE_REQUESTS: 'すべての署名リクエストを管理',
        SHARED_ON: '共有日：',
        BY: '{{SHARED_BY}}によって',
        DESCRIPTION: '説明',
        CONTRIBUTORS: 'コントリビュータ',
        CONTRIBUTOR: 'コントリビュータ',
        FILES: 'ファイル',
        NO_ACCESS: 'アクセス権なし',
        ASSET_LABEL_LOCATION: 'アセットラベルと場所',
        METADATA: 'メタデータ',
        CREATED_ON: '作成日',
        CREATED_BY: '作成者',
        LAST_MODIFIED_ON: '最終更新日',
        REFERENCE_NUMBER: '参照番号',
        COPY: 'コピー',
        COPIED: 'コピーされました！',
        TAGS: 'タグ',
        BUSINESS_VALUE: 'ビジネス価値',
        PROJECTS: 'プロジェクト',
        DEPARTMENTS: '部署',
        OTHERS_1: 'その他1',
        OTHERS_2: 'その他2',
        DETAILS: '詳細',
        NO_DETAILS_DATA: 'このアセットの詳細データがありません',
        NOT_SIGNED: '未署名',
        NO_EXTERNAL_USERS: '外部ユーザーなし',
        EXTERNAL_USERS: '外部ユーザー',
        IMPORTANCE: '分類',
        BUSINESS_RELATED: 'ビジネス関連',
        COMMERCIAL_PRODUCT: '商用製品',
        UNKNOWN_FILE: '不明なファイル',
        A_LINK_IS_ATTACHED: 'リンクが貼ってあります',
        NO_FILES_OR_URLS_INCLUDED: 'ファイル/リンクは含まれていません',
      },
      TITLE: {
        ASSETS: 'アセット',
        SEND_ASSET_NOTIFICATION: '"{{ASSET_NAME}}" アセット通知を送信',
        PEOPLE_WITH_ACCESS: 'アクセス権者',
        CONFIRMATION_IS_REQUIRED: '確認が必要です',
        INFORMATION_IS_CONFIDENTIAL: '以下の情報は機密情報であり、営業秘密を含んでいます：',
      },
      TOOLTIP: {
        GRID_VIEW: 'グリッドビュー',
        LIST_VIEW: 'リストビュー',
        ASSETS_CREATED_BY_EMAIL_XRAY: 'Email X-Ray Finderによって作成されたアセット',
        ASSETS_CREATED_BY_PATENT_XRAY: '特許X-Rayによって作成されたアセット',
        EDIT_ASSET: 'アセットを編集',
        CANNOT_EDIT_ASSET: 'サードパーティによって作成されたアセットは編集できません',
        RECIPIENTS: '受信者',
        NOTIFY: '通知',
        THIRD_PARTY: '第三者',
        ASSET_CREATED_BY_PARTNER: 'パートナーによって作成されたアセット',
        ASSET_CREATED_BY_CLIENT: 'クライアントが作成したアセット',
        PRIVILEGE_LOG_DOWNLOAD: '権限ログをダウンロード',
        COMING_SOON: '近日公開',
        DELETED_SUCCESSFULLY: '{{NAME}} が正常に削除されました',
        EMAILS_SENT: '{{NAME}}メールが送信されました！',
        SOMETHING_WENT_WRONG: '問題が発生しました。もう一度やり直してください',
        CREATED_BY_FINDER: 'Email X-Ray Finderによって{{createdAt}}に作成されたユーザー',
        ASSETS_AWAITING_YOUR_SIGNATURE: '署名待ちのアセットが{{NEEDTOBEACKNOWLEDGEDNUM}} にあります',
        NAVIGATE_TO_ALL_SIGNATURE_REQUESTS: 'すべての署名リクエストに移動',
        CREATED_BY_TRACKER: 'Email X-Ray Trackerによって{{createdAt}}に作成されたユーザー',
        CONTRIBUTORS_CAN_BE_DELETED: 'コントリビュータは削除できます',
        ONLY_IN_EDIT_ASSET_POPUP: '「アセットを編集」のポップアップからのみ',
        FINDER_IS_AVAILABLE: 'X-RayプランでFinderが利用可能です',
        CONTACT_US: '詳細については、お問い合わせください',
        ACKNOWLEDGED: '認識された',
        NOT_ACKNOWLEDGED: '認識されない',
        AND_MORE: 'もっと...',
        ACKNOWLEDGE_ASSET: '承認',
        MORE_OPTIONS: 'より多くのオプション',
      },
      BUTTONS_TEXT: {
        CREATE_ASSET: '新しいアセットを作成',
        ACKNOWLEDGE: '承認',
        CLEAR_ALL: 'すべてクリア',
        BACK_TO_ALL_ASSETS: 'すべてのアセット',
      },
      INPUT: {
        SEARCH_BY_NAME_OR_ID: '名前またはIDで検索',
        SEARCH_OPTION: '検索オプション',
        FULL_NAME: 'フルネーム',
        SEARCH_BY_NAME_OR_EMAIL: '名前またはメールで検索',
        SEARCH_ON_ASSETS: 'アセットを検索',
      },
      MODAL: {
        CLOSE_ASSET: '本当にこのアセットを閉じてもよろしいですか？',
        ARE_YOU_SURE: '本当によろしいですか？',
        DELETE_ASSET: '本当にこのアセットを削除してもよろしいですか？',
        DELETE_FILE: '本当に削除してもよろしいですか？',
        ADD_USER_TO: '<strong>{{NAME}}</strong>にユーザーを追加',
        DELETE_RECIPIENT: '受信者を削除',
        RECIPIENTS_WONT_VIEW_ASSET: 'この受信者はアセットを閲覧できなくなります。本当にこの受信者を削除してもよろしいですか？',
        PLEASE_READ_THE_FOLLOWING_INFORMATION: '以下の情報をよくお読みください',
      },
      DROP_DOWN: {
        BATCH_UPLOAD: '一括アップロード',
        DOWNLOAD_EXCEL: 'Excelテンプレートをダウンロード',
        UPLOAD_EXCEL: 'Excelファイルをアップロード',
        EDIT_ASSET: 'アセットを編集',
        NOTIFY: '通知',
        DELETE_ASSET: 'アセットを削除',
        DEPARTMENT: '部署',
        NO_DEPARTMENTS: '部署なし',
        PROJECTS: 'プロジェクト',
        TAGS: 'タグ',
        BUSINESS_VALUE: 'ビジネス価値',
        CONTRIBUTOR: 'コントリビュータ',
        ASSET_TYPE: 'アセットタイプ',
        TAG: 'タグ',
        CATEGORIZATION: '分類',
        BUSINESS_RELATED: '事業関連',
        COMMERCIAL_PRODUCT: '商用製品',
        RECIPIENTS: '受信者',
      },
      ERROR_MESSAGE: {
        RECIPIENT_EXISTS: '受信者はすでに存在します',
        NOT_VALID_EMAIL: '有効なメールアドレスではありません',
        EMAIL_IS_REQUIRED: 'メールアドレスが必要です',
      },
      CARD: {
        NO_ASSETS_FOUND: 'アセットが見つかりません',
        EMPLOYEES: '従業員',
        CONTRACTORS: '請負業者',
        PARTNERS: 'パートナー',
        LAWYERS: '弁護士',
        NOT_REGISTERED: '登録されていません',
        NO_RECIPIENT_FOUND: '検索に一致する受信者が見つかりません。別の名前で検索してみてください。',
        ASSET_HAS_NO_RECIPIENTS: 'このアセットにはまだ受信者がいません',
      },
      TOAST: {
        ASSET_EDITED_SUCCESSFULLY: 'アセットが正常に編集されました',
        ASSETS_DOWNLOADED_SUCCESSFULLY: 'ファイルのダウンロードに成功しました。 パスワードをEメールアドレスに送信しました。',
      },
      FILTERS: {
        MORE_FILTERS: 'フィルタを追加',
        SEARCH_BY_ASSET: 'アセット名、ID、ファイル名、ラベル、ロケーションで検索',
      },
      CREATE_EDIT_ASSET: {
        TITLE: {
          EDIT: '編集',
          CREATE_ASSET: 'アセットを作成',
          FILL_DETAILS: '詳細を記入',
          METADATA: 'メタデータ',
          ASSET_DETAILS: 'アセットの詳細',
        },
        LABEL: {
          TITLE: 'タイトル',
          DESCRIPTION: '説明',
          FILE_LOCATION: 'ファイルの場所',
          FILE_LABEL: 'ファイルラベル',
          ASSETS_PREFIX: 'アセットの接頭辞',
          REFERENCE_NUMBER: '参照番号',
          CONTRIBUTORS: 'コントリビュータ',
          TAGS: 'タグ',
          SELECT_ASSET_TYPE: 'アセットタイプを選択',
          BUSINESS_VALUE: 'ビジネス価値',
          PROJECTS: 'プロジェクト',
          DEPARTMENTS: '部署',
          BUSINESS_RELATED: 'ビジネス関連',
          OTHERS_1: 'その他1',
          OTHERS_2: 'その他2',
          ADD_FILES: 'ファイルを追加',
          IMPORTANCE: '分類',
          COMMERCIAL_PRODUCT: '商用製品',
          PUBLISHED: '公開済み',
        },
        PLACEHOLDERS: {
          ENTER_TITLE: 'タイトルを入力',
          ENTER_DESCRIPTION: '説明を入力',
          ENTER_FILE_LOCATION: 'ファイルの場所を入力',
          ENTER_FILE_LABEL: 'ファイルラベルを入力',
          EDIT_REFERENCE_NUMBER: '参照番号を編集',
          ENTER_ADDITIONAL_INFORMATION: '追加情報を入力',
        },
        TOOLTIP: {
          CLOSE_ASSET: 'アセットを閉じる',
        },
        ERROR_MESSAGE: {
          TITLE_FIELD_REQUIRED: 'タイトルフィールドは必須です',
          LOCATION_AND_LABEL_REQUIRED: 'ファイルの場所を追加する際、ファイルの場所とラベルの両方が必要です',
          FILE_LOCATION_LINK_EXISTS: 'ファイルの場所リンクが存在する場合は必須です',
          FILE_LABEL_EXISTS: 'ファイルラベルが存在する場合は必須です',
          REFERENCE_NUMBER_MUST_BE_EXACTLY: '参照番号は正確に6文字である必要があります',
          REFERENCE_NUMBER_IS_REQUIRED: '参照番号は必須です',
          ONLY_DIGITS: '数字[0-9]のみ許可されています！',
          CONTRIBUTORS_FIELD_IS_REQUIRED: 'コントリビュータフィールドは必須です',
          TAGS_FIELD_IS_REQUIRED: 'タグフィールドは必須です',
          TYPE_ASSET_FIELD_IS_REQUIRED: 'アセットタイプフィールドは必須です',
        },
        ADD_FILES: {
          DROP_FILES: 'ここにファイルをドロップしてください…',
          DROP_FILE: 'ここにファイルをドロップしてください…',
          DRAG_AND_DROP: 'ここにファイルをドラッグ＆ドロップするか、またはクリックしてファイルを選択してください',
          DRAG_AND_DROP_FILE: 'ここにファイルをドラッグ＆ドロップするか、またはコンピュータからファイルを参照して選択してください',
          DRAG_AND_DROP_FILE_EDIT_MODE: 'ここに新しいファイルをドラッグ＆ドロップするか、または',
          DRAG_AND_DROP_CLICK: 'コンピュータからファイルを参照して選択してください',
          REJECT_PLEASE_UPLOAD_ONLY_EXCEPTED: 'サポートされている形式のみアップロードしてください！将来的に、他のファイル形式もサポートするように更新します！',
          UPLOAD_ONLY_ACCEPTED_FORMATS: 'サポートされている形式のみアップロードしてください（.pdf、.doc、.docx、.xls、.xlsx、.ppt、.pptx、.jpeg、.png、.jpg）。',
          FILE_CANNOT_CONTAIN_SPECIAL_CHARACTERS: 'ファイル名に特殊文字（！@＃＄など）を含めることはできません',
        },
        EDIT_MODE_ASSET_ITEM: {
          UPLOADED: 'アップロード日',
        },
      },
      BATCH_UPLOAD: {
        UPLOAD_MULTIPLE_ASSETS: '一括アップロード - 複数アセットをアップロード',
        UP_TO_50_ASSETS: '最大50アセットまで',
        DRAG_AND_DROP: 'ここに画像をドラッグ＆ドロップするか、またはファイルを参照して選択してください',
        DROP_THE_FILES: 'ここにファイルをドロップしてください…',
        DONT_CLOSE_WINDOW: 'ウィンドウを閉じないでください。アセットをアップロードしています…',
        VALIDATING_ASSETS: 'アセットを検証しています…',
        BUTTONS_TEXT: {
          DOWNLOAD_EXCEL_TEMPLATE: 'Excelテンプレートをダウンロード',
          UPLOAD_NEW_FILE: '新しいファイルをアップロード',
          CONFIRM_UPLOAD: 'アップロードを確認',
        },
        ERROR_MESSAGE: {
          CANT_UPLOAD_MORE_THAN_50: '50を超えるアセットをアップロードできません',
          NO_ASSETS_UPLOADED: 'アセットがアップロードされていません',
          THERE_ARE: '見つかったのは',
          ERRORS: 'エラー',
          NO_ERRORS_FOUND: 'エラーは見つかりませんでした',
          UPLOAD_ONLY_ACCEPTED_FORMATS: 'サポートされている形式のみアップロードしてください！',
          ROW: '行',
          IS_REQUIRED: 'が必要です',
          DOESNT_EXIST: 'が存在しません',
          ASSETS_ADDED_TO_TANGIBLY: 'の新しいアセットがTangiblyに追加されます',
        },
      },
      ASSET_TABLE: {
        TAGS: 'タグ',
        ASSET_TYPE: 'アセットタイプ',
        NAME: '名前',
        CREATION_DATE: '作成日',
        RECIPIENTS: '受信者',
        ACTIONS: 'アクション',
      },
    },
    PEOPLE: {
      TITLE: {
        PEOPLE: 'ピープル',
        EMPLOYEES: '従業員',
        CONTRACTORS: '請負業者',
        EMPLOYEES_TOTAL: '{{totalEmployees}}従業員',
        CONTRACTORS_TOTAL: '{{totalContractors}} 請負業者',
      },
      LABEL: {
        VERSION: 'バージョン',
        EXECUTED_ON: '実行日',
        EXPIRES_ON: '有効期限',
      },
      SELECT: {
        STATUS: 'ステータス',
        AGREEMENTS: '契約',
        TRAINING: 'トレーニング',
        START_DATE: '開始日',
        ACTIVATE_USER: 'ユーザーをアクティブ化',
        DEACTIVATE_USER: 'ユーザーを非アクティブ化',
        DELETE_USER: 'ユーザーを削除',
        ROLE: '役割',
        COUNTRIES: '国',
        FILTER_EMPTY_STATE: '{{filterName}} はまだまだ人々に割り当てられていませんされていません',
        BUSINESS_UNIT: '事業部門',
        HIRE_DATE: '雇用日',
      },
      AGREEMENT_ADD_FILE: {
        DRAG_AND_DROP: 'ここにファイルをドラッグするか、または',
        CHOOSE_FILE: 'ファイルを選択してください',
      },
      TOOLTIP: {
        CREATED_BY_FINDER: 'Email X-Ray Finderによって{{createdAt}}に作成されたユーザー',
        CREATED_BY_TRACKER: 'Email X-Ray Trackerによって{{createdAt}}に作成されたユーザー',
        EDIT: '編集',
        TRADE_SECRET_ACCESS: '営業秘密にアクセス',
      },
      DROPDOWN: {
        BATCH_UPLOAD: '一括アップロード',
        DOWNLOAD_EXCEL: 'Excelテンプレートをダウンロード',
        UPLOAD_EXCEL: 'Excelファイルをアップロード',
      },
      INPUT: {
        SEARCH_BY_DISPLAY_NAME_OR_EMAIL: '表示名またはメールアドレスで検索',
      },
      FILTERS: {
        SEARCH_BY_USER_NAME_ID_OR_EMAIL: '表示名、IDまたはEメールアドレスで検索',
        SEARCH: '検索',
      },
      TABLE: {
        NO_EMPLOYEES_FOUND: '従業員が見つかりませんでした',
        NO_CONTRACTORS_FOUND: '請負業者が見つかりませんでした',
      },
      MODAL: {
        TITLE: {
          CONFIRM_DELETION: '削除を確認',
          CONFIRM_DEACTIVATION: '非アクティブ化を確認',
          ADD_USER_TO: '<strong>{{NAME}}</strong>にユーザーを追加',
          CONFIRM_ACTIVATION: 'アクティブ化を確認',
        },
        MODAL_BODY: {
          DELETE_ACCOUNT: 'このアカウントを永久に削除しようとしています',
          DELETE_ACCOUNT_CONFIRMATION: 'このアカウントを削除すると、システムから永久に除去され、\nデータはすべて失われます。',
          NON_REVERSIBLE_ACTION: 'このアクションは不可逆的です',
          NO_REVERSAL: 'このアクションを元に戻すことはできません。',
          DEACTIVATE_ACCOUNT_TITLE: 'このアカウントを非アクティブ化しようとしています。',
          DEACTIVATE_ACCOUNT_TEXT: 'このアカウントを非アクティブ化すると、Tangibly へのアクセスが失われ、\nアセットの承認と閲覧はメール経由でのみ可能になります',
          REVERSIBLE_ACTION: 'このアクションは可逆的です',
          ACTIVATE_ACCOUNT_LATER: 'あとでアカウントをアクティブ化することができます。',
          REACTIVATE_ACCOUNT_TITLE: 'このアカウントをアクティブ化しようとしています',
          REACTIVATE_ACCOUNT_TEXT: 'このアカウントをアクティブ化すると、Tangiblyにアクセスできるようになり、\n確認メールが届きます。',
          DEACTIVATE_ACCOUNT_LATER: 'あとでアカウントを非アクティブ化することができます。',
          I_AGREE: '同意します',
          PLEASE_READ: '以下の情報をよくお読みください',
        },
        CHECKBOX_TEXT: {
          I_UNDERSTAND_REMOVE: 'このユーザーアカウントはシステムから永久に除去され、回復できないことを理解しました。',
        },
      },
      BATCH_UPLOAD: {
        UPLOAD_MULTIPLE_ASSETS: '一括アップロード - 複数アセットをアップロード',
        UP_TO_50_EMPLOYEES: '最大従業員50人まで',
        DRAG_AND_DROP: '画像をドラッグ＆ドロップするか、ファイルを参照して選択してください',
        DROP_THE_FILES: 'ここにファイルをドラッグ…',
        DONT_CLOSE_WINDOW: 'ウインドウを閉じないでください。従業員をアップロードしています…',
        VALIDATING_EMPLOYEES: '従業員を検証しています…',
        BUTTONS_TEXT: {
          DOWNLOAD_EXCEL_TEMPLATE: 'Excelテンプレートをダウンロード',
          UPLOAD_NEW_FILE: '新しいファイルをアップロード',
          CONFIRM_UPLOAD: 'アップロードを確認',
        },
        ERROR_MESSAGE: {
          CANT_UPLOAD_MORE_THAN_50: '50人を超える従業員をアップロードすることはできません',
          NO_EMPLOYEES_UPLOADED: 'アップロードされた従業員はいません',
          NO_ASSETS_UPLOADED: 'アップロードされたアセットはありません',
          THERE_ARE: '{{batchErrors}}のエラーがあります',
          NO_ERRORS_FOUND: 'エラーは見つかりませんでした',
          UPLOAD_ONLY_ACCEPTED_FORMATS: 'サポートされている形式のみアップロードしてください！',
          ROW: '行',
          EMPLOYEES_ADDED_TO_TANGIBLY: '新しい従業員がTangiblyに追加されます',
        },
      },
      INVITE_FORM: {
        TITLE: {
          AGREEMENTS: '契約',
        },
        SELECT_A_ROLE: '契約をアップロードするには、役割を選択してください',
        MANAGERS_NOTIFICATION: 'マネージャーは自分の部署に関連するアセットのみ見ることができます。',
        ACTIVE_TOGGLE: 'アクティブ',
        CHECKBOX_OKTA_MESSAGE: 'このユーザーはSSO（シングルサインオン）にOktaを使用しています',
        ACTIVE_TOOLTIP: 'アクティブアカウントはアセットを作成し、すべての割り当てられたコースを閲覧できます。',
        NONACTIVE_TOOLTIP: '非アクティブアカウントは管理者によって共有された特定のアセットについて一時的な閲覧者となります。',
        INPUT_LABEL: {
          EMAIL: 'メール',
          DISPLAY_NAME: '表示名',
          TITLE: 'タイトル',
          PHONE: '電話',
          LINKEDIN_PROFILE: 'LinkedInプロフィール',
          EMPLOYEE_ID: '従業員ID',
          COUNTRY: '国',
          LOCATION: '場所',
          HIRE_DATE: '雇用日',
          EXIT_DATE: '退職日',
          OTHERS_1: 'その他1',
          OTHERS_2: 'その他2',
          STATUS: 'ステータス',
        },
        SELECTOR: {
          ROLE: '役割',
          DEPARTMENTS: '部署',
          BUSINESS_UNITS: 'ビジネスユニット',
        },
        PLACEHOLDER: {
          EMAIL_PLACEHOLDER: 'email@example.com',
          ENTER_ADDITIONAL_INFORMATION: '追加情報を入力してください',
        },
        VALIDATION_MESSAGE: {
          VALID_EMAIL: '有効なメールアドレスを入力してください',
          NEVER_SHARE_EMAIL: 'メールアドレスを第三者と共有することはありません',
          VALID_LINKEDIN: '有効なLinkedInリンクを入力してください',
        },
      },
      VIEW_EMPLOYEE: {
        ACCOUNT_DETAILS: 'アカウントの詳細',
        EXECUTED_ON: '{{SIGNED_DATE}}に実行',
        PENDING: '保留',
        HEADER: {
          AGREEMENTS: '契約',
          TRAINING: 'トレーニング',
          ASSETS_CREATED: '作成されたアセット（{{ASSET_LENGTH}}）',
          ASSETS_SHARED: '従業員と共有されたアセット（{{ASSET_SHARED_LENGTH}}）',
          ASSETS_CONTRIBUTED: '寄付資産（{{ASSET_LENGTH}}）',
        },
        ACCOUNT_DATA: {
          NAME: '名前：',
          EMAIL: 'メール：',
          TITLE: 'タイトル：',
          DEPARTMENTS: '部署：',
          BUSINESS_UNITS: 'ビジネスユニット：',
          STATUS: 'ステータス：',
          ROLE: '役割：',
          HIRE_DATE: '雇用日：',
          EXIT_DATE: '退職日：',
        },
        TABLE: {
          CREATED_ON: '作成日',
          SHARED_BY: '共有者',
          SHARED_ON: '共有日',
          ASSET: 'アセット',
          TYPE: 'タイプ',
          TAGS: 'タグ',
          RECIPIENTS: '受信者',
          STATUS: 'ステータス',
          ASSET_COLUMN: 'アセット',
          TAGS_COLUMN: 'タグ',
          CREATION_DATE: '作成日',
          SENT_DATE: '送信日',
          ACKNOWLEDGEMENT: '了承',
        },
      },
    },
    DISCOVERY_LOBBY: {
      UPLOAD_CONTRACT_APPLICATION: {
        UPLOAD_CONTRACT_APPLICATION_FILE: '契約書ファイルをアップロード',
        RECENT_UPLOAD: '最近のアップロード',
        WE_FOUND_RESULTS: 'ファイル"{{FILE_NAME}}"から結果が得られました',
        WE_ARE_FINDING: '文書の営業秘密の保護スコアを確認しています。これには数分かかることがあります。',
        TRADE_SECRET_PROTECTION_SCORE: '営業秘密の保護スコア',
        DOCUMENT_TRADE_SECRET_PROTECTION_SCORE: '営業秘密の保護スコアを記録',
        PARAMETERS: 'パラメーター',
        RECOMMENDATIONS: '推奨',
      },
      CONTRACT_RESPONSE: {
        CONFIDENTIALITY_PROVISION: {
          LABEL: '秘密保持条項',
          CONTENT:
            '有効な契約では守秘義務が課され、共有される秘密情報を保護します。秘密保持条項も、受領当事者が秘密情報を利用できる範囲を制限するものです。要求された法的·規制的開示などの状況については例外が定められており、情報が公知となった場合は、受領当事者は秘密性を維持しなければならず、開示や不正な利用は禁じられています。ただし法的義務やすでに公知となっていた情報については、その限りではありません。',
          RECOMMENDATION:
            '受領当事者は、すべての秘密情報を秘密情報および機密情報として取り扱い、その秘密性および機密性を保持するものとします。また受領当事者は、開示当事者から書面による合意を得ることなく、本契約に規定される以外のいかなる者に対しても、秘密情報を直接的または間接的に（書面、口頭、その他の形式を問いません）伝達または開示しないものとします。',
        },
        TRADE_SECRET: {
          LABEL: '営業秘密の保護について言及しています',
          CONTENT: '契約では、取り扱う保護対象の知的財産の種類として、営業秘密を明示的に記載する必要があります。',
          RECOMMENDATION:
            '情報とは、次に列挙するすべての情報をいいます（以下を含むがこれに限定されません）。あらゆる知的財産権、システム·運用·計画·意向·市場機会·顧客·サプライヤー·ノウハウ·営業秘密·商務·事業手段·事業構想に関する情報（口頭·有形·文書など形式のいかんを問いません）。',
        },
        CLEARLY_DEFINED: {
          LABEL: '営業秘密が定義されました',
          CONTENT: '営業秘密の定義を明確に定めて、営業秘密に該当するかどうか受領者が明確に判断できるようにする必要があります。',
          RECOMMENDATION:
            '営業秘密とは、次に該当するものをいいます。（a）一般に公開されていない情報、（b）公知となっていないことから、保持者に経済的利益をもたらす情報、（c）その秘密性を保持するため、保持者が合理的な努力を払う情報。',
        },
        TERMINATION: {
          LABEL: '契約期間および契約の終了',
          CONTENT: '契約では、契約期間を明確に定める必要があります。契約を早期終了または延長する際の注意点や選択肢を明示する必要があります。',
          RECOMMENDATION: '本契約はABC年間有効に存続するものとします。両当事者の相互の合意により、本契約を終了することができます。',
        },
        RETURNING_OR_DESTROYING: {
          LABEL: '秘密情報は契約期間の終了時に返却または破棄されます。',
          CONTENT: '契約の終了時点における秘密情報の削除、返却、または破棄に関する受領当事者の義務について明確化する必要があります。',
          RECOMMENDATION:
            '受領当事者は次のいずれか早い方の時点で、本契約に基づき開示された秘密情報に関するすべての注釈および派生物を含む、すべての複製物（複製または保存の形式のいかんを問いません）を速やかに返却または破棄するものとします。（a）本契約で企図される取引の完了時点または終了時点、（b）本契約の終了時点、（c）開示当事者が受領当事者に要請する時点。',
        },
        SURVIVAL_CLAUSE: {
          LABEL: '存続条項',
          CONTENT:
            '契約の存続条項は、契約の解除後も秘密保持義務は存続し、開示済み情報の保護を維持する旨、規定するものとします。ただし、受領当事者の過失によらず公知となった情報については、この限りでありません。',
          RECOMMENDATION:
            '秘密保持義務は、次のいずれか遅い時点まで継続するものとします。（a）受領当事者の過失によらず情報の秘密性が失われた時点、または（b ）開示当事者による開示日から DEF 年が経過した時点。',
        },
        DISCOVERING_INFORMATION: {
          LABEL: '調停または仲裁において許可された証拠開示',
          CONTENT: '調停または 仲裁といった裁判外紛争解決手続（ADR）が望ましい場合には、証拠開示の量および範囲を定めることができます。',
          RECOMMENDATION:
            'ADRを開始する前に、両当事者とも限定的な証拠開示手続きを行う権利を有しています。これには、20件を超えない質問状の作成、および3件を超えない供述録取の作成に使用するために、合理的な数量の文書を要求する権利が含まれます。',
        },
        LIMITS_ON_LIABILITY: {
          LABEL: '営業秘密の喪失に関する責任制限',
          CONTENT: '必要に応じて、営業秘密の喪失に対する責任制限を規定することができます。',
          RECOMMENDATION: '本契約に含まれるいかなる秘密保持条項の違反に対する責任も、XYZに対して限定されるものとします。',
        },
        SHARING_WITHOUT_RESTRICTIONS: {
          LABEL: '第三者への開示',
          CONTENT: '承認される第三者への開示について明確に規定する必要があります。',
          RECOMMENDATION: '開示当事者から書面による同意を事前に得ることなく、受領当事者が受領したいかなる秘密情報も第三者に開示することはありません。',
        },
        MANDATORY_DISCLOSURE: {
          LABEL: '義務的開示',
          CONTENT: '裁判所または規制当局などへの義務的開示について、規定する必要があります。',
          RECOMMENDATION:
            '裁判所または規制当局から秘密情報を伝達するよう要請された場合、または強行法規によりそのような情報の開示を同様に義務付けられた場合、受領当事者はそのような開示要請の条件について、開示当事者に対し速やかに通知するものとし、開示当事者と協力して、要請に応じるとともに、秘密情報の秘密性を保持するものとします。',
        },
      },
      BUTTONS_TEXT: {
        CONTACT_SUPPORT: 'サポートに連絡',
        SEARCH: '検索',
        UPDATE_XRAY_DATA: 'X-Rayデータを更新',
        UPLOAD_PATENT_APPLICATION: '特許申請書類をアップロード',
        ANALYZE: '分析',
        ANALYZE_AND_DOWNLOAD_REPORT: '分析し、レポートをダウンロードする。',
        UPLOAD_CONTRACT_FILE: '契約書ファイルをアップロード',
      },
      TITLE: {
        EMAIL_XRAY: 'メールX-Ray',
        PATENT_XRAY: '特許エックスレイ',
        TRADE_SECRET_FINDER: '営業秘密Finder',
        ASSET_EXPOSURE_TRACKER: 'アセット露出Tracker',
        INSIGHTS: 'インサイト',
        LAST_UPDATE_REQUEST: '最終更新リクエスト日：{{DATE}}',
        EXTRACTION: '抽出',
        MAPPING: 'マッピング',
        CONTRACT_XRAY: 'X-レイにコンタクト',
        INVENTION_DISCLOSURE: '発明の開示',
        OR: 'または',
        CONTINUATION_ANALYSIS: '継続解析',
      },
      CARD_TEXT: {
        JOIN_PILOT_GROUP: 'アセットの管理をアップグレードするには、パイロットグループに参加してX-Ray機能をアンロックしてください',
        FIND_TRADE_SECRETS_FROM_PATENTS: '特許から営業秘密を探して編集',
        FIND_TRADE_SECRETS_FROM_GOOGLE_PATENTS: 'TangiblyのAI を用いて、潜在的な営業秘密を発見することができます。',
        SEARCH_FOR_POTENTIAL_TRADE_SECRETS: '潜在的な営業秘密をメール内で検索',
        MONITOR_TRADE_SECRETS_EXPOSURE: '既存の営業秘密の露出を監視',
        ASSETS_FROM_PATENTS: '特許から作成されたアセット',
        ASSETS_BY_FINDER: 'Finderによって作成されたアセット',
        PEOPLE_BY_TRACKER: 'Trackerによって追加されたピープル',
        WRITE_SMARTER_PATENT_APPLICATIONS: 'TangiblyのAIを用いて、潜在的な営業秘密に関する開示レベルを決定することができます。',
        CONTINUATION_ANALYSIS_DESCRIPTION: 'TangiblyのAIは検討事項について、継続出願ドラフトを作成し、時間 と お金を節約します。 ',
        LOAD_MORE: 'もう一度読み込んでください。',
        UPLOAD_CONTRACT: '契約書をここにアップロードして、質のチェックやすべてのトピックのカバー状況を確認できます',
        CONTRACT_ANALYZER: 'こちらに契約書をアップロードして営業秘密は十分に保護されているか、重要な営業秘密条項はすべて取り上げられているかをチェックする。',
        ASSETS_FROM_INVENTION_DISCLOSURE: '発明の開示により作成されたアセット',
        PASTE_INVENTION_DISCLOSURE_HERE: '発明の開示をここに貼り付けて、潜在的な営業秘密を見つける',
      },
      PLACEHOLDER: {
        ENTER_PATENT_NUMBER: '特許番号を入力してください',
        PATENT_XRAY: '特許出願番号を入力する。',
      },
      XRAY_STATUS_NOTIFICATION: {
        YOU_CAN_CONTINUE: 'プロセスの実行中も他のページで作業を続行して、結果を後で確認することができます。',
      },
      PATENT_NOTIFICATION: {
        WE_ARE_FINDING: '営業秘密を探しています。これには数分かかる場合があります。',
        SWITCHING_CLIENTS: 'クライアントを切り替えると、以前の検索結果はクリアされますのでご注意ください。',
        RECENT_SEARCH: '最近の検索結果',
        FOUND_RESULTS_FROM_PATENT: '特許“{{PATENT_ID}}”から、 {{RESULT_NUM}} 件の商業秘密が見つかりました',
        FOUND_RESULTS_FROM_FILE: ' ファイル “{{FILE_NAME}}”から、 {{RESULT_NUM}} 件の商業秘密が見つかりました',
      },
      PATENT_NOTIFICATION_BUTON: {
        SEE_RESULTS: '検索結果を表示',
      },
      PATENT_NOTIFICATION_ERROR: {
        WE_DID_NOT_FIND_PATENT: '残念ながら、お探しの特許が見つかりませんでした。',
        SOMETHING_WENT_WRONG: '申し訳ありませんが、何か問題が発生しました。',
        TRY_SEARCHING_AGAIN: '再度検索してみてください。',
        TRY_SEARCHING_AGAIN_EXPORT: 'おっと。何か問題が発生しました。もう一度検索してみてください。',
        TRY_IN_CORRECT_FORMAT: '他の特許番号を検索するか、番号が正しい形式（特殊文字なし）であることを確認してください。',
        NO_CLAIMS: '特許 “{{PATENT_ID}}”には、特許請求の範囲が記載されていません。',
        NO_CLAIMS_IN_FILE: '残念ながら ドキュメントでは、関連するデータが見つかりませんでした。',
        MAKE_SURE_FILE_CONTAINS_CLAIMS: 'ファイルの“特許請求の範囲”セクションに請求項数のリストが含まれていることを確認してください。',
        NO_RESULTS: '残念ながら、現時点ではお探しの特許が見つかりませんでした。',
        PROBLEM_READING_FILE: 'ファイルの読み込みに失敗しました。',
        PLEASE_UPLOAD_AGAIN: '再度アップロードしてみてください。',
        PLEASE_UPLOAD_ACCEPTED_FORMAT: 'サポートされている形式でファイルをアップロードしてください。',
        NO_RESULTS_FOR_LATEST_SEARCH: '残念ながら、最新の検索結果が見つかりませんでした。',
        NO_SATISFACTORY_RESULTS: '残念ながら、現時点では満足のいく検索結果が得られませんでした。',
        NO_DESCRIPTION_IN_DOCUMENT: 'おっと、ドキュメントには説明がありません。',
        TRY_UPLOADING_DIFFERENT_DOCUMENT: '別のドキュメントをアップロードしてください。',
      },
      PATENT_APPLICATION: {
        WANT_TO_SEARCH: '出願中の特許を検索しますか？',
        UPLOAD_PATENT_WORD_FILE: '特許出願Wordファイルをアップロード',
      },
      UPLOAD_PATENT_APPLICATION: {
        SEARCH_WITH_PATENT_FILE: '特許出願ファイルで検索',
        DRAG_AND_DROP_WORD_FILE: 'ここにWordファイルをドラッグ＆ドロップするか、または',
        UPLOAD_WORD_FILE: 'Wordファイルをアップロードしてください。',
        UPLOAD_PATENT_APPLICATION_FILE: '特許申請ファイルをアップロード',
      },
      CHIP: {
        BETA: 'ベータ',
        NEW: 'ニュー',
        ERROR: '文書は営業秘密を保護していません',
        SUCCESS: '文書は営業秘密を保護しています',
        WARNING: '文書は営業秘密を部分的に保護しています',
      },
      POPUPS: {
        TITLE: '新機能が 追加 され ました',
        BUTTONS: {
          NEXT: '次へ',
          OK_I_GET_IT: '分かりました！',
        },
        PATENT_TITLE: '特許X‐レイによる抽出および解析',
        SUBTITLE: 'あなたや競争相手の特許を取り巻く潜在的な営業秘密を特定し、包括的に保護します。',
      },
      INVENTION_DISCLOSURE: {
        WORDS_COUNT: '{{WORDS_COUNT}} ワード',
        TEXT_TOO_LONG_ERROR: '最大{{MAX_WORDS}} ワードまで検索可能',
        TEXT_TOO_SHORT_ERROR: '入力した文字列が短すぎます。検索するには、最低{{MIN_WORDS}} ワード数を入力してください',
        TEXTAREA_PLACEHOLDER: 'テキストをペーストする。（最大{{MAX_WORDS}} 字）',
      },
      CONTINUATION_ANALYSIS_NOTIFICATION: {
        WE_ARE_FINDING: '継続解析を処理しています。後ほどダウンロードできます。',
        RECENT_REPORT_DOWNLOAD: '最近のレポートをダウンロードする。',
        REPORT_FOR: '{{PATENT_IDENTIFIER}} についてのレポート',
      },
      INVENTION_DISCLOSURE_NOTIFICATION: {
        ANALYSIS_IN_PROGRESS: '分析中',
        WE_FOUND_POTENTIAL_TS_IN_IDF: '発明の開示の中に潜在的な営業秘密が見つかりました',
      },
      INVENTION_DISCLOSURE_ERROR: {
        TEXT_TOO_SHORT: '入力した文字列が短すぎます。',
        WE_CAN_ONLY_SEARCH_MIN_WORDS: '{{MIN_WORDS}}文字以上の文字列で検索してください',
      },
      INFO_TEXT: {
        PATENT_XRAY: '自身または競合他社の特許に関わる潜在的な営業秘密を特定し、総合的な保護を得る。',
      },
    },
    DISCOVERY_JOIN: {
      TITLE: {
        XRAY_FEATURES: 'エックスレイの機能',
        JOIN_PRIVATE_BETA: 'Tangiblyのプライベートベータに参加してエックスレイ機能の制限を解除し、営業秘密の管理を強化しましょう。.',
        FINDER: '営業秘密ファインダー',
        TRACKER: 'アセット露出トラッカー',
        PATENT_XRAY: '特許エックスレイ',
        SEARCHING_USING_KEYWORDS: 'キーワードで営業秘密を検索',
        MATCHING_FILES: 'ファイルをマッチングして営業秘密の露出を検知',
        FINDING_FROM_PATENTS: '特許から営業秘密を探して編集',
      },
      BUTTONS_TEXT: {
        JOIN_BETA: 'ベータ版に参加',
      },
      TOAST: {
        ERROR: '何か問題が発生しました。あとでもう一度試してください。',
      },
    },
    PATENT_TOOL: {
      DROPDOWN_BUTTON: {
        TURN_INTO_ASSET: 'アセットに変換',
        EDIT_AND_TURN_INTO_ASSET: '編集してアセットに変換',
      },
      TOAST: {
        ASSET_CREATED_SUCCESSFULLY: 'アセットが正常に作成されました',
      },
      LOADER: {
        FINDING_TRADE_SECRETS: '特許“{{PATENT_ID}}”から営業秘密を探す',
        THIS_CAN_TAKE_1_3_MINUTES: 'これには1～3分かかることがあります。他のページへ移動せずにそのままお待ちください',
      },
      BUTTON: {
        REGROUP_RESULTS: '結果を再グループ化',
      },
      CONTENT: {
        ASSET_CREATED: 'アセットが作成されました',
        BACK_TO_PATENT_X_RAY_HOME: '特許X－レイ',
        BACK_TO_CONTRACT_ANALYZER_HOME: '契約書チェッカー',
        BACK_TO_INVENTION_DISCLOSURE_HOME: '発明の開示',
        TRADE_SECRETS_RESULTS: '特許 “{{PATENT_ID}}”に含まれる営業秘密の検索結果',
        INVENTORS: '発明者',
        RESULTS: '検索結果',
      },
      MODAL: {
        ARE_YOU_SURE: '本当にこのアセットを閉じてもよろしいですか？',
      },
      ADD_NEW_CONTRIBUTORS: {
        ERROR_MESSAGE: {
          EMAIL_SHOULD_BE_UNIQUE: '各メールアドレスはユニークである必要があります',
          EMAIL_IS_REQUIRED: 'メールアドレスは必須です',
          INVALID_EMAIL: '有効なメールアドレスを入力してください',
        },
        TOAST: {
          USERS_ADDED_SUCCESSFULLY: '{{NUM}}人のユーザーが正常に追加されました',
        },
        TOOLTIP: {
          FOUND_EXISTING_USERS: 'システム内に、投資家名に一致し、コントリビュータとしてアセットの作成に関連する可能性のある既存ユーザーが見つかりました。',
          ADD_INVENTORS_AS_USERS: '以下の発明者をシステムのユーザーとして追加し、アセットのコントリビュータになるようにしてください。あとでピープルページで雇用日を編集できます。',
        },
        INPUT_LABEL: {
          EMAIL: 'メールアドレス',
          ROLE: '役割',
          HIRE_DATE: '雇用日',
        },
        BUTTON: {
          ADD_CONTRIBUTORS: 'コントリビュータを追加',
        },
        ADD_INVENTORS_AS_CONTRIBUTORS: '特許の発明者をコントリビュータとして追加',
        SUGGESTED_CONTRIBUTORS: '提案されたコントリビュータ',
        NOT_RIGHT_PERSON: '適切な人ですか？',
        NEW_USERS: '新しいユーザー',
      },
      EDIT_TEXT: {
        EDIT_SUBJECT: '件名を編集',
      },
      PLACEHOLDER: {
        ENTER_PATENT_NUMBER: '特許番号を入力してください',
      },
      ERROR_PAGE: {
        SOMETHING_WENT_WRONG: '申し訳ありません、何か問題が発生しました。',
        SEARCH_AGAIN: '再検索',
        NEW_SEARCH: '新規検索',
        WE_DID_NOT_FIND: '申し訳ありません。お探しの特許が見つかりませんでした',
        TRY_IN_CORRECT_FORMAT: '他の特許番号を検索するか、番号が正しい形式であることを確認してください（特殊文字を使用しないでください）。',
        NO_CLAIMS: '特許 “{{PATENT_ID}}”には、特許請求の範囲が記載されていません。',
        XRAY: 'X-Ray',
      },
    },
    PATENT_APPLICATION_TOOL: {
      CONTENT: {
        TRADE_SECRETS_RESULTS: 'Wordファイル“{{FILE_NAME}}”内の営業秘密の検索結果',
      },
      NOTIFICATION: {
        NO_CLAIMS:
          'アップロードされたファイルは正しい形式で書かれていないため、検索結果に影響を及ぼす可能性があります。\n“特許請求の範囲”セクションに請求項数のリストが含まれている更新されたファイルを再度アップロードしてください。',
      },
    },
    GENERAL: {
      BUTTONS_TEXT: {
        CANCEL: 'キャンセル',
        SKIP: 'スキップ',
        SAVE_CHANGES: '変更を保存',
        ADD_USER: 'ユーザーを追加',
        ADD_USER_CAPITAL: 'ユーザーを追加',
        UPDATE_USER: 'ユーザーを更新',
        LATER: 'あとで実行',
        ADD: '追加',
        GO_TO_ASSET_PAGE: 'アセットページに移動',
        CONTINUE: '続ける',
        BACK: '戻る',
        DONE: '完了',
        EDIT: '編集',
        YES: 'はい',
        NO: 'いいえ',
        COMPLETE: '完了',
        DELETE: '削除',
        APPLY: '適用',
        NOTIFY: '通知',
        CONFIRM: '確認',
        CLEAR_ALL: 'すべてクリア',
        SEARCH: '検索',
        SUBMIT: '提出',
        I_AM_SURE: 'はい',
        SAVE: '保存',
        REQUEST_ACCESS: 'アクセス権をリクエストする。',
        SAVE_AND_CONTINUE: '保存して続ける',
      },
      TIPPY: {
        MORE_ACTIONS: 'その他のアクション',
      },
      UPLOAD_FILES_MODAL: {
        DRAG_AND_DROP_FILE: 'ここにファイルをドラッグ＆ドロップするか、または',
        SELECT_FILE_FROM_COMPUTER: 'コンピュータからファイルを選択してください',
        DRAG_AND_DROP_FILE_EDIT_MODE: 'ここに新しいファイルをドラッグ＆ドロップするか、または',
        DRAG_AND_DROP_CLICK: 'コンピュータからファイルを参照して選択してください',
        FILE_IS_NOT_SUPPORTED: 'このファイルタイプはサポートされていません。',
        FILE_IS_TOO_LARGE: 'ファイル{{FILE_NAME}}サイズが大きすぎます。{{FILE_SIZE}}MBより小さいファイルをアップロードしてください。',
      },
      ERROR: {
        SOMETHING_WENT_WRONG: '何か問題が発生しました。後でもう一度試してください。',
      },
      SORT_BY_DROPDOWN: {
        NEWEST: '最新',
        RECENTLY_MODIFIED: '最近更新',
        SORT_BY: '並べ替え',
      },
    },
    DASHBOARD_PAGE: {
      HEADER: {
        TITLE: 'ダッシュボード',
        DEPARTMENTS: {
          TITLE: '部門フィルター',
          ALL_DEPARTMENT: 'すべての部署',
          NO_DEPARTMENT: '部署なし',
        },
        EXPORT_DATA: 'データのエクスポート',
      },
      ASSETS: 'アセット',
      NEW_ASSETS: '新しいアセット',
      ASSET_TYPES: 'アセットタイプ',
      PROJECTS: 'プロジェクト',
      NEW_PROJECTS: '新しいプロジェクト',
      TOP_PROJECTS: 'トッププロジェクト（アセットの件数）',
      PEOPLE: 'ピープル',
      NEW_USERS: '新しいユーザー',
      TOP_CONTRIBUTORS: 'トップコントリビュータ',
      AVG_NEW_WEEK: '週平均新規登録',
      ALL_ASSETS: 'すべてのアセット',
      EMPLOYEES: '従業員',
      CONTRACTORS: '請負業者',
      PARTNERS: 'パートナー',
      FIRST_TIME_ENTRY_TEXT:
        ' 私は {{CLIENT_NAME}}との雇用期間において、前職または兼職の雇用主のいかなる営業秘密も不適切に開示または使用しないことを確認します。私は営業秘密について前職または兼職の雇用主（営業秘密の所有者）から書面による承認を得ることなく、 {{CLIENT_NAME}}に持ち込んだり、この{{CLIENT_NAME}}のTagbiglyアカウントに取り込むことはありません。',
      GET_LOGIN_ENTRY_TEXT:
        '本プラットフォーム上の情報およびアセットは {{CLIENT_NAME}}の知的財産であり、もしくは {{CLIENT_NAME}によってきちんとした形で所有されています。そのため、機密性の高いものとして取り扱われなければなりません。{{CLIENT_NAME}}から書面による同意を得ることなく、情報をダウンロード、共有、複製してはなりません。',
      GET_LOGIN_ENTRY_NOTE:
        '\nご注意事項：Tangiblyのプラットフォームは管理を容易にすることを目的としており、総合的なリスク管理を目的とするものではありません。アカウント所有者は異なる説明の、あるいは掲載されていない別の営業秘密を所有していることがあります。',
      WIDGETS: {
        EMPTY_STATE: {
          TITLE: 'アセットが見つかりません',
          BUTTON_CTA_ASSETS: 'アセットを作成する',
          DESCRIPTION: 'アセットを作成して結果を収集する',
        },
        COMMON: {
          NAME: '名前',
          ASSETS: 'アセット',
          TOTAL: '合計',
          TYPES: 'タイプ',
          PAST_12_MONTH: '過去12か月間',
        },
        ASSETS_BY_TYPE: 'アセット（タイプ別）',
        ASSETS_OVER_TIME: 'アセット（時間経過別）',
        TOTAL_ASSETS: 'アセット合計',
        DATA_BY_DEPARTMENTS: {
          TITLE: 'データ（部署別）',
          EMPTY_STATE: {
            TITLE: '部署が見つかりません',
            BUTTON_CTA_ASSETS: 'アセットを見る',
            DESCRIPTION: 'アセットを部署で更新する',
          },
        },
        ASSETS_DATA: 'アセット・データ',
        LABEL: {
          OTHER: 'その他',
          NO_DEPARTMENT: '該当部署なし',
          WEEK: '週',
          GROUP: 'グループ',
          UNPUBLISHED_ASSETS: '非公開アセット',
          CREATED_BY_3RD_PARTY: '第三者が作成したアセット',
          EMPLOYEES: '従業員',
          CONTRIBUTORS: 'コントリビュータ',
          EXTERNAL: '外部',
        },
        PEOPLE_STATUS: 'ピープル・ステータス',
        CONTRIBUTORS: '上位コントリビュータ',
        TOP_USED: {
          TAGS_TITLE: '上位使用タグ',
          TAGS: 'タグ',
          BUSINESS_VALUE_TITLE: '上位使用ビジネス価値',
          BUSINESS_VALUE: 'ビジネス価値',
        },
        USERS: 'ユーザー',
        PROJECTS_BY_ASSETS: {
          TITLE: '上位プロジェクト（アセット別）',
          PROJECT: 'プロジェクト',
          EMPTY_STATE: {
            TITLE: 'プロジェクトが見つかりません',
            BUTTON_CTA_ASSETS: 'アセットを見る',
            DESCRIPTION: 'アセットをプロジェクトで更新する',
          },
        },
        PEOPLE_BY_ROLE: 'ピープル（役割別）',
        INSIGHTS: {
          TITLE: 'インサイト',
          MOST_NOTIFIED_ASSET: '通知数が最も多いアセット',
          MOST_SHARED_BY: '最も資産を共有した人々',
          TOP_ASSET_CREATOR: '上位アセット作成者',
          EMPTY: '該当なし',
        },
      },
      TABLES: {
        ASSET_TABLE: {
          ASSET: 'アセット',
          TYPE: 'タイプ',
          CREATED_ON: '作成日',
          RECIPIENTS: '受信者',
          PROGRESS: '進捗',
          COMPLIANT: '適合',
        },
        PEOPLE_AND_CONTRACTORS_TABLE: {
          NAME: '名前',
          STATUS: 'ステータス',
          COMPLIANT: '適合',
          TRAINING: 'トレーニング',
          START_DATE: '開始日',
          EXIT_DATE: '終了日',
          ACTIONS: 'アクション',
          ACKNOWLEDGMENT: '承認',
          AGREEMENTS: '契約',
          IS_SIGNED: '署名されました',
          NOT_SIGNED: '署名されていません',
          AND_MORE: '{{count}} 以上',
          MISSING: '見つかりません',
          TRAINING_COMPLETED: 'トレーニングを完了しました',
          ASSETS_RECEIVED: 'アセットを受領しました',
        },
      },
    },
    SIDE_BAR: {
      HOME: 'ホーム',
      PREDICTED_PORTFOLIO: '予測ポートフォリオ™',
      COMPETITIVE_INTELLIGENCE: '競合分析',
      DISCOVER: '発見する。',
      PATENT_XRAY: '特許X－レイ',
      CONTINUATION_ANALYSIS: '継続解析',
      INVENTION_DISCLOSURE: '発明の開示',
      CONTRACT_ANALYZER: '契約書チェッカー',
      EMAIL_TRACKER: 'Eメール追跡',
      TRAINING: 'トレーニング',
      MANAGE: '管理する。',
      DASHBOARD: 'ダッシュボード',
      ASSETS: 'アセット',
      PEOPLE: 'ピープル',
      PARTNERS: 'パートナー',
      PARTNER_DRAWER: {
        PARTNERS: 'パートナー',
        ADD_PARTNER: 'パートナーを追加',
        SEARCH_BY_NAME: '名前で検索',
        ENABLED: '有効',
        DISABLED: '無効',
        PARTNER_NOT_FOUND: 'パートナーが見つかりません',
        TRY_ANOTHER_NAME: '別の名前で検索してください',
        NO_ACTIVE_PARTNERS_TO_SHOW: '表示するアクティブなパートナーがいません',
        NO_DISABLED_PARTNERS_TO_SHOW: '表示する無効なパートナーがいません',
        AI_TECHNOLOGY: 'AIテクノロジーを用いて、取引の関係者すべてが契約上の義務を負い、それぞれの契約を守るよう確保してください',
        DATA_AND_MANAGEMENT: 'データの取り扱いと管理を簡素化',
        ENSURE_AGREEMENTS: '契約はすべて、当該条件に従って守られるよう確保してください。',
        NO_CONFUSION: '共有マテリアルのステータスについて混乱は生じていません。',
      },
      SWITCH_CLIENTS: 'クライアントを切り替える',
      //OLD
      COMPETITIVE_ANALYSIS: '競合分析',
      XRAY: 'X-Ray',
      LEARN: '学習',
      SETTINGS: '設定',
      HOMEPAGE: 'ホームページ',
      //
    },
    LEARN_PAGE: {
      MY_COURSES: 'マイコース',
      SAVE_CHANGES: '変更を保存',
      REQUIRED: '必須',
      GO_BACK_TO_COURSES: 'コースに戻る',
      CONTINUE_TO_QUIZ: 'クイズに進む',
      I_CONFIRM: 'コースを視聴しました',
      TAKE_TO_QUIZ: 'クイズに答える',
    },
    SETTINGS_PAGE: {
      WELCOME_MESSAGE:
        'ここで行う選択は、組織内の全ユーザに影響します。これらのオプションをよく検討してから設定してください。 一部の設定は、Tangiblyのプラットフォームからあとで変更できません。ご質問がある場合、または将来的にこれらの設定を変更する必要がある場合は、カスタマーサポートチームにお問い合わせください。',
      UPLOAD_FILES: 'ファイルをアップロード',
      SAVE: '保存',
      LANGUAGE: {
        SYSTEM_LANGUAGE: 'システム言語',
        YOU_CAN_CHANGE: 'あとからプロフィールエリアで、アカウント専用に言語を変更することができます。',
      },
      IMPORTANCE_FIELDS: {
        ASSET_CATEGORY: 'アセットの分類',
        YOU_CAN_CHANGE: '企業ニーズに応じて、以下のパラメータの変更が可能です。以下で指定した分類は、新しいアセットの作成時に、アセットの分類オプションとして従業員に表示されます。',
        RESET_TO_DEFAULT: 'デフォルトにリセット',
        ENTER_CATEGORY: '分類を指定',
        TEXT: 'テキスト',
        FIELD_IS_REQUIRE: 'このフィールドは必須です',
      },
      CHANGE_LANGUAGE: '言語を変更する',
      TIPPY: {
        SETTING: 'これらの設定は、Tangiblyのプラットフォームから変更できません。変更が必要な場合は、カスタマーサポートチームにお問い合わせください。',
        ACCOUNTS: 'アカウント',
      },
      SET_ASSET_TYPE: 'アセットタイプを設定',
      COMPLIANCE_REQUIREMENTS: 'コンプライアンス要件',
      CONFIRM: '確認',
      CONFIRM_START_USING: '確認およびTangiblyの利用を開始',
      PLACEHOLDER: {
        SELECT_ONE: '1つ選択',
      },
      TITLE: {
        PERMISSIONS: '承認',
        EMPLOYEES: '従業員',
        CONTRACTORS: '請負業者',
        PARTNERS: 'パートナー',
        ORGANIZATION_SETTINGS: '組織の設定',
        ACCOUNTS: 'アカウント',
      },
    },
    PATENT_MAPPING_TOOL: {
      TITLE: {
        POTENTIAL_TS: 'ファイル“{{FILE_NAME}}”で開示された潜在的な企業秘密',
        POTENTIAL_TS_ID: '特許“{{PATENT_ID}}”の潜在的な企業秘密',
        POTENTIAL_TS_TITLE: '"{{PATENT_TITLE}}" の潜在的な企業秘密',
        IS_MAPPING_TOOL: {
          TITLE: 'マッピング',
        },
        PATENT_NUMBER: '特許番号',
        YEAR: '年',
        INVENTORS: '発明者',
        RESULTS: '検索結果',
        DISCLOSURE_FOUND_IN_FILE: 'ファイル内で開示が見つかりました',
        MISSING_IN_FILE: 'ファイル内で見つかりません',
        MAPPING_ANALYSIS_IN_PROGRESS: 'マッピング解析を進行中',
        MAPPING_ANALYSIS_NOT_FOUND: 'この特許に関するマッピング解析が見つかりません',
        NO_REFERENCE: '仕様内で参照が見つかりません',
      },
      NOTIFICATION: {
        NO_CLAIMS:
          'アップロードされたファイルは正しいフォーマットで記述されていないため、結果に影響を与える可能性があpります。“特許請求の範囲”セクションに請求項数のリストが含まれている更新ファイルをアップロードしてください。',
        DESC_NOT_FOUND: 'アップロードされたファイルは正しいフォーマットで記述されていないため、結果に影響を与える可能性があります。',
      },
      HIGHLIGHTS: {
        OVERALL_DISCLOSURE: '全体的な開示',
        DESCRIPTIONS_FOUND_IN_FILE: 'ファイル内で見つかった説明テキスト',
        FOUND: '見つかりました',
        NOT_FOUND: '見つかりません',
        SPECIFICATION_FOUND: '仕様のステータス',
      },
      EXTRA: {
        MADE_IMPROVEMENTS_IN_FILE: '申請ファイルに改良が加えられましたか？',
        UPLOAD_MODIFIED_FILE: 'アップロード',
      },
      TOOLTIP: {
        DESCRIPTION_FOUND_IN_FILE: 'ファイル内で見つかった説明テキスト',
        SHOW_ORIGINAL_TEXT: '元のテキストを表示',
        MISSING_IN_FILE: 'ファイル内で見つかりません',
        NO_REFERENCE_IN_THE_SPECIFICATION: '仕様に参照が見つかりませんでした',
        COPY: 'コピー',
        COPIED: 'コピーされました！',
        ORIGINAL_TEXT_COPIED: '元のテキストがコピーされました！',
        ORIGINAL_TEXT: '元のテキスト',
        WE_USE_OPEN_AI_TECHNOLOGY: 'オープンAI テクノロジーを使用して営業秘密を抽出します。',
        IT_CAN_STILL_BE_A_TRADE_SECRET: '仕様に開示が見られたとしても、開示された内容によっては引き続き営業秘密になる可能性があります',
        STRONG_CANDIDATE_FOR_A_TRADE_SECRET: 'これは開示不足のため、営業秘密の有力な候補です',
        ALIAS_ID_COPY: 'Eメールで資産を作成するには、新しいEメールの受信者フィールドにエイリアスリンクを貼り付けて、資産ファイルを添付してください',
        ALIAS_LINK: 'エイリアスリンク',
      },
      POPUP: {
        HOW_TO_USE_THIS_FEATURE: 'この機能の使い方',
        WAIT_HOW_CAN: 'そもそも、特許に営業秘密が含まれることがあるのはなぜでしょうか？公的な文書であるからです！',
        EXTRACTION_TEXT:
          '多くの場合、特許は重要な情報が開示されない形で書かれています。特許X‐レイは特許取得済みの技術に関連する潜在的な営業秘密を示すように設計されたAIエンジンです。特許X‐レイを実行すると、営業秘密は主要なカテゴリーに分類され、各カテゴリーにおいて潜在的な営業秘密の分野がいくつかが示されます。',
        DONT_SHOW_THIS_AGAIN: '再度表示しない',
        OK_I_GET_IT: '分かりました！',
        MAPPING_ANALYSIS: 'マッピング解析',
        MAPPING_TEXT:
          '特許において開示された内容に応じて、特許X‐レイが指摘する営業秘密が実際に営業秘密に相当するか否かが決まります。当社のマッピング機能は、特許明細書に含まれる潜在的な営業秘密の関連性を発見します。結果の上にマウスを移動すると、特許明細書で関連する開示が見つかった場合、右側にポップアップ表示されます。これは特許明細書からの実際のテキストです。',
        EXPORT: 'エクスポート',
      },
      TOAST: {
        FILES_EXPORT: 'ファイルをエクスポート',
        CONTINUATION_ANALYSIS: '特許X-レイ 継続解析レポート {{patentId}}',
        MAPPING_ANALYSIS: '特許X-レイ マッピング解析レポート {{patentId}}',
        TRADE_SECRET: '特許X-レイ 営業秘密分析レポート {{patentId}}',
        CONTINUATION_ANALYSIS_EXCEL: '特許Xレイ継続解析 Excelレポート{{patentIdentifier}}',
        CONTINUATION_ANALYSIS_WORD: '特許Xレイ継続解析 Wordレポート{{patentIdentifier}}',
        WORD_NOT_EXPORTED: 'Wordドキュメントがエクスポートされませんでした。',
        PLEASE_TRY_AGAIN: 'もう一度お試しください。',
      },
      MODAL: {
        EXPORT_PATENT_DATA: '特許X-レイデータのエクスポート',
        MAPPING_ANALYSIS_EXCEL: 'マッピング解析 Excel',
        EXTRACTION_ANALYSIS_EXCEL: '抽出解析 Excel',
        TRADE_SECRET_ANALYSIS_PDF: '営業秘密分析 PDF',
      },
    },
    LOGIN_PAGE: {
      RESET_PASSWROD_MODAL: {
        RESET_PASSWORD: 'パスワードのリセット',
        VERIFICATION_CODE: 'メールアドレスに確認コードとリンクを送信しました。',
        ENTER_USERNAME: 'ここにユーザー名を入力：',
        ENTER_EMAIL: 'Eメールアドレスを入力してください',
        USERNAME: 'ユーザー名',
        EMAIL: 'Eメール',
      },
      NAVBAR: {
        HOME: 'ホーム',
        DASHBOARD: 'ダッシュボード',
        LOGIN: 'ログイン',
      },
      ERROR_OCCURRED: 'エラーが発生しました',
      CARD: {
        TRADE_SECRETS_SECURED: '営業秘密が安全に保たれるようにお手伝いします',
        KEEPING_TRADE_SECRETS: '営業秘密が ＜強力＞かつ安全に保たれるようにすることが弊社の最優先事項です',
      },
      LOGIN_FORM: {
        LOGIN: 'ログイン',
        USERNAME: 'ユーザー名',
        EMAIL: 'Eメール',
        PASSWORD: 'パスワード',
        SIGN_IN: 'サインイン',
        FORGOT_PASSWORD: 'パスワードをお忘れですか？',
        OTP_SIX_DIGITS: 'ワンタイムパスコードは6桁です',
        WRONG_OTP: 'ワンタイムパスコードが間違っています',
        LOGIN_AGAIN: 'エラーが発生しました。ログインし直してください',
      },
      TWO_FACTOR_AUTH: {
        SET_UP: '二要素認証を設定',
        IN_ORDER_TO_CONTINUE: '続けるには、以下のステップを完了してください：',
        STEP_1: '1. アプリ“Google Authenticator”をお使いのモバイル端末にダウンロード',
        STEP_2: '2.アプリ内で“+”ボタンをクリックし、以下のQRコードをスキャン',
        STEP_3: '3.受け取った6桁のコードを入力',
        ENTER_CODE: 'アプリに表示されたコードを以下に入力してください。一度接続が確立されると、電話番号が記憶されます。',
        YOU_CAN_USE_IT: 'そのため、ログインのたびに入力する必要がありません。',
        NOTE: '注：ログインするたびに、認証アプリを開いて最新の6桁のコードを取得する必要があります。',
        ENTER_OTP: '認証アプリで生成されたワンタイムパスコードを入力して、アカウントの認証を行ってください',
        HAVING_TROUBLE: 'ログインに問題がありますか？- お問い合わせはこちら ',
      },
      REMEMBER_MY_DEVICE: {
        DONT_ASK_AGAIN: 'このデバイスでは今後尋ねない',
      },
    },
    EMAIL_FINDER: {
      TOAST: {
        ASSET_CREATED_SUCCESSFULLY: 'アセットが正常に作成されました',
      },
      MODAL: {
        CLOSE_ASSET: '本当にこのアセットを閉じてもよいですか？',
        ADD_USER_TO: 'ユーザーを追加',
      },
      CONTENT: {
        BACK_TO_X_RAY_HOME: 'X-Rayホームに戻る',
        BACK_TO_FINDER: 'Finderに戻る',
        TO: '{{NAME}}へ',
      },
      TITLE: {
        FINDER: 'Finder',
        KEYWORDS: 'キーワード',
        FILES: 'ファイル',
        PARTICIPANTS: '参加者',
      },
      DISCOVERY_SUBTITLE: {
        PAGE_LAST_UPDATED_ON: 'このページの最終更新日 ',
        REFRESH_THE_PAGE: 'リフレッシュして最新の情報を表示します。',
        WE_ARE_REFRESHING: 'データを再読み込みします。これには数時間かかることがあります。その場合も、システム上で作業を続けることができます。',
      },
      BUTTONS_TEXT: {
        UPDATE_XRAY_DATA: 'X-Rayデータを更新',
        TURN_INTO_ASSET: 'アセットに変換',
      },
      TABLE: {
        NO_DATA: 'データが見つかりません',
      },
      TABLE_HEADERS: {
        EMAILS_SUBJECT: 'メールの件名',
        KEYWORDS: 'キーワード',
        FILES: 'ファイル',
        PARTICIPANTS: '参加者',
        DATE: '日付',
      },
      FETCH_FINDER_DATA: {
        DID_YOU_SIGN_IN: 'Googleアカウントでサインインしましたか？',
        AFTER_AUTHENTICATED_CLICK: '認証が完了したら、以下のボタンをクリックして',
        RECEIVE_NEW_DATA: '新しいデータを受け取ってください',
        CONTINUE_WORKING: 'データのリフレッシュには少し時間がかかることがありますが、そのまま作業を続けることができます。',
        BUTTON_TEXT: {
          START_REFRESHING: 'データのリフレッシュを開始',
        },
      },
    },
    EMAIL_TRACKER: {
      CONTENT: {
        BACK_TO_XRAY: 'X-Rayホームに戻る',
        ASSETS_AT_RISK_FOUND: 'メール内で一致するファイルが見つかったため、アセットは露出のリスクがあります',
      },
      TITLE: {
        TRACKER: 'Tracker',
      },
      TABLE: {
        NO_DATA: 'データが見つかりません',
      },
      TABLE_HEADERS: {
        ASSET: 'アセット',
        FILE_MATCH: 'ファイルの一致',
        PEOPLE_EXPOSED: '露出しているピープル',
        DATE: '日付',
        ACTIONS: 'アクション',
      },
      TOOLTIP: {
        NOTIFY: '通知',
        REGISTERED: '登録済',
        NON_REGISTERED: '未登録',
        ADD_USERS: 'ユーザーを追加',
      },
      TOAST: {
        SUCCESS_REQUEST_ACCESS: 'ありがとうございます。後ほどご連絡します。',
      },
    },
    EMAIL_TRACKER_MAIN_PAGE: {
      NOTIFICATION: {
        TITLE: 'Tangiblyのサンプル・プロジェクトを表示しています。',
        DESCRIPTION: '貴社のEメールの追跡をご希望ですか？ぜひご連絡ください。',
      },
    },
    POLICY: {
      OPTIONS: {
        DOWNLOAD: 'ダウンロード',
        EDIT: '編集',
        UPLOAD: 'アップロード',
      },
      UPLOAD: {
        TITLE: '営業秘密のポリシー',
        SAVE: '保存',
      },
      NEW_TEXT_NOTICE: 'NEW！営業秘密のポリシー',
      POLICY_PHRASE_TOP: '管理者は営業秘密のポリシードキュメントを にアップロードすることができます',
      POLICY_PHRASE_BOTTOM: 'は全従業員に表示されます。',
      TOAST: {
        SUCCESS: 'ポリシーが正常に変更されました。',
        ERROR: '失敗しました。もう一度試してください。',
      },
    },
    HEADER: {
      LOG_OUT: 'ログアウト',
    },
    LAWYER_CLIENTS: {
      ASSETS: 'アセット',
      NEW_ASSETS: '新しいアセット',
      NO_ASSETS: 'アセットがありません',
      VIEW_ALL_ASSETS_OF: '{{CLIENT_NAME}} のアセットをすべて表示する',
      CREATE_FIRST_ASSETS_WITH: '{{CLIENT_NAME}} との最初のアセットを作成する',
      NO_CLIENTS_AT_THE_MOMENT: '現在、クライアントが見つかりません',
      PROBLEM_LOADING_CLIENTS: 'クライアントのロードに失敗しました。',
      TITLE: '開始するクライアントを選択してください',
      SUBTITLE: 'トップバーにあるドロップダウンメニューから希望のクライアントを選択することで、プラットフォーム上のクライアント間を簡単に切り替えることができます。',
      CHOOSE_CLIENT: 'クライアントを選択',
    },
    ACTIVITY_LOG: {
      ASSET: {
        ASSET_EDITED: 'アセットが編集されました',
        ASSET_CREATED: 'アセットが作成されました',
        WERE_NOTIFIED: 'アセットについて+{{NAMES}}に通知されました',
        WAS_NOTIFIED: 'アセットについて{{ACTION}}が通知されました',
        WAS: 'されました',
        WERE: 'されました',
        TITLE: 'タイトル',
        DESCRIPTION: '説明',
        ASSET_TYPE: 'タイプ',
        TAG: 'タグ',
        PROJECT: 'プロジェクト',
        DEPARTMENT: '部署',
        BUSINESS_VALUE: '事業価値',
        FILES: 'ファイル',
        CONTRIBUTOR: 'コントリビュータ',
        OTHERS1: 'その他1',
        OTHERS2: 'その他２',
        IMPORTANCE: '分類',
        FILE_LOCATION: 'ファイルの場所',
        FILE_LABEL: 'ファイルのラベル',
        FILE_LOCATION_AND_LABEL: 'ファイルのラベルと場所',
        COMMERCIAL_PRODUCT: '商用製品',
        BUSINESS_RELATED: '事業関連',
        COMMERCIAL_PRODUCT_AND_BUSINESS_RELATED: '商用製品と事業関連',
        RECIPIENTS: 'アセットについて通知されました',
        RECIPIENT_REMOVED: '通知が削除されました',
        ACKNOWLEDGED_ASSET: '認識されたアセット',
        DISCLAIMER: 'Prior to this date, the activity log data details is partial',
      },
      CREATED: '作成されました',
      EDITED: '編集されました',
      DELETED: '削除されました',
      ADDED: '作成されました',
      BY: '{{NAME}}によって',
      ERROR_STATE: 'あいにく現在、アセットのアクティビティを表示できません',
      TRY_RELOAD: 'ページを再読み込みしてください',
      PREVIOUS_VALUE: '以前の入力データ',
      UPDATED_VALUE: '以前の入力データ',
    },
    INVENTION_DISCLOSURE: {
      MODAL: {
        TITLE: '発明の開示テキスト',
        DELETE_ITEM: 'この項目を削除しますか？',
        DELETE_ITEM_CONTENT: 'この項目を削除すると、削除された項目の復元や内容の表示ができなくなり、他の項目との関連付けが解除されます。',
        DONT_SHOW_AGAIN_DELETE: '項目の削除前に確認メッセージを表示しない',
        EDIT_ITEM: 'この項目を編集しますか？',
        EDIT_ITEM_CONTENT: '項目を編集すると、他の項目との関連付けが解除されます。',
        DONT_SHOW_AGAIN_EDIT: '項目の編集前に確認メッセージを表示しない',
        LEAVE_PAGE_TITLE: 'このページから移動してもよろしいですか？',
        UNSAVED_CHANGES: '保存していない変更内容は失われます',
        LEAVE_PAGE_BUTTON: 'このページから移動する',
      },
      TOOLTIP: {
        ASSOCIATIONS: '{{NUMBER}} 個の関連付け',
        ASSOCIATION: '{{NUMBER}} の関連付け',
        DRAG_TO_REORDER_CLAIMS: 'ドラッグして特許請求項を並び替える',
      },
      TITLE: '発明の開示からの、特許請求項と潜在的な営業秘密の関連付け',
      SEE_ORIGINAL_TEXT: '元のテキストを表示',
      PATENT_CLAIMS: '特許のドラフト',
      POTENTIAL_TRADE_SECRETS: '潜在的な営業秘密',
      SHORT_INPUT: '入力した文字列が短いと、検索結果に影響が出ることがあります。検索するには、50ワード以上の文字列にしてください。',
      FOUND_POTENTIAL_TRADE_SECRETS: '発明の開示の中に潜在的な営業秘密が見つかりました',
      WRITE_YOUR_CLAIM_HERE: '特許請求の範囲を記載してください',
      TABS: {
        CLAIMS: '特許請求項',
        SPEC: '仕様',
        COMING_SOON: '近日公開',
        ADD_CLAIM: '特許請求項を追加',
      },
      PLACEHOLDER: {
        WRITE_YOUR_CLAIM_HERE: 'ここにあなたの主張を記入してください',
      },
    },
    UNDER_CONSTRUCTION_PAGE: {
      TITLE: 'セキュリティ更新を行っています',
      SUBTITLE: '現在サービスを利用できません',
    },
    IP_AUDIT: {
      HEADER: 'クイックスタート',
      BUTTON_TEXT: {
        START_NOW: 'さぁ始めましょう',
        UPGRADE_TO_AUDIT: 'アップグレードしてロックを解除する',
        SEE_INFO: '情報を見る',
        COMPLETED: '完了',
      },
      COMING_SOON: '近日公開',
      CONTACT_US: '問い合わせる',
      BANNER_TEXT: 'これらの {{TOTAL}} ステップを取ることで、営業秘密を保護し、合理的な措置を速やかに講じることが可能です。',
      TOAST: {
        ERROR: '失敗しました。もう一度お試しください。',
      },
      IP_AUDIT_STEPS: {
        STEP: 'ステップ {{INDEX}}',
        STEP_OF: '{{TOTAL}}のステップ {{INDEX}}',
        COMPLETED: '完了',
        STANDARD_VERSION: '標準版',
        OVERVIEW: {
          NAME: '概要',
          TITLE: '合理的な措置分類への{{TOTAL}}ステップ',
          DESCRIPTION: '知的財産権を特定、評価、管理することにより、会社の事業戦略に沿って効果的な保護と適切な利用を確実にします。',
          COMPLETE_FLOW_BTN: '完全なフロー',
          HR_PROCESS_DESCRIPTION: '人材は営業秘密を保護するための最初で最後の防波堤です。',
          SYSTEM_SECURITY_DESCRIPTION: 'これらのシステムは、デジタル侵害を防ぐ上で非常に重要です。',
          LLM_POLICY_DESCRIPTION: 'LLMとコパイロットは、貴重な情報の秘匿化を大きなリスクにさらします。以下のLLMポリシーをアップロードしてください。',
          TRADE_SECRET_POLICY_DESCRIPTION: '営業秘密の保護はここから始まります。適切なポリシーがなければ、保護することはできません。',
          PROGRESS_BAR_TEXT: '{{COMPLETED}} 完了',
        },
        TRADE_SECRET_POLICY: {
          NAME: '営業秘密ポリシー',
          DESCRIPTION: '営業秘密の保護はここから始まります。適切なポリシーがなければ、保護することはできません。以下の営業秘密ポリシーをアップロードしてください。',
          DOWNLOAD_POLICY: '営業秘密ポリシーはありますか？',
        },
        SYSTEM_SECURITY: {
          NAME: 'システムのセキュリティ',
          SELECTION: '貴組織で使用しているシステムを選択してください。',
          NONE_OF_THE_ABOVE: '上記のいずれにも該当しない',
          DESCRIPTION: 'これらのシステムは、デジタル侵害を防ぐ上で非常に重要です。',
        },
        HR_PROCESS: {
          NAME: '人材プロセス',
          DESCRIPTION: '人材は営業秘密を保護するための最初で最後の防波堤です。',
          PROTOCOL: '貴組織では、オンボーディング／オフボーディングの対象である従業員に対し、秘密情報の適切な取り扱いに関するプロトコルを定めていますか？',
          TRAINING: '従業員は秘密情報の適切な取り扱いについて研修を受けていますか？',
        },
        LLM_POLICY: {
          NAME: 'LLMポリシー',
          DESCRIPTION: 'LLMとコパイロットは、貴重な情報の秘匿化を大きなリスクにさらします。以下のLLMポリシーをアップロードしてください。',
          DOWNLOAD_POLICY: 'LLMポリシーはありますか？',
        },
        CONTRACTS: {
          NAME: '問い合わせ先',
        },
        KEY_ASSETS_SYSTEMS: {
          NAME: 'システムからの主要なアセット',
        },
        KEY_ASSETS_PATENT_X_RAY: {
          NAME: '特許Xレイからの主要なアセット',
        },
      },
    },
  },
};
