/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import { Account } from 'utils/types/account/account';
import { Client } from 'utils/types/client/client';
import { Metadata } from 'utils/types/types';

//TODO: change to ACTIVITY_TYPE
export enum AUDIT_TYPE {
  GROUP_TITLE = 'groupTitle',
  CREATE = 'create',
  EDIT = 'edit',
  DELETE = 'delete',
  NOTIFY = 'notify',
  DELETE_NOTIFY = 'deleteNotify',
  ACKNOWLEDGE = 'acknowledge',
  DISCLAIMER = 'disclaimer',
}

export enum MODIFICATION_TYPE {
  CREATE = 'create',
  EDIT = 'edit',
  DELETE = 'delete',
  ADDED = 'added',
  DISCLAIMER = 'disclaimer',
}
export const MODIFICATION_TYPE_TRANSLATED = {
  [MODIFICATION_TYPE.CREATE]: 'ACTIVITY_LOG.CREATE',
  [MODIFICATION_TYPE.EDIT]: 'ACTIVITY_LOG.EDITED',
  [MODIFICATION_TYPE.DELETE]: 'ACTIVITY_LOG.DELETED',
  [MODIFICATION_TYPE.ADDED]: 'ACTIVITY_LOG.ADDED',
  [MODIFICATION_TYPE.DISCLAIMER]: 'ACTIVITY_LOG.DISCLAIMER',
};

export interface LeanClient extends Pick<Client, 'name'> {
  _id: string;
}

export interface LeanAccount extends Pick<Account, 'displayName' | 'isActive'> {
  user: { email: string };
  createdAt?: string;
  createdFrom?: string;
}

export enum assetFields {
  NAME = 'name',
  DESCRIPTION = 'description',
  ASSET_TYPE = 'assetType',
  TAGS = 'tags',
  PROJECTS = 'projects',
  DEPARTMENTS = 'departments',
  BUSINESS_VALUES = 'businessValues',
  FILES = 'files',
  CONTRIBUTOR = 'contributor',
  OTHERS1 = 'others1',
  OTHERS2 = 'others2',
  URLS = 'urls',
  FILE_LABEL = 'fileLabel',
  FILE_URL = 'fileUrl',
  DISPLAY_NAME = 'displayName',
  IS_PUBLISHED = 'isPublished',
  IMPORTANCE = 'importance',
  ASSET_META_DATA = 'assetMetaData',
  COMMERCIAL_PRODUCT = 'commercialProduct',
  BUSINESS_RELATED = 'businessRelated',
  RECIPIENTS = 'recipients',
  ACKNOWLEDGEMENT = 'acknowledgment',
  ASSET = 'asset',
}
export type TranslationMappingValues = typeof AssetFieldsMapping[keyof typeof AssetFieldsMapping];

export enum AssetFieldsMapping {
  NAME = 'ACTIVITY_LOG.ASSET.TITLE',
  DESCRIPTION = 'ACTIVITY_LOG.ASSET.DESCRIPTION',
  ASSET_TYPE = 'ACTIVITY_LOG.ASSET.ASSET_TYPE',
  TAGS = 'ACTIVITY_LOG.ASSET.TAG',
  PROJECTS = 'ACTIVITY_LOG.ASSET.PROJECT',
  DEPARTMENTS = 'ACTIVITY_LOG.ASSET.DEPARTMENT',
  BUSINESS_VALUES = 'ACTIVITY_LOG.ASSET.BUSINESS_VALUE',
  FILES = 'ACTIVITY_LOG.ASSET.FILES',
  CONTRIBUTOR = 'ACTIVITY_LOG.ASSET.CONTRIBUTOR',
  OTHERS1 = 'ACTIVITY_LOG.ASSET.OTHERS1',
  OTHERS2 = 'ACTIVITY_LOG.ASSET.OTHERS2',
  IMPORTANCE = 'ACTIVITY_LOG.ASSET.IMPORTANCE',
  FILE_LOCATION = 'ACTIVITY_LOG.ASSET.FILE_LOCATION',
  FILE_LABEL = 'ACTIVITY_LOG.ASSET.FILE_LABEL',
  FILE_LOCATION_AND_LABEL = 'ACTIVITY_LOG.ASSET.FILE_LOCATION_AND_LABEL',
  COMMERCIAL_PRODUCT_AND_BUSINESS_RELATED = 'ACTIVITY_LOG.ASSET.COMMERCIAL_PRODUCT_AND_BUSINESS_RELATED',
  COMMERCIAL_PRODUCT = 'ACTIVITY_LOG.ASSET.COMMERCIAL_PRODUCT',
  BUSINESS_RELATED = 'ACTIVITY_LOG.ASSET.BUSINESS_RELATED',
  RECIPIENTS = 'ACTIVITY_LOG.ASSET.RECIPIENTS',
}

type PathConfig = {
  path: string[];
  label: any;
};
export const pathConfigs: PathConfig[] = [
  { path: [assetFields.URLS], label: [AssetFieldsMapping.FILE_LOCATION_AND_LABEL, AssetFieldsMapping.FILE_LOCATION, AssetFieldsMapping.FILE_LABEL] },
  { path: [assetFields.COMMERCIAL_PRODUCT], label: [AssetFieldsMapping.COMMERCIAL_PRODUCT] },
  { path: [assetFields.BUSINESS_RELATED], label: [AssetFieldsMapping.BUSINESS_RELATED] },
  { path: [assetFields.DESCRIPTION], label: AssetFieldsMapping.DESCRIPTION },
  { path: [assetFields.TAGS], label: AssetFieldsMapping.TAGS },
  { path: [assetFields.ASSET_TYPE], label: AssetFieldsMapping.ASSET_TYPE },
  { path: [assetFields.PROJECTS], label: AssetFieldsMapping.PROJECTS },
  { path: [assetFields.DEPARTMENTS], label: AssetFieldsMapping.DEPARTMENTS },
  { path: [assetFields.BUSINESS_VALUES], label: AssetFieldsMapping.BUSINESS_VALUES },
  { path: [assetFields.FILES], label: AssetFieldsMapping.FILES },
  { path: [assetFields.CONTRIBUTOR], label: AssetFieldsMapping.CONTRIBUTOR },
  { path: [assetFields.OTHERS1], label: AssetFieldsMapping.OTHERS1 },
  { path: [assetFields.OTHERS2], label: AssetFieldsMapping.OTHERS2 },
  { path: [assetFields.IMPORTANCE], label: AssetFieldsMapping.IMPORTANCE },
  { path: [assetFields.NAME], label: AssetFieldsMapping.NAME },
  { path: [assetFields.RECIPIENTS], label: AssetFieldsMapping.RECIPIENTS },
];
export const fileUrlAndLabelMapping = {
  [assetFields.FILE_LABEL]: AssetFieldsMapping.FILE_LABEL,
  [assetFields.FILE_URL]: AssetFieldsMapping.FILE_LOCATION,
};

export interface URLItem {
  label: string;
  url: string;
  _id: string;
}
export enum assetMetaDataValues {
  TYPE = 'type',
  VALUE = 'value',
}

export interface ActivityLogData {
  type: string;
  translation: string;
  actionType: MODIFICATION_TYPE;
  from: any[];
  to: any[];
}
export interface ActivityLog {
  createdAt: string;
  auditType: MODIFICATION_TYPE;
  entityType: string;
  subAuditType?: string;
  accountId: LeanAccount | null;
  clientId: LeanClient;
  diff: {
    [key: string]: any;
  };
}

export interface NormalizedData {
  entityType: string;
  date: string;
  actionType: MODIFICATION_TYPE;
  by: {
    account: LeanAccount | null;
    client: LeanClient;
  };
  data: ActivityLogData[];
}
export type ActivityLogs = {
  [key: string]: NormalizedData[];
};

export interface ActivityLogResponse {
  data: DayData[];
  metadata: LeanMetaData;
}

export interface ActivityMetaData extends Pick<Metadata, 'total' | 'page'> {
  limit: number;
  hasMore: boolean;
}

export interface DayData {
  date: string;
  data: ActivityLog[];
}

type ModificationItem = {
  _id: string;
  value: string;
  clientID: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
};

export type assetMetaData = {
  from: ModificationItem[]; // Replace 'any' with a more specific type if the 'from' array has a specific structure
  to: ModificationItem[];
};

type acknowledgeEvent = {
  _id: string;
  asset: string;
  account: string;
  username: string;
  email: string;
  fullName: string;
  sharedBy: string;
  acknowledgedOn: string | null;
  acknowledged: boolean;
  registered: boolean;
  createdFrom: string;
  updatedFrom: string;
  isActive: boolean;
  isPartner: boolean;
  deletedBy: null;
  sharedOn: string;
  createdAt: string;
  updatedAt: string;
};

export type fromAndToValues = acknowledgeEvent[] | string[] | string | undefined;

export interface LeanClient extends Pick<Client, 'name'> {
  _id: string;
}

export interface LeanAccount extends Pick<Account, 'displayName' | 'isActive'> {
  user: { email: string };
  createdAt?: string;
  createdFrom?: string;
}

export interface URLItem {
  label: string;
  url: string;
}

export interface ActivityLog {
  createdAt: string;
  auditType: MODIFICATION_TYPE;
  entityType: string;
  subAuditType?: string;
  accountId: LeanAccount | null;
  clientId: LeanClient;
  diff: {
    [key: string]: any;
  };
}

export interface NormalizedData {
  entityType: string;
  date: string;
  actionType: MODIFICATION_TYPE;
  by: {
    account: LeanAccount | null;
    client: LeanClient;
  };
  data: ActivityLogData[];
}

export interface ActivityLogResponse {
  data: DayData[];
  metadata: LeanMetaData;
}

export interface LeanMetaData extends Pick<Metadata, 'total' | 'page'> {
  limit: number;
  hasMore: boolean;
}

export interface DayData {
  date: string;
  data: ActivityLog[];
}

export const INITIAL_PAGE = 1;
export const INITIAL_LIMIT = 10;
