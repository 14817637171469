import styled from 'styled-components';

import { NEUTRAL_SHADES, SPACING } from '../../../../utils/theme';
import { StyledAssetCard, AssetBodyContainer, AssetCardHeader, AssetCardFooter } from '../../style';

// ----- Locked asset card ----- //

export const StyledLockedAssetCard = styled(StyledAssetCard)`
  background: ${NEUTRAL_SHADES[100]};
`;

export const LockedAssetCardHeader = styled(AssetCardHeader)`
  height: ${SPACING[5]};
`;

export const Divider = styled.hr`
  width: 100%;
  border-bottom: 1px solid ${NEUTRAL_SHADES[600]};
  margin: 0;
`;

export const LockedAssetBodyContainer = styled(AssetBodyContainer)`
  height: 53px;
`;

export const LockedAssetCardFooter = styled(AssetCardFooter)`
  align-self: flex-end;
  width: 152px;
`;
