import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';

import { TangiTypography, TangiSvgIcon, TangiDropdownButton, TANGI_BUTTON_SIZES, IconTypes, WeightType, TangiToast } from '_components/TangiLibrary';
import UploadFileModal from '_components/UploadFileModal/UploadFileModal';
import { RootState } from '_helpers';
import { clientActions } from 'redux-toolkit/slices/clientSlice';
import { deletePolicy, downloadPolicy, uploadFiles } from 'redux-toolkit/thunks/clientThunks';
import { UPLOAD_FILE_TYPES, ENTITY_TYPES, MIME_TYPES, RESULT_STATUS } from 'utils/enums';
import { BUTTON_VARIANTS } from '../../utils/componentUtils';
import { PRIMARY_SHADES, NEUTRAL_SHADES } from '../../utils/theme';
import { DropdownItemContainer, customDropdownStyle } from './style';

interface IPolicyDropDownItemOptions {
  text: string;
  color: string;
  componentIcon?: IconTypes;
  isDisabled: boolean;
  weight?: WeightType;
  handleItem?: () => void | JSX.Element | undefined;
}

interface Props {
  hasPolicyUploadPermissions: boolean;
  isFileExist: boolean;
  isLawyer: boolean;
  activeClientId: string;
}

const PolicyDropDown = ({ hasPolicyUploadPermissions, isFileExist, isLawyer, activeClientId = '' }: Props) => {
  const [showUploadFileModal, setShowUploadFileModal] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { clientId } = useParams<{ clientId: string }>();

  const activeAccount = useSelector((state: RootState) => state.authentication.activeAccount);
  const activeClient = useSelector((state: RootState) => state.lawfirm.activeClient);
  const { policyLoader, policyToastProps } = useSelector((state: RootState) => state.client);

  const acceptedFilesList = Object.values(MIME_TYPES).join(', ');

  const handleDeletePolicy = () => {
    dispatch(deletePolicy({ isLawyer, clientId: clientId ?? activeClientId, accountId: activeAccount._id, type: UPLOAD_FILE_TYPES.POLICY }));
  };

  const handleUploadFile = (file: File[]) => {
    if (isFileExist) {
      handleDeletePolicy();
    }
    if (file.length > 0) {
      dispatch(uploadFiles({ clientId: clientId ?? activeClientId, policyFiles: file, type: UPLOAD_FILE_TYPES.POLICY, isLawyer, accountId: activeAccount._id, entityType: ENTITY_TYPES.ACCOUNTS }));
    }
    setShowUploadFileModal(false);
  };

  const handleDownLoadOption = () => {
    const getPolicyFileName = () => {
      if (!isFileExist) return '';
      return isLawyer ? activeClient?.policyFiles[0]?.fileName : activeAccount?.client?.policyFiles[0]?.fileName;
    };
    dispatch(downloadPolicy(getPolicyFileName()));
  };

  const getDefaultFile = () => {
    if (!isFileExist) return null;
    return isLawyer ? activeClient?.policyFiles : activeAccount?.client?.policyFiles;
  };

  const renderDropDownItem = (item: IPolicyDropDownItemOptions) => {
    return {
      text: (
        <DropdownItemContainer>
          {item?.componentIcon && <TangiSvgIcon color={item?.color} component={item.componentIcon} />}
          <TangiTypography color={item?.color} weight={item.weight}>
            {item?.text}
          </TangiTypography>
        </DropdownItemContainer>
      ),
      handleItem: item?.handleItem,
      disabled: item.isDisabled,
    };
  };

  const renderDropdownOptions = () => {
    const options: IPolicyDropDownItemOptions[] = [
      {
        text: t('POLICY.UPLOAD.TITLE'),
        color: NEUTRAL_SHADES.BLACK,
        isDisabled: true,
        weight: 'bold',
      },
    ];
    if (hasPolicyUploadPermissions) {
      if (isFileExist) {
        options.push(
          {
            text: t('POLICY.OPTIONS.DOWNLOAD'),
            color: PRIMARY_SHADES[800],
            componentIcon: 'download',
            isDisabled: false,
            handleItem: () => {
              handleDownLoadOption();
            },
          },
          {
            text: t('POLICY.OPTIONS.EDIT'),
            color: PRIMARY_SHADES[800],
            componentIcon: 'edit',
            isDisabled: false,
            handleItem: () => {
              setShowUploadFileModal(true);
            },
          },
        );
      } else {
        options.push({
          text: t('POLICY.OPTIONS.UPLOAD'),
          color: PRIMARY_SHADES[800],
          componentIcon: 'upload',
          isDisabled: false,
          handleItem: () => {
            setShowUploadFileModal(true);
          },
        });
      }
    } else if (!hasPolicyUploadPermissions && isFileExist) {
      options.push({
        text: t('POLICY.OPTIONS.DOWNLOAD'),
        color: PRIMARY_SHADES[800],
        componentIcon: 'download',
        isDisabled: false,
        handleItem: () => {
          handleDownLoadOption();
        },
      });
    }

    return options.map((item: IPolicyDropDownItemOptions) => {
      return renderDropDownItem(item);
    });
  };

  return (
    <>
      <Tippy content={t('POLICY.UPLOAD.TITLE')} placement="bottom">
        <span>
          <TangiDropdownButton
            variant={BUTTON_VARIANTS.TERTIARY_GREY}
            size={TANGI_BUTTON_SIZES.MEDIUM}
            arrow={false}
            svgIcon="policyIcon"
            loading={policyLoader}
            endIcon={true}
            options={renderDropdownOptions()}
            data-testid="policy-dropdown-button"
            smallbtn={true}
            dropdownStyles={customDropdownStyle}
          />
        </span>
      </Tippy>
      <UploadFileModal
        title={t('POLICY.UPLOAD.TITLE')}
        submitButtonText={t('POLICY.UPLOAD.SAVE')}
        acceptedFilesList={acceptedFilesList}
        isOpen={showUploadFileModal}
        onClose={() => setShowUploadFileModal(false)}
        onSubmit={handleUploadFile}
        isEditMode={isFileExist}
        defaultFile={getDefaultFile()}
        phraseLineTop={t('POLICY.POLICY_PHRASE_TOP')}
        phraseLineBottom={t('POLICY.POLICY_PHRASE_BOTTOM')}
        onDelete={handleDeletePolicy}
        loading={policyLoader}
      />
      <TangiToast
        {...policyToastProps}
        onSuccess={() => {
          dispatch(clientActions.setPolicyToastToastProps({ show: false, type: RESULT_STATUS.BLANK, text: '' }));
        }}
      />
    </>
  );
};

export { PolicyDropDown };
