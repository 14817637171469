import React from 'react';
import { Form } from 'react-bootstrap';

import { Star } from '../../Star';
import { disabledStyle, TangiInputContainer, InvalidFeedback } from './style';

export const TangiInput = ({ required = false, label, error, placeholder, type = 'text', smallText, register, name, registerRules, disabled = false, ...props }) => {
  return (
    <TangiInputContainer {...props} isDisabled={disabled}>
      {label && <Form.Label className="text-dark fw-600 txt-size">{label}</Form.Label>}
      {required && <Star />}
      <Form.Control
        className="tangi-input"
        disabled={disabled}
        data-testid={name}
        style={error ? { borderColor: 'red' } : disabled ? disabledStyle : {}}
        type={type}
        placeholder={placeholder}
        {...register(name, {
          required: { value: required, message: `${label} is required` },
          ...registerRules,
        })}
      />
      {smallText && <small className="form-text text-muted txt-size">{smallText}</small>}
      <InvalidFeedback isError={error}>{error && error.message}</InvalidFeedback>
    </TangiInputContainer>
  );
};
