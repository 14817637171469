import { useTranslation } from 'react-i18next';

import { TangiButton, TangiSvgIcon } from '../../../TangiLibrary';
import AssetTypeColorBlock from '../../../AssetTypeColorBlock/AssetTypeColorBlock';
import ThirdPartyTag from '../../../ThirdPartyTag/ThirdPartyTag';
import useAssetAttachmentsInfo from '_hooks/useAssetAttachmentsInfo';
import { LOCKED_ASSET } from '../../../../utils/assetTypes';
import lock from '../../../../assets/icons/lock.svg';
import { DuoContainer } from '../../../../utils/globalStyles';
import { StyledLockedAssetCard, Divider, LockedAssetCardHeader, LockedAssetBodyContainer, LockedAssetCardFooter } from './style';
import { FileNameText, NoFilesText, AssetFileNum } from '../../../../pages/ClientAssets/styles';
import { AssetName, ColorAndType, AssetFileContainer, AssetFileIconAndName } from '../../style';
import { Asset } from 'utils/types/assets/assets';

interface Props {
  asset: Asset;
  handleUnlockAsset: (asset: Asset) => void;
}

const AssetCardLocked = ({ asset, handleUnlockAsset }: Props) => {
  const { attachmentName, attachmentIconType, attachmentAdditionalAmount, isAttachmentFile, isAttachmentEmpty } = useAssetAttachmentsInfo({
    filesArr: asset?.files,
    urlsArr: asset?.urls,
    partnership: asset?.createdBy?.partnership,
  });

  const { t } = useTranslation();

  return (
    <StyledLockedAssetCard data-testid="locked-asset-card">
      <LockedAssetCardHeader>
        <ColorAndType>
          <AssetTypeColorBlock assetType={LOCKED_ASSET} />
          <span>{LOCKED_ASSET}</span>
        </ColorAndType>
      </LockedAssetCardHeader>
      <Divider />
      <LockedAssetBodyContainer>
        <DuoContainer>
          <AssetName>
            <span>{asset.name}</span>
          </AssetName>
          {asset?.isThirdParty && <ThirdPartyTag asset={asset} />}
        </DuoContainer>
      </LockedAssetBodyContainer>
      <AssetFileContainer>
        <AssetFileIconAndName>
          {!isAttachmentEmpty && attachmentIconType && <TangiSvgIcon component={attachmentIconType} />}
          {isAttachmentEmpty ? <NoFilesText>{attachmentName}</NoFilesText> : <FileNameText className={isAttachmentFile ? 'file-attachment-name' : ''}>{attachmentName}</FileNameText>}
        </AssetFileIconAndName>
        {!!attachmentAdditionalAmount && <AssetFileNum>{`+ ${attachmentAdditionalAmount}`}</AssetFileNum>}
      </AssetFileContainer>
      <Divider />
      <LockedAssetCardFooter>
        {asset?.needsToBeAcknowledgedByMe && (
          <TangiButton data-testid="acknowledge-asset-button" variant="secondary" text={t('ASSET_PAGE.BUTTONS_TEXT.ACKNOWLEDGE')} icon={lock} onClick={() => handleUnlockAsset(asset)} />
        )}
      </LockedAssetCardFooter>
    </StyledLockedAssetCard>
  );
};

export default AssetCardLocked;
