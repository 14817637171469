import React, { useContext, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { SessionIsOverModal } from '../../_components/SessionIsOverModal';

import { history } from '../../_helpers/history';
import { AuthContext } from '../Cognito/Auth';

const IdleHandler = ({ children }) => {
  const { loggedIn } = useSelector((state) => state.authentication);
  const { logout } = useContext(AuthContext);
  const [promptOpen, setPromptOpen] = useState(false);
  const dispatch = useDispatch();

  const handleOnIdle = () => {
    if (loggedIn) {
      dispatch(logout());
    }
  };

  const onPrompt = () => {
    if (loggedIn) {
      setPromptOpen(true);
    }
  };

  const handleClose = () => {
    history.go(0);
  };

  useIdleTimer({
    // setting idle after 60 minutes
    timeout: 1000 * 60 * 60,
    onIdle: handleOnIdle,
    debounce: 500,
    promptTimeout: 1,
    onPrompt,
  });

  return (
    <>
      <SessionIsOverModal open={promptOpen} handleClose={handleClose} modalBody="Your session has expired due to inactivity. Please login again." />
      {children}
    </>
  );
};

export default IdleHandler;
