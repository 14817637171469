import React, { CSSProperties } from 'react';
import { Modal } from 'react-bootstrap';

import { TangiIconButton } from '../TangiIconButton';
import { BUTTON_VARIANTS } from 'utils/componentUtils';
import { BodyContainer, FooterContainer, HeaderContainer, ModalInnerContainer } from './style';
import './modal.css';

interface SlotStyles {
  style?: React.CSSProperties;
  className?: string;
}

interface Props {
  show: boolean;
  onHide: () => void;
  header: React.ReactNode;
  body: React.ReactNode;
  footer?: React.ReactNode;
  containerStyle?: CSSProperties;
  slotsStyles?: {
    bodyContainer?: SlotStyles;
    headerContainer?: SlotStyles;
    footerContainer?: SlotStyles;
  };
  dataTestId?: string;
}

const TangiModal = ({ show, onHide, header, body, footer, containerStyle, slotsStyles, dataTestId }: Props) => {
  return (
    <Modal
      dialogClassName="tangi-modal-centered"
      backdropClassName="tangi-modal-backdrop"
      contentClassName="tangi-modal-content"
      show={show}
      onHide={onHide}
      centered
      data-testid={`modal-${dataTestId}`}
    >
      <ModalInnerContainer style={containerStyle}>
        <HeaderContainer style={slotsStyles?.headerContainer?.style} className={slotsStyles?.headerContainer?.className}>
          {header}
          <TangiIconButton variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={onHide} icon="close" />
        </HeaderContainer>

        <BodyContainer className={`tangi-modal-body ${slotsStyles?.bodyContainer?.className || ''}`} style={slotsStyles?.bodyContainer?.style}>
          {body}
        </BodyContainer>

        {footer && (
          <FooterContainer style={slotsStyles?.footerContainer?.style} className={slotsStyles?.footerContainer?.className}>
            {footer}
          </FooterContainer>
        )}
      </ModalInnerContainer>
    </Modal>
  );
};

export default TangiModal;
