import { TangiTypography } from '_components/TangiLibrary';
import { Container } from './styles';
import { NEUTRAL_SHADES, PRIMARY_SHADES } from 'utils/theme';
import { IpAuditStepKey } from '_components/IpAudit/types';
import { useTranslation } from 'react-i18next';
import { useIpAudit } from '_components/IpAudit/useIpAudit';

export interface StepTitleProps {
  stepKey: IpAuditStepKey;
}

export const StepTitle = ({ stepKey }: StepTitleProps) => {
  const { userSteps } = useIpAudit();

  const stepData = userSteps.find(({ key: _key }) => stepKey == _key);
  const { t } = useTranslation();
  return (
    <Container>
      <TangiTypography weight="bold" color={PRIMARY_SHADES[1200]} type="heading-md">
        {t(stepData!.name)}
      </TangiTypography>
      <TangiTypography color={NEUTRAL_SHADES[800]} type="subheading">
        {t('IP_AUDIT.IP_AUDIT_STEPS.STEP_OF', { INDEX: stepData!.index, TOTAL: userSteps.length })}
      </TangiTypography>
    </Container>
  );
};
