import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RegisterLogin } from '../../_components/RegisterLogin/RegisterLogin';
import { Loader } from '../../_components/Loader';
import { InvalidError } from '../../_components/InvalidError/InvalidError';
import { acceptInvitation } from 'redux-toolkit/thunks/usersThunks';
import { setChangeOfActiveAccount, clearAuthState } from 'redux-toolkit/slices/authSlice';
import { AuthContext } from '../../_hocs/Cognito/Auth';
import { isTypeAccountMinimalData } from 'utils/types/account/guards';
import { getRedirectRoute } from 'utils/roles';
import { REQUEST_STATUS, RESPONSE_STATUS_CODES } from 'utils/enums';
import { AppRoutes } from 'utils/routing/consts';

const AcceptInvitePage = () => {
  const dispatch = useDispatch();
  const urlString = window.location.href;
  const url = new URL(urlString);
  const encryptedToken = url.searchParams.get('token');

  const loggedIn = useSelector((state) => state.authentication.loggedIn);
  const activeAccount = useSelector((state) => state.authentication.activeAccount);
  const acceptInviteError = useSelector((state) => state.users.acceptInviteError);
  const loading = useSelector((state) => state.users.loading);

  const [shouldRenderRegisterLogin, setShouldRenderRegisterLogin] = useState(false);

  const { logout } = useContext(AuthContext);
  const history = useHistory();

  const handleLogoutAndRedirect = (route) => {
    dispatch(logout());
    dispatch(clearAuthState());
    if (route) history.push(route);
  };

  const handleInvitationResult = (result) => {
    const isRejected = result.meta.requestStatus === REQUEST_STATUS.REJECTED;
    const isPartialContent = result.payload?.status === RESPONSE_STATUS_CODES.PARTIAL_CONTENT;
    const isOk = result.meta.requestStatus === REQUEST_STATUS.FULFILLED && result.payload?.status === RESPONSE_STATUS_CODES.OK;
    const newAccount = result.payload?.account;

    // Case 1: Request rejected or no account
    if (isRejected || !newAccount) {
      handleLogoutAndRedirect();
      return;
    }

    // Case 2: Minimal account data, logged in, partial content
    if (isTypeAccountMinimalData(newAccount) && isPartialContent && loggedIn) {
      handleLogoutAndRedirect(AppRoutes.LOGIN);
      return;
    }

    // Case 3: Request fulfilled, status OK, logged in
    if (isOk && loggedIn) {
      const redirectRoute = getRedirectRoute(newAccount);
      dispatch(setChangeOfActiveAccount(newAccount));
      history.push(redirectRoute);
      return;
    }

    // Case 4: Minimal account data, partial content, not logged in
    if (isPartialContent && isTypeAccountMinimalData(newAccount) && !loggedIn) {
      setShouldRenderRegisterLogin(true);
      return;
    }

    // Default case: Logout and redirect to login
    handleLogoutAndRedirect(AppRoutes.LOGIN);
  };

  useEffect(() => {
    dispatch(acceptInvitation(encryptedToken)).then(handleInvitationResult);
  }, []);

  if (loading) return <Loader isLoading={loading} />;
  if (acceptInviteError) return <InvalidError type="invite" />;
  if (shouldRenderRegisterLogin || !activeAccount) return <RegisterLogin encryptedToken={encryptedToken} />;

  return null;
};

export { AcceptInvitePage };
