import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TangiButton, TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { useHistory, useParams } from 'react-router-dom';
import { EmptyWidgetProps } from '../Widgets.types';
import { generateRoute } from 'utils/routing/generateRoute';
import { AppRoutes } from 'utils/routing/consts';

const EmptyStateTitle = styled(TangiTypography)`
  margin-top: ${SPACING[2]};
`;

const EmptyStateDescription = styled(TangiTypography)`
  margin-top: ${SPACING[1]};
`;

const EmptyWidget = ({ emptyTitle, emptyDescription, emptyButton }: EmptyWidgetProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { clientId } = useParams<{ clientId: string }>();

  const handleClick = () => {
    history.push(generateRoute(AppRoutes.ASSETS_MAIN_PAGE, { clientId }));
  };

  return (
    <>
      <TangiSvgIcon component="noAssetsFound" />
      <EmptyStateTitle weight="semibold" color={NEUTRAL_SHADES[1100]}>
        {emptyTitle ?? t('DASHBOARD_PAGE.WIDGETS.EMPTY_STATE.TITLE')}
      </EmptyStateTitle>
      <EmptyStateDescription color={NEUTRAL_SHADES[1100]}>{emptyDescription ?? t('DASHBOARD_PAGE.WIDGETS.EMPTY_STATE.DESCRIPTION')}</EmptyStateDescription>
      <TangiButton
        text={emptyButton?.text ?? t('DASHBOARD_PAGE.WIDGETS.EMPTY_STATE.BUTTON_CTA_ASSETS')}
        size="small"
        style={{ width: 111, marginTop: SPACING[3] }}
        onClick={emptyButton?.onClick ?? handleClick}
        data-testid="widget-card-empty-state-assets-cta"
        smallbtn={true}
      />
    </>
  );
};

export default EmptyWidget;
