import _ from 'lodash';
import i18next from 'i18next';

import { CREATED_FROM } from '../utils/enums';
import { ASSET_TYPE, assetTypeNameFormatter, isCustomAssetType } from './assetTypes';
import { IRoles } from './roles';
import { ASSET_TYPE_COLORS, CUSTOM_ASSET_TYPE_COLORS, PIE_CHART_COLORS } from './theme';
import { AccountRawData, AssetRawData, Account, FILTER_BY, FilterBy, RecipientsRawData, RecipientAsset, SharedBy } from './types/dashboard/dashboard';
import { WidgetDataObject, WidgetAccountObject } from '../pages/ClientDashboard/components/Widgets/Widgets.types';
import { PIE_CHART_CONSTS } from '../pages/ClientDashboard/consts';
import { IdAndName } from './types/types';

interface WidgetDataObjectMap {
  [key: string]: WidgetDataObject;
}
type GroupByField = 'projects' | 'tags' | 'businessValues' | 'departments';

interface Filterable {
  departments?: (IdAndName | string)[];
  asset?: {
    departments?: (IdAndName | string)[];
  };
}

export enum ROLE_GROUP_NAMES {
  CONTRACTORS = 'Contractors',
  PARTNERS = 'Partners',
  LAWYERS = 'Lawyers',
  ADMINS = 'Admins',
  EMPLOYEES = 'Employees',
  MANAGERS = 'Managers',
  HR = 'HR',
}

/**
 * Generic function to filter an array of items by their department.
 * It accommodates items where departments are either directly on the item or within an 'asset' property of the item.
 *
 * @template T - A type that extends the Filterable interface, ensuring the item has a 'departments' property or an 'asset' with a 'departments' property.
 *
 * @param {T[]} items - An array of items to filter. Each item must have a 'departments' property or 'asset.departments'.
 * @param {(FilterBy | string)} [departmentFilter=FILTER_BY.ALL_DEPARTMENT] - The department criteria to filter by.
 *    If FILTER_BY.ALL_DEPARTMENT, all items are returned.
 *    If FILTER_BY.NO_DEPARTMENT, only items with no departments are returned.
 *    Otherwise, only items containing the department specified by departmentFilter are returned.
 *
 * @returns {T[]} A filtered array of items based on the department criteria.
 */
export const filterByDepartment = <T extends Filterable>(items: T[], departmentFilter: FilterBy = FILTER_BY.ALL_DEPARTMENT): T[] => {
  return items.filter((item: T) => {
    const departments = item.departments || item.asset?.departments;

    if (!departments) return false;

    switch (departmentFilter) {
      case FILTER_BY.ALL_DEPARTMENT:
        return true; // return all items if the filter is ALL_DEPARTMENT
      case FILTER_BY.NO_DEPARTMENT:
        return departments.length === 0; // return items with no departments if the filter is NO_DEPARTMENT
      default:
        // filter by specific department
        return departments.some((dep: IdAndName | string) => (typeof dep === 'string' ? dep === departmentFilter : dep?._id === departmentFilter));
    }
  });
};

/**
 * Finds the top asset creator(s) based on the number of assets created.
 *
 * This function groups assets by creator, counts the assets per creator, and identifies the creator(s) with the most assets.
 * It returns an array of objects containing information about each top creator, and the number of assets they've created.
 * In case of assets without a 'createdBy' field, they are omitted from grouping.
 * If there are multiple top creators with the same number of assets, they are all included in the results, sorted by their display names.
 * If no assets are provided, an empty array is returned.
 *
 * @param {AssetRawData[]} assets - The array of assets to analyze. Each asset should contain a 'createdBy' object with the creator's details.
 * @returns {WidgetAccountObject[]} An array of objects representing the top asset creator(s). Each object includes the creator's ID, display name, email, 'createdFrom' origin, and asset count.
 */
export const findTopAssetCreator = (assets: AssetRawData[]): WidgetAccountObject[] => {
  // Filter out assets without a createdBy field
  const validAssets = assets.filter((asset) => asset.createdBy !== undefined);

  // Group assets by creator ID
  const groupedAssets = _(validAssets)
    .groupBy((asset) => asset.createdBy?._id)
    .map((group: AssetRawData[]): WidgetAccountObject => {
      const createdBy = group[0].createdBy!;
      return {
        _id: createdBy._id!,
        displayName: createdBy.displayName,
        email: createdBy.user.email,
        value: group.length,
        createdFrom: createdBy.createdFrom,
      };
    })
    .value();

  if (!groupedAssets.length) {
    return [];
  }

  const maxAssetCount = _.maxBy(groupedAssets, 'value')!.value;

  return _(groupedAssets)
    .filter((item) => item.value === maxAssetCount)
    .sortBy((item) => item.displayName.toLocaleLowerCase())
    .value();
};

/**
 * Aggregates groups beyond a certain index into a single "Other" group.
 *
 * This function takes an array of group objects, each expected to have a 'value' property.
 * It slices the array to only include groups beyond a predefined index (PIE_CHART_CONSTS.MAX_GROUPS)
 * and aggregates their 'value' properties into a single cumulative value.
 * This aggregated data is then used to create a new "Other" group, which is returned as an object.
 *
 * @param {WidgetDataObject[]} groupedArray - The array of group objects to be aggregated.
 *        Each group object must include a 'value' property, which is a number representing the group's value.
 * @returns {Object} A new group object representing all groups beyond the PIE_CHART_CONSTS.MAX_GROUPS index.
 *          The object includes 'id', 'label', 'color', and 'value' properties, where 'value' is the sum of the 'value'
 *          properties of the aggregated groups.
 */
const createOtherGroup = (groupedArray: WidgetDataObject[]): WidgetDataObject => {
  const otherGroups = groupedArray.slice(PIE_CHART_CONSTS.MAX_GROUPS);
  const sumOfOtherValues = _.sumBy(otherGroups, 'value');

  return {
    id: PIE_CHART_CONSTS.OTHER,
    label: i18next.t('DASHBOARD_PAGE.WIDGETS.LABEL.OTHER'),
    color: PIE_CHART_CONSTS.OTHER_COLOR,
    value: sumOfOtherValues,
  };
};

/**
 * Handles the "No Department" case by initializing or incrementing its count.
 *
 * This function checks if a "No Department" entry exists in the result object.
 * If it doesn't, the function initializes a new "No Department" entry with a default value.
 * If the entry already exists, it increments the 'value' property, which keeps a count.
 * The "No Department" case is identified by the FILTER_BY.NO_DEPARTMENT key.
 *
 * Note: This function directly modifies the `result` object passed as a parameter
 * and doesn't return anything.
 *
 * @param {WidgetDataObjectMap} result - The map object holding the groups data,
 *        where the key is the department identifier and the value is an object containing
 *        department details and associated values.
 */
const handleNoDepartment = (result: WidgetDataObjectMap) => {
  if (!result[FILTER_BY.NO_DEPARTMENT]) {
    result[FILTER_BY.NO_DEPARTMENT] = {
      id: FILTER_BY.NO_DEPARTMENT,
      label: i18next.t('DASHBOARD_PAGE.WIDGETS.LABEL.NO_DEPARTMENT'),
      value: 0,
    };
  }
  result[FILTER_BY.NO_DEPARTMENT].value++;
};

/**
 * Processes an array of field values, accumulating counts for each unique field in the result map.
 *
 * This function iterates over each item in the `fieldValues` array, using the item's `_id` property
 * to check if an entry for this field already exists in the `result` map. If an entry exists, it increments
 * the 'value' property for this field. If not, it creates a new entry in the `result` map for the field,
 * initializing 'id', 'label', and 'value' properties for it, with 'value' initialized to 0 before it's incremented.
 *
 * Note: This function mutates the `result` object directly and doesn't return anything.
 *
 * @param {WidgetDataObjectMap} result - The map object that accumulates data for each field.
 *        It's keyed by field identifiers, and each value is an object that contains
 *        the 'id', 'label', and 'value' properties for that field.
 * @param {IdAndName[]} fieldValues - An array of objects, each representing a field value with
 *        an '_id' and 'name' property.
 */
const handleArrayValues = (result: WidgetDataObjectMap, fieldValues: IdAndName[]) => {
  fieldValues.forEach((item) => {
    const fieldNameId = item._id;

    if (!result[fieldNameId]) {
      result[fieldNameId] = {
        id: fieldNameId,
        label: item.name,
        value: 0,
      };
    }

    result[fieldNameId].value++;
  });
};

/**
 * Consolidates groups beyond a certain limit into an "Other" category and adds it to the array if applicable.
 *
 * This function uses `createOtherGroup` to aggregate groups beyond a specific index (PIE_CHART_CONSTS.MAX_GROUPS)
 * into a single "Other" group. If the "Other" group has a 'value' greater than 0, it's added to the end of a new
 * array that contains groups only up to the specified index. If the "Other" group's 'value' is not greater than 0,
 * the original array is returned unmodified.
 *
 * @param {WidgetDataObject[]} array - The array of group objects. Each object in the array must have a 'value' property.
 * @returns {WidgetDataObject[]} A new array of group objects, potentially including the "Other" group if its
 *          'value' is greater than 0. If the "Other" group's 'value' is not greater than 0, the original array
 *          is returned.
 */
const handleOther = (array: WidgetDataObject[]): WidgetDataObject[] => {
  const otherGroup = createOtherGroup(array);
  return otherGroup?.value > 0 ? [...array.slice(0, PIE_CHART_CONSTS.MAX_GROUPS), otherGroup] : array;
};

/**
 * Groups an array of items by a specific field and applies various optional transformations, such as filtering, sorting, and including an "Other" category.
 * @param {Object} params Configuration parameters for the grouping function.
 * @returns {WidgetDataObject[]} - The array of grouped items, each represented as a WidgetDataObject, possibly sorted and/or including an "Other" group.
 */
export const groupArrayByField = ({
  array,
  groupByField,
  departmentFilter = FILTER_BY.ALL_DEPARTMENT,
  includeOther = false,
}: {
  array: (AssetRawData | Account)[];
  groupByField: GroupByField;
  departmentFilter?: FilterBy;
  includeOther?: boolean;
}) => {
  const groupedItemsMap = array.reduce<WidgetDataObjectMap>((accum, item) => {
    const fieldValues = item[groupByField];
    if (groupByField === 'departments' && departmentFilter === FILTER_BY.NO_DEPARTMENT && !fieldValues?.length) {
      handleNoDepartment(accum);
    } else if (Array.isArray(fieldValues)) {
      handleArrayValues(accum, fieldValues);
    }
    return accum;
  }, {});

  let resultArray = Object.values(groupedItemsMap);
  if (groupByField === 'departments' && departmentFilter !== FILTER_BY.ALL_DEPARTMENT) {
    resultArray = resultArray.filter((group) => group.id === departmentFilter);
  }

  resultArray.sort((a, b) => b.value - a.value || a.label.localeCompare(b.label));

  const shouldHandleOther = resultArray.length > PIE_CHART_CONSTS.MAX_GROUPS && includeOther;
  return shouldHandleOther ? handleOther(resultArray) : resultArray;
};

/**
 * Creates a data object for an asset type widget, containing information including a formatted label and specific color according to the asset type.
 * The function first formats the asset type's name, checks if the asset type is custom, and then assigns a color based on this information.
 *
 * @param {AssetRawData[]} group An array of asset raw data, grouped by asset type.
 * @param {string} assetTypeId The unique identifier of the asset type.
 * @param {[key: string]: string} customAssetTypeColorMapping - Dictionary who holds the custom asset type color mapping
 * @returns {WidgetDataObject} A data object containing the ID, a formatted label, specific color, and value (count of assets) for the specified asset type.
 */
const createAssetTypeWidgetDataObject = (group: AssetRawData[], assetTypeId: string, customAssetTypeColorMapping: { [key: string]: string } = {}): WidgetDataObject => {
  const assetType = group[0].assetType;
  const label = assetTypeNameFormatter(assetType.name);
  let color: string = ASSET_TYPE_COLORS[assetType.name];
  if (isCustomAssetType(assetType.name)) {
    if (!customAssetTypeColorMapping[assetType.name]) {
      customAssetTypeColorMapping[assetType.name] = CUSTOM_ASSET_TYPE_COLORS[Object.keys(customAssetTypeColorMapping).length % CUSTOM_ASSET_TYPE_COLORS.length];
    }
    color = customAssetTypeColorMapping[assetType.name];
  }

  const value = group.length;
  return { id: assetTypeId, label, color, value };
};

/**
 * Groups assets by their type and creates a data object for each group, sorted primarily by the value in descending order and then by ID in ascending alphabetical order.
 * If the number of assets exceeds the maximum number of groups defined in PIE_CHART_CONSTS, it creates an "Other" group consolidating the remaining assets.
 *
 * @param {AssetRawData[]} assets An array of assets to be grouped.
 * @returns {WidgetDataObject[]} An array of data objects, each representing a grouped asset type, sorted by specified criteria, and potentially including an "Other" group if necessary.
 */
export const groupAssetsByAssetType = (assets: AssetRawData[]): WidgetDataObject[] => {
  const customAssetTypeColorMapping = {};
  const groupedAssets = _(assets)
    .groupBy((asset: AssetRawData) => asset.assetType._id)
    .map((group: AssetRawData[], assetTypeId: string) => createAssetTypeWidgetDataObject(group, assetTypeId, customAssetTypeColorMapping))
    .sortBy([(item: WidgetDataObject) => -item.value, (item: WidgetDataObject) => item.id.toLocaleLowerCase()])
    .value();

  const shouldHandleOther = assets?.length > PIE_CHART_CONSTS.MAX_GROUPS;

  if (shouldHandleOther) {
    const otherGroup = createOtherGroup(groupedAssets);
    return otherGroup?.value > 0 ? [...groupedAssets.slice(0, PIE_CHART_CONSTS.MAX_GROUPS), otherGroup] : groupedAssets;
  }
  return groupedAssets;
};

/**
 * Groups an array of contributors by their ID and sorts them.
 *
 * This function takes an array of contributors, groups them by their unique contributor ID,
 * and then sorts the resulting groups primarily by the size of each group in descending order
 * and secondarily by the contributor's display name in ascending alphabetical order.
 * It transforms each group into an object containing the contributor's ID, display name,
 * email, total number of occurrences (value), and the source they were created from.
 *
 * @param {Account[]} contributors - An array of contributors to be grouped and sorted.
 * @returns {WidgetAccountObject[]} - An array of objects, each representing a grouped contributor
 * with their ID, display name, email, total count (value), and the source of creation.
 * The array is sorted by total count in descending order and display name in ascending alphabetical order.
 */
export const groupByContributorIdAndSort = (contributors: Account[]): WidgetAccountObject[] => {
  return _(contributors)
    .groupBy((contributor: Account) => contributor._id)
    .map((group: Account[], _id: string) => ({
      _id,
      displayName: group[0].displayName,
      email: group[0].user.email,
      value: group.length,
      createdFrom: group[0].createdFrom,
    }))
    .sortBy([(item: WidgetAccountObject) => -item.value, (item: WidgetAccountObject) => item.displayName.toLocaleLowerCase()])
    .value();
};

/**
 * Filters contributors based on the department filter provided.
 *
 * This function accepts an array of assets and a department filter. It first extracts the contributors
 * from each asset and then applies the department filter. The filter can have three states:
 * showing all departments, showing only contributors without a department, or showing contributors from a specific department.
 *
 * @param {AssetRawData[]} assets - An array of assets from which contributors will be extracted.
 * @param {FilterBy} [departmentFilter=FILTER_BY.ALL_DEPARTMENT] - The department filter to be applied.
 *   It defaults to FILTER_BY.ALL_DEPARTMENT, indicating no filtering. Other values can indicate
 *   filtering for contributors without a department or contributors belonging to a specific department.
 * @returns {Account[]} - An array of contributors that satisfy the department filter condition.
 */
export const filterContributorsByDepartment = (assets: AssetRawData[], departmentFilter: FilterBy = FILTER_BY.ALL_DEPARTMENT): Account[] => {
  const contributors = assets.flatMap((asset: AssetRawData) => asset.contributor);
  return filterByDepartment(contributors, departmentFilter);
};

/**
 * Extracts unique departments from an array of assets, and prepends and appends the result array with default "All Department" and "No Department" options, respectively.
 *
 * This function first retrieves all departments from the provided assets. After deduplication, the result is prepended with
 * an "All Department" entry and appended with a "No Department" entry, both having localized names.
 *
 * @param {AssetRawData[]} assets - An array of assets from which unique departments will be extracted.
 * @returns {string[]} - An array of unique department names.
 */
export const extractUniqueDepartments = (assets: AssetRawData[]) => {
  const departments = assets.flatMap((asset) => asset.departments.map((department) => department));
  const uniqueDepartments = _.uniqWith(departments, _.isEqual);

  const allDepartmentsObject = { name: i18next.t('DASHBOARD_PAGE.HEADER.DEPARTMENTS.ALL_DEPARTMENT'), _id: FILTER_BY.ALL_DEPARTMENT };
  const noDepartmentsObject = { name: i18next.t('DASHBOARD_PAGE.HEADER.DEPARTMENTS.NO_DEPARTMENT'), _id: FILTER_BY.NO_DEPARTMENT };
  return [allDepartmentsObject, ...uniqueDepartments, noDepartmentsObject];
};

/**
 * Extracts unique asset type names from an array of assets, formats each name using assetTypeNameFormatter,
 * and appends an 'Other' label to the end of the result array.
 *
 * @param {AssetRawData[]} assets - An array of raw asset data.
 * @returns {string[]} An array of formatted unique asset type names with 'Other' appended to the end.
 */
export const extractUniqueAssetTypeNames = (assets: AssetRawData[]) => {
  const assetTypeNames = _(assets)
    .map((asset: AssetRawData) => asset.assetType.name)
    .uniq()
    .value();

  assetTypeNames.push(i18next.t('DASHBOARD_PAGE.WIDGETS.LABEL.OTHER'));
  return assetTypeNames;
};

/**
 * Counts the number of assets created from X-RAY.
 *
 * This function evaluates an array of assets and counts how many of them were created
 * from either the "Patent Tool" or the "Email Finder". This is determined by checking
 * the `createdFrom` property of each asset against predefined constants.
 *
 * @param {AssetRawData[]} assets - An array of assets to be evaluated.
 * @returns {number} - The count of assets created from either the "Patent Tool" or the "Email Finder".
 */
export const countCreatedFromXRay = (assets: AssetRawData[]): number => {
  return assets.filter((asset: AssetRawData) => asset.createdFrom === CREATED_FROM.PATENT_TOOL || asset.createdFrom === CREATED_FROM.EMAIL_FINDER).length;
};

/**
 * Counts the number of assets associated with partners (third-party).
 *
 * This function evaluates an array of assets and counts how many of them were created by users with a role "Partner".
 * The determination is made by inspecting the `createdBy.role.name` property of each asset.
 *
 * @param {AssetRawData[]} assets - An array of assets to be evaluated.
 * @returns {number} The count of assets created by third-party partners.
 */
export const countAssetsByThirdParty = (assets: AssetRawData[]): number => {
  return assets.filter((asset: AssetRawData) => asset?.createdBy?.role?.name === IRoles.PARTNER).length;
};

/**
 * Counts the number of assets with asset type names that are considered as "unpublished".
 *
 * @param {AssetRawData[]} assets - An array of assets to be filtered.
 * @returns {number} The count of unpublished assets based on their asset type names.
 *
 */
export const countUnpublishedAssets = (assets: AssetRawData[]): number => {
  const unpublishedAssets = [ASSET_TYPE.CONFIDENTIAL_OTHER, ASSET_TYPE.TRADE_SECRET, ASSET_TYPE.TRADE_SECRET_PATENT_CANDIDATE, ASSET_TYPE.TRADE_SECRET_UNPUBLISHED_PATENTS];
  return assets.filter((asset) => unpublishedAssets.includes(asset.assetType.name)).length;
};

/**
 * Filters and returns all active assets from the provided array.
 *
 * The function checks the `isActive` property of each asset in the input array, only including those assets where `isActive` is `true`.
 *
 * @param {AssetRawData[]} assets - Array of assets to be filtered.
 * @returns {AssetRawData[]} An array consisting of only the active assets.
 */
export const filterActiveAssets = (assets: AssetRawData[]): AssetRawData[] => {
  return assets.filter((asset) => asset.isActive || !Object.hasOwn(asset, 'isActive'));
};

/**
 * Attaching a color to each data object
 *
 * The function add a color attribute to each object in the data array based on the given colors array
 *
 * @param {string[]} colors - Array of assets to be filtered.
 * @param {AssetRawData[]} assets - Array of assets to be filtered.
 * @returns {WidgetDataObject[]} - The array of grouped items, each represented as a WidgetDataObject, possibly sorted and/or including an "Other" group.
 */
export const attachColorToWidgetDataObject = (data: WidgetDataObject[], colors: string[]): WidgetDataObject[] => {
  data.forEach((cur, index) => (cur.color = colors[index % colors.length]));
  return data;
};

/**
 * Formats role names based on predefined conditions.
 *
 * @param {IRoles} roleName - The role name to format, expected as a member of the IRoles enum.
 * @returns {string} The formatted role name according to specific matching conditions. If the role name does not match any specific condition, the original role name is returned.
 */
const formatRoleNameGroup = (roleName: string): string => {
  const roleFormats: { [key: string]: string } = {
    [IRoles.LAWYER]: ROLE_GROUP_NAMES.LAWYERS,
    [IRoles.EMPLOYEE_ADMIN]: ROLE_GROUP_NAMES.ADMINS,
    [IRoles.EMPLOYEE]: ROLE_GROUP_NAMES.EMPLOYEES,
    [IRoles.EMPLOYEE_MANAGER]: ROLE_GROUP_NAMES.MANAGERS,
    [IRoles.EMPLOYEE_HR]: ROLE_GROUP_NAMES.HR,
    [IRoles.PARTNER]: ROLE_GROUP_NAMES.PARTNERS,
    [IRoles.CONTRACTOR_ADMIN]: ROLE_GROUP_NAMES.CONTRACTORS,
    [IRoles.CONTRACTOR]: ROLE_GROUP_NAMES.CONTRACTORS,
    [IRoles.CONTRACTOR_OTHER]: ROLE_GROUP_NAMES.CONTRACTORS,
  };

  // if (roleName.includes(IRoles.CONTRACTOR)) {
  //   return ROLE_GROUP_NAMES.CONTRACTORS;
  // }

  return roleFormats[roleName] || roleName;
};

/**
 * Groups accounts by role, returning each role's ID, name, and count.
 *
 * @param {AccountRawData[]} accounts - Array of accounts with role information.
 * @returns {WidgetDataObject[]} Array of {id, label, value}, representing role ID, name, and account with a role count, respectively.
 */
export const groupAccountsByRole = (accounts: AccountRawData[]): WidgetDataObject[] => {
  return _(accounts)
    .groupBy((account: AccountRawData) => account.role._id)
    .map(
      (group: AccountRawData[], roleId: string): WidgetDataObject => ({
        id: roleId,
        label: formatRoleNameGroup(group[0].role.name),
        value: group.length,
      }),
    )
    .groupBy((group: WidgetDataObject) => group.label)
    .map(
      (group: WidgetDataObject[], label: string): WidgetDataObject => ({
        id: group[0].id,
        label,
        value: group.reduce((acc, cur) => acc + cur.value, 0),
      }),
    )
    .orderBy([(group: WidgetDataObject) => group.value], ['desc'])
    .value();
};

/**
 * Calculates the total count of users based on specified role group labels.
 *
 * @param {WidgetDataObject[]} accountsByRoles - Array of objects, each representing a role group with a label and value.
 * @param {ROLE_GROUP_NAMES[]} labelsToInclude - Array of role group labels to include in the total count.
 * @returns {number} The total count of users who belong to the specified role groups.
 */
export const countUsersBasedOnRoleGroup = (accountsByRoles: WidgetDataObject[], labelsToInclude: ROLE_GROUP_NAMES[]) => {
  return _.sumBy(accountsByRoles, (item: WidgetDataObject) => (_.includes(labelsToInclude, item.label) ? item.value : 0));
};

/**
 * Counts the number of accounts with each specific status, ensuring a count for 'active', 'pending', and 'disabled', even if some are absent.
 *
 * @param {AccountRawData[]} accounts - The array of accounts to process.
 * @returns {{ active: number, pending: number, disabled: number }} An object with the counts of accounts for each status.
 */
export const countAccountsStatus = (accounts: AccountRawData[]): { active: number; pending: number; disabled: number } => {
  const defaultCounts = {
    active: 0,
    pending: 0,
    disabled: 0,
  };

  const counts = _(accounts)
    .countBy((account: AccountRawData) => (account.status ? account.status.toLowerCase() : 'unknown'))
    .mapKeys((_, key: string) => key.toLowerCase())
    .defaults(defaultCounts)
    .value();

  return {
    active: counts['active'],
    pending: counts['pending'],
    disabled: counts['disabled'],
  };
};

/**
 * Groups recipient records by their asset property, then creates an array of objects with data aggregated from each group.
 * It sorts the groups based on the 'value' property in descending order and then filters them to include only the groups with the maximum 'value'.
 * It further sorts the filtered groups by 'name' in ascending alphabetical order. If there are no assets to group, it returns an empty array.
 *
 * @param {RecipientsRawData[]} assets - The array of recipient records to group. Each record must have an 'asset' property.
 * @returns {RecipientAsset[]} An array of objects, each representing a group of recipient records that share the same 'asset' property.
 *                             Each object includes aggregated data from the recipient records in its group and a 'value' property indicating the size of the group.
 */
export const groupAssetRecipientsByAsset = (assets: RecipientsRawData[]): RecipientAsset[] => {
  const groupedAssets = _(assets)
    .groupBy((recipRecord: RecipientsRawData) => recipRecord.asset._id)
    .map(
      (group: RecipientsRawData[], assetId: string): RecipientAsset => ({
        _id: assetId,
        name: group[0].asset.name,
        assetType: group[0].asset.assetType,
        departments: group[0].asset.departments,
        value: group.length,
      }),
    )
    .value();

  if (!groupedAssets.length) {
    return [];
  }

  const maxAssetCount = _.maxBy(groupedAssets, 'value')!.value;

  // TODO: add the test for this function
  const filteredAndSorted = _(groupedAssets)
    .filter((asset: RecipientAsset) => asset.value === maxAssetCount)
    .orderBy([(asset: RecipientAsset) => asset.value], ['desc'])
    .sortBy([(asset: RecipientAsset) => asset.name.toLocaleLowerCase()])
    .value();

  return filteredAndSorted;
};

/**
 * Groups recipient records by the sharedBy property, then creates an array of objects with data aggregated from each group.
 * It sorts the groups based on the 'value' property in descending order and then filters them to include only the groups with the maximum 'value'.
 * It further sorts the filtered groups by 'displayName' in ascending alphabetical order. If there are no assets to group, it returns an empty array.
 *
 * @param {RecipientsRawData[]} assets - The array of recipient records to group. Each record must have a 'sharedBy' property.
 * @returns {SharedBy[]} An array of objects, each representing a group of recipient records that share the same 'sharedBy' property.
 *                        Each object includes aggregated data from the recipient records in its group and a 'value' property indicating the size of the group.
 */
export const groupAssetRecipientsBySharedBy = (assets: RecipientsRawData[]): SharedBy[] => {
  const groupedAssets = _(assets)
    .groupBy((recipRecord: RecipientsRawData) => recipRecord.sharedBy._id)
    .map(
      (group: RecipientsRawData[], sharedById: string): SharedBy => ({
        _id: sharedById,
        displayName: group[0].sharedBy.displayName,
        departments: group[0].sharedBy.departments,
        isActive: group[0].sharedBy.isActive,
        isAnotherClient: group[0].sharedBy.isAnotherClient,
        role: group[0].sharedBy.role,
        user: group[0].sharedBy.user,
        createdFrom: group[0].sharedBy.createdFrom,
        value: group.length,
      }),
    )
    .value();

  if (!groupedAssets.length) {
    return [];
  }

  const maxAssetCount = _.maxBy(groupedAssets, 'value')!.value;

  const filteredAndSorted = _(groupedAssets)
    .filter((account: SharedBy) => account.value === maxAssetCount)
    .orderBy([(account: SharedBy) => account.value], ['desc'])
    .sortBy([(account: SharedBy) => account.displayName.toLocaleLowerCase()])
    .value();

  return filteredAndSorted;
};

/**
 * Formats raw asset data for dashboard display, applying various filters and aggregations.
 * Prepares and structures data related to assets and contributors for dashboard widgets.
 * This higher-order function returns another function that takes assets raw data and an optional department filter.
 *
 * @param {Object} params - Function configuration parameters.
 * @param {boolean} params.includeActiveAndAllData - Flag indicating whether to array of active assets in the results.
 * @returns {function(AssetRawData[], FilterBy): Object} A function that takes a list of raw asset data and an optional department filter, and returns an object containing structured data for dashboard widgets.
 *
 * @example
 * const configuredFunction = prepareDashboardAssetsData({ includeActiveAndAllData: true });
 * const dashboardData = configuredFunction(assetRawDataArray, 'noDepartment'); // uses 'noDepartment' as department filter
 */
export const prepareDashboardAssetsData = ({ includeActiveAndAllData = true }: { includeActiveAndAllData: boolean }) => {
  return (assetsRawData: AssetRawData[], departmentFilter: FilterBy = FILTER_BY.ALL_DEPARTMENT) => {
    const allAssets = includeActiveAndAllData && assetsRawData;
    const activeAssets = includeActiveAndAllData ? filterActiveAssets(assetsRawData) : assetsRawData;
    const assets = filterByDepartment(activeAssets, departmentFilter);
    const contributors = filterContributorsByDepartment(activeAssets, departmentFilter);
    const contributorsUnique = groupByContributorIdAndSort(contributors);

    return {
      metadata: { assetsTotal: assets.length },
      data: {
        // If 'includeActiveAndAllData' is true, include 'activeAssetsRawData', 'allAssetsRawData', 'departments', and 'assetTypes' in the result
        ...(includeActiveAndAllData && {
          activeAssetsRawData: activeAssets,
          allAssetsRawData: allAssets,
          departments: extractUniqueDepartments(activeAssets),
          assetTypes: extractUniqueAssetTypeNames(assetsRawData),
        }),
        assetsByAssetType: groupAssetsByAssetType(assets),
        assetsByDepartments: attachColorToWidgetDataObject(groupArrayByField({ array: assets, groupByField: 'departments', departmentFilter, includeOther: true }), PIE_CHART_COLORS),
        assetsByProject: groupArrayByField({ array: assets, groupByField: 'projects', departmentFilter, includeOther: false }),
        assetsByTag: groupArrayByField({ array: assets, groupByField: 'tags', departmentFilter, includeOther: false }),
        assetsByBusinessValues: groupArrayByField({ array: assets, groupByField: 'businessValues', departmentFilter, includeOther: false }),
        assetsCreatedFromXRay: countCreatedFromXRay(assets),
        assetsByThirdParty: countAssetsByThirdParty(assets),
        unpublishedAssets: countUnpublishedAssets(assets),
        topAssetCreator: findTopAssetCreator(assets),
        contributorsByDepartments: groupArrayByField({ array: contributors, groupByField: 'departments', departmentFilter, includeOther: true }),
        topContributors: contributorsUnique,
        contributors: contributorsUnique.length,
      },
    };
  };
};

/**
 * Prepares and organizes accounts data for the dashboard.
 *
 * @param {AccountRawData[]} accountsRawData - The initial array of raw account data to process.
 * @param {FilterBy} [departmentFilter=FILTER_BY.ALL_DEPARTMENT] - The department filter to apply for the accounts data.
 *
 * @returns {Object} An object containing organized account data, including total accounts, groupings by role and department, and specific user counts.
 * @property {Object} metadata - Contains the total number of accounts after applying filters.
 * @property {Object} data - Contains the raw account data, accounts organized by role, counts of external users and employees, accounts' statuses, and accounts grouped by departments.
 */
export const prepareDashboardAccountsData = (accountsRawData: AccountRawData[], departmentFilter: FilterBy = FILTER_BY.ALL_DEPARTMENT) => {
  const accounts = filterByDepartment(accountsRawData, departmentFilter);
  const accountsByRole = groupAccountsByRole(accounts);
  return {
    metadata: { accountsTotal: accounts.length },
    data: {
      accountsRawData,
      accountsByRole,
      externalUsers: countUsersBasedOnRoleGroup(accountsByRole, [ROLE_GROUP_NAMES.CONTRACTORS, ROLE_GROUP_NAMES.PARTNERS]),
      employees: countUsersBasedOnRoleGroup(accountsByRole, [ROLE_GROUP_NAMES.ADMINS, ROLE_GROUP_NAMES.EMPLOYEES, ROLE_GROUP_NAMES.MANAGERS, ROLE_GROUP_NAMES.HR]),
      accountsStatus: countAccountsStatus(accounts),
      accountsByDepartments: groupArrayByField({ array: accounts, groupByField: 'departments', departmentFilter, includeOther: true }),
    },
  };
};

/**
 * Prepares the data for dashboard insights by applying department filters and grouping the results.
 * This function first filters recipient records based on department criteria and then organizes the data to identify the most notified assets and the most frequent sharers.
 *
 * @param {RecipientsRawData[]} recipientsRawData - The array of recipient records to process. Each record should comply with the RecipientsRawData structure.
 * @param {FilterBy} [departmentFilter=FILTER_BY.ALL_DEPARTMENT] - Optional. The criteria used to filter recipient records by department.
 *    Defaults to FILTER_BY.ALL_DEPARTMENT, which doesn't apply any filter (i.e., all records are considered).
 *
 * @returns {Object} An object containing structured data for dashboard insights.
 */
export const prepareDashboardInsightsData = (recipientsRawData: RecipientsRawData[], departmentFilter: FilterBy = FILTER_BY.ALL_DEPARTMENT) => {
  const recipientsAssets = filterByDepartment(recipientsRawData, departmentFilter);
  return {
    data: {
      mostNotifiedAsset: groupAssetRecipientsByAsset(recipientsAssets),
      mostSharedBy: groupAssetRecipientsBySharedBy(recipientsAssets),
      recipientsRawData: recipientsRawData,
    },
  };
};
