import { useSelector } from 'react-redux';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';

import { TangiIconButton, TangiSvgIcon } from '../../../TangiLibrary';
import AssetTypeColorBlock from '../../../AssetTypeColorBlock/AssetTypeColorBlock';
import useAssetAttachmentsInfo from '_hooks/useAssetAttachmentsInfo';
import { BUTTON_VARIANTS } from '../../../../utils/componentUtils';
import AssetOptions from '../../../AssetOptions/AssetOptions';
import AcknowledgementTooltip from '../AcknowledgementTooltip/AcknowledgementTooltip';
import { isCustomAssetType, CUSTOM_ASSET_TYPE, assetTypeNameFormatter } from '../../../../utils/assetTypes';
import { CREATED_FROM } from '../../../../utils/enums';
import { StyledActiveAssetCard, Divider, AssetTagsContainer, AssetNumber, RecipientNumber, customAssetOptionsStyles, ActiveCardFooter, StyledMagicIcon, ShareAssetContainer } from './style';
import { AssetCardHeader, ColorAndType, AssetBodyContainer, AssetName, AssetFileContainer, AssetFileIconAndName } from '../../style';
import { FileNameText, NoFilesText, AssetFileNum } from '../../../../pages/ClientAssets/styles';
import { formatDate } from '../../../../utils/dateUtils';
import { IRoles, checkAssetSharePermission } from 'utils/roles';
import ThirdPartyTag from '../../../ThirdPartyTag/ThirdPartyTag';
import { isPartnershipDisabled } from '../../../ThirdPartyTag/utils';
import { DuoContainer } from '../../../../utils/globalStyles';

const AssetCardActive = ({ asset, permissions, handleDistributeAsset, options, isAdmin }) => {
  const Role = useSelector((state) => state.authentication.Role);

  const { attachmentName, attachmentIconType, attachmentAdditionalAmount, isAttachmentFile, isAttachmentEmpty } = useAssetAttachmentsInfo({
    filesArr: asset?.files,
    urlsArr: asset?.urls,
    partnership: asset?.createdBy?.partnership,
  });

  const allAcknowledged = asset?.recipients?.every((recipient) => recipient.acknowledged === true);
  const { t } = useTranslation();

  const isEditDisable = asset?.isThirdParty && Role === IRoles.PARTNER;
  const editTooltipContent = isEditDisable ? t('ASSET_PAGE.TOOLTIP.CANNOT_EDIT_ASSET') : t('ASSET_PAGE.TOOLTIP.EDIT_ASSET');

  const renderMagicIcon = (value) => {
    let tooltipContent = '';
    let isShowIcon = false;

    switch (value?.createdFrom) {
      case CREATED_FROM.EMAIL_FINDER:
        tooltipContent = `${t('ASSET_PAGE.TOOLTIP.ASSETS_CREATED_BY_EMAIL_XRAY')} ${formatDate(value?.createdAt)}`;
        isShowIcon = true;
        break;
      case CREATED_FROM.PATENT_TOOL:
        tooltipContent = `${t('ASSET_PAGE.TOOLTIP.ASSETS_CREATED_BY_PATENT_XRAY')} ${formatDate(value?.createdAt)}`;
        isShowIcon = true;
        break;
      default:
        isShowIcon = false;
    }

    return (
      isShowIcon && (
        <Tippy content={<div className="text-xs">{tooltipContent}</div>}>
          <StyledMagicIcon />
        </Tippy>
      )
    );
  };

  return (
    <StyledActiveAssetCard permissionView={permissions?.Assetview} data-testid="asset-card">
      <AssetCardHeader>
        <ColorAndType>
          <AssetTypeColorBlock assetType={isCustomAssetType(asset.assetType.name) ? CUSTOM_ASSET_TYPE : asset.assetType.name} />
          <span>{asset?.assetType?.name ? assetTypeNameFormatter(asset.assetType.name) : ''}</span>
        </ColorAndType>
        {isAdmin && permissions?.Assetedit ? (
          <AssetOptions item={asset} options={options} styles={customAssetOptionsStyles} />
        ) : (
          <>
            {!isAdmin && permissions?.Assetedit && (
              <Tippy content={editTooltipContent} placement="top-end">
                <span data-testid="edit-asset">
                  <TangiIconButton disabled={isEditDisable} icon="edit" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={() => options[0].handleItem(asset)} />
                </span>
              </Tippy>
            )}
          </>
        )}
      </AssetCardHeader>
      <Divider />
      <AssetBodyContainer>
        <DuoContainer>
          <AssetName>
            {renderMagicIcon(asset)}
            <span>{asset.name}</span>
          </AssetName>
          {asset?.isThirdParty && <ThirdPartyTag asset={asset} />}
        </DuoContainer>

        <AssetTagsContainer>
          <span>{asset.tags[0]?.name}</span>
          {asset.tags.length > 1 && (
            <Tippy
              content={asset.tags.map((item, index) => (
                <div key={index}>{item.name}</div>
              ))}
              placement="top-end"
            >
              <span>+ {asset.tags.length - 1}</span>
            </Tippy>
          )}
          <AssetNumber>{asset.refNumber}</AssetNumber>
        </AssetTagsContainer>
      </AssetBodyContainer>
      <AssetFileContainer>
        <AssetFileIconAndName isDisabled={isPartnershipDisabled(asset?.createdBy?.partnership)}>
          {!isAttachmentEmpty && <TangiSvgIcon component={attachmentIconType} />}
          {isAttachmentEmpty ? <NoFilesText>{attachmentName}</NoFilesText> : <FileNameText className={isAttachmentFile && 'file-attachment-name'}>{attachmentName}</FileNameText>}
        </AssetFileIconAndName>
        {!!attachmentAdditionalAmount && <AssetFileNum>{`+ ${attachmentAdditionalAmount}`}</AssetFileNum>}
      </AssetFileContainer>
      <Divider />
      <ActiveCardFooter>
        {checkAssetSharePermission(Role, permissions, asset?.isThirdParty) && (
          <>
            <Tippy
              content={<AcknowledgementTooltip acknowledgedRecipientsList={asset.acknowledgedAccountsList} notAcknowledgedRecipientsList={asset.notAcknowledgedAccountsList} />}
              placement="top-end"
              disabled={!asset?.recipients?.length}
            >
              {!!asset?.recipients?.length && (
                <RecipientNumber allAcknowledged={allAcknowledged} data-testid="recipient-number">
                  <span>
                    {allAcknowledged
                      ? asset.recipients.length
                      : asset?.acknowledgedAccountsList?.length
                      ? asset?.acknowledgedAccountsList?.length + '/' + asset.recipients.length
                      : '0/' + asset.recipients.length}
                  </span>
                  <span>{t('ASSET_PAGE.TOOLTIP.RECIPIENTS')}</span>
                </RecipientNumber>
              )}
            </Tippy>
            <Tippy content={t('ASSET_PAGE.TOOLTIP.NOTIFY')} placement="top-end">
              <ShareAssetContainer data-testid="share-asset-container">
                <TangiIconButton icon="send" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={() => handleDistributeAsset(asset)} />
              </ShareAssetContainer>
            </Tippy>
          </>
        )}
      </ActiveCardFooter>
    </StyledActiveAssetCard>
  );
};

export default AssetCardActive;
