import React from 'react';

import { ChipContentContainer, StyledChip } from './style';
import { IconTypes, TangiSvgIcon } from '_components/TangiLibrary';
import { ERROR_SHADES, SUCCESS_SHADES, WARNING_SHADES } from 'utils/theme';

export enum ChipTextType {
  ERROR = 'DISCOVERY_LOBBY.CHIP.ERROR',
  SUCCESS = 'DISCOVERY_LOBBY.CHIP.SUCCESS',
  WARNING = 'DISCOVERY_LOBBY.CHIP.WARNING',
}

export enum ChipType {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}

export interface ITangiChipProps {
  text: string | React.ReactNode;
  variant: ChipType;
}

export const VARIANT_COLOR: Record<ChipType, string> = {
  [ChipType.SUCCESS]: SUCCESS_SHADES[600],
  [ChipType.WARNING]: WARNING_SHADES[1000],
  [ChipType.ERROR]: ERROR_SHADES[500],
};

export const VARIANT_SVG: Record<ChipType, IconTypes> = {
  [ChipType.SUCCESS]: 'complaint',
  [ChipType.WARNING]: 'pendingAcknowledgment',
  [ChipType.ERROR]: 'errorIcon',
};

export const ContractChip = ({ text, variant }: ITangiChipProps) => {
  return (
    <StyledChip data-testid={`${variant}-tangi-chip`} variant={variant}>
      <ChipContentContainer>
        <TangiSvgIcon component={VARIANT_SVG[variant]} size="20px" color={VARIANT_COLOR[variant]} />
        <span>{text}</span>
      </ChipContentContainer>
    </StyledChip>
  );
};
