import styled from 'styled-components';
import { BORDER_RADIUS, NEUTRAL_SHADES, PRIMARY_SHADES, SPACING } from 'utils/theme';

export const StepperContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const StepsContainer = styled.ul`
display: flex;
flex-direction: column;
gap: ${SPACING[2]};
  background-color: ${NEUTRAL_SHADES[50]};
  margin: 0;
  padding: ${SPACING[3]};
  list-style: none;
  width: 265px;
`;

export const StepItem = styled.li<{ $selected?: boolean; $disabled?: boolean }>`
  display: flex;
  padding: ${SPACING[2]};
  background-color: ${(props) => (props.$selected ? PRIMARY_SHADES[200] : 'inherit')};
  border-radius: ${BORDER_RADIUS[4]};
  justify-content: space-between;
  align-items: center;
  &:hover {
    cursor: ${(props) => (props.$disabled ? 'default' : 'pointer')};
    background-color: ${(props) => (props.$disabled ? 'inherit' : props.$selected ? PRIMARY_SHADES[200] : NEUTRAL_SHADES[100])};
  }
`;

export const View = styled.div`
  padding: ${SPACING[4]};
  border-left: 1px solid ${NEUTRAL_SHADES[300]};
`;
