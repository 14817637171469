/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import { Filter } from '_services/utils/getFilterObj';
import { Metadata } from '../types';
import { XRAY_JOB_STATUS } from '../xRay/xRay';
import { Account } from '../account/account';
import { IdAndName } from '../types';

export interface IshareContributorsStatus {
  status: string;
  message: string;
}
export interface Assets {
  metadata: Metadata[];
  data: Asset[];
}
export interface File {
  _id: string;
  name: string;
  fileName?: string;
  fileId: string;
  fileType: string;
  uploadedOn: string;
  trackerId?: string;
}

export interface URL {
  _id: string;
  label: string;
  url: string;
  uploadedOn: string;
}

interface SharedRecipientAccount extends Pick<Account, '_id' | 'status' | 'createdAt'> {
  displayName: string;
  role: IdAndName;
  partnership?: IdAndName;
  isActive: boolean;
  createdFrom: string;
}

export interface SharedRecipient extends Pick<Recipient, '_id' | 'username' | 'email' | 'fullName' | 'acknowledgedOn' | 'acknowledged' | 'sharedOn'> {
  account: SharedRecipientAccount | null;
}

export interface Asset {
  id: string;
  name: string;
  description: string;
  tags: any[];
  projects?: any[];
  departments?: any[];
  businessValues: any[];
  contributor: any[];
  referenceId: any;
  createdFrom: string;
  updatedFrom: string;
  isActive: boolean;
  refNumber: string;
  others1: string;
  others2: string;
  assetType: any;
  urls: URL[];
  client: any;
  createdBy: any;
  updatedBy: any;
  files: File[];
  createdAt: string;
  updatedAt: string;
  recipients?: SharedRecipient[];
  acknowledgedAccountsLists?: SharedRecipient[];
  notAcknowledgedAccountsLists?: SharedRecipient[];
  needsToBeAcknowledgedByMe?: boolean;
  recipientsContractors?: SharedRecipient[];
  recipientsEmployees?: SharedRecipient[];
  recipientsLawyers?: SharedRecipient[];
  recipientsPartners?: SharedRecipient[];
  recipientsNoAccounts?: SharedRecipient[];
  numAccountsCreatedFromFinder?: number;
  numAccountsCreatedFromTracker?: number;
  assetMetaData: AssetMetaDataValue[];
  isPublished?: boolean;
  importance?: Importance;
  isThirdParty?: boolean;
  hasAccessToThirdPartyAsset?: boolean;
}

export interface Recipient {
  _id: string;
  asset: string;
  account: string | null;
  username: string;
  email: string;
  fullName: string;
  sharedBy: string;
  acknowledgedOn: string | null;
  acknowledged: boolean;
  registered: boolean;
  createdFrom: string;
  updatedFrom: string;
  isActive: boolean;
  isPartner: boolean;
  deletedBy: string | null;
  sharedOn: string;
  createdAt: string;
  updatedAt: string;
}

export interface DeleteRecipientParams {
  assetId: string;
  recipientId: string;
}

export interface AcknowledgePostAssetParams {
  code: string;
  token: string;
}

export interface AcknowledgePreviewAssetPOSTParams {
  token: string;
  otp: string;
}

export interface GetAllAssetsClientParams {
  limit?: number;
  clientId: string;
  accountId: string;
}

export interface Pagination {
  limit: number;
  page: number;
}

export interface GetAssets {
  sortValue?: string;
  searchValue?: string;
  filter?: Filter;
  page: number;
  limit: number;
}

export interface GetPrivilegeLogData extends Omit<GetAssets, 'page' | 'limit'> {
  isFilterApplied: boolean;
}

export interface PrivilegeLogRecipient {
  _id: string;
  account?: {
    _id: string;
    displayName: string;
  };
  email: string;
  acknowledgedOn: string;
}

export interface PrivilegeLogAsset {
  _id: string;
  name: string;
  refNumber: string;
  assetType: {
    _id: string;
    name: string;
  };
  recipients: PrivilegeLogRecipient[];
}

export interface AssetMetaDataValue {
  type: ASSET_METADATA_VALUE;
  value: string;
  clientID: string;
  createdAt: string;
  updatedAt: string;
  _id: string;
  newRecords?: string;
}

export interface ImportanceFieldValue {
  value: string;
  score: string;
}

export interface GetAssetAuditLog {
  assetId: string;
  page?: number;
  limit: number;
}

export interface DeleteAssetData {
  fileName: string;
  assetId: string;
}
export interface DeleteAssetFileRequest {
  data: DeleteAssetData;
  token: string;
  isAuthenticated?: boolean;
}

export type Importance = {
  value: string;
  score: string;
};

export enum ASSET_METADATA_VALUE {
  BUSINESS_RELATED = 'businessRelated',
  COMMERCIAL_PRODUCT = 'commercialProduct',
}

export type RecipientFilters = {
  id: string;
  name: string | null;
};

export type RecipientsFiltersArray = RecipientFilters[];
export interface AllAssetsFilesResponse {
  jobStatus: XRAY_JOB_STATUS;
  jobId?: string;
  downloadUrl?: string;
}
