import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { FlexContainer } from 'utils/globalStyles';
import { ActivityRowDivider } from '../style';
import { TranslationMappingValues, MODIFICATION_TYPE, MODIFICATION_TYPE_TRANSLATED } from 'utils/types/activityLog/asset/types';

const Container = styled(FlexContainer)`
  padding: ${SPACING[0]} ${SPACING[7]} ${SPACING[0]} ${SPACING[3.5]};
  gap: ${SPACING[5]};
  width: 70%;
  padding-top: ${SPACING[3]};
  > :nth-child(2) {
    align-self: center;
  }
`;

const LeftSide = styled(FlexContainer)`
  max-width: 45%;
  flex: 1;
  flex-direction: column;
  gap: ${SPACING[0]};
`;

const RightSide = styled(LeftSide)`
  padding-top: 28px;
`;

const DetailsTitle = styled(TangiTypography)`
  white-space: nowrap;
`;

const CollapseContentDivider = styled(ActivityRowDivider)`
  margin-left: ${SPACING[1]};
`;

const breakWordTypographyStyles = `
word-wrap: break-word;
`;

interface Props {
  field: TranslationMappingValues | string;
  prevValueComponent?: React.ReactNode;
  currValueComponent: React.ReactNode;
  modificationType: MODIFICATION_TYPE;
  showDivider?: boolean;
}

const ActivityCollapseContent = (props: Props) => {
  const { t } = useTranslation();
  const modificationType = MODIFICATION_TYPE_TRANSLATED[props.modificationType];
  const renderChangeDetail = () => {
    return (
      <LeftSide>
        <DetailsTitle type="subheading" weight="semibold">
          {/* TODO: add translations */}
          {t(props.field)} {t(modificationType)}
        </DetailsTitle>
        <TangiTypography customStyles={breakWordTypographyStyles}>{props.currValueComponent}</TangiTypography>
      </LeftSide>
    );
  };

  const renderChangeDetailBeforeAfter = () => {
    return (
      <>
        <LeftSide>
          <DetailsTitle type="subheading" weight="semibold">
            {t(props.field)} {t(modificationType)}
          </DetailsTitle>
          <TangiTypography color={NEUTRAL_SHADES[800]}>{t('ACTIVITY_LOG.PREVIOUS_VALUE')}</TangiTypography>
          <TangiTypography customStyles={breakWordTypographyStyles}>{props.prevValueComponent}</TangiTypography>
        </LeftSide>
        <TangiSvgIcon component="arrowSideRight" />
        <RightSide>
          <TangiTypography color={NEUTRAL_SHADES[800]}>{t('ACTIVITY_LOG.UPDATED_VALUE')}</TangiTypography>
          <TangiTypography customStyles={breakWordTypographyStyles}> {props.currValueComponent}</TangiTypography>
        </RightSide>
      </>
    );
  };
  return (
    <>
      <Container>{props.modificationType === MODIFICATION_TYPE.EDIT && props.prevValueComponent ? renderChangeDetailBeforeAfter() : renderChangeDetail()}</Container>
      {!props.showDivider && <CollapseContentDivider />}
    </>
  );
};

export default ActivityCollapseContent;
