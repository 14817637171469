/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import { IRoles } from 'utils/roles';
import { Partnership } from '../partnership/partnership';
import { LANGUAGE } from 'translations/enums';
import { ENTITY_TYPES, UPLOAD_FILE_TYPES } from 'utils/enums';
import { IpAuditData } from '_components/IpAudit/types';

export interface UpdateRequiredCoursesParams {
  clientId: string;
  updatedCourses: any;
}

export interface Client {
  id: string;
  _id?: string;
  description: string;
  isActive: boolean;
  createdFrom: string;
  updatedFrom: string;
  logo: string;
  name: string;
  industries: any[];
  numberOfEmployees: number | null;
  outsideCounsel: any;
  tradeSecretStorage: any;
  yearsInBusiness: any;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  requiredCourses?: string[];
  settings: string;
  assetsPrefix: string;
  refCountStarter: string;
  lawyers: string[];
  partnerships?: {
    active?: Partnership[];
    disabled?: Partnership[];
  };
  assetCount?: number;
  language?: LANGUAGE;
  policyFiles?: PolicyFileDetails[]; // used for trade secret policy files
  llmFiles?: PolicyFileDetails[]; // used for llm policy files
  ipAuditData?: OptionalIpAuditData;
  permissions?: ClientPermissions | null;
  isDemo?: boolean | null;
  discoverySpecialAdminsCount?: number;
  __v: number;
}

export interface PolicyFileDetails {
  fileName: string;
  fileType: string;
}

export type OptionalIpAuditData = Partial<IpAuditData>;

//  --- Client Permissions  ---
export type BasicClientPermission = { hasPermission: boolean };

export interface TSAnalysisPermissions extends BasicClientPermission {
  portfolioId: string;
}

export interface ClientPermissions {
  ipPortfolio?: TSAnalysisPermissions;
  ipCompetitive?: TSAnalysisPermissions;
  aliasId?: BasicClientPermission;
  ipAudit?: BasicClientPermission;
}

export type ClientIpPermissionsPartial = Pick<Client, 'isDemo' | 'permissions'> & Partial<Client>;
//  ---   ---

export interface JoinDiscoveryEnquiryParams {
  name: string;
  email: string;
  clientName: string;
  role: IRoles;
  source?: string;
}

export interface ClientRequestParams extends Omit<UpdateRequiredCoursesParams, 'updatedCourses'> {}

export interface UploadFilesParams {
  clientId: string;
  policyFiles: File[];
  type: string;
  isLawyer: boolean;
  accountId: string;
  entityType?: ENTITY_TYPES;
}

export interface DeletePolicyParams extends ClientRequestParams {
  isLawyer: boolean;
  accountId: string;
  type: PolicyType;
  isDeleteBeforeUpload?: boolean;
}
export interface ClientLanguage {
  clientId: string;
  language: LANGUAGE;
}

export interface IClientXrayInsights {
  assetFromFinder: number;
  assetFromPatents: number;
  accountsFromTracker: number;
  assetFromInventionDisclosure: number;
}

export const POLICIES = {
  TRADE_SECRET: UPLOAD_FILE_TYPES.POLICY,
  LLM: UPLOAD_FILE_TYPES.LLM,
} as const;

export type PolicyType = typeof POLICIES[keyof typeof POLICIES];

export interface UpdateIpAuditParams {
  clientId: string;
  ipAudit: OptionalIpAuditData;
  isLawyer: boolean;
  accountId: string;
}
