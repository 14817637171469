import { Redirect, Route } from 'react-router-dom';
import { AppRoutes, XrayRoutes } from 'utils/routing/consts';
import { loadStateFromLocalStorage } from 'utils/localStorage';
import { authConstants } from '_constants';

function PrivateRoute({ component: Component, permissions = [], ...rest }) {
  const activeAccount = loadStateFromLocalStorage(authConstants.ACTIVATE_ACCOUNT);
  const { specialAdminPermissions = {} } = activeAccount ?? {};

  return (
    <Route
      {...rest}
      render={(props) => {
        // Not logged in or not permitted - redirect to login page with the return url
        if (!activeAccount || !permissions.includes(activeAccount.role.name)) {
          return <Redirect to={{ pathname: AppRoutes.LOGIN, state: { from: props.location } }} />;
        }

        const path = props.location.pathname;

        // Xray permissions
        const hasNoPermissionsToXray = !specialAdminPermissions?.Patent && Object.values(XrayRoutes).some((route) => path.includes(route));
        const hasNoPermissionsToContinuation = !(specialAdminPermissions?.Patent && specialAdminPermissions?.ContinuationXRay) && path.includes(XrayRoutes.CONTINUATION_ANALYSIS);

        // Should redirect to MAIN route if user has no permissions to specific features
        const shouldRedirectToMain = hasNoPermissionsToXray || hasNoPermissionsToContinuation;

        if (shouldRedirectToMain) {
          return <Redirect to={{ pathname: AppRoutes.MAIN, state: { from: props.location } }} />;
        }

        // Logged in or permitted so return component
        return <Component {...props} />;
      }}
    />
  );
}

export { PrivateRoute };
