import styled from 'styled-components';

import { SPACING } from 'utils/theme';

export const Container = styled.div`
  padding: ${SPACING[7]} 0;
  width: 942px;
  margin: auto;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[2]};
  margin-bottom: ${SPACING[6]};
`;

export const Cards = styled.div`
  display: flex;
  gap: ${SPACING[4]};
  flex-wrap: wrap;
`;
