import axios from 'axios';
import { history } from './../../_helpers/history';
import Pool from '../../_hocs/Cognito/Auth';
import { ERROR_MESSAGES} from 'utils/token/localStorage';


const logout = () => {
  const user = Pool.getCurrentUser();
  if (user) {
    localStorage.clear();
    user.signOut();
  }
};

export function apiCall(requestOptions) {
  return new Promise(function (resolve, reject) {
    axios(requestOptions).then(
      (result) => {
        resolve(result.data);
      },
      (error) => {
        if (error?.response) {
          const { status, data } = error.response;
          if (status === 401 && data.message === ERROR_MESSAGES.UNAUTHORIZED_ACCESS) {
            logout();
            history.push('/login');
            window.location.reload();
          }
          reject(data);
        } else {
          reject(error);
        }
      },
    );
  });
}
