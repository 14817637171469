import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TangiCheckbox, TangiTypography } from '_components/TangiLibrary';
import { useTranslation } from 'react-i18next';
import { SecurityCard } from './components/SecurityCard';
import { SystemSecuritySelection, SystemSecurityType } from './types';
import { IpAuditStepKey } from '_components/IpAudit/types';
import { NEUTRAL_SHADES } from 'utils/theme';
import { StepFooter } from '../components/StepFooter/StepFooter';
import { StepTitle } from '../components/StepTitle/StepTitle';
import { StepContainer, StepInnerContainer, StepTextContainer } from '../styles';
import { CheckboxWithTextCard, nonOfTheAboveTextStyle, OptionCardsContainer } from './styles';
import { useIpAudit } from '_components/IpAudit/useIpAudit';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '_helpers';
import { clientActions } from 'redux-toolkit/slices/clientSlice';
import { useHandleSteps } from '_components/TangiLibrary/TangiStepper/useHandleSteps';

export const SystemSecurity = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const systemSecurityAction: { hasData: boolean; isLoading: boolean } = useSelector((state: RootState) => state.client.ipAudit[IpAuditStepKey.SYSTEM_SECURITY]);

  const { handleStepChange } = useHandleSteps();
  const { getStepCurrentData, submitSystemSecurity } = useIpAudit();

  const initialValues = getStepCurrentData(IpAuditStepKey.SYSTEM_SECURITY);

  const [selectedOptions, setSelectedOptions] = useState<SystemSecuritySelection[]>(initialValues?.length ? initialValues : []);

  const { handleSubmit } = useForm();

  useEffect(() => {
    if (systemSecurityAction.hasData) {
      dispatch(clientActions.clearIpAuditAction(IpAuditStepKey.SYSTEM_SECURITY));
      handleStepChange(IpAuditStepKey.HR_PROCESS);
    }
  }, [systemSecurityAction.hasData]);

  const onSubmit = () => {
    submitSystemSecurity(selectedOptions);
  };

  const handleNoneOfTheAbove = () => {
    setSelectedOptions(selectedOptions.includes('noneOfTheAbove') ? [] : ['noneOfTheAbove']);
  };

  const handleOptionSelection = (option: SystemSecurityType) => {
    setSelectedOptions((prevSelectedOptions) => {
      if (prevSelectedOptions.includes('noneOfTheAbove')) {
        return [option];
      }
      const isOptionSelected = prevSelectedOptions.includes(option);
      return isOptionSelected ? prevSelectedOptions.filter((item) => item !== option) : [...prevSelectedOptions, option];
    });
  };

  const renderText = (textKey: string) => (
    <TangiTypography type="subheading" color={NEUTRAL_SHADES[1100]}>
      {t(textKey)}
    </TangiTypography>
  );

  return (
    <StepContainer>
      <StepInnerContainer>
        <StepTextContainer>
          <StepTitle stepKey={IpAuditStepKey.SYSTEM_SECURITY} />
          {renderText('IP_AUDIT.IP_AUDIT_STEPS.SYSTEM_SECURITY.DESCRIPTION')}
          {renderText('IP_AUDIT.IP_AUDIT_STEPS.SYSTEM_SECURITY.SELECTION')}
        </StepTextContainer>
        <form>
          <OptionCardsContainer>
            {Object.values(SystemSecurityType).map((systemOption) => (
              <SecurityCard key={systemOption} systemOption={systemOption} isSelected={selectedOptions.includes(systemOption)} toggleSelection={handleOptionSelection} />
            ))}
          </OptionCardsContainer>
          <CheckboxWithTextCard onClick={handleNoneOfTheAbove}>
            <TangiCheckbox type="checkbox" data-cy="checkbox-none" checked={selectedOptions.includes('noneOfTheAbove')} readOnly name="none-of-the-above" />
            <TangiTypography customStyles={nonOfTheAboveTextStyle}>{t('IP_AUDIT.IP_AUDIT_STEPS.SYSTEM_SECURITY.NONE_OF_THE_ABOVE')}</TangiTypography>
          </CheckboxWithTextCard>
        </form>
      </StepInnerContainer>
      <StepFooter onSubmit={handleSubmit(onSubmit)} isContinueBtnDisabled={!selectedOptions.length} isContinueBtnLoading={systemSecurityAction.isLoading} />
    </StepContainer>
  );
};
