import { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector, batch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { getAssetMetaData } from 'redux-toolkit/thunks/assetMetaDataThunks';
import { deleteAsset, getAssets, getPrivilegeLogData, pendingAcknowledgement } from 'redux-toolkit/thunks/assetsThunks';
import { acknowledgementActions } from '../../_actions/acknowledgementActions';
import { mixpanelEvents } from '_services/utils/MixPanel/mixpanelConfig';
import { clientSettingsActions } from '../../_actions/clientSettingsActions';
import { getAllRecipients } from 'redux-toolkit/thunks/assetMetaDataThunks';

export const useClientAssets = () => {
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const metaDataState = useSelector((state) => state.assetMetaData);
  const activeClient = useSelector((state) => state.lawfirm.activeClient);
  const { permissions, activeAccount, Role, user } = useSelector((state) => state.authentication);
  const { assets, loading: assetsLoading, asset, uploadedBatchAssets, deleteAssetSuccess, isTableView, pagination } = useSelector((state) => state.asset);
  const updatedAcknowledgedAssets = useSelector((state) => state.acknowledgementReducer.updatedAssets);
  const { t } = useTranslation();

  const [isSingleSignatureRequest, setIsSingleSignatureRequest] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showRecipientModal, setShowRecipientModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateAsset, setShowCreateAsset] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // filters
  const initialFilter = {
    department: [],
    project: [],
    tag: [],
    businessValue: [],
    contributor: [],
    assetType: [],
    importance: [],
    commercialProduct: [],
    recipients: [],
    businessRelated: [],
    thirdParty: false,
  };
  const initialSearchParams = { sortValue: 'createdAt:desc', searchValue: '' };

  const [searchParams, setSearchParams] = useState(null);
  const [filter, setFilter] = useState(initialFilter);

  const isFilterApplied = useMemo(() => {
    const isAnyArrayNonEmpty = Object.values(filter).some((array) => array.length > 0);
    const isSearchValueNonEmpty = searchValue !== '';
    const isThirdPartyActive = filter.thirdParty;
    return isAnyArrayNonEmpty || isSearchValueNonEmpty || isThirdPartyActive;
  }, [filter, searchValue]);

  const clientName = useMemo(() => {
    return activeAccount?.client ? activeAccount.client.name : activeClient?.name;
  }, [activeAccount, activeClient]);

  // data
  const fetchAssetsData = () => {
    batch(() => {
      dispatch(assetsActions.setGetAssetsClear());
      dispatch(getAssetMetaData(clientId));
    });
  };

  const handleSearch = (searchValue) => {
    setSearchParams((prev) => {
      return { ...prev, searchValue };
    });
    dispatch(assetsActions.setPagination({ ...pagination, page: 1 }));
  };

  const handleClearSearch = () => {
    setSearchValue('');
    setSearchParams((prev) => {
      return { ...prev, searchValue: '' };
    });
    dispatch(assetsActions.setPagination({ ...pagination, page: 1 }));
  };

  const setAsset = (assetData) => {
    dispatch(assetsActions.setAsset(assetData));
  };

  const resetBatchAssets = () => {
    dispatch(assetsActions.setResetBatchAssets());
  };

  // Asset Options
  const handleEditAsset = (data) => {
    setAsset(data);
    setShowEditModal(true);
  };

  const handleDistributeAsset = (data) => {
    setAsset(data);
    setShowRecipientModal(true);
  };

  const handleDeleteAsset = (data) => {
    setAsset(data);
    setShowDeleteModal(true);
  };

  const handleDelete = (id) => {
    dispatch(deleteAsset(id));
    dispatch(mixpanelEvents.deleteAsset(id));
  };

  const clearDeleteStatus = () => {
    dispatch(assetsActions.setClearDeleteStatus());
  };

  const handleUnlockAsset = (data) => {
    setAsset(data);
    setIsSingleSignatureRequest(true);
    dispatch(acknowledgementActions.changeModalVisibility(true));
  };

  const handleFinishCreateEditAsset = () => {
    dispatch(pendingAcknowledgement(clientId));
    setShowEditModal(false);
    setShowCreateAsset(false);
  };

  const handleAssetCreate = () => {
    batch(() => {
      setSearchValue('');
      dispatch(assetsActions.setInitialPagination());
      setFilter(initialFilter);
      setSearchParams(initialSearchParams);
    });
    handleFinishCreateEditAsset();
  };

  const handleAssetEdit = () => {
    dispatch(getAssets({ ...searchParams, ...pagination, filter }));
    handleFinishCreateEditAsset();
  };

  const assetOptions = useMemo(() => {
    return [
      { text: t('ASSET_PAGE.DROP_DOWN.EDIT_ASSET'), handleItem: (data) => handleEditAsset(data), disabled: !permissions.Assetedit },
      { text: t('ASSET_PAGE.DROP_DOWN.NOTIFY'), handleItem: (data) => handleDistributeAsset(data), disabled: !permissions.Assetshare },
      { text: t('ASSET_PAGE.DROP_DOWN.DELETE_ASSET'), handleItem: (data) => handleDeleteAsset(data), disabled: !permissions.Assetdelete },
    ];
  }, [permissions, t]);

  const handlePrivilegeLogDownload = useCallback(() => {
    dispatch(getPrivilegeLogData({ ...searchParams, filter, isFilterApplied }));
    mixpanelEvents.privilegeLogDownload(activeAccount._id, clientName, Role, user.email);
  }, [dispatch, searchParams, filter, isFilterApplied]);

  // Clear assets and fetch metadata
  useEffect(() => {
    dispatch(clientSettingsActions.getClientSettings(clientId));
    fetchAssetsData();
    dispatch(assetsActions.setInitialPagination());
    setFilter(initialFilter);
    setSearchParams(initialSearchParams);
    dispatch(pendingAcknowledgement(clientId));
    dispatch(getAllRecipients());
  }, [clientId]);

  useEffect(() => {
    if (searchParams) {
      dispatch(getAssets({ ...searchParams, ...pagination, filter }));
    }
  }, [searchParams, pagination, filter]);

  useEffect(() => {
    if (uploadedBatchAssets.length) {
      handleAssetCreate();
      dispatch(pendingAcknowledgement(clientId));
    }
  }, [uploadedBatchAssets]);

  useEffect(() => {
    if (updatedAcknowledgedAssets.length) {
      dispatch(assetsActions.setClearAsset());
      dispatch(pendingAcknowledgement(clientId));
      dispatch(getAssets({ ...searchParams, ...pagination, filter }));
    }
  }, [updatedAcknowledgedAssets]);

  useEffect(() => {
    if (deleteAssetSuccess) {
      setShowDeleteModal(false);
      setTimeout(() => {
        clearDeleteStatus();
      }, [2000]);
      dispatch(getAssets({ ...searchParams, ...pagination, filter }));
    }
  }, [deleteAssetSuccess]);

  return {
    handleSearch,
    setSearchParams,
    setAsset,
    resetBatchAssets,
    setFilter,
    setSearchValue,
    handleClearSearch,
    handleDistributeAsset,
    setShowRecipientModal,
    setShowEditModal,
    handleDelete,
    setShowDeleteModal,
    clearDeleteStatus,
    handleUnlockAsset,
    handleAssetCreate,
    handleAssetEdit,
    setIsSingleSignatureRequest,
    setShowCreateAsset,
    handlePrivilegeLogDownload,
    searchParams,
    pagination,
    showCreateAsset,
    isSingleSignatureRequest,
    showRecipientModal,
    assetOptions,
    showDeleteModal,
    showEditModal,
    searchValue,
    metaDataState,
    permissions,
    activeAccount,
    clientId,
    assets,
    assetsLoading,
    asset,
    filter,
    initialFilter,
    isFilterApplied,
    isTableView,
  };
};
