import styled from 'styled-components';

import { NEUTRAL_SHADES, PRIMARY_SHADES } from '../../utils/theme';
import { FlexContainer } from 'utils/globalStyles';

// ----- Client assets ----- //

export const FilterSearch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding-inline: 3.5%;
  margin-top: 40px;
`;

// ----- Assets sort ----- //

export const AssetsSortContainer = styled.div`
  display: flex;
  margin: 32px auto 0px;
  width: 93%;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const ViewModeButtonsContainer = styled.div`
  padding-left: 20px;
  border-left: 1px solid ${NEUTRAL_SHADES[400]};
  display: flex;
  gap: 8px;
  flex-wrap: nowrap;
`;

export const SortContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: fit-content;
  height: 32px;
  align-items: center;
`;

// ----- Assets amount ----- //

export const AssetsAmountContainer = styled(FlexContainer)`
  align-self: center;
`;

// ----- Asset file text ----- //

export const FileNameText = styled.div`
  color: ${NEUTRAL_SHADES.BLACK};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
`;

export const NoFilesText = styled.span`
  font-family: 'DIN2014-Regular';
  color: ${NEUTRAL_SHADES[800]};
  margin-left: 0px;
`;

export const AssetFileNum = styled.div`
  white-space: nowrap;
`;

// ----- Assets cards pagination ----- //

export const ClientAssetCardsContainer = styled.div`
  .pagination {
    margin-top: 32px;
  }
  .pagination-page-link {
    font-family: 'DIN2014-Regular';
    font-size: 16px;
    line-height: 24px;
    color: ${PRIMARY_SHADES[800]};
    width: 100%;
    text-align: center;
  }
  .pagination-page-item {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    &:hover:not(.disabled):not(.active) {
      background: ${PRIMARY_SHADES[100]};
      > * {
        color: ${PRIMARY_SHADES[900]};
      }
    }
    &.disabled {
      color: ${NEUTRAL_SHADES[700]};
      svg.tangi-icon-svg path {
        fill: ${NEUTRAL_SHADES[700]};
      }
      > * {
        cursor: default;
      }
    }
    &.active {
      background: ${PRIMARY_SHADES[800]};
      > * {
        color: ${NEUTRAL_SHADES.WHITE};
        cursor: default;
      }
    }
  }
`;
