import React from 'react';
import Tippy from '@tippyjs/react';
// import 'tippy.js/dist/tippy.css';
import { Container, Label } from './style';
import infoIcon from '../../../assets/icons/help-black-bg.svg';

export const TangiToggle = ({ label, setChecked, checked, tooltip, disabled, startLabel = false, dataTestId = 'toggle-switch' }) => {
  return (
    <Container>
      {!!startLabel && <Label checked={checked}>{label}</Label>}
      {!!startLabel && tooltip && (
        <Tippy content={<div>{tooltip}</div>}>
          <img src={infoIcon} alt="" className="info-toggle-icon" />
        </Tippy>
      )}
      <div className="toggle-switch">
        <input type="checkbox" className="checkbox" name={label} id={label} checked={checked} onChange={setChecked} disabled={disabled} data-testid={dataTestId} />
        <label className={`label ${disabled && 'disabled'}`} htmlFor={label}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
      {!startLabel && <Label checked={checked}>{label}</Label>}
      {!startLabel && tooltip && (
        <Tippy content={<div>{tooltip}</div>}>
          <img src={infoIcon} alt="" className="info-toggle-icon" />
        </Tippy>
      )}
    </Container>
  );
};
