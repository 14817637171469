import React from 'react';

import { ColorBlockContainer } from './style';

const AssetTypeColorBlock = ({ assetType }) => {
  return (
    <ColorBlockContainer assetType={assetType} data-testid="asset-type-color-block" className="asset-type-color-block">
      <div />
      <div />
    </ColorBlockContainer>
  );
};

export default AssetTypeColorBlock;
