import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';
import 'tippy.js/dist/tippy.css';

import { deactivateAccount, deleteAccount, reactivateAccount } from 'redux-toolkit/thunks/usersThunks';
import { usersActions } from 'redux-toolkit/slices/usersSlice';
import { TangiPopup, TangiIconButton } from '../TangiLibrary';
import { useComponentVisible } from '../../utils/customHooks';
import { BUTTON_VARIANTS } from '../../utils/componentUtils';
import { isPartnershipDisabled } from '_components/ThirdPartyTag/utils';
import { RESULT_STATUS } from '../../utils/enums';
import { StyledDropDown, StyledDropDownItem, ModalBody } from './style';

export const PeopleOptions = ({ accountStatus, accountId, onFinish, shouldReloadPage, partnership = null, dropdownStyles = null, handleEdit = undefined, ...props }) => {
  const [popupProps, setPopupProps] = useState({ show: false, text: 'a', type: 'success' });

  const { updateAccountStatusStatus, updateAccountStatusError } = useSelector((state) => state.users);
  const { loading: userLoading } = useSelector((state) => state.users);

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleEditUser = () => {
    setIsComponentVisible(false);
    handleEdit();
  };

  const handleDelete = () => {
    setIsComponentVisible(false);
    setPopupProps({
      show: true,
      title: t('PEOPLE.MODAL.TITLE.CONFIRM_DELETION'),
      body: (
        <ModalBody>
          <b>{t('PEOPLE.MODAL.MODAL_BODY.DELETE_ACCOUNT')}</b>
          <p>{t('PEOPLE.MODAL.MODAL_BODY.DELETE_ACCOUNT_CONFIRMATION')}</p>
          <b>{t('PEOPLE.MODAL.MODAL_BODY.NON_REVERSIBLE_ACTION')}</b>
          <p>{t('PEOPLE.MODAL.MODAL_BODY.NO_REVERSAL')}</p>
        </ModalBody>
      ),
      checkBox: true,
      checkBoxText: t('PEOPLE.MODAL.CHECKBOX_TEXT.I_UNDERSTAND_REMOVE'),
      onConfirm: () => dispatch(deleteAccount(accountId)),
    });
    setIsComponentVisible(false);
  };

  const handleDeactivate = () => {
    setPopupProps({
      show: true,
      title: t('PEOPLE.MODAL.TITLE.CONFIRM_DEACTIVATION'),
      body: (
        <ModalBody>
          <b>{t('PEOPLE.MODAL.MODAL_BODY.DEACTIVATE_ACCOUNT_TITLE')}</b>
          <p>{t('PEOPLE.MODAL.MODAL_BODY.DEACTIVATE_ACCOUNT_TEXT')}</p>
          <b>{t('PEOPLE.MODAL.MODAL_BODY.REVERSIBLE_ACTION')}</b>
          <p>{t('PEOPLE.MODAL.MODAL_BODY.ACTIVATE_ACCOUNT_LATER')}</p>
        </ModalBody>
      ),
      onConfirm: () => dispatch(deactivateAccount(accountId)),
    });
    setIsComponentVisible(false);
  };
  const handleReactivate = () => {
    setPopupProps({
      show: true,
      title: t('PEOPLE.MODAL.TITLE.CONFIRM_ACTIVATION'),
      body: (
        <ModalBody>
          <b>{t('PEOPLE.MODAL.MODAL_BODY.REACTIVATE_ACCOUNT_TITLE')}</b>
          <p>{t('PEOPLE.MODAL.MODAL_BODY.REACTIVATE_ACCOUNT_TEXT')}</p>
          <b>{t('PEOPLE.MODAL.MODAL_BODY.REVERSIBLE_ACTION')}</b>
          <p>{t('PEOPLE.MODAL.MODAL_BODY.DEACTIVATE_ACCOUNT_LATER')}</p>
        </ModalBody>
      ),
      checkboxText: '',
      onConfirm: () => dispatch(reactivateAccount(accountId)),
    });
    setIsComponentVisible(false);
  };
  const onCancel = () => {
    setPopupProps({ show: false, text: '', type: '' });
  };

  const options = useMemo(() => {
    return [
      !partnership && {
        text: t?.('PEOPLE.TOOLTIP.EDIT'),
        handleItem: handleEditUser,
      },
      {
        text: t?.('PEOPLE.SELECT.ACTIVATE_USER'),
        handleItem: handleReactivate,
        disabled: accountStatus !== 'Disabled',
      },
      {
        text: t?.('PEOPLE.SELECT.DEACTIVATE_USER'),
        handleItem: handleDeactivate,
        disabled: accountStatus === 'Disabled',
      },
      {
        text: t?.('PEOPLE.SELECT.DELETE_USER'),
        handleItem: handleDelete,
        disabled: accountStatus !== 'Disabled',
      },
    ].filter(Boolean);
  }, [accountStatus, partnership, t]);

  useEffect(() => {
    if (updateAccountStatusError?.message) {
      onCancel();
      if (onFinish) {
        onFinish();
      }
      dispatch(usersActions.setPeopleToastProps({ show: true, type: RESULT_STATUS.ERROR, text: updateAccountStatusError.message }));
    }
  }, [updateAccountStatusError]);

  useEffect(() => {
    if (updateAccountStatusStatus) {
      onCancel();
      if (onFinish) {
        onFinish();
      }
      dispatch(usersActions.setPeopleToastProps({ show: true, type: RESULT_STATUS.SUCCESS, text: updateAccountStatusStatus }));
      if (shouldReloadPage) window.location.reload();
    }
  }, [updateAccountStatusStatus]);

  const renderActionsTippyContent = () => {
    if (isPartnershipDisabled(partnership)) return t('PARTNERSHIP_ADD_EDIT_PARTNER.PARTNER_TOOLTIP.EDIT_DISABLED');
    else return t('GENERAL.TIPPY.MORE_ACTIONS');
  };

  return (
    <div ref={ref} {...props} style={{ display: 'relative' }} data-testid="people-options-dropdown-button">
      <Tippy content={renderActionsTippyContent()} placement="top-end">
        <span>
          <TangiIconButton
            icon="threeDotsVertical"
            onClick={() => setIsComponentVisible((prevState) => !prevState)}
            variant={BUTTON_VARIANTS.TERTIARY_GREY}
            isActive={isComponentVisible}
            disabled={isPartnershipDisabled(partnership)}
          />
        </span>
      </Tippy>
      {isComponentVisible && (
        <StyledDropDown className="people-options-dropdown" data-testid="people-options-dropdown" dropdownStyles={dropdownStyles}>
          {options?.map((item, index) => (
            <StyledDropDownItem key={index} onClick={item.disabled ? undefined : item.handleItem} disabled={item.disabled}>
              {item.text}
            </StyledDropDownItem>
          ))}
        </StyledDropDown>
      )}
      <TangiPopup {...popupProps} onCancel={onCancel} loading={userLoading} alert />
    </div>
  );
};
