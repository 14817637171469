import styled, { css } from 'styled-components';

import { ASSET_TYPE_COLORS } from '../../utils/theme';
import { LOCKED_ASSET } from '../../utils/assetTypes';

export const ColorBlockContainer = styled.div`
  ${({ assetType }) => css`
    width: 16px;
    height: 24px;
    display: flex;
    flex-wrap: nowrap;
    div:first-of-type {
      width: 10px;
      background: ${ASSET_TYPE_COLORS[assetType]};
      height: 100%;
    }
    div:last-of-type {
      width: 6px;
      background: ${ASSET_TYPE_COLORS[assetType]}50;
      height: 100%;
    }
  `}
`;
