import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import QRCode from 'qrcode.react';
import OTPInput from 'otp-input-react';
import { useTranslation } from 'react-i18next';

import { authenticate } from 'redux-toolkit/thunks/authThunks';
import { AuthContext } from '../../_hocs/Cognito/Auth';
import { useLoggedInRedirect } from '../../utils/customHooks';
import { TangiButton, TangiAlert, TangiTypography } from '../TangiLibrary';
import { NEUTRAL_SHADES, PRIMARY_SHADES } from 'utils/theme';
import { googlePlusIcon, googleAuthIcon } from 'assets/icons';
import { Description, MFAContainer, ContactUs, FormContainer, TextWithImageContainer, QrStyling, ThirdStepContainer, OtpTitleContainer } from './styles';
import { tangiblyMail } from '_constants/consts';

function CognitoMFA({ QRCodeValue, accountId }) {
  const { senfMfa, verifyToken } = useContext(AuthContext);
  const { error } = useSelector((state) => state.authentication);
  const showQR = useMemo(() => !!QRCodeValue.toString(), [QRCodeValue]);
  const [otpValue, setOtpValue] = useState('');
  const [loading, setLoading] = useState(false);
  useLoggedInRedirect(true);
  const [alertProps, setAlertProps] = useState({ show: false, text: '', type: '' });

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    setAlertProps({ show: false, text: '', type: '' });
    e.preventDefault();
    if (otpValue.length < 6) {
      setAlertProps({ show: true, type: 'error', text: t('LOGIN_PAGE.LOGIN_FORM.OTP_SIX_DIGITS') });
      return;
    }
    setLoading(true);
    if (QRCodeValue) {
      verifyToken(otpValue)
        .then(async (res) => {
          const { idToken } = res;
          dispatch(authenticate({ idToken: idToken.jwtToken, accountId }));
        })
        .catch(() => {
          setAlertProps({ show: true, type: 'error', text: t('LOGIN_PAGE.LOGIN_FORM.WRONG_OTP') });
          setLoading(false);
        });
    } else {
      senfMfa(otpValue)
        .then(async (res) => {
          const { idToken } = res;
          dispatch(authenticate({ idToken: idToken.jwtToken, accountId }));
        })
        .catch((error) => {
          if (error.toString().includes('Invalid code received for user')) {
            setAlertProps({ show: true, type: 'error', text: t('LOGIN_PAGE.LOGIN_FORM.WRONG_OTP') });
          } else {
            setAlertProps({ show: true, type: 'error', text: t('LOGIN_PAGE.LOGIN_FORM.LOGIN_AGAIN') });
          }
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (error?.message) {
      setLoading(false);
      setAlertProps({ show: true, type: 'error', text: error.message });
    }
  }, [error]);

  const renderSetUpOtpSteps = () => {
    return (
      <Description>
        <TangiTypography type="heading-lg" weight="semibold">
          {t('LOGIN_PAGE.TWO_FACTOR_AUTH.SET_UP')}
        </TangiTypography>
        <TangiTypography type="subheading" weight="semibold">
          {t('LOGIN_PAGE.TWO_FACTOR_AUTH.IN_ORDER_TO_CONTINUE')}
        </TangiTypography>
        <TextWithImageContainer>
          <TangiTypography> {t('LOGIN_PAGE.TWO_FACTOR_AUTH.STEP_1')}</TangiTypography>
          <img src={googleAuthIcon} width="20" height="20" alt="" />
        </TextWithImageContainer>
        <TextWithImageContainer>
          <TangiTypography>{t('LOGIN_PAGE.TWO_FACTOR_AUTH.STEP_2')}</TangiTypography>
          <img src={googlePlusIcon} alt="" />
        </TextWithImageContainer>
        <QRCode value={QRCodeValue} style={QrStyling} />
        <ThirdStepContainer>
          <TangiTypography>{t('LOGIN_PAGE.TWO_FACTOR_AUTH.STEP_3')}</TangiTypography>
          <TangiTypography type="sub-body" size="12px" color={NEUTRAL_SHADES[900]}>
            {t('LOGIN_PAGE.TWO_FACTOR_AUTH.ENTER_CODE')}
          </TangiTypography>
          <TangiTypography type="sub-body" size="12px" color={NEUTRAL_SHADES[900]}>
            {t('LOGIN_PAGE.TWO_FACTOR_AUTH.YOU_CAN_USE_IT')}
          </TangiTypography>
          <TangiTypography type="sub-body" size="12px" color={NEUTRAL_SHADES[900]}>
            {t('LOGIN_PAGE.TWO_FACTOR_AUTH.NOTE')}
          </TangiTypography>
        </ThirdStepContainer>
      </Description>
    );
  };

  const renderOtpLoginTitle = () => {
    return (
      <OtpTitleContainer>
        <TangiTypography type="heading-lg">{t('LOGIN_PAGE.TWO_FACTOR_AUTH.ENTER_OTP')}</TangiTypography>
      </OtpTitleContainer>
    );
  };

  const renderOtpComponent = () => {
    return (
      <OTPInput
        value={otpValue}
        onChange={setOtpValue}
        style={{ justifyContent: 'center' }}
        autoFocus
        OTPLength={6}
        otpType="number"
        secure={false}
        inputStyles={{ margin: '10px', border: '1px solid #DCDCDC' }}
      />
    );
  };

  const renderSubmitButton = () => {
    return <TangiButton type="submit" text={t('GENERAL.BUTTONS_TEXT.SUBMIT')} loading={loading} className="submit-button" data-testid="cognito-mfa-submit" />;
  };

  const renderContactUs = () => {
    return (
      <ContactUs>
        {t('LOGIN_PAGE.TWO_FACTOR_AUTH.HAVING_TROUBLE')}
        <a href={`mailto:${tangiblyMail}`} style={{ color: PRIMARY_SHADES[800] }}>
          {tangiblyMail}
        </a>
      </ContactUs>
    );
  };
  return (
    <MFAContainer>
      <Form onSubmit={handleSubmit}>
        <FormContainer showQR={showQR}>
          {showQR ? renderSetUpOtpSteps() : renderOtpLoginTitle()}
          {renderOtpComponent()}
          {renderSubmitButton()}
          {renderContactUs()}
          <TangiAlert className="error-alert-login" {...alertProps} data-testid="error-alert-register-login" />
        </FormContainer>
      </Form>
    </MFAContainer>
  );
}

export { CognitoMFA };
