import { useEffect } from 'react';
import axios from 'axios';
import { LOCAL_STORAGE_KEYS } from 'utils/token/localStorage';
import { loadStateFromLocalStorage } from 'utils/localStorage';

export function useAxiosInterceptor() {
  useEffect(() => {
    const setupInterceptor = () => {
      axios.interceptors.request.use((config) => {
        try {
          const newToken = loadStateFromLocalStorage(LOCAL_STORAGE_KEYS.JWT_TOKEN);
          if (newToken) {
            config.headers.Authorization = `Bearer ${newToken}`;
          }
        } catch (error) {
          console.error(error);
        }
        return config;
      });
    };
    setupInterceptor();
  }, []);
}
