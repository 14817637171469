import styled from 'styled-components';

import { FlexContainer } from 'utils/globalStyles';
import { NEUTRAL_SHADES } from 'utils/theme';

export const DuoCenteredContainer = styled(FlexContainer)`
  align-items: center;
  height: fit-content;
`;

export const DropdownItemContainer = styled(DuoCenteredContainer)`
  gap: 12px;
  min-width: 100%;
`;

export const customDropdownStyle = {
  '.tangi-dropdown-item:nth-of-type(3)': {
    borderTop: `1px solid ${NEUTRAL_SHADES[300]}`,
  },
};
