import { ERROR_SHADES, SUCCESS_SHADES, WARNING_SHADES } from 'utils/theme';
import { ChipTextType, ChipType } from './components/ContractChip';

const ScoreValues = {
  SUCCESS: 70,
  ERROR: 40,
};

export const getScoreColor = (score: number) => {
  const numericScore = score;

  if (numericScore > ScoreValues.SUCCESS) {
    return ChipType.SUCCESS;
  } else if (numericScore > ScoreValues.ERROR && numericScore <= ScoreValues.SUCCESS) {
    return ChipType.WARNING;
  } else {
    return ChipType.ERROR;
  }
};

export const getProgressBarColor = (score: number) => {
  const numericScore = score;

  if (numericScore > ScoreValues.SUCCESS) {
    return SUCCESS_SHADES[300];
  } else if (numericScore > ScoreValues.ERROR && numericScore <= ScoreValues.SUCCESS) {
    return WARNING_SHADES[1100];
  } else {
    return ERROR_SHADES[500];
  }
};

export const getTextByScore = (score: number) => {
  const numericScore = score;
  if (numericScore > ScoreValues.SUCCESS) {
    return ChipTextType.SUCCESS;
  } else if (numericScore > ScoreValues.ERROR && numericScore <= ScoreValues.SUCCESS) {
    return ChipTextType.WARNING;
  } else {
    return ChipTextType.ERROR;
  }
};
export interface ChildData {
  key: ContractKeys;
  content: string;
  recommendation: string;
  label: string;
}
export enum ContractKeys {
  confidentiality_provision = 'confidentiality_provision',
  trade_secret = 'trade_secret',
  clearly_defined = 'clearly_defined',
  termination = 'termination',
  returning_or_destroying = 'returning_or_destroying',
  survival_clause = 'survival_clause',
  discovering_information = 'discovering_information',
  limits_on_liability = 'limits_on_liability',
  sharing_without_restrictions = 'sharing_without_restrictions',
  mandatory_disclosure = 'mandatory_disclosure',
}

export const initialStates: { [key in ContractKeys]: boolean } = {
  confidentiality_provision: false,
  trade_secret: false,
  clearly_defined: false,
  termination: false,
  returning_or_destroying: false,
  survival_clause: false,
  discovering_information: false,
  limits_on_liability: false,
  sharing_without_restrictions: false,
  mandatory_disclosure: false,
};

export const contractOptionsData: ChildData[] = [
  {
    key: ContractKeys.confidentiality_provision,
    label: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.CONFIDENTIALITY_PROVISION.LABEL',
    content: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.CONFIDENTIALITY_PROVISION.CONTENT',
    recommendation: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.CONFIDENTIALITY_PROVISION.RECOMMENDATION',
  },
  {
    key: ContractKeys.trade_secret,
    label: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.TRADE_SECRET.LABEL',
    content: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.TRADE_SECRET.CONTENT',
    recommendation: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.TRADE_SECRET.RECOMMENDATION',
  },
  {
    key: ContractKeys.clearly_defined,
    label: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.CLEARLY_DEFINED.LABEL',
    content: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.CLEARLY_DEFINED.CONTENT',
    recommendation: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.CLEARLY_DEFINED.RECOMMENDATION',
  },
  {
    key: ContractKeys.termination,
    label: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.TERMINATION.LABEL',
    content: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.TERMINATION.CONTENT',
    recommendation: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.TERMINATION.RECOMMENDATION',
  },
  {
    key: ContractKeys.returning_or_destroying,
    label: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.RETURNING_OR_DESTROYING.LABEL',
    content: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.RETURNING_OR_DESTROYING.CONTENT',
    recommendation: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.RETURNING_OR_DESTROYING.RECOMMENDATION',
  },
  {
    key: ContractKeys.survival_clause,
    label: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.SURVIVAL_CLAUSE.LABEL',
    content: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.SURVIVAL_CLAUSE.CONTENT',
    recommendation: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.SURVIVAL_CLAUSE.RECOMMENDATION',
  },
  {
    key: ContractKeys.discovering_information,
    label: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.DISCOVERING_INFORMATION.LABEL',
    content: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.DISCOVERING_INFORMATION.CONTENT',
    recommendation: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.DISCOVERING_INFORMATION.RECOMMENDATION',
  },
  {
    key: ContractKeys.limits_on_liability,
    label: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.LIMITS_ON_LIABILITY.LABEL',
    content: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.LIMITS_ON_LIABILITY.CONTENT',
    recommendation: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.LIMITS_ON_LIABILITY.RECOMMENDATION',
  },
  {
    key: ContractKeys.sharing_without_restrictions,
    label: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.SHARING_WITHOUT_RESTRICTIONS.LABEL',
    content: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.SHARING_WITHOUT_RESTRICTIONS.CONTENT',
    recommendation: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.SHARING_WITHOUT_RESTRICTIONS.RECOMMENDATION',
  },
  {
    key: ContractKeys.mandatory_disclosure,
    label: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.MANDATORY_DISCLOSURE.LABEL',
    content: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.MANDATORY_DISCLOSURE.CONTENT',
    recommendation: 'DISCOVERY_LOBBY.CONTRACT_RESPONSE.MANDATORY_DISCLOSURE.RECOMMENDATION',
  },
];
