import React from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.scss';

import { PrivateRoute } from '../_components/PrivateRoute';
import IdleHandler from '../_hocs/IdleHandler/IdleHandler';
import VersionControlHandler from '_hocs/VersionControlHandler/VersionControlHandler';
import { history } from '../_helpers';
import { publicRoutes, routes, superAdminRoutes } from 'utils/routing/routes';
import { checkSentryEnv, sentryConfig } from '_services/utils/sentryConfig';
import { XRayResultsWrapper } from '_context/XrayResults/XRayResultsWrapper';
import { mixpanelConfig } from '_services/utils/MixPanel/mixpanelConfig';
import { config } from 'config/config';
import UnderConstruction from 'pages/UnderConstruction/UnderConstruction';
import { useAxiosInterceptor } from '_hooks/useAxiosInterceptor';


checkSentryEnv() && sentryConfig([...publicRoutes, ...routes, superAdminRoutes]);
mixpanelConfig();

function App() {
  useAxiosInterceptor();
  return (
    <div className="Canopy-App">
      <Router history={history}>
        <VersionControlHandler>
          <IdleHandler>
            <XRayResultsWrapper>
              <Switch>
                {routes.map((route, index) => {
                  return <PrivateRoute key={`${route.path}-${index}`} exact={route.exact} path={route.path} component={route.component} permissions={route.permissions} />;
                })}

                {/* super admin */}
                {superAdminRoutes.map((route, index) => {
                  return <PrivateRoute key={`${route.path}-${index}`} exact={route.exact} path={route.path} component={route.component} permissions={route.permissions} />;
                })}

                {publicRoutes.map((route, index) => {
                  return config.isUnderMaintenance ? (
                    <>
                      <Route path="/" component={UnderConstruction} />
                      <Redirect from={route.path} to="/" />
                    </>
                  ) : (
                    <Route key={`${route.path}-${index}`} exact={route.exact} path={route.path} component={route.component} />
                  );
                })}
              </Switch>
            </XRayResultsWrapper>
          </IdleHandler>
        </VersionControlHandler>
      </Router>
    </div>
  );
}

export { App };
