import './Header.scss';
import React, { useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navbar, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';

import { TangiIconButton } from '_components/TangiLibrary';
import { MobileHeader } from './MobileHeader';
import { PolicyDropDown } from '_components/PolicyDropDown/PolicyDropDown';
import SwitchClientDropdown from '_components/SwitchClientDropdown/SwitchClientDropdown';
import SwitchAccountDropdown from './components/SwitchAccountDropdown/SwitchAccountDropdown';
import { AuthContext } from '_hocs/Cognito/Auth';
import { history } from '../../_helpers';
import { adminsExceptLearn, settingsRoles, lawyers } from '../../utils/roles';
import { BUTTON_SIZES } from 'utils/componentUtils';
import { AppRoutes } from 'utils/routing/consts';
import { generateRoute } from 'utils/routing/generateRoute';
import { tangiblyLogo } from '../../assets/img';
import { BUTTON_VARIANTS } from 'utils/theme';
import { StyledNavbar, StyledNavSubmenu } from './style';

function Header({}) {
  const { logout } = useContext(AuthContext);
  const { activeAccount, Role } = useSelector((state) => state.authentication);
  const activeClient = useSelector((state) => state.lawfirm.activeClient);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const hasPolicyUploadPermissions = useMemo(() => adminsExceptLearn.includes(Role), [Role]);
  const isLawyer = useMemo(() => lawyers.includes(Role), [Role]);

  const handleLogout = () => {
    dispatch(logout());
    history.push(AppRoutes.LOGIN);
    window.location.reload();
  };

  const shouldRenderPolicyDropdown = () => {
    const policyFiles = isLawyer ? activeClient?.policyFiles : activeAccount?.client?.policyFiles;
    const shouldRenderPolicyForNotAdmins = !!policyFiles?.[0];
    const shouldRenderDropDown = policyFiles && (hasPolicyUploadPermissions || (!hasPolicyUploadPermissions && shouldRenderPolicyForNotAdmins));
    return shouldRenderDropDown;
  };

  const onSettingsClick = () => {
    const clientId = isLawyer ? activeClient?.id : activeAccount?.client?._id;
    history.push(generateRoute(AppRoutes.SETTINGS, { clientId }));
    window.location.reload();
  };

  const renderSwitchClientDropdown = () => {
    return isLawyer && <SwitchClientDropdown />;
  };

  const renderPolicyDropdown = () => {
    const isFileExist = isLawyer ? !!activeClient?.policyFiles?.length : !!activeAccount?.client?.policyFiles?.length;

    return (
      shouldRenderPolicyDropdown() && (
        <PolicyDropDown
          hasPolicyUploadPermissions={hasPolicyUploadPermissions}
          isFileExist={isFileExist}
          isLawyer={isLawyer}
          activeClientId={isLawyer ? activeClient?.id : activeAccount?.client?.id}
        />
      )
    );
  };

  const renderMobileHeader = () => {
    return (
      <>
        {/*eslint-disable-next-line react/no-unknown-property */}
        <div fluid className="py-1 d-sm-none d-md-none d-lg-none d-xl-none" id="MobileHeaderView">
          <MobileHeader handleLogout={handleLogout} />
        </div>
        {/*eslint-disable-next-line react/no-unknown-property */}
        <div fluid className="py-1 d-none d-sm-block d-md-none d-lg-none d-xl-none" id="MobileHeaderView">
          <MobileHeader handleLogout={handleLogout} />
        </div>
      </>
    );
  };

  const renderSettingsButton = () => {
    if (isLawyer && !activeClient) return null;

    return (
      <Tippy content={t('SIDE_BAR.SETTINGS')} placement="bottom">
        <span>
          <TangiIconButton customDataTestid="settings-button" size={BUTTON_SIZES.MD} icon="settings" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={onSettingsClick} />
        </span>
      </Tippy>
    );
  };

  return (
    <StyledNavbar collapseOnSelect expand="lg" bg="white" variant="light" className="navbar-expand-md navbar-top border-bottom" fixed="top">
      <div className="d-none d-sm-none d-md-block" id="header-nav-container">
        <Navbar id="responsive-navbar-nav">
          <div>
            <img width="160" alt="TANGIBLY" src={tangiblyLogo} />
          </div>
          <Nav className="me-auto" />
          <StyledNavSubmenu>
            {settingsRoles.includes(Role) && renderSettingsButton()}
            {renderPolicyDropdown()}
            {renderSwitchClientDropdown()}
          </StyledNavSubmenu>
          <SwitchAccountDropdown handleLogout={handleLogout} />
        </Navbar>
      </div>
      {renderMobileHeader()}
    </StyledNavbar>
  );
}

export { Header };
