import styled from 'styled-components';

import { NEUTRAL_SHADES } from '../../utils/theme';

// ----- Common styles for asset cards ----- //

export const StyledAssetCard = styled.div`
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 232px;
`;

export const AssetCardHeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

export const ColorAndType = styled.div`
  display: flex;
  gap: 16px;
  font-family: 'DIN2014-Regular';
  font-size: 14px;
  align-items: center;
  span {
    padding-top: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 224px;
  }
`;

export const AssetBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const AssetName = styled.div`
  font-size: 16px;
  font-family: 'DIN2014-DemiBold';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 256px;
`;

export const AssetFileContainer = styled.div`
  font-family: 'DIN2014-Regular';
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
`;

export const AssetFileIconAndName = styled.div<{ isDisabled?: boolean }>`
  font-family: 'DIN2014-DemiBold';
  width: 75%;
  min-width: 168px;
  height: 40px;
  border: 1px dashed ${NEUTRAL_SHADES[500]};
  padding: 10px 12px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 14px;
  gap: 10px;
  background-color: ${({ isDisabled }) => (isDisabled ? NEUTRAL_SHADES[50] : 'initial')};
`;

export const AssetCardFooter = styled.div`
  width: 100%;
  padding: 8px 0px 8px 8px;
  font-family: 'DIN2014-Regular';
  font-size: 14px;
  height: 32px;
  display: flex;
  align-items: center;
`;
