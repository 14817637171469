import { SPACING } from 'utils/theme';

export const ipAuditModalBodyStyle = {
  padding: SPACING[0],
  height: '100%',
};

export const ipAuditModalContainerStyle = {
  height: '676px',
};

export const ipAuditStepperViewStyle = {
  width: '995px',
  padding: 0,
};
