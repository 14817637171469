/* eslint-disable @typescript-eslint/no-explicit-any */
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { loadStateFromLocalStorage } from 'utils/localStorage';
import { LOCAL_STORAGE_KEYS } from 'utils/token/localStorage';

const getRememberMyDeviceTokens = (cognitoUser: any) => {
  const { clientId } = cognitoUser.pool;
  const localStorageDeviceGroupKey = `CognitoIdentityServiceProvider.${clientId}.${cognitoUser.username}.deviceGroupKey`;
  const localStorageDeviceKey = `CognitoIdentityServiceProvider.${clientId}.${cognitoUser.username}.deviceKey`;
  const localStorageRandomPasswordKey = `CognitoIdentityServiceProvider.${clientId}.${cognitoUser.username}.randomPasswordKey`;
  const deviceGroupKey = localStorage.getItem(localStorageDeviceGroupKey);
  const deviceKey = localStorage.getItem(localStorageDeviceKey);
  const randomPasswordKey = localStorage.getItem(localStorageRandomPasswordKey);

  return {
    deviceGroup: {
      key: localStorageDeviceGroupKey,
      value: deviceGroupKey as string,
    },
    deviceKey: {
      key: localStorageDeviceKey,
      value: deviceKey as string,
    },
    randomPasswordKey: {
      key: localStorageRandomPasswordKey,
      value: randomPasswordKey as string,
    },
  };
};

const checkTokenExpiration = (minutes = 50) => {
  const accessToken = loadStateFromLocalStorage(LOCAL_STORAGE_KEYS.JWT_TOKEN);
  const decodedToken: JwtPayload = jwtDecode(accessToken as string);

  const currentTime = Math.floor(Date.now() / 1000);
  const validUntil = (currentTime + minutes * 60) * 1000;
  const expiration = decodedToken.exp! * 1000;

  return validUntil > expiration;
};

const cognitoUtil = { getRememberMyDeviceTokens, checkTokenExpiration };

export default cognitoUtil;
