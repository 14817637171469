import { useTranslation } from 'react-i18next';

import { TangiSvgIcon } from '_components/TangiLibrary';
import { BUTTON_VARIANTS, SECONDARY_SHADES } from 'utils/theme';
import { Container, StyledTangiIconButton, StyledTangiTypography } from './styles';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '_helpers';
import { generateRoute } from 'utils/routing/generateRoute';
import { AppRoutes } from 'utils/routing/consts';

const CompetitiveActionLink = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const activeAccount = useSelector((state: RootState) => state.authentication.activeAccount);
  const activeClient = useSelector((state: RootState) => state.lawfirm.activeClient);

  const clientId = activeClient ? activeClient.id : activeAccount?.client?._id;

  return (
    <Container>
      <TangiSvgIcon component="bulb" color={SECONDARY_SHADES[800]} />
      <StyledTangiTypography type="body">{t('PREDICTED_PORTFOLIO.COMPETITIVE_BOX_TEXT')}</StyledTangiTypography>
      <StyledTangiIconButton icon="arrowRight" variant={BUTTON_VARIANTS.SECONDARY_PURPLE} onClick={() => history.push(generateRoute(AppRoutes.COMPETITIVE_ANALYSIS, { clientId }))} />
    </Container>
  );
};

export default CompetitiveActionLink;
