import React from 'react';
import { ItemContainer, ImgCourse, CourseName, StatusIcon } from './style';
import successIcon from '../../../assets/img/check-circle-solid.png';
import defaultImg from '../../../assets/img/tangibly200.jpeg';

export const getUrl = (url) => {
  const videoId = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/) || url.match(/youtu.be\/(.{11})/) || null;
  if (videoId) {
    return `https://img.youtube.com/vi/${videoId[2]}/0.jpg`;
  }
  return defaultImg;
};

const CoursesItem = ({ url, title, status }) => {
  return (
    <ItemContainer>
      <ImgCourse src={getUrl(url)} alt="test" />
      <CourseName>{title}</CourseName>
      <StatusIcon>{status === 'Completed' ? <img className="m-auto" width="16" height="16" alt="..." src={successIcon} /> : null}</StatusIcon>
    </ItemContainer>
  );
};

export default CoursesItem;
