import { Row, Col } from 'react-bootstrap';
import { SuperAdminSideMenu } from '../../../_components/Admin/SideMenu/SuperAdminSideMenu';
import { Footer } from '../../../_components/Footer';
import { AdminHeader } from '../../../_components/Header';

function AdminDashboard({ children, title }) {
  return (
    <Row className="m-0 p-0">
      <Col className="col-lg-2 col-sm-3 p-0 d-none d-sm-none d-md-block" id="sidebar-wrapper">
        <SuperAdminSideMenu />
      </Col>
      <Col className="col-lg-10 col-sm-9 p-0" id="page-content-wrapper">
        <AdminHeader title={title} />
        <div className="content-height">{children}</div>
        <Footer />
      </Col>
    </Row>
  );
}

export { AdminDashboard };
