import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';

import agreementAdapter from 'redux-toolkit/adapters/agreementAdapter';
import { DownloadAgreementParams, FilePayloadWithIndex } from 'utils/types/agreement/agreement';
import { agreementNewActions } from 'redux-toolkit/slices/agreementSlice';
import { RESULT_STATUS } from 'utils/enums';
import { downloadFile } from 'utils/downloadFile';

// TODO: ------ THIS IS NEW ------
// agreementActions NEED TO BE REFACTORED AND MOVED HERE

export const downloadAgreement = createAsyncThunk<
  FilePayloadWithIndex, //Thunk return type
  DownloadAgreementParams // This is the type of the argument that thunk receives
>('/agreement/downloadAgreement', async (downloadAgreementParams, { dispatch }) => {
  const res = await agreementAdapter.downloadAgreement(downloadAgreementParams);
  if (res?.file && res?.base64) {
    const fileName = res.file.split('/').pop();
    await downloadFile(res.mimeType, res.base64, fileName || '');
  } else {
    const text = i18next.t('GENERAL.ERROR.SOMETHING_WENT_WRONG');
    dispatch(agreementNewActions.setAgreementToastProps({ show: true, text, type: RESULT_STATUS.ERROR }));
  }
  return { agreementOrderNumber: downloadAgreementParams.agreementOrderNumber };
});
