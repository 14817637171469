import React, { useMemo } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import './Avatar.scss';
import defaultProfile from '../../assets/img/default_profile.png';
import { SECONDARY_SHADES, NEUTRAL_SHADES } from '../../utils/theme';
import { AVATAR_SIZES } from '../../utils/componentUtils';
import { AvatarContainerLink, AvatarContainerDiv, LetteredAvatar, AvatarImg } from './style';

function AvatarEmail({ name, email, image, href = '#', styles, isLinkDisabled = false, color = SECONDARY_SHADES[500], bordered = false, size = AVATAR_SIZES.SM, ...props }) {
  if (image === 'default') {
    // eslint-disable-next-line
    image = defaultProfile;
  }

  const bgColor = useMemo(() => {
    return image ? NEUTRAL_SHADES.WHITE : color;
  }, [image]);

  const names = name ? name.split(' ').reverse() : [];
  let initials = '';
  names.forEach((element) => {
    initials = element.slice(0, 1) + initials;
  });
  return (
    <Tippy content={<div className="font-bold text-xs">{email} </div>}>
      {!isLinkDisabled ? (
        <AvatarContainerLink {...props} size={size} bgColor={bgColor} to={href} className={`avatar ${styles} ${bordered ? 'border-2 border border-white' : ''}`}>
          {image ? (
            <AvatarImg className="text-capitalize object-cover" alt={name} src={image} />
          ) : (
            <LetteredAvatar className="fw-bolder uppercase">
              <span>{initials.toUpperCase()}</span>
            </LetteredAvatar>
          )}
        </AvatarContainerLink>
      ) : (
        <AvatarContainerDiv {...props} size={size} bgColor={bgColor} className={`avatar ${styles} ${bordered ? 'border-2 border border-white' : ''}`}>
          {image ? (
            <AvatarImg className="text-capitalize object-cover" alt={name} src={image} />
          ) : (
            <LetteredAvatar className="fw-bolder uppercase">
              <span>{initials.toUpperCase()}</span>
            </LetteredAvatar>
          )}
        </AvatarContainerDiv>
      )}
    </Tippy>
  );
}

export { AvatarEmail };
