import { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getUsers } from 'redux-toolkit/thunks/clientThunks';
import { getRedirectRoute, IRoles } from './roles';
import { AppRoutes } from './routing/consts';

export const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

/**
 * Custom hook for handling redirects after logging in.
 * @param {boolean} isPushHistory - Indicates whether to push history when redirecting.
 * @returns {object} An object containing login status and redirect information.
 */
export const useLoggedInRedirect = (isPushHistory = false) => {
  const { user, loggedIn, activeAccount } = useSelector((state) => state.authentication);
  const history = useHistory();
  const [redirect, setRedirect] = useState('/');

  /**
   * Effect to handle actions after login and user account validation.
   * @effect
   * @param {boolean} loggedIn - Indicates if the user is currently logged in.
   * @param {object} user - The user object containing user information.
   * @param {object} activeAccount - The user's active account object.
   * @param {boolean} isPushHistory - Indicates whether to push history when redirecting.
   */
  useEffect(() => {
    if (loggedIn && user && activeAccount) {
      const currRole = activeAccount.role.name;
      if (currRole !== IRoles.SUPER_ADMIN && isPushHistory) {
        localStorage.setItem(`seenLoginDisclaimer[${activeAccount._id}]`, false);
      }
      setRedirect(getRedirectRoute(activeAccount));
    }
  }, [loggedIn, user, activeAccount, activeAccount]);

  useEffect(() => {
    if (redirect !== AppRoutes.MAIN && isPushHistory) {
      history.push(redirect);
    }
  }, [redirect]);

  return { loggedIn, redirect };
};

export const dispatchGetUsersEmployee = (dispatch2, roleName, clientId, entityType, pagination = { page: 1, limit: 10 }, filter = {}) => {
  dispatch2(getUsers({ userRoleName: roleName, client: clientId, type: entityType, pagination, filter }));
};

/**
 * Custom hook for managing the visibility of a component based on clicks outside the component.
 * @param {boolean} initialIsVisible - The initial visibility state of the component.
 * @returns {object} An object containing references and state for managing component visibility.
 */
export const useComponentVisible = (initialIsVisible) => {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
};
