import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';

import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { ProgressBar } from 'pages/ClientDashboard/components/Charts';
import { courseProgressionConstants } from '_constants/courseConstants';
import { NEUTRAL_SHADES, PRIMARY_SHADES, SPACING, SUCCESS_SHADES } from 'utils/theme';
import { Container } from './style';

const StyledProgressBar = styled(ProgressBar)`
  margin-bottom: ${SPACING[0]};
  width: 80px;
  .progress-bar {
    background-color: ${PRIMARY_SHADES[800]};
  }
`;

const ProgressWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${SPACING[2]};
`;

const CoursesProgress = ({ coursesArray }) => {
  const { t } = useTranslation();
  const [completedCoursesNumber, setCompletedCoursesNumber] = useState(0);

  useEffect(() => {
    let num = 0;
    for (let i = 0; i < coursesArray.length; i += 1) {
      if (coursesArray[i].progression && coursesArray[i].progression.status === courseProgressionConstants.COMPLETED) {
        num += 1;
      }
    }
    setCompletedCoursesNumber(num);
  }, [coursesArray]);

  return (
    !!coursesArray?.length && (
      <Container>
        <Tippy
          placement="top"
          content={
            <TangiTypography color={NEUTRAL_SHADES.WHITE} type="sub-body">
              {(completedCoursesNumber / coursesArray.length) * 100}% {t('DASHBOARD_PAGE.TABLES.PEOPLE_AND_CONTRACTORS_TABLE.TRAINING_COMPLETED')}
            </TangiTypography>
          }
        >
          <ProgressWrapper>
            {completedCoursesNumber === coursesArray.length ? (
              <TangiSvgIcon component="check" color={SUCCESS_SHADES[500]} />
            ) : (
              <StyledProgressBar now={(completedCoursesNumber / coursesArray.length) * 100} max={100} />
            )}
            <TangiTypography color={NEUTRAL_SHADES[800]} type="sub-body">
              {completedCoursesNumber}/{coursesArray.length}
            </TangiTypography>
          </ProgressWrapper>
        </Tippy>
      </Container>
    )
  );
};

export default CoursesProgress;
