import { IpAuditStepKey, IpAuditStepsInfoMap } from './types';
import { SystemSecurity } from './components/Steps/SystemSecurity/SystemSecurity';
import { TradeSecretPolicy } from './components/Steps/TradeSecretPolcy/TradeSecretPolicy';
import { LlmPolicy } from './components/Steps/LlmPolicy/LlmPolicy';
import { Overview } from './components/Steps/Overview/Overview';
import { HrProcess } from './components/Steps/HrProcess/HrProcess';

export const ipAuditStepsData: IpAuditStepsInfoMap = {
  [IpAuditStepKey.OVERVIEW]: {
    name: 'IP_AUDIT.IP_AUDIT_STEPS.OVERVIEW.NAME',
    component: <Overview />,
  },
  [IpAuditStepKey.TRADE_SECRET_POLICY]: {
    name: 'IP_AUDIT.IP_AUDIT_STEPS.TRADE_SECRET_POLICY.NAME',
    component: <TradeSecretPolicy />,
  },
  [IpAuditStepKey.SYSTEM_SECURITY]: {
    name: 'IP_AUDIT.IP_AUDIT_STEPS.SYSTEM_SECURITY.NAME',
    component: <SystemSecurity />,
  },
  [IpAuditStepKey.HR_PROCESS]: {
    name: 'IP_AUDIT.IP_AUDIT_STEPS.HR_PROCESS.NAME',
    component: <HrProcess />,
  },
  [IpAuditStepKey.LLM_POLICY]: {
    name: 'IP_AUDIT.IP_AUDIT_STEPS.LLM_POLICY.NAME',
    component: <LlmPolicy />,
  },
  [IpAuditStepKey.CONTRACTS]: {
    name: 'IP_AUDIT.IP_AUDIT_STEPS.CONTRACTS.NAME',
    component: null,
  },
  [IpAuditStepKey.KEY_ASSETS_SYSTEMS]: {
    name: 'IP_AUDIT.IP_AUDIT_STEPS.KEY_ASSETS_SYSTEMS.NAME',
    component: null,
  },
  [IpAuditStepKey.KEY_ASSETS_PATENT_X_RAY]: {
    name: 'IP_AUDIT.IP_AUDIT_STEPS.KEY_ASSETS_PATENT_X_RAY.NAME',
    component: null,
  },
};
