import { useTranslation } from 'react-i18next';
import { CompleteFlowBtnWrapper, Description, OverviewHeader, Title, StepCardsContainer, ProgressBarContainer, ProgressFlowSection } from './styles';
import { TangiTypography } from '_components/TangiLibrary/TangiTypography/TangiTypography';
import { TangiProgressBar } from '_components/TangiLibrary/TangiProgressBar/TangiProgressBar';
import { TangiButton } from '_components/TangiLibrary/TangiButton/TangiButton';
import { OverviewCard } from './components/OverviewCard/OverviewCard';
import { TangiSvgIcon } from '_components/TangiLibrary';
import { ipAuditStepsData } from '_components/IpAudit/consts';
import { IpAuditUserStepsKey, IpAuditStepKey } from '_components/IpAudit/types';
import { cardsDescriptionMap } from './consts';
import { useHandleSteps } from '_components/TangiLibrary/TangiStepper/useHandleSteps';
import { StepInnerContainer } from '../styles';
import { useIpAudit } from '_components/IpAudit/useIpAudit';
import { NEUTRAL_SHADES, SUCCESS_SHADES } from 'utils/theme';

export const Overview = () => {
  const { t } = useTranslation();

  const { handleStepChange } = useHandleSteps();
  const { isStepCompleted, getNextStepForCompleteFlow, numberOfSteps, numberOfCompletedSteps } = useIpAudit();

  const overviewCards = Object.entries(ipAuditStepsData).filter(([key, data]) => key !== IpAuditStepKey.OVERVIEW && data.component);

  const isAllCompleted = numberOfCompletedSteps === numberOfSteps;

  const onClickCompleteFlow = () => {
    const nextStepKey = getNextStepForCompleteFlow();
    if (nextStepKey) {
      handleStepChange(nextStepKey);
    }
  };

  return (
    <StepInnerContainer>
      <OverviewHeader>
        <Title>
          <TangiSvgIcon component="activateWithGradient" color="" />
          <TangiTypography weight="semibold" type="heading-md">
            {t('IP_AUDIT.IP_AUDIT_STEPS.OVERVIEW.TITLE', { TOTAL: numberOfSteps })}
          </TangiTypography>
        </Title>

        <Description>{t('IP_AUDIT.IP_AUDIT_STEPS.OVERVIEW.DESCRIPTION')}</Description>

        <ProgressFlowSection>
          <ProgressBarContainer>
            <div style={{ flex: 1 }}>
              <TangiProgressBar percentage={(numberOfCompletedSteps / numberOfSteps) * 100} indicatorColor={isAllCompleted ? SUCCESS_SHADES[600] : undefined} />
            </div>

            <TangiTypography color={NEUTRAL_SHADES[800]}>{t('IP_AUDIT.IP_AUDIT_STEPS.OVERVIEW.PROGRESS_BAR_TEXT', { COMPLETED: `${numberOfCompletedSteps}/${numberOfSteps}` })}</TangiTypography>
          </ProgressBarContainer>

          {!isAllCompleted && (
            <CompleteFlowBtnWrapper>
              <TangiButton text={t('IP_AUDIT.IP_AUDIT_STEPS.OVERVIEW.COMPLETE_FLOW_BTN')} onClick={onClickCompleteFlow} svgIcon="arrowRight" endIcon />
            </CompleteFlowBtnWrapper>
          )}
        </ProgressFlowSection>
      </OverviewHeader>

      <StepCardsContainer>
        {overviewCards.map(([stepKey, stepData], index) => {
          return (
            <OverviewCard
              key={stepKey}
              stepData={{
                ...stepData,
                index: index + 1,
                description: cardsDescriptionMap[stepKey as IpAuditUserStepsKey],
                isCompleted: isStepCompleted(stepKey as IpAuditUserStepsKey),
              }}
              onClickStep={() => handleStepChange(stepKey)}
            />
          );
        })}
      </StepCardsContainer>
    </StepInnerContainer>
  );
};
