/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: Fix any types
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';

import { noActivityFound } from '../../assets/img';
import { TangiTypography, TangiCollapse } from '_components/TangiLibrary';
import ActivityRow from './components/ActivityRow';
import ActivitySkeleton from './components/ActivitySkeleton';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { EmptyStateContainer, MainContainer, emptyStateTypographyStyles, FlexContainerColumn, ActivityGridContainer } from './style';
import { RootState } from '_helpers';
import { ActivityTypeLabel } from './components/ActivityTypeLabel';
import ActivityCollapseContent from './components/ActivityCollapseContent';
import ActivityChip from './components/ActivityChip';
import { AUDIT_TYPE, ActivityLogData, INITIAL_LIMIT, MODIFICATION_TYPE, NormalizedData, assetFields } from 'utils/types/activityLog/asset/types';
import { AssetActivityNotify } from './components/AssetActivityLog/AssetActivityNotify';
import ActivityAvatar from './components/ActivityAvatar';
import { AssetActivityNotifyContent } from './components/AssetActivityLog/AssetActivityNotifyContent';
import { AssetActivityDeleteNotifyLabel } from './components/AssetActivityLog/AssetActivityDeleteNotifyLabel';
import { AssetActivityAcknowledge } from './components/AssetActivityLog/AssetActivityAcknowledge';
import ActivityFile from './components/ActivityFile';
import { getAssetAuditLog } from 'redux-toolkit/thunks/assetsThunks';
import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { CenteredContainer } from 'utils/globalStyles';
import { determineValueBasedOnAction, extractDisplayNames, getValues } from './utils';
import AssetActivityDisclaimer from './components/AssetActivityLog/AssetActivityDisclaimer';

interface Props {
  isEmpty: boolean;
  loading: boolean;
}

const ActivityLog = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { asset, assetAuditLog, assetAuditLogMetaData } = useSelector((state: RootState) => state.asset);

  const renderEmptyState = () => {
    return (
      <EmptyStateContainer>
        <img width="178px" alt="no-activity-found" src={noActivityFound} />

        <FlexContainerColumn>
          <TangiTypography customStyles={emptyStateTypographyStyles} type="heading-lg" weight="semibold">
            {t('ACTIVITY_LOG.ERROR_STATE')}
          </TangiTypography>
          <TangiTypography color={NEUTRAL_SHADES[1100]}>{t('ACTIVITY_LOG.TRY_RELOAD')}</TangiTypography>
        </FlexContainerColumn>
      </EmptyStateContainer>
    );
  };

  const renderFiles = (event: ActivityLogData, isLast: boolean) => {
    const isEditAction = event.actionType === MODIFICATION_TYPE.EDIT;
    const createOrDeletedValue = determineValueBasedOnAction(event.actionType, event.from, event.to);

    return (
      <ActivityCollapseContent
        field={event.translation}
        modificationType={event.actionType}
        prevValueComponent={isEditAction && <ActivityFile fileNames={event.from} modificationType={event.actionType} />}
        currValueComponent={<ActivityFile fileNames={createOrDeletedValue} modificationType={event.actionType} />}
        showDivider={isLast}
      />
    );
  };
  const renderContributor = (event: ActivityLogData, isLast: boolean) => {
    const fromValues = getValues(event.from);
    const toValues = getValues(event.to);

    return (
      <>
        <ActivityCollapseContent
          field={event.translation}
          modificationType={event.actionType}
          prevValueComponent={<ActivityAvatar account={fromValues} client={fromValues} />}
          currValueComponent={<ActivityAvatar account={toValues} client={toValues} />}
          showDivider={isLast}
        />
      </>
    );
  };

  const renderTags = (event: ActivityLogData, isLast: boolean) => {
    const fromValues = extractDisplayNames(event.from);
    const toValues = extractDisplayNames(event.to);
    const isEditAction = event.actionType === MODIFICATION_TYPE.EDIT;

    const createOrDeletedValue = determineValueBasedOnAction(event.actionType, fromValues, toValues);
    return (
      <ActivityCollapseContent
        field={event.translation}
        modificationType={event.actionType}
        prevValueComponent={isEditAction && <ActivityChip value={fromValues} />}
        currValueComponent={<ActivityChip value={createOrDeletedValue} />}
        showDivider={isLast}
      />
    );
  };

  const renderDefault = (event: ActivityLogData, isLast: boolean) => {
    const fromValues = extractDisplayNames(event.from);
    const toValues = extractDisplayNames(event.to);
    const isEditAction = event.actionType === MODIFICATION_TYPE.EDIT;
    const isDeleteAction = event.actionType === MODIFICATION_TYPE.DELETE;

    const renderValues = (values: string[]) => {
      return (
        <div>
          {values.map((value: string, index: number) => {
            return (
              <div key={index}>
                <TangiTypography type="subheading">{value}</TangiTypography>
              </div>
            );
          })}
        </div>
      );
    };
    return (
      <ActivityCollapseContent
        field={event.translation}
        modificationType={event.actionType}
        prevValueComponent={isEditAction && renderValues(fromValues)}
        currValueComponent={renderValues(isDeleteAction ? fromValues : toValues)}
        showDivider={isLast}
      />
    );
  };

  const renderRecipientActivityRow = (activity: any, isLast: boolean) => {
    const { date, actionType, by, data } = activity;
    const isRecipients = data[0].type === assetFields.RECIPIENTS;
    const recipients = data[0].to?.flat() || [];

    if (AUDIT_TYPE.CREATE === actionType && isRecipients) {
      const hasMultipleRecipients = recipients.length >= 3;

      const activityContent = hasMultipleRecipients ? (
        <TangiCollapse label={<AssetActivityNotify initiator={by} data={data} />} content={renderActivityContent(activity)} key={activity.date} />
      ) : (
        <AssetActivityNotify initiator={by} data={data} />
      );

      return (
        <ActivityRow formattedDate={date} auditType={AUDIT_TYPE.NOTIFY} isLast={isLast}>
          {activityContent}
        </ActivityRow>
      );
    } else {
      return (
        <ActivityRow formattedDate={date} auditType={actionType} isLast={isLast}>
          <ActivityTypeLabel initiator={by} entityType={activity.entityType} auditType={actionType} />
        </ActivityRow>
      );
    }
  };

  const renderActivityGrid = () => {
    return (
      <ActivityGridContainer>
        {Object.entries(assetAuditLog).map(([month, activitiesArray]: [string, any], index: number) => {
          return (
            <React.Fragment key={index}>
              <ActivityRow formattedDate={month} auditType={AUDIT_TYPE.GROUP_TITLE} />
              {activitiesArray.map((activity: NormalizedData, index: number) => {
                const isLast = index === activitiesArray.length - 1;
                return <React.Fragment key={index}>{renderActivityComponent(activity, index, isLast)}</React.Fragment>;
              })}
            </React.Fragment>
          );
        })}
      </ActivityGridContainer>
    );
  };
  const renderActivityComponent = (activity: any, index: number, isLast: boolean) => {
    switch (activity.actionType) {
      case AUDIT_TYPE.CREATE:
        return renderRecipientActivityRow(activity, isLast);
      case AUDIT_TYPE.EDIT:
        const isAcknowledgement = activity.data[0]?.type === assetFields.ACKNOWLEDGEMENT;

        if (isAcknowledgement) {
          return (
            <ActivityRow formattedDate={activity.date} auditType={AUDIT_TYPE.ACKNOWLEDGE} isLast={isLast}>
              <AssetActivityAcknowledge initiator={activity.by} data={activity.data} />
            </ActivityRow>
          );
        }
        return (
          <ActivityRow formattedDate={activity.date} auditType={activity.actionType} isLast={isLast}>
            <TangiCollapse
              label={<ActivityTypeLabel initiator={activity.by} entityType={activity.entityType} auditType={activity.actionType} />}
              content={renderActivityContent(activity)}
              key={activity.date}
            />
          </ActivityRow>
        );
      case AUDIT_TYPE.DELETE:
        //CAN be only Notify delete
        if (activity.data[0].type === assetFields.RECIPIENTS) {
          return (
            <ActivityRow formattedDate={activity.date} auditType={AUDIT_TYPE.DELETE_NOTIFY} isLast={isLast}>
              <AssetActivityDeleteNotifyLabel event={activity} initiator={activity.by} />
            </ActivityRow>
          );
        }
        break;
      case AUDIT_TYPE.DISCLAIMER:
        return (
          <ActivityRow formattedDate={activity.date} auditType={AUDIT_TYPE.DISCLAIMER} isLast={isLast}>
            <AssetActivityDisclaimer activity={activity} />
          </ActivityRow>
        );
        break;
      default:
        return null;
    }
  };

  const renderActivityContent = (activityContent: NormalizedData) => {
    return activityContent.data.map((event: any, index: number, array: any[]) => {
      const isLast = index === array.length - 1;

      switch (event.type) {
        case assetFields.CONTRIBUTOR:
          return renderContributor(event, isLast);

        case assetFields.RECIPIENTS:
          return <AssetActivityNotifyContent people={event.to} marginTop={SPACING[4]} />;

        case assetFields.FILES:
          return renderFiles(event, isLast);

        case assetFields.TAGS:
          return renderTags(event, isLast);

        default:
          return renderDefault(event, isLast);
      }
    });
  };

  const fetchMoreData = useCallback(() => {
    batch(() => {
      dispatch(getAssetAuditLog({ assetId: asset.id, limit: INITIAL_LIMIT }));
      dispatch(assetsActions.resetAssetAuditLogLoader());
    });
  }, [asset.id, dispatch]);

  return (
    <MainContainer>
      {assetAuditLogMetaData && props.loading ? (
        <ActivitySkeleton />
      ) : props.isEmpty ? (
        renderEmptyState()
      ) : (
        <InfiniteScroll
          dataLength={assetAuditLogMetaData.total}
          next={fetchMoreData}
          hasMore={assetAuditLogMetaData.hasMore}
          loader={
            <CenteredContainer>
              <Spinner animation="border" variant="primary" />
            </CenteredContainer>
          }
          style={{ overflowY: 'hidden' }}
        >
          {renderActivityGrid()}
        </InfiniteScroll>
      )}
    </MainContainer>
  );
};

export default ActivityLog;
