import { useForm } from 'react-hook-form';
import { TangiTypography } from '_components/TangiLibrary';
import { useTranslation } from 'react-i18next';
import { OptionWrapper, RadioWrapper, RadioLabel, RadioInput, QuestionsContainer } from './styles';
import { StepTitle } from '../components/StepTitle/StepTitle';
import { IpAuditStepKey } from '_components/IpAudit/types';
import { StepContainer, StepInnerContainer } from '../styles';
import { StepFooter } from '../components/StepFooter/StepFooter';
import { NEUTRAL_SHADES } from 'utils/theme';
import { HrProcessFormValues } from './types';
import { useIpAudit } from '_components/IpAudit/useIpAudit';
import { parseValuesToFormValues } from '../HrProcess/utils';
import { useEffect } from 'react';
import { RootState } from '_helpers';
import { useDispatch, useSelector } from 'react-redux';
import { clientActions } from 'redux-toolkit/slices/clientSlice';
import { useHandleSteps } from '_components/TangiLibrary/TangiStepper/useHandleSteps';

export const HrProcess = () => {
  const { t } = useTranslation();
  const { handleStepChange } = useHandleSteps();
  const dispatch = useDispatch();
  const hrProcessAction: { hasData: boolean; isLoading: boolean } = useSelector((state: RootState) => state.client.ipAudit[IpAuditStepKey.HR_PROCESS]);
  const { getStepCurrentData, submitHrProcess } = useIpAudit();

  const values = getStepCurrentData(IpAuditStepKey.HR_PROCESS);

  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm<HrProcessFormValues>({
    defaultValues: parseValuesToFormValues(values),
    mode: 'onChange',
  });

  useEffect(() => {
    if (hrProcessAction.hasData) {
      dispatch(clientActions.clearIpAuditAction(IpAuditStepKey.HR_PROCESS));
      handleStepChange(IpAuditStepKey.LLM_POLICY);
    }
  }, [hrProcessAction.hasData]);

  const onSubmit = (data: HrProcessFormValues) => {
    const { protocol, training } = data;
    const isIncorporateProtocolsConfidential = protocol === 'true';
    const employeeTraining = training === 'true';
    submitHrProcess({ isIncorporateProtocolsConfidential, employeeTraining });
  };

  const renderOptionWithRadios = (key: keyof HrProcessFormValues, label: string) => (
    <OptionWrapper key={key}>
      <TangiTypography type="subheading">{t(label)}</TangiTypography>
      <RadioWrapper>
        <RadioLabel key="yes">
          <RadioInput data-cy={`radio-${key}`} type="radio" value="true" {...register(key, { required: true })} />
          {t('GENERAL.BUTTONS_TEXT.YES')}
        </RadioLabel>
        <RadioLabel key="no">
          <RadioInput data-cy={`radio-${key}`} type="radio" value="false" {...register(key, { required: true })} />
          {t('GENERAL.BUTTONS_TEXT.NO')}
        </RadioLabel>
      </RadioWrapper>
    </OptionWrapper>
  );

  return (
    <StepContainer>
      <StepInnerContainer>
        <StepTitle stepKey={IpAuditStepKey.HR_PROCESS} />
        <TangiTypography type="subheading" color={NEUTRAL_SHADES[1100]}>
          {t('IP_AUDIT.IP_AUDIT_STEPS.HR_PROCESS.DESCRIPTION')}
        </TangiTypography>
        <form>
          <QuestionsContainer>
            {renderOptionWithRadios('protocol', 'IP_AUDIT.IP_AUDIT_STEPS.HR_PROCESS.PROTOCOL')}
            {renderOptionWithRadios('training', 'IP_AUDIT.IP_AUDIT_STEPS.HR_PROCESS.TRAINING')}
          </QuestionsContainer>
        </form>
      </StepInnerContainer>
      <StepFooter onSubmit={handleSubmit(onSubmit)} isContinueBtnDisabled={!isValid} isContinueBtnLoading={hrProcessAction.isLoading} />
    </StepContainer>
  );
};
