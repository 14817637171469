export const en = {
  translation: {
    LOBBY_PAGE: {
      TITLE: 'Welcome to Tangibly',
      SUB_TITLE: 'Choose how to start your trade secrets adventure.',
      DISCOVER_AND_PROTECT: 'Discover, document and protect trade secrets',
      RECONNECT: 'Whoops! We need to reconnect you to the system',
      GO_TO_HOMEPAGE: 'Go to Homepage',
      LOGIN: 'Login',
      CHIP: {
        NEW: 'NEW',
      },
      CARDS: {
        PREDICTED_PORTFOLIO: {
          TITLE: 'Predicted Portfolio™',
          SUB_TITLE: 'Reveal the IP hidden in your patent portfolio. These trade secrets are key assets that have to be managed',
        },
        COMPETITIVE_INTELLIGENCE: {
          TITLE: 'Competitive Intelligence',
          SUB_TITLE: "Gain advantage by predicting your competitors' trade secrets",
        },
        CONTINUATION_ANALYSIS: {
          TITLE: 'Continuation Analysis',
          SUB_TITLE: 'Easily draft continuation claims to extract more value from your patents',
        },
        PATENT_X_RAY: {
          TITLE: 'Patent X-Ray',
          SUB_TITLE: 'AI prediction of potential trade secrets associated with patents',
        },
        INVENTION_DISCLOSURE: {
          TITLE: 'Invention Disclosure',
          SUB_TITLE: 'Make informed patenting decisions by comparing patent claims and trade secrets side-by-side',
        },
        MANAGE: {
          TITLE: 'Manage',
          SUB_TITLE: 'Use best practices standards by cataloging assets, limiting access, verifying contracts, and training employees',
        },
        ASSETS_CATALOG: {
          TITLE: 'Assets Catalog',
          SUB_TITLE: 'Treat your trade secrets like the valuable assets they are',
        },
        ADD_EMPLOYEES: {
          TITLE: 'Add Employees',
          SUB_TITLE: 'Track your employees’ access, compliance, and training for best practices',
        },
        COLLABORATE_WITH_PARTNERS: {
          TITLE: 'Collaborate with Partners',
          SUB_TITLE: 'Everything you need to manage exchange of sensitive information with your partners',
        },
        LEARN: {
          TITLE: 'Learn',
          SUB_TITLE: 'Train your employees on what trade secrets are and how to properly handle them',
        },
        DISCOVER: {
          TITLE: 'Discover',
          SUB_TITLE: "Leverage Tangibly's AI to streamline patent workflows and to reveal insights around your trade secrets",
        },
        SELECT_COURSES: {
          TITLE: 'Select Courses',
          SUB_TITLE: 'You can select which training courses are mandatory for your employees, or just suggested',
        },
        TRACK_PROGRESS: {
          TITLE: 'Track Progress',
          SUB_TITLE: 'Easily track which employees have completed required training and which still have one or more trainings to go',
        },
      },
      REQUEST_ACCESS_MODAL: {
        DISCOVER: {
          HEADER: 'Discover',
          TITLE: 'Tangibly’s X-Ray™ Platform',
          SUB_TITLE: 'X-Ray™ is a fully-integrated AI-based tool designed for both in-house legal teams and law firms to help identify trade secrets and measure and manage risk.',
          POINT1: 'Identify Trade Secrets with Patent X-Ray™',
          POINT2: 'Assess your protection of trade secrets with Contract X-Ray™',
          POINT3: 'Identify and manage risk with Email X-Ray™',
        },
        PREDICTED_PORTFOLIO: {
          HEADER: 'Predicted Portfolio',
          TITLE: 'Tangibly’s Predicted Portfolio Tool',
          SUB_TITLE: 'Tangibly’s SaaS Platform Protects Your Confidential Information As Legally Enforceable Trade Secrets',
          POINT1: 'Find trade secrets you never knew you had',
          POINT2: 'Understand the strength and weakness of your trade secret portfolio',
          POINT3: 'Learn how your trade secrets stack up to your competitors',
        },
        MANAGE: {
          HEADER: 'Manage',
          TITLE: 'Tangibly’s Manage Platform',
          SUB_TITLE:
            "Tangibly is the only SaaS platform dedicated to trade secrets, arguably the most important class of IP assets. Tangibly's AI is your copilot that helps you identify trade secrets, measure and manage risk, and add new capabilities to your patent process.",
          POINT1: 'Label Trade Secrets',
          POINT2: 'Notify and acknowledge access to trade secrets',
          POINT3: 'Train employees and partners',
          POINT4: 'Securely collaborate with partners',
        },
        BUTTON_TEXT: 'Request Access',
      },
    },
    PARTNERSHIP_PAGE: {
      HEADERS: {
        PEOPLE: 'People',
        ASSETS_SHARED_WITH_PARTNERS: 'Assets Shared With Partners',
        ASSETS_CREATED: 'Assets Contributed By Partners',
        SUMMARY: 'Summary',
        DOCUMENTS: 'Documents',
      },
      SUMMARY: {
        ONBOARDED_ON: 'Onboarded on:',
        CREATED_BY: 'Created by',
        NUMBER_OF_EMPLOYEES: 'Number of employees:',
      },
      TABLE: {
        MISSING: 'Missing',
        NO_ASSETS: 'No Assets',
        NO_DOCUMENTS: 'No Documents',
        NO_PEOPLE_YET: 'No people yet',
      },
      TOOLTIP: {
        EDIT: 'Edit',
        DOWNLOAD_AGREEMENT: 'Download agreement',
      },
      DROPDOWN_BUTTON: {
        DEACTIVATE: 'Deactivate',
      },
      TOAST: {
        PARTNERSHIP_CREATED: 'Partner Created Successfully',
        PARTNERSHIP_UPDATED: 'Partner Updated Successfully',
        PARTNERSHIP_DEACTIVATED: 'Partner Deactivated Successfully',
      },
      TABLE_HEADERS: {
        TITLE: 'Title',
        SIGNED: 'Signed',
        VERSION: 'Version',
        EXECUTED_ON: 'Executed on',
        EXPIRES_ON: 'Expires on',
        NAME: 'Name',
        STATUS: 'Status',
        ASSETS: 'Assets',
        CREATION_DATE: 'Creation date',
        ACTIONS: 'Actions',
        ASSET_NAME: 'Asset name',
        TYPE: 'Type',
        SHARED_WITH: 'Shared with',
        CONTRIBUTORS: 'Contributors',
      },
      DEACTIVATION_POPUP_BODY: {
        WILL_LOSE_ACCESS: 'By deactivating this partner it will lose access to Tangibly and will only acknowledge and view assets via email.',
        CAN_ACTIVATE_LATER: 'You can activate the account later if you wish.',
        CAN_FIND_IN_SIDEMENU: 'You can find disabled partners in the partners section in the side menu.',
        NOTE: 'Note',
      },
      DEACTIVATION_POPUP_TITLE: {
        DEACTIVATE: 'Deactivate',
      },
    },
    PARTNERSHIP_ADD_EDIT_PARTNER: {
      INPUT_LABEL: {
        EMAIL: 'Email',
        DISPLAY_NAME: 'Display Name',
        PARTNER_NAME: 'Partner Name',
        ADDRESS: 'Address',
      },
      SELECT_LABEL: {
        COUNTRY: 'Country',
      },
      ERROR_MESSAGE: {
        INVALID_EMAIL_MESSAGE: 'Please enter a valid email',
      },
      TITLE: {
        EDIT: 'Edit <strong>{{NAME}}</strong>',
        ADD_USER_TO: 'Add user to <strong>{{NAME}}</strong>',
        ADD_USERS_TO: 'Add users to <strong>{{PARTNER_NAME}}</strong>',
        EDIT_PARTNER: 'Edit partner',
        CREATE_NEW_PARTNER_IN: 'Create new partner in',
        ALL_SET: 'All Set!',
        ADD_PARTNER_LOGO: 'Add partner logo',
        ADD_RELEVANT_DOCUMENTS: 'Add relevant documents',
      },
      DESCRIPTION: {
        SHARE_ASSETS: 'You can now share assets with your new partner’s users',
      },
      TOGGLE: {
        STATUS: 'Status: ',
        ACTIVE: 'Active',
      },
      PARTNER_TOOLTIP: {
        ACTIVE: 'Active accounts can create assets and view all assigned courses.',
        NONACTIVE: 'Non Active accounts can only be one-time-viewers of specific assets shared by admins.',
        EDIT_DISABLED: 'You cannot edit people when partner is disabled',
        PARTNERSHIP_DISABLED: 'You cannot edit partner when it is disabled',
      },
      CHECKBOX: {
        OKTA_MESSAGE: 'This user is using Okta as SSO',
        ACTIVE: 'Active',
      },
      TOAST: {
        USER_ADDED_SUCCESSFULLY: 'users added successfully',
      },
    },
    PREDICTED_PORTFOLIO: {
      HEADER: {
        TITLE: 'Predicted Portfolio™',
        CHIP: 'Beta',
        UPDATE: 'Updated on',
        CHIP_TOOLTIP: 'This feature is currently in beta. We are continuously learning and improving based on the data to provide you with the best possible results',
      },
      BANNER: {
        TITLE: 'You are viewing a Tangibly sample project',
        INFO: 'Want to see your company’s Predicted Portfolio and strategy analysis? Contact us!',
        LEARN_MORE: 'Learn More',
        REQUEST_ACCESS: 'Request Access',
      },
      WIDGET: {
        PATENT: 'Patents',
        POTENTIAL_TREDE_SECRETS: 'Potential Trade Secrets',
        SUBJECTS: 'Subjects',
      },
      CARD: {
        TITLE: 'Potential Trade Secrets by Top Subjects',
      },
      PORTFOLIO_TABLE: {
        TITLE: 'Portfolio Data',
        POTENTIAL_TRADE_SECRET: 'Potential Trade Secret',
        PATENT: 'Patent',
        YEAR: 'Year',
        SUBJECTS: 'Subjects',
        EXPORT_DATA: 'Export Data',
        TOTAL_ITEMS: '{{NUMBER}} items',
      },
      COMPETITIVE_BOX_TEXT: 'Check your company’s Competitive Analysis',
      CHARTS: {
        SUBJECT: 'Subject',
        SUBJECTS: 'Subjects',
        POTENTIAL_TRADE_SECRETS: 'Potential Trade Secrets',
        PATENTS: 'Patents',
        PATENT_NUMBER: 'Patent Number',
      },
      FILTERS: {
        COMPENY: 'Company',
        PATENT: 'Patent',
        YEAR: 'Year',
        SUBJECT: 'Subject',
        SEARCH: 'Search',
        CLEAR_ALL: 'Clear all',
      },
    },
    COMPETITIVE_ANALYSIS: {
      HEADER: {
        TITLE: 'Competitive Analysis',
      },
      CHART: {
        COMPETITORS: 'Competitors',
      },
      WIDGET: {
        COMPANIES: 'Companies',
      },
      CARDS: {
        PERCENTAGE_CHART: {
          TITLE: 'Potential Trade Secrets by Companies and Subjects',
        },
        BY_COMPANIES_CHART: {
          TITLE: 'Potential Trade Secrets by Companies',
          TOTAL_TEXT: 'Total Potential \nTrade Secrets',
        },
        BY_SUBJECT_CHART: {
          TITLE: 'Potential Trade Secrets by Top Subjects',
          TOOLTIP_COMPANY_TITLE: 'Company',
          TOOLTIP_POTENTIAL_TRADE_SECRETS_TITLE: 'Potential Trade Secrets',
        },
      },
      DATA_TABLE: {
        TITLE: 'Portfolio and Competitors Data',
        POTENTIAL_TRADE_SECRET: 'Potential Trade Secret',
        COMPANY: 'Company',
        PATENT: 'Patent',
        YEAR: 'Year',
        SUBJECTS: 'Subjects',
        EXPORT_DATA: 'Export Data',
      },
    },
    ASSET_PAGE: {
      RECIPIENT_TABLE: {
        RECIPIENTS: 'Recipients',
        ACK_DATE: 'Ack. date',
        ACK_STATUS: 'Ack. status',
        ACTIONS: 'Actions',
        COMPLIANT: ' Compliant',
        PENDING: ' Pending',
      },
      WIDGET: {
        USERS_WERE_CREATED_BY_FINDER: 'Users were created by Finder',
        NO_USERS_CREATED_BY_FINDER: 'No users created by Finder',
        USERS_WERE_CREATED_BY_TRACKER: 'Users were created by Tracker',
        NO_USERS_CREATED_BY_TRACKER: 'No users created by Tracker',
      },
      TAB: {
        OVERVIEW: 'Overview',
        ACTIVITY: 'Activity',
        RECIPIENTS: 'Recipients',
      },
      CONTENT: {
        ASSET_REQUIRES_ACKNOWLEDGEMENT: 'This asset requires your acknowledgement',
        CONTACT_PARTNER_TO_GAIN_ACCESS: 'Please contact the partner to gain access to third-party assets',
        NO_RECIPIENTS_YET: 'No recipients yet',
        CONFIRMATION_TEXT:
          'Please type your name here to confirm you acknowledge this information is being treated as a trade secret and that you will adhere to all of your confidentiality, employment, and/or service obligations. To confirm acknowledging of <strong>{{AMOUNTS}}</strong>',
        SINGEL_REQUEST_CONFIRMATION_TEXT:
          'Please type your name here to confirm you acknowledge this information is being treated as a trade secret and that you will adhere to all of your confidentiality, employment, and/or service obligations.',
        ENTER_NAME: 'assets, please enter your name below',
        MANAGE_SIGNATURE_REQUESTS: 'Manage all signature requests',
        SHARED_ON: 'Shared on:',
        BY: 'by {{SHARED_BY}}',
        DESCRIPTION: 'Description',
        CONTRIBUTORS: 'Contributors',
        CONTRIBUTOR: 'Contributor',
        FILES: 'Files',
        NO_ACCESS: 'No access',
        ASSET_LABEL_LOCATION: 'Asset label & location',
        METADATA: 'Metadata',
        CREATED_ON: 'Created on',
        CREATED_BY: 'Created by',
        LAST_MODIFIED_ON: 'Last modified on',
        REFERENCE_NUMBER: 'Reference number',
        COPY: 'Copy',
        COPIED: 'Copied!',
        TAGS: 'Tags',
        BUSINESS_VALUE: 'Business Value',
        PROJECTS: 'Projects',
        DEPARTMENTS: 'Departments',
        OTHERS_1: 'Others 1',
        OTHERS_2: 'Others 2',
        DETAILS: 'Details',
        NO_DETAILS_DATA: 'This asset has no details data',
        NOT_SIGNED: 'Not signed',
        NO_EXTERNAL_USERS: 'No external users',
        EXTERNAL_USERS: 'External users',
        IMPORTANCE: 'Categorization',
        BUSINESS_RELATED: 'Business Related',
        COMMERCIAL_PRODUCT: 'Commercial product',
        UNKNOWN_FILE: 'Unknown File',
        A_LINK_IS_ATTACHED: 'A link is attached',
        NO_FILES_OR_URLS_INCLUDED: 'No Files / URLs Included',
      },
      TITLE: {
        ASSETS: 'Assets',
        SEND_ASSET_NOTIFICATION: 'Send "{{ASSET_NAME}}" asset notification',
        PEOPLE_WITH_ACCESS: 'People with access',
        CONFIRMATION_IS_REQUIRED: 'Confirmation is required',
        INFORMATION_IS_CONFIDENTIAL: 'The following information is confidential and contains trade secrets:',
      },
      TOOLTIP: {
        GRID_VIEW: 'Grid view',
        LIST_VIEW: 'List view',
        ASSETS_CREATED_BY_EMAIL_XRAY: 'Asset created by Email X-Ray Finder on',
        ASSETS_CREATED_BY_PATENT_XRAY: 'Asset created by Patent X-Ray on',
        EDIT_ASSET: 'Edit asset',
        CANNOT_EDIT_ASSET: 'You cannot edit assets created by 3rd party',
        RECIPIENTS: 'Recipients',
        NOTIFY: 'Notify',
        THIRD_PARTY: '3rd Party',
        ASSET_CREATED_BY_PARTNER: 'Asset created by partner',
        ASSET_CREATED_BY_CLIENT: 'Asset created by client',
        PRIVILEGE_LOG_DOWNLOAD: 'Privilege Log Download',
        COMING_SOON: 'Coming soon',
        DELETED_SUCCESSFULLY: '{{NAME}} was deleted successfully',
        EMAILS_SENT: '{{NAME}} emails sent!',
        SOMETHING_WENT_WRONG: 'Something went wrong, please try again',
        CREATED_BY_FINDER: 'User created by Email X-Ray Finder on {{createdAt}}',
        ASSETS_AWAITING_YOUR_SIGNATURE: 'There are {{NEEDTOBEACKNOWLEDGEDNUM}} assets awaiting your signature',
        NAVIGATE_TO_ALL_SIGNATURE_REQUESTS: 'Navigate to all signature requests',
        CREATED_BY_TRACKER: 'User created by Email X-Ray Tracker on {{createdAt}}',
        CONTRIBUTORS_CAN_BE_DELETED: 'Contributors can be deleted',
        ONLY_IN_EDIT_ASSET_POPUP: 'only in edit asset popup',
        FINDER_IS_AVAILABLE: 'Finder is available on the X-Ray plan',
        CONTACT_US: 'Contact us to learn more',
        ACKNOWLEDGED: 'Acknowledged',
        NOT_ACKNOWLEDGED: 'Not acknowledged',
        AND_MORE: 'and more...',
        ACKNOWLEDGE_ASSET: 'Acknowledge asset',
        MORE_OPTIONS: 'More options',
      },
      BUTTONS_TEXT: {
        CREATE_ASSET: 'Create New Asset',
        ACKNOWLEDGE: 'Acknowledge',
        CLEAR_ALL: 'Clear all',
        BACK_TO_ALL_ASSETS: 'All assets',
      },
      INPUT: {
        SEARCH_BY_NAME_OR_ID: 'Search by name/ID',
        SEARCH_OPTION: 'Search option',
        FULL_NAME: 'Full name',
        SEARCH_BY_NAME_OR_EMAIL: 'Search by name or email',
        SEARCH_ON_ASSETS: 'Search on assets',
      },
      MODAL: {
        CLOSE_ASSET: 'Are you sure, you would like to close this asset?',
        ARE_YOU_SURE: 'Are you sure?',
        DELETE_ASSET: 'Do you really want to delete the asset',
        DELETE_FILE: 'Do you really want to delete',
        ADD_USER_TO: 'Add user to <strong>{{NAME}}</strong>',
        DELETE_RECIPIENT: 'Delete Recipient',
        RECIPIENTS_WONT_VIEW_ASSET: "This recipient won't be able to view the asset. Are you sure you want to delete this recipient?",
        PLEASE_READ_THE_FOLLOWING_INFORMATION: 'Please read the following information carefully',
      },
      DROP_DOWN: {
        BATCH_UPLOAD: 'Batch Upload',
        DOWNLOAD_EXCEL: 'Download Excel Template',
        UPLOAD_EXCEL: 'Upload an Excel File',
        EDIT_ASSET: 'Edit asset',
        NOTIFY: 'Notify',
        DELETE_ASSET: 'Delete asset',
        DEPARTMENT: 'Department',
        NO_DEPARTMENTS: 'No Departments',
        PROJECTS: 'Projects',
        TAG: 'Tag',
        BUSINESS_VALUE: 'Business Value',
        CONTRIBUTOR: 'Contributor',
        ASSET_TYPE: 'Asset Type',
        CATEGORIZATION: 'Categorization',
        BUSINESS_RELATED: 'Business Related',
        COMMERCIAL_PRODUCT: 'Commercial Product',
        RECIPIENTS: 'Recipients',
      },
      ERROR_MESSAGE: {
        RECIPIENT_EXISTS: 'Recipient already exists',
        NOT_VALID_EMAIL: 'Not valid email',
        EMAIL_IS_REQUIRED: 'Email is required',
      },
      CARD: {
        NO_ASSETS_FOUND: 'No asset was found',
        EMPLOYEES: 'Employees',
        CONTRACTORS: 'Contractors',
        PARTNERS: 'Partners',
        LAWYERS: 'Lawyers',
        NOT_REGISTERED: 'Not Registered',
        NO_RECIPIENT_FOUND: 'No recipient found for your search. Try searching by another name.',
        ASSET_HAS_NO_RECIPIENTS: 'This asset has no recipients yet',
      },
      TOAST: {
        ASSET_EDITED_SUCCESSFULLY: 'Asset was edited successfully',
        ASSETS_DOWNLOADED_SUCCESSFULLY: 'The files have been successfully downloaded and the password has been sent to your email.',
      },
      FILTERS: {
        MORE_FILTERS: 'More filters',
        SEARCH_BY_ASSET: 'Search by asset name or ID, file name, label or location',
      },
      CREATE_EDIT_ASSET: {
        TITLE: {
          EDIT: 'Edit',
          CREATE_ASSET: 'Create Asset',
          FILL_DETAILS: 'Fill Details',
          METADATA: 'Metadata',
          ASSET_DETAILS: 'Asset Details',
        },
        LABEL: {
          TITLE: 'Title',
          DESCRIPTION: 'Description',
          FILE_LOCATION: 'File location',
          FILE_LABEL: 'File Label',
          ASSETS_PREFIX: 'Assets Prefix',
          REFERENCE_NUMBER: 'Reference Number',
          CONTRIBUTORS: 'Contributors',
          TAGS: 'Tags',
          SELECT_ASSET_TYPE: 'Select Asset Type',
          BUSINESS_VALUE: 'Business Value',
          PROJECTS: 'Projects',
          DEPARTMENTS: 'Departments',
          BUSINESS_RELATED: 'Business Related',
          OTHERS_1: 'Others 1',
          OTHERS_2: 'Others 2',
          ADD_FILES: 'Add Files',
          IMPORTANCE: 'Categorization',
          COMMERCIAL_PRODUCT: 'Commercial Product',
          PUBLISHED: 'Published',
        },
        PLACEHOLDERS: {
          ENTER_TITLE: 'Enter Title',
          ENTER_DESCRIPTION: 'Enter Description',
          ENTER_FILE_LOCATION: 'Enter File location',
          ENTER_FILE_LABEL: 'File Label',
          EDIT_REFERENCE_NUMBER: 'Edit Reference Number',
          ENTER_ADDITIONAL_INFORMATION: 'Enter additional information',
        },
        TOOLTIP: {
          CLOSE_ASSET: 'Close asset',
        },
        ERROR_MESSAGE: {
          TITLE_FIELD_REQUIRED: 'Title field is required',
          LOCATION_AND_LABEL_REQUIRED: 'Both location and label are required when adding a file location',
          FILE_LOCATION_LINK_EXISTS: 'Required if File location Link exists',
          FILE_LABEL_EXISTS: 'Required if File label exists',
          REFERENCE_NUMBER_MUST_BE_EXACTLY: 'Reference Number must be exactly 6 characters',
          REFERENCE_NUMBER_IS_REQUIRED: 'Reference Number is required',
          ONLY_DIGITS: 'Only digits [0-9] are allowed!',
          CONTRIBUTORS_FIELD_IS_REQUIRED: 'Contributors field is required',
          TAGS_FIELD_IS_REQUIRED: 'Tags field is required',
          TYPE_ASSET_FIELD_IS_REQUIRED: 'Type asset field is required',
        },
        ADD_FILES: {
          DROP_FILES: 'Drop the files here...',
          DROP_FILE: 'Drop the file here...',
          DRAG_AND_DROP: "Drag 'n' drop some files here, or click to select files",
          DRAG_AND_DROP_FILE: 'Drag and drop file here or click to browse from your computer',
          DRAG_AND_DROP_FILE_EDIT_MODE: 'Drag and drop a new file here or click',
          DRAG_AND_DROP_CLICK: 'to browse from your computer',
          REJECT_PLEASE_UPLOAD_ONLY_EXCEPTED: 'Please upload only accepted formats! We are trying to update other formats type in future!',
          UPLOAD_ONLY_ACCEPTED_FORMATS: 'Upload only accepted formats (.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .jpeg, .png, .jpg).',
          FILE_CANNOT_CONTAIN_SPECIAL_CHARACTERS: 'File name cannot contain special characters (e.g !@#$...)',
        },
        EDIT_MODE_ASSET_ITEM: {
          UPLOADED: 'Uploaded',
        },
      },
      BATCH_UPLOAD: {
        UPLOAD_MULTIPLE_ASSETS: 'Batch Upload - Upload multiple assets',
        UP_TO_50_ASSETS: 'Up to 50 Assets',
        DRAG_AND_DROP: 'Drag and drop some your image or browse your files',
        DROP_THE_FILES: 'Drop the files here...',
        DONT_CLOSE_WINDOW: 'Don`t close the window. Uploading Assets...',
        VALIDATING_ASSETS: 'Validating Assets...',
        BUTTONS_TEXT: {
          DOWNLOAD_EXCEL_TEMPLATE: 'Download Excel Template',
          UPLOAD_NEW_FILE: 'Upload a New File',
          CONFIRM_UPLOAD: 'Confirm Upload',
        },
        ERROR_MESSAGE: {
          CANT_UPLOAD_MORE_THAN_50: 'Can`t Upload More than 50 Assets',
          NO_ASSETS_UPLOADED: 'No Assets Uploaded',
          THERE_ARE: 'There are ',
          ERRORS: 'errors',
          NO_ERRORS_FOUND: 'No errors found',
          UPLOAD_ONLY_ACCEPTED_FORMATS: 'Please upload only accepted formats!',
          ROW: 'Row',
          IS_REQUIRED: ' is required',
          DOESNT_EXIST: "doesn't exist",
          ASSETS_ADDED_TO_TANGIBLY: 'new assets will be added to Tangibly',
        },
      },
      ASSET_TABLE: {
        TAGS: 'Tags',
        ASSET_TYPE: 'Asset Type',
        NAME: 'Name',
        CREATION_DATE: 'Creation Date',
        RECIPIENTS: 'Recipients',
        ACTIONS: 'Actions',
      },
    },
    PEOPLE: {
      TITLE: {
        PEOPLE: 'People',
        EMPLOYEES: 'Employees',
        EMPLOYEES_TOTAL: '{{totalEmployees}} Employees',
        CONTRACTORS: 'Contractors',
        CONTRACTORS_TOTAL: '{{totalContractors}} Contractors',
      },
      LABEL: {
        VERSION: 'Version',
        EXECUTED_ON: 'Executed On',
        EXPIRES_ON: 'Expires On',
      },
      SELECT: {
        STATUS: 'Status',
        AGREEMENTS: 'Agreements',
        TRAINING: 'Training',
        START_DATE: 'Start date',
        ACTIVATE_USER: 'Activate user',
        DEACTIVATE_USER: 'Deactivate user',
        DELETE_USER: 'Delete user',
        ROLE: 'Role',
        COUNTRIES: 'Countries',
        FILTER_EMPTY_STATE: '{{filterName}} were not\nassigned to people yet',
        BUSINESS_UNIT: 'Business Unit',
        HIRE_DATE: 'Hire Date',
      },
      AGREEMENT_ADD_FILE: {
        DRAG_AND_DROP: 'Drag and drop a file here, or',
        CHOOSE_FILE: 'Choose file',
      },
      TOOLTIP: {
        CREATED_BY_FINDER: 'User created by Email X-Ray Finder on {{createdAt}}',
        CREATED_BY_TRACKER: 'User created by Email X-Ray Tracker on {{createdAt}}',
        EDIT: 'Edit User',
        TRADE_SECRET_ACCESS: 'Trade Secret Access',
      },
      DROPDOWN: {
        BATCH_UPLOAD: 'Batch Upload',
        DOWNLOAD_EXCEL: 'Download Excel Template',
        UPLOAD_EXCEL: 'Upload an Excel File',
      },
      INPUT: {
        SEARCH_BY_DISPLAY_NAME_OR_EMAIL: 'Search by display name/email',
      },
      FILTERS: {
        SEARCH_BY_USER_NAME_ID_OR_EMAIL: 'Search by display name, ID or email',
        SEARCH: 'Search',
      },
      TABLE: {
        NO_EMPLOYEES_FOUND: 'No employees found',
        NO_CONTRACTORS_FOUND: 'No contractors found',
      },
      MODAL: {
        TITLE: {
          CONFIRM_DELETION: 'Confirm Deletion',
          CONFIRM_DEACTIVATION: 'Confirm Deactivation',
          ADD_USER_TO: 'Add user to <strong>{{NAME}}</strong>',
          CONFIRM_ACTIVATION: 'Confirm Activation',
        },
        MODAL_BODY: {
          DELETE_ACCOUNT: 'You are about to permanently delete this account',
          DELETE_ACCOUNT_CONFIRMATION: 'If you choose to delete this account, it will be permanently removed from the system and all of its data will be lost.',
          NON_REVERSIBLE_ACTION: 'This action is non-reversible action',
          NO_REVERSAL: 'There is no reversal to this action.',
          DEACTIVATE_ACCOUNT_TITLE: 'You are about to deactivate this account',
          DEACTIVATE_ACCOUNT_TEXT: 'If you choose to deactivate this account, it will lose access to Tangibly and can only acknowledge and view assets via email.',
          REVERSIBLE_ACTION: 'This action is reversible',
          ACTIVATE_ACCOUNT_LATER: 'You can activate the account later if you wish.',
          REACTIVATE_ACCOUNT_TITLE: 'You are about to activate this account',
          REACTIVATE_ACCOUNT_TEXT: 'If you choose to activate this account, it will get access to Tangibly and a confirmation email.',
          DEACTIVATE_ACCOUNT_LATER: 'You can deactivate the account later if you wish.',
          I_AGREE: 'I agree',
          PLEASE_READ: 'Please read the following information carefully',
        },
        CHECKBOX_TEXT: {
          I_UNDERSTAND_REMOVE: 'I understand that this user account will be permanently removed from the system and cannot be restored.',
        },
      },
      BATCH_UPLOAD: {
        UPLOAD_MULTIPLE_ASSETS: 'Batch Upload - Upload multiple assets',
        UP_TO_50_EMPLOYEES: 'Up to 50 Employees',
        DRAG_AND_DROP: 'Drag and drop some your image or browse your files',
        DROP_THE_FILES: 'Drop the files here...',
        DONT_CLOSE_WINDOW: 'Don`t close the window. Uploading Employees...',
        VALIDATING_EMPLOYEES: 'Validating Employees...',

        BUTTONS_TEXT: {
          DOWNLOAD_EXCEL_TEMPLATE: 'Download Excel Template',
          UPLOAD_NEW_FILE: 'Upload a New File',
          CONFIRM_UPLOAD: 'Confirm Upload',
        },
        ERROR_MESSAGE: {
          CANT_UPLOAD_MORE_THAN_50: 'Can`t Upload More than 50 Employees',
          NO_EMPLOYEES_UPLOADED: 'No Employees Uploaded',
          NO_ASSETS_UPLOADED: 'No Assets Uploaded',
          THERE_ARE: 'There are {{batchErrors}} errors',
          NO_ERRORS_FOUND: 'No errors found',
          UPLOAD_ONLY_ACCEPTED_FORMATS: 'Please upload only accepted formats!',
          ROW: 'Row',
          EMPLOYEES_ADDED_TO_TANGIBLY: 'new Employees will be added to Tangibly',
        },
      },
      INVITE_FORM: {
        TITLE: { AGREEMENTS: 'Agreements' },
        SELECT_A_ROLE: 'Select a role in order to upload agreements',
        MANAGERS_NOTIFICATION: 'Managers will only see assets related to their departments.',
        ACTIVE_TOGGLE: 'Active',
        CHECKBOX_OKTA_MESSAGE: 'This user is using Okta as SSO',
        ACTIVE_TOOLTIP: 'Active accounts can create assets and view all assigned courses.',
        NONACTIVE_TOOLTIP: 'Non Active accounts can only be one-time-viewers of specific assets shared by admins.',
        INPUT_LABEL: {
          EMAIL: 'Email',
          DISPLAY_NAME: 'Display Name',
          TITLE: 'Title',
          PHONE: 'Phone',
          LINKEDIN_PROFILE: 'Linkedin Profile',
          EMPLOYEE_ID: 'Employee Id',
          COUNTRY: 'Country',
          LOCATION: 'Location',
          HIRE_DATE: 'Hire Date',
          EXIT_DATE: 'Exit Date',
          OTHERS_1: 'Others 1',
          OTHERS_2: 'Others 2',
          STATUS: 'Status',
        },
        SELECTOR: {
          ROLE: 'Role',
          DEPARTMENTS: 'Departments',
          BUSINESS_UNITS: 'Business Units',
        },
        PLACEHOLDER: {
          EMAIL_PLACEHOLDER: 'email@example.com',
          ENTER_ADDITIONAL_INFORMATION: 'Enter additional information',
        },
        VALIDATION_MESSAGE: {
          VALID_EMAIL: 'Please enter a valid email',
          NEVER_SHARE_EMAIL: 'We never share your email with anyone',
          VALID_LINKEDIN: 'Please enter a valid linkedin Link',
        },
      },
      VIEW_EMPLOYEE: {
        ACCOUNT_DETAILS: 'Account details',
        EXECUTED_ON: 'executed on {{SIGNED_DATE}}',
        PENDING: 'pending',
        HEADER: {
          AGREEMENTS: 'Agreements',
          TRAINING: 'Training',
          ASSETS_CREATED: 'Assets Created ({{ASSET_LENGTH}})',
          ASSETS_SHARED: 'Assets Shared ({{ASSET_SHARED_LENGTH}})',
          ASSETS_CONTRIBUTED: 'Assets Contributed ({{ASSET_LENGTH}})',
        },
        ACCOUNT_DATA: {
          NAME: 'Name:',
          EMAIL: 'Email:',
          TITLE: 'Title:',
          DEPARTMENTS: 'Departments:',
          BUSINESS_UNITS: 'Business Units:',
          STATUS: 'Status:',
          ROLE: 'Role:',
          HIRE_DATE: 'Hire Date:',
          EXIT_DATE: 'Exit Date:',
        },
        TABLE: {
          CREATED_ON: 'CREATED ON',
          SHARED_BY: 'SHARED BY',
          SHARED_ON: 'SHARED ON',
          ASSET: 'ASSET',
          TYPE: 'TYPE',
          TAGS: 'TAGS',
          RECIPIENTS: 'RECIPIENTS',
          STATUS: 'STATUS',
          ASSET_COLUMN: 'Asset',
          TAGS_COLUMN: 'Tags',
          CREATION_DATE: 'Creation date',
          SENT_DATE: 'Sent date',
          ACKNOWLEDGEMENT: 'Acknowledgement',
        },
      },
    },
    DISCOVERY_LOBBY: {
      BUTTONS_TEXT: {
        CONTACT_SUPPORT: 'Contact support',
        SEARCH: 'Search',
        UPDATE_XRAY_DATA: 'Update X-Ray Data',
        UPLOAD_PATENT_APPLICATION: 'Upload patent application',
        ANALYZE: 'Analyze',
        ANALYZE_AND_DOWNLOAD_REPORT: 'Analyze & Download Report',
        UPLOAD_CONTRACT_FILE: 'Upload Contract File',
      },
      TITLE: {
        EMAIL_XRAY: 'Email X-Ray™',
        PATENT_XRAY: 'Patent X-Ray™',
        CONTRACT_XRAY: 'Contract Analyzer',
        TRADE_SECRET_FINDER: 'Trade Secret Finder',
        ASSET_EXPOSURE_TRACKER: 'Asset Exposure Tracker',
        INSIGHTS: 'Insights',
        LAST_UPDATE_REQUEST: 'Last update request on {{DATE}}',
        EXTRACTION: 'Extraction',
        MAPPING: 'Mapping',
        INVENTION_DISCLOSURE: 'Invention Disclosure',
        OR: 'OR',
        CONTINUATION_ANALYSIS: 'Continuation Analysis',
      },
      CARD_TEXT: {
        JOIN_PILOT_GROUP: 'Join the pilot group and unlock Email X-Ray features to upgrade your assets management',
        FIND_TRADE_SECRETS_FROM_PATENTS: 'Find and edit trade secrets from patents',
        FIND_TRADE_SECRETS_FROM_GOOGLE_PATENTS: 'Tangibly’s AI can help you locate potential trade secrets.',
        SEARCH_FOR_POTENTIAL_TRADE_SECRETS: 'Search for potential trade secrets in emails',
        MONITOR_TRADE_SECRETS_EXPOSURE: 'Monitor existing trade secrets exposure',
        ASSETS_FROM_PATENTS: 'Assets created from patents',
        ASSETS_FROM_INVENTION_DISCLOSURE: 'Assets created from Invention Disclosure',
        ASSETS_BY_FINDER: 'Assets was created by Finder',
        PEOPLE_BY_TRACKER: 'People were added by Tracker',
        WRITE_SMARTER_PATENT_APPLICATIONS: 'Tangibly’s AI can determine the level of disclosure related to potential trade secrets.',
        CONTINUATION_ANALYSIS_DESCRIPTION: 'Tangibly’s AI will draft continuation claims for your consideration, saving time and money.',
        LOAD_MORE: 'Load more.',
        UPLOAD_CONTRACT: 'Upload your contract here and we will check its quality and whether all topics have been covered',
        CONTRACT_ANALYZER: 'Upload your contract here and check how well protected your trade secrets are and whether all significant trade secret clauses have been addressed.',
        PASTE_INVENTION_DISCLOSURE_HERE: 'Paste your invention disclosure here to discover potential trade secrets',
      },
      PLACEHOLDER: {
        ENTER_PATENT_NUMBER: 'Enter patent number',
        PATENT_XRAY: 'Enter patent application number',
      },
      XRAY_STATUS_NOTIFICATION: {
        YOU_CAN_CONTINUE: 'Don’t worry, you can continue working on other pages and come back here later to see the results.',
      },
      INVENTION_DISCLOSURE_NOTIFICATION: {
        ANALYSIS_IN_PROGRESS: 'Analysis in progress.',
        WE_FOUND_POTENTIAL_TS_IN_IDF: 'We created independent claims and identified potential trade secrets',
      },
      CONTINUATION_ANALYSIS_NOTIFICATION: {
        WE_ARE_FINDING: 'The continuation analysis is being processed and will be downloaded soon.',
        RECENT_REPORT_DOWNLOAD: 'Recent report download',
        REPORT_FOR: 'Report for {{PATENT_IDENTIFIER}}',
      },
      PATENT_NOTIFICATION: {
        WE_ARE_FINDING: 'We are finding trade secrets and it can take several minutes.',
        RECENT_SEARCH: 'Recent search',
        FOUND_RESULTS_FROM_PATENT: 'We found {{RESULT_NUM}} trade secret results from patent "{{PATENT_ID}}"',
        FOUND_RESULTS_FROM_FILE: 'We found {{RESULT_NUM}} trade secret results from file "{{FILE_NAME}}"',
      },
      PATENT_NOTIFICATION_BUTON: {
        SEE_RESULTS: 'See results',
      },
      PATENT_NOTIFICATION_ERROR: {
        WE_DID_NOT_FIND_PATENT: 'Oops. We did not find the patent you were looking for.',
        SOMETHING_WENT_WRONG: 'Oops. Something went wrong.',
        TRY_SEARCHING_AGAIN: 'Try searching again.',
        TRY_SEARCHING_AGAIN_EXPORT: 'Oops. Something went wrong. Please try searching again.',
        TRY_IN_CORRECT_FORMAT: 'Try searching for a different patent number or check the number is in the correct format (no special characters).',
        NO_CLAIMS: 'The patent "{{PATENT_ID}}" does not contain claims.',
        NO_CLAIMS_IN_FILE: 'Oops. We did not find relevant data in your document.',
        MAKE_SURE_FILE_CONTAINS_CLAIMS: 'Make sure that your file contains a list of numbered claims in the "claims" section.',
        NO_RESULTS: 'Oops. We did not find results for the patent you were looking for at the moment.',
        PROBLEM_READING_FILE: 'There was a problem reading your file.',
        PLEASE_UPLOAD_AGAIN: 'Please upload again.',
        PLEASE_UPLOAD_ACCEPTED_FORMAT: 'Please upload a file in the accepted format.',
        NO_RESULTS_FOR_LATEST_SEARCH: 'Oops. We could not find results for your latest search.',
        NO_SATISFACTORY_RESULTS: 'Oops. We did not find satisfactory results for your search at the moment.',
        NO_DESCRIPTION_IN_DOCUMENT: 'Oops. The document does not contain a description.',
        TRY_UPLOADING_DIFFERENT_DOCUMENT: 'Try uploading a different document.',
      },
      PATENT_APPLICATION: {
        WANT_TO_SEARCH: 'Want to search for a patent that is in application?',
        UPLOAD_PATENT_WORD_FILE: 'Upload patent application word file',
      },
      UPLOAD_PATENT_APPLICATION: {
        SEARCH_WITH_PATENT_FILE: 'Search with patent application file',
        UPLOAD_PATENT_APPLICATION_FILE: 'Upload patent application file',
        DRAG_AND_DROP_WORD_FILE: 'Drag and drop word file here or',
        UPLOAD_WORD_FILE: 'Please upload a word file.',
      },
      UPLOAD_CONTRACT_APPLICATION: {
        UPLOAD_CONTRACT_APPLICATION_FILE: 'Upload Contract File',
        RECENT_UPLOAD: 'Recent upload',
        WE_FOUND_RESULTS: 'We found results from the file "{{FILE_NAME}}"',
        WE_ARE_FINDING: 'We are checking your document’s trade secret protection score and it can take several minutes.',
        TRADE_SECRET_PROTECTION_SCORE: 'Trade Secret Protection Score',
        DOCUMENT_TRADE_SECRET_PROTECTION_SCORE: 'Document trade secret protection score',
        PARAMETERS: 'Parameters',
        RECOMMENDATIONS: 'Recommendations',
      },
      CONTRACT_RESPONSE: {
        CONFIDENTIALITY_PROVISION: {
          LABEL: 'Confidentiality provision',
          CONTENT:
            'An effective contract must provide confidentiality to protect confidential information being shared.  The confidentiality provision also limits what the receipient can do with the information.  Exceptions are defined for situations like required legal or regulatory disclosures, and if the information becomes public.receiving party must maintain its confidentiality, prohibiting disclosure or unauthorized use, with specified exceptions such as legal obligations or pre-existing public knowledge.',
          RECOMMENDATION:
            "The Receiving Party shall treat and keep all Confidential Information as secret and confidential, and will not, without the Disclosing Party's written consent, directly or indirectly communicate or disclose (whether in writing or orally or in any other manner) Confidential Information to any other person other than in accordance with the terms of this Agreement.",
        },
        TRADE_SECRET: {
          LABEL: 'Trade secret protection is mentioned',
          CONTENT: 'The contract should list trade secrets explicitly as a type of covered intellectual property that is to be protected.',
          RECOMMENDATION:
            'Information means all information including, without limitation, any Intellectual Property Rights, information relating to systems, operations, plans, intentions, market opportunities, customers, suppliers, know-how, trade secrets, business affairs, business methods, and business concepts in whatever form, whether in oral, tangible, or in documented form.',
        },
        CLEARLY_DEFINED: {
          LABEL: 'Trade secrets are defined',
          CONTENT: 'Trade secrets should be clearly defined so it is clear to the recipient what is (or is not) a trade secret.',
          RECOMMENDATION:
            'A trade secret is information that: a) is not generally known to the public; b) confers economic benefit on its holder because the information is not publicly known; and c) where the holder makes reasonable efforts to maintain its secrecy.',
        },
        TERMINATION: {
          LABEL: 'Term and termination',
          CONTENT: 'The contract should have a specific length of time defining the term.  Events or options for early termination or extension should be clearly presented.',
          RECOMMENDATION: 'This Agreement shall remain in effect for a term of ABC years.  The Agreement can be terminated by mutual agreement of the Parties.',
        },
        RETURNING_OR_DESTROYING: {
          LABEL: 'Return/destroy confidential information at the end of the term.',
          CONTENT: 'Obligations for the receiving party to delete, return, or destroy confidential information at the end of the term should be clear.',
          RECOMMENDATION:
            'The Receiving Party shall promptly return or destroy all copies (in whatever form reproduced or stored), including all notes and derivatives of the Confidential Information disclosed under this Agreement, upon the earlier of (a) the completion or termination of the dealings contemplated in this Agreement; (b) or the termination of this Agreement; (c) or at the time the Disclosing Party may request it to the Receiving Party.',
        },
        SURVIVAL_CLAUSE: {
          LABEL: 'Survival clause',
          CONTENT:
            "The survival clause in a contract should state that the confidentiality obligations persist even after the agreement's termination, maintaining protection for the disclosed information, with exceptions outlined for any information that becomes public knowledge through no fault of the receiving party.",
          RECOMMENDATION:
            'The obligations for confidentiality shall continue until the later of a) that the information ceases to be confidential through no fault of the Receiving Party, or b) DEF years from the date of disclosure by the Disclosing Party.',
        },
        DISCOVERING_INFORMATION: {
          LABEL: 'Permitted discovery in arbitration or mediation',
          CONTENT: 'If alternative dispute resolution (ADR) such as mediation or arbitration is desired, the amount and extent of discovery can be defined.',
          RECOMMENDATION:
            'Prior to the commencement of ADR, each Party is entitled to take limited discovery, including the right to request a reasonable number of documents, to serve no more than twenty interrogatories, and to conduct no more than three depositions.',
        },
        LIMITS_ON_LIABILITY: {
          LABEL: 'Limitation of liability with respect to trade secret losses',
          CONTENT: 'If desired, limitations of liability for any trade secret losses can be defined.',
          RECOMMENDATION: 'Liability for breach of any of the confidentiality provisions contained within this Agreement shall be limited to XYZ.',
        },
        SHARING_WITHOUT_RESTRICTIONS: {
          LABEL: 'Third party disclosure',
          CONTENT: 'Any permitted third party disclosures should be clearly defined.',
          RECOMMENDATION: 'The Receiving Party will not disclose any Confidential Information received to any third parties without prior written consent of the Disclosing Party.',
        },
        MANDATORY_DISCLOSURE: {
          LABEL: 'Mandatory disclosures',
          CONTENT: 'Mandatory disclosures such as to a court or regulatory authority should be defined.',
          RECOMMENDATION:
            'If the Receiving Party is requested to communicate Confidential Information to a court or regulatory authority or is similarly obligated to reveal such information by mandatory law, the Receiving Party shall promptly notify the Disclosing Party of the terms of such required disclosure, and will cooperate with the Disclosing Party to comply with the request and to preserve the confidentiality of the Confidential Information.',
        },
      },
      CHIP: {
        BETA: 'BETA',
        NEW: 'NEW AI FEATURE',
        ERROR: 'The document does not protect your trade secrets',
        SUCCESS: 'The document protects your trade secrets',
        WARNING: 'The document partially protects your trade secrets',
      },
      POPUPS: {
        TITLE: "We've got new features",
        BUTTONS: {
          NEXT: 'Next',
          OK_I_GET_IT: 'Ok, I get it!',
        },
        PATENT_TITLE: 'Patent X-Ray Extraction & Analysis',
        SUBTITLE: "Identify potential trade secrets surrounding your patents or your competitor's patents and get holistic protection.",
      },
      INVENTION_DISCLOSURE: {
        WORDS_COUNT: '{{WORDS_COUNT}} words',
        TEXT_TOO_LONG_ERROR: 'We can search up to {{MAX_WORDS}} words',
        TEXT_TOO_SHORT_ERROR: 'Input too short. We can only search text that is longer than {{MIN_WORDS}} words',
        TEXTAREA_PLACEHOLDER: 'Paste text of maximum {{MAX_WORDS}} words',
      },
      INVENTION_DISCLOSURE_ERROR: {
        TEXT_TOO_SHORT: 'Oops. Text is too short.',
        WE_CAN_ONLY_SEARCH_MIN_WORDS: 'We can only search text that is longer than {{MIN_WORDS}} words',
      },
      INFO_TEXT: {
        PATENT_XRAY: "Identify potential trade secrets surrounding your patents or your competitor's patents and get holistic protection.",
      },
    },
    DISCOVERY_JOIN: {
      TITLE: {
        XRAY_FEATURES: 'X-Ray Features',
        JOIN_PRIVATE_BETA: "Join Tangibly's private beta to unlock X-Ray features to enhance your trade secret management",
        FINDER: 'Trade Secret Finder',
        TRACKER: 'Asset Exposure Tracker',
        PATENT_XRAY: 'Patent X-Ray',
        SEARCHING_USING_KEYWORDS: 'Searching for trade secrets using keywords',
        MATCHING_FILES: 'Matching files to detect trade secrets exposure',
        FINDING_FROM_PATENTS: 'Finding and editing trade secrets from patents',
      },
      BUTTONS_TEXT: {
        JOIN_BETA: 'Join beta',
      },
      TOAST: {
        ERROR: 'Something went wrong, please try again later',
      },
    },
    PATENT_TOOL: {
      DROPDOWN_BUTTON: {
        TURN_INTO_ASSET: 'Turn into asset',
        EDIT_AND_TURN_INTO_ASSET: 'Edit and turn into asset',
      },
      TOAST: {
        ASSET_CREATED_SUCCESSFULLY: 'Asset was created successfully',
      },
      LOADER: {
        FINDING_TRADE_SECRETS: 'Finding trade secrets from patent "{{PATENT_ID}}"',
        THIS_CAN_TAKE_1_3_MINUTES: 'This can take 1-3 minutes, please stay on the page',
      },
      BUTTON: {
        REGROUP_RESULTS: 'Regroup Results',
      },
      CONTENT: {
        ASSET_CREATED: 'Asset created',
        BACK_TO_PATENT_X_RAY_HOME: 'Patent X-Ray',
        BACK_TO_CONTRACT_ANALYZER_HOME: 'Contract Analyzer',
        BACK_TO_INVENTION_DISCLOSURE_HOME: 'Invention Disclosure',
        TRADE_SECRETS_RESULTS: 'Trade secrets results from patent "{{PATENT_ID}}"',
        INVENTORS: 'Inventors',
        RESULTS: 'results',
      },
      MODAL: {
        ARE_YOU_SURE: 'Are you sure, you would like to close this asset?',
      },
      ADD_NEW_CONTRIBUTORS: {
        ERROR_MESSAGE: {
          EMAIL_SHOULD_BE_UNIQUE: 'Each email should be unique',
          EMAIL_IS_REQUIRED: 'Email is required',
          INVALID_EMAIL: 'Please enter a valid email',
        },
        TOAST: {
          USERS_ADDED_SUCCESSFULLY: '{{NUM}} users added successfully',
        },
        TOOLTIP: {
          FOUND_EXISTING_USERS: 'We have found existing users in the system that match the investors names and might be relevant as contributors to the asset you are creating.',
          ADD_INVENTORS_AS_USERS: "Add the following inventors as users in the system so they will be contributors to the asset you're creating. You can edit the hire date later on the people page.",
        },
        INPUT_LABEL: {
          EMAIL: 'Email',
          ROLE: 'Role',
          HIRE_DATE: 'Hire date',
        },
        BUTTON: {
          ADD_CONTRIBUTORS: 'Add contributors',
        },
        ADD_INVENTORS_AS_CONTRIBUTORS: 'Add patent inventors as contributors',
        SUGGESTED_CONTRIBUTORS: 'Suggested contributors',
        NOT_RIGHT_PERSON: 'Not the right person?',
        NEW_USERS: 'New users',
      },
      EDIT_TEXT: {
        EDIT_SUBJECT: 'Edit subject',
      },
      PLACEHOLDER: {
        ENTER_PATENT_NUMBER: 'Enter patent number',
      },
      ERROR_PAGE: {
        SOMETHING_WENT_WRONG: 'Oops! Something went wrong.',
        SEARCH_AGAIN: 'Search Again',
        NEW_SEARCH: 'New search',
        WE_DID_NOT_FIND: 'Oops. We did not find the patent you were looking for.',
        TRY_IN_CORRECT_FORMAT: 'Try searching for a different patent number or check the number is in the correct format (no special characters).',
        NO_CLAIMS: 'The patent "{{PATENT_ID}}" does not contain claims.',
        XRAY: 'X-Ray',
      },
    },
    PATENT_APPLICATION_TOOL: {
      CONTENT: {
        TRADE_SECRETS_RESULTS: 'Trade secrets results from word file "{{FILE_NAME}}"',
      },
      NOTIFICATION: {
        NO_CLAIMS:
          'We noticed that the file uploaded is not written in the correct format, therefore the results might be impacted. Try uploading an updated file containing a list of numbered claims in the "claims" section.',
      },
    },
    PATENT_MAPPING_TOOL: {
      TITLE: {
        POTENTIAL_TS: 'Potential trade secrets disclosed in file "{{FILE_NAME}}"',
        POTENTIAL_TS_ID: 'Potential trade secrets for patent "{{PATENT_ID}}"',
        POTENTIAL_TS_TITLE: 'Potential trade secrets for "{{PATENT_TITLE}}"',
        IS_MAPPING_TOOL: {
          TITLE: 'Mapping',
        },
        PATENT_NUMBER: 'Patent number',
        YEAR: 'Year',
        INVENTORS: 'Inventors',
        RESULTS: 'results',
        MAPPING_ANALYSIS_IN_PROGRESS: 'Mapping Analysis in Progress',
        MAPPING_ANALYSIS_NOT_FOUND: 'Mapping Analysis not found for this patent',
        NO_REFERENCE: 'No reference found in the specification',
        DISCLOSURE_FOUND_IN_FILE: 'Disclosure found in file',
        MISSING_IN_FILE: 'Missing in file',
      },
      NOTIFICATION: {
        NO_CLAIMS:
          'We noticed that the file uploaded is not written in the correct format, therefore the results might be impacted. Try uploading an updated file containing a list of numbered claims in the "claims" section.',
        DESC_NOT_FOUND: 'We noticed that the file uploaded is not written in the correct format, therefore the results might be impacted.',
      },
      HIGHLIGHTS: {
        OVERALL_DISCLOSURE: 'Overall disclosure',
        DESCRIPTIONS_FOUND_IN_FILE: 'Descriptions found in file',
        FOUND: 'Yes',
        NOT_FOUND: 'No',
        SPECIFICATION_FOUND: 'Specification status',
      },
      EXTRA: {
        MADE_IMPROVEMENTS_IN_FILE: 'Made improvements in the application file?',
        UPLOAD_MODIFIED_FILE: 'Upload modified file',
      },
      TOOLTIP: {
        DESCRIPTION_FOUND_IN_FILE: 'Description found in file',
        SHOW_ORIGINAL_TEXT: 'Show original text',
        MISSING_IN_FILE: 'Missing in file',
        NO_REFERENCE_IN_THE_SPECIFICATION: 'No reference was found in the specification',
        COPY: 'Copy',
        COPIED: 'Copied!',
        ORIGINAL_TEXT_COPIED: 'Original text copied!',
        ORIGINAL_TEXT: 'Original text',
        WE_USE_OPEN_AI_TECHNOLOGY: 'We use Open AI technology to extract trade secrets',
        IT_CAN_STILL_BE_A_TRADE_SECRET: 'Although disclosure was found in the specification, it can still be a trade secret depending on what was disclosed',
        STRONG_CANDIDATE_FOR_A_TRADE_SECRET: 'This is a strong candidate for a trade secret due to the lack of disclosure',
        ALIAS_ID_COPY: 'To create assets by email paste your alias link into the recipient field of a new email and attach your asset file',
        ALIAS_LINK: 'Alias link',
      },
      POPUP: {
        HOW_TO_USE_THIS_FEATURE: 'How to use this feature',
        WAIT_HOW_CAN: 'Wait, how can a patent contain trade secrets? It’s a public document!',
        EXTRACTION_TEXT:
          'Patents are often written in such a way that key information is not disclosed. Patent X-Ray is an AI engine designed to point you in the direction of potential trade secrets that are associated with the patented technology. You will see from the results, Patent X-Ray divides the trade secrets into key categories and within each category articulates a few of the areas of potential trade secrets.',
        DONT_SHOW_THIS_AGAIN: 'Don’t show this again',
        OK_I_GET_IT: 'Ok, I get it!',
        EXPORT: 'Export',
        MAPPING_ANALYSIS: 'Mapping Analysis',
        MAPPING_TEXT:
          'Depending on what was disclosed in the patent, Patent X-Ray’s suggested trade secrets may or may not be actual trade secrets. Our mapping function finds references to the potential trade secrets in the patent specification. By hovering over the results, if related disclosure was found in the specification, it will appear to the right in a pop-up. This is the actual text from the specification.',
      },
      TOAST: {
        FILES_EXPORT: 'Files Export',
        CONTINUATION_ANALYSIS_EXCEL: 'Patent X-Ray Continuation Analysis Excel Report {{patentIdentifier}}',
        CONTINUATION_ANALYSIS_WORD: 'Patent X-Ray Continuation Analysis Word Report {{patentIdentifier}}',
        MAPPING_ANALYSIS: 'Patent X-Ray Mapping Analysis Report {{patentId}}',
        TRADE_SECRET: 'Patent X-Ray Trade Secret Analysis Report {{patentId}}',
        WORD_NOT_EXPORTED: 'Word document was not exported.',
        PLEASE_TRY_AGAIN: 'Please try again',
      },
      MODAL: {
        EXPORT_PATENT_DATA: 'Export Patent X-Ray Data',
        MAPPING_ANALYSIS_EXCEL: 'Mapping Analysis Excel',
        EXTRACTION_ANALYSIS_EXCEL: 'Extraction Analysis Excel',
        TRADE_SECRET_ANALYSIS_PDF: 'Trade Secret Analysis PDF',
      },
    },
    GENERAL: {
      BUTTONS_TEXT: {
        CANCEL: 'Cancel',
        SKIP: 'Skip',
        SAVE_CHANGES: 'Save changes',
        ADD_USER: 'Add user',
        ADD_USER_CAPITAL: 'Add User',
        UPDATE_USER: 'Update user',
        LATER: "I'll do it later",
        ADD: 'Add',
        GO_TO_ASSET_PAGE: 'Go to assets page',
        CONTINUE: 'Continue',
        BACK: 'Back',
        DONE: 'Done',
        EDIT: 'Edit ',
        YES: 'Yes',
        NO: 'No',
        COMPLETE: 'Complete',
        DELETE: 'Delete',
        APPLY: 'Apply',
        NOTIFY: 'Notify',
        CONFIRM: 'Confirm',
        CLEAR_ALL: 'Clear all',
        SEARCH: 'Search',
        SUBMIT: 'Submit',
        I_AM_SURE: 'I’m sure',
        SAVE: 'Save',
        REQUEST_ACCESS: 'Request Access',
        SAVE_AND_CONTINUE: 'Save & Continue',
      },
      TIPPY: {
        MORE_ACTIONS: 'More Actions',
      },
      UPLOAD_FILES_MODAL: {
        DRAG_AND_DROP_FILE: 'Drag and drop file here or',
        SELECT_FILE_FROM_COMPUTER: 'select file from your computer',
        DRAG_AND_DROP_FILE_EDIT_MODE: 'Drag and drop a new file here or click',
        DRAG_AND_DROP_CLICK: 'to browse from your computer',
        FILE_IS_NOT_SUPPORTED: 'This file type is not supported.',
        FILE_IS_TOO_LARGE: 'The file {{FILE_NAME}} is too large. Please upload a file less than {{FILE_SIZE}}MB',
      },
      ERROR: {
        SOMETHING_WENT_WRONG: 'Something went wrong, please try again later',
      },
      SORT_BY_DROPDOWN: {
        NEWEST: 'Newest',
        RECENTLY_MODIFIED: 'Recently modified',
        SORT_BY: 'Sort by:',
      },
    },
    DASHBOARD_PAGE: {
      HEADER: {
        TITLE: 'Dashboard',
        DEPARTMENTS: {
          TITLE: 'Showing data for',
          ALL_DEPARTMENT: 'All Departments',
          NO_DEPARTMENT: 'No Departments',
        },
        EXPORT_DATA: 'Export Data',
      },
      FIRST_TIME_ENTRY_TEXT:
        ' I confirm that I will not improperly disclose or use any trade secrets of any former or concurrent employer during my employment with the {{CLIENT_NAME}}. I will not bring such trade secrets into the {{CLIENT_NAME}} or load onto this {{CLIENT_NAME}}’s Tangibly account without written authorization from the former or concurrent employer owner of the trade secrets.',
      GET_LOGIN_ENTRY_TEXT:
        'The information and assets on this platform are the intellectual property of {{CLIENT_NAME}}, or are properly in the possession of {{CLIENT_NAME}}, and should be treated as highly confidential. Information should not be downloaded, shared or duplicated without written consent from {{CLIENT_NAME}}.',
      GET_LOGIN_ENTRY_NOTE:
        '\n<i>Note:</i> The Tangibly platform is an aid to management, and is not intended to be comprehensive. The account owner may have other trade secrets that are described differently or are not listed.',
      ASSETS: 'Assets',
      NEW_ASSETS: 'NEW ASSETS',
      ASSET_TYPES: 'ASSET TYPES',
      PROJECTS: 'Projects',
      NEW_PROJECTS: 'New Projects',
      TOP_PROJECTS: 'TOP PROJECTS (Number of Assets)',
      PEOPLE: 'People',
      NEW_USERS: 'NEW USERS',
      TOP_CONTRIBUTORS: 'TOP CONTRIBUTORS',
      AVG_NEW_WEEK: 'Avg New / Week',
      ALL_ASSETS: 'All Assets',
      EMPLOYEES: 'Employees',
      CONTRACTORS: 'Contractors',
      PARTNERS: 'Partners',
      WIDGETS: {
        EMPTY_STATE: { TITLE: 'No assets found', BUTTON_CTA_ASSETS: 'Create asset', DESCRIPTION: 'Create assets to start getting results' },
        COMMON: {
          NAME: 'Name',
          ASSETS: 'Assets',
          TOTAL: 'total',
          TYPES: 'Types',
          PAST_12_MONTH: 'Past 12 Months',
        },
        ASSETS_BY_TYPE: 'Assets by Type',
        ASSETS_OVER_TIME: 'Assets Over Time',
        TOTAL_ASSETS: 'Total assets',
        DATA_BY_DEPARTMENTS: { TITLE: 'Data by Departments', EMPTY_STATE: { TITLE: 'No departments found', BUTTON_CTA_ASSETS: 'Go to assets', DESCRIPTION: 'Update your assets using departments' } },
        ASSETS_DATA: 'Assets Data',
        LABEL: {
          OTHER: 'Other',
          NO_DEPARTMENT: 'No Department',
          WEEK: 'Week',
          GROUP: 'Group',
          UNPUBLISHED_ASSETS: 'Unpublished Assets',
          CREATED_BY_3RD_PARTY: 'Assets created by 3rd party',
          EMPLOYEES: 'Employees',
          CONTRIBUTORS: 'Contributors',
          EXTERNAL: 'External',
        },
        PEOPLE_STATUS: 'People Status',
        CONTRIBUTORS: 'Top Contributors',
        TOP_USED: {
          TAGS_TITLE: 'Top Used Tags',
          TAGS: 'Tags',
          BUSINESS_VALUE_TITLE: 'Top Used Business value',
          BUSINESS_VALUE: 'Business Value',
        },
        USERS: 'Users',
        PROJECTS_BY_ASSETS: {
          TITLE: 'Top Projects by Assets',
          PROJECT: 'Project',
          EMPTY_STATE: { TITLE: 'No projects found', BUTTON_CTA_ASSETS: 'Go to assets', DESCRIPTION: 'Update your assets using project' },
        },
        PEOPLE_BY_ROLE: 'People By Role',
        INSIGHTS: {
          TITLE: 'Insights',
          MOST_NOTIFIED_ASSET: 'Most notified Asset',
          MOST_SHARED_BY: 'Most shared by',
          TOP_ASSET_CREATOR: 'Top Asset Creator',
          EMPTY: 'N/A',
        },
      },
      TABLES: {
        ASSET_TABLE: {
          ASSET: 'ASSET',
          TYPE: 'TYPE',
          CREATED_ON: 'CREATED ON',
          RECIPIENTS: 'RECIPIENTS',
          PROGRESS: 'PROGRESS',
          COMPLIANT: 'COMPLIANT',
        },
        PEOPLE_AND_CONTRACTORS_TABLE: {
          NAME: 'Name',
          STATUS: 'Status',
          COMPLIANT: 'Copmliant',
          TRAINING: 'Training',
          START_DATE: 'Hire Date',
          EXIT_DATE: 'Exit Date',
          ACTIONS: 'Actions',
          ACKNOWLEDGMENT: 'Acknowledgment',
          AGREEMENTS: 'Agreements',
          IS_SIGNED: 'is signed',
          NOT_SIGNED: 'Not signed',
          AND_MORE: 'and {{count}} more',
          MISSING: 'Missing',
          TRAINING_COMPLETED: 'training completed',
          ASSETS_RECEIVED: 'assets received',
        },
      },
    },
    SIDE_BAR: {
      HOME: 'Home',
      PREDICTED_PORTFOLIO: 'Predicted Portfolio™',
      COMPETITIVE_INTELLIGENCE: 'Competitive Intelligence',
      DISCOVER: 'Discover',
      PATENT_XRAY: 'Patent X-Ray',
      CONTINUATION_ANALYSIS: 'Continuation Analysis',
      INVENTION_DISCLOSURE: 'Invention Disclosure',
      CONTRACT_ANALYZER: 'Contract Analyzer',
      EMAIL_TRACKER: 'Email Tracker',
      TRAINING: 'Training',
      MANAGE: 'Manage',
      DASHBOARD: 'Dashboard',
      ASSETS: 'Assets',
      PEOPLE: 'People',
      PARTNERS: 'Partners',
      PARTNER_DRAWER: {
        PARTNERS: 'Partners',
        ADD_PARTNER: 'Add Partner',
        SEARCH_BY_NAME: 'Search by name',
        ENABLED: 'Enabled',
        DISABLED: 'Disabled',
        PARTNER_NOT_FOUND: 'Partner not found',
        TRY_ANOTHER_NAME: 'Try searching by another name.',
        NO_ACTIVE_PARTNERS_TO_SHOW: 'No active partners to show',
        NO_DISABLED_PARTNERS_TO_SHOW: 'No disabled partners to show',
        AI_TECHNOLOGY: 'Use AI technology to make sure that all parties involved in a transaction are contractually obligated to uphold their agreements',
        DATA_AND_MANAGEMENT: 'Simplify data handling and management',
        ENSURE_AGREEMENTS: 'Ensure that all agreements are upheld according to their terms',
        NO_CONFUSION: 'No confusion about the status of shared materials',
      },
      SWITCH_CLIENTS: 'Switch Clients',
      //OLD
      COMPETITIVE_ANALYSIS: 'Competitive Analysis',
      XRAY: 'X-Ray',
      LEARN: 'Learn',
      SETTINGS: 'Settings',
      HOMEPAGE: 'Homepage',
      //
    },
    LEARN_PAGE: {
      MY_COURSES: 'My Courses',
      SAVE_CHANGES: 'Save Changes',
      REQUIRED: 'Required',
      GO_BACK_TO_COURSES: 'Go back to Courses',
      CONTINUE_TO_QUIZ: 'Continue to Quiz',
      I_CONFIRM: 'I confirm that I have watched the course',
      TAKE_TO_QUIZ: 'Take the Quiz',
    },
    SETTINGS_PAGE: {
      WELCOME_MESSAGE: `The choices you make here will affect all users within your organization. 
      Please review and set these options carefully, as some settings cannot be changed later from Tangibly platform. 
      For any questions or if you require changes to these settings in the future, contact our customer support team.`,
      UPLOAD_FILES: 'Upload Files',
      SET_ASSET_TYPE: 'Set Asset Type',
      COMPLIANCE_REQUIREMENTS: 'Compliance Requirements',
      CONFIRM: 'Confirm',
      SAVE: 'Save',
      CONFIRM_START_USING: 'Confirm and Start Using Tangibly',
      LANGUAGE: {
        SYSTEM_LANGUAGE: 'System Language',
        YOU_CAN_CHANGE: 'You can change the language exclusively for your account within the profile area later',
      },
      IMPORTANCE_FIELDS: {
        ASSET_CATEGORY: 'Asset Categorization',
        YOU_CAN_CHANGE: "You can change the parameters below according to your company's needs. The categories set below will be shown to employees as asset category options when creating new asset.",
        RESET_TO_DEFAULT: 'Reset to Default',
        ENTER_CATEGORY: 'Enter category',
        TEXT: 'text',
        FIELD_IS_REQUIRE: 'This field is required',
      },
      CHANGE_LANGUAGE: 'Change Language',
      PLACEHOLDER: {
        SELECT_ONE: 'Select One',
      },
      TITLE: {
        PERMISSIONS: 'PERMISSIONS',
        EMPLOYEES: 'EMPLOYEES',
        CONTRACTORS: 'CONTRACTORS',
        PARTNERS: 'PARTNERS',
        ORGANIZATION_SETTINGS: 'Organization Settings',
        ACCOUNTS: 'Accounts',
      },
      TIPPY: {
        SETTING: `These settings cannot be changed from Tangibly platform.\nIf you require changes, contact our customer support team.`,
        ACCOUNTS: 'Accounts',
      },
    },
    LOGIN_PAGE: {
      RESET_PASSWROD_MODAL: {
        RESET_PASSWORD: 'Reset Password',
        VERIFICATION_CODE: 'Verification code and a link was sent to your email.',
        ENTER_USERNAME: 'Enter Username here:',
        ENTER_EMAIL: 'Please enter your email address',
        USERNAME: 'Username',
        EMAIL: 'Email',
      },
      NAVBAR: {
        HOME: 'Home',
        DASHBOARD: 'Dashboard',
        LOGIN: 'Login',
      },
      ERROR_OCCURRED: 'Error occurred',
      CARD: { TRADE_SECRETS_SECURED: 'Helping you keep your trade secrets secured', KEEPING_TRADE_SECRETS: 'Keeping your trade secrets <strong> secure</strong> is our top priority' },
      LOGIN_FORM: {
        LOGIN: 'Login',
        USERNAME: 'Username',
        EMAIL: 'Email',
        PASSWORD: 'Password',
        SIGN_IN: 'Sign In',
        FORGOT_PASSWORD: 'Forgot password?',
        OTP_SIX_DIGITS: 'OTP code is 6 digit',
        WRONG_OTP: 'Wrong OTP',
        LOGIN_AGAIN: 'Error occurred, please login again',
      },
      TWO_FACTOR_AUTH: {
        SET_UP: 'Set up two - factor authentication',
        IN_ORDER_TO_CONTINUE: 'In order to continue, please complete the following steps:',
        STEP_1: '1. Download "Google Authenticator" app on your mobile device',
        STEP_2: '2. Inside the App, click on the “+” button and scan the QR code below',
        STEP_3: '3. Enter the 6-digit code received',
        ENTER_CODE: 'Enter the code from the app below. Once connected, we will remember your phone',
        YOU_CAN_USE_IT: 'so you can use it each time you log in.',
        NOTE: 'Note: you will be required to open the authenticator app to get the updated 6-digit code every time you log in.',
        ENTER_OTP: 'Please enter the OTP code from the Authenticator app to verify your account',
        HAVING_TROUBLE: 'Having troubles logging in? - contact us at ',
      },
      REMEMBER_MY_DEVICE: {
        DONT_ASK_AGAIN: 'Don’t ask me again on this device',
      },
    },
    EMAIL_FINDER: {
      TOAST: {
        ASSET_CREATED_SUCCESSFULLY: 'Asset was created successfully',
      },
      MODAL: {
        CLOSE_ASSET: 'Are you sure, you would like to close this asset?',
        ADD_USER_TO: 'Add user to ',
      },
      CONTENT: {
        BACK_TO_X_RAY_HOME: 'X-Ray Home',
        BACK_TO_FINDER: 'Back to Finder',
        TO: 'To {{NAME}}',
      },
      TITLE: {
        FINDER: 'Finder',
        KEYWORDS: 'Keywords',
        FILES: 'Files',
        PARTICIPANTS: 'Participants',
      },
      DISCOVERY_SUBTITLE: {
        PAGE_LAST_UPDATED_ON: 'This page was last updated on ',
        REFRESH_THE_PAGE: ' Refresh to see the most recent information.',
        WE_ARE_REFRESHING: 'We are refreshing the data and it can take several hours. Do not worry, you can continue working on the system.',
      },
      BUTTONS_TEXT: {
        UPDATE_XRAY_DATA: 'Update X-Ray Data',
        TURN_INTO_ASSET: 'Turn into asset',
      },
      TABLE: {
        NO_DATA: 'No data found',
      },
      TABLE_HEADERS: {
        EMAILS_SUBJECT: 'Emails Subject',
        KEYWORDS: 'Keywords',
        FILES: 'Files',
        PARTICIPANTS: 'Participants',
        DATE: 'Date',
      },
      FETCH_FINDER_DATA: {
        DID_YOU_SIGN_IN: 'Did you sign in with Google?',
        AFTER_AUTHENTICATED_CLICK: 'Once you have authenticated, click on the button below to',
        RECEIVE_NEW_DATA: 'receive new data.',
        CONTINUE_WORKING: ' The data might take some time to refresh, but do not worry, you can continue working.',
        BUTTON_TEXT: {
          START_REFRESHING: 'Start refreshing data',
        },
      },
    },
    EMAIL_TRACKER: {
      CONTENT: {
        BACK_TO_XRAY: 'X-Ray Home',
        ASSETS_AT_RISK_FOUND: ' assets are at risk of exposure due to matching files found in emails',
      },
      TITLE: {
        TRACKER: 'Tracker',
      },
      TABLE: {
        NO_DATA: 'No data found',
      },
      TABLE_HEADERS: {
        ASSET: 'Asset',
        FILE_MATCH: 'File Match',
        PEOPLE_EXPOSED: 'People Exposed',
        DATE: 'Date',
        ACTIONS: 'Actions',
      },
      TOOLTIP: {
        NOTIFY: 'Notify',
        REGISTERED: 'Registered',
        NON_REGISTERED: 'Non-registered',
        ADD_USERS: 'Add users',
      },
      TOAST: {
        SUCCESS_REQUEST_ACCESS: 'Thank you, we will contact you soon',
      },
    },
    EMAIL_TRACKER_MAIN_PAGE: {
      NOTIFICATION: {
        TITLE: 'You are viewing a Tangibly sample project',
        DESCRIPTION: 'Want to see your companies email tracking? Contact us!',
      },
    },
    POLICY: {
      OPTIONS: { DOWNLOAD: 'Download', EDIT: 'Edit', UPLOAD: 'Upload' },
      UPLOAD: {
        TITLE: 'Trade secret policy',
        SAVE: 'Save',
      },
      NEW_TEXT_NOTICE: 'NEW! Trade Secret Policy',
      POLICY_PHRASE_TOP: 'Admins have the capability to upload a trade secret policy document to',
      POLICY_PHRASE_BOTTOM: 'be displayed to all employees.',
      TOAST: {
        SUCCESS: 'Policy changed successfully',
        ERROR: 'Failed, try again',
      },
    },
    HEADER: {
      LOG_OUT: 'Log out',
    },
    LAWYER_CLIENTS: {
      ASSETS: 'Assets',
      NEW_ASSETS: 'New Assets',
      NO_ASSETS: 'No Asset',
      VIEW_ALL_ASSETS_OF: 'View all assets of {{CLIENT_NAME}}',
      CREATE_FIRST_ASSETS_WITH: 'Create your first asset with {{CLIENT_NAME}}',
      NO_CLIENTS_AT_THE_MOMENT: 'No clients available at the moment',
      PROBLEM_LOADING_CLIENTS: 'There was a problem loading clients.',
      TITLE: 'Choose client to start with',
      SUBTITLE: 'Easily switch between clients on the platform by selecting your desired client from the dropdown menu located at the top bar.',
      CHOOSE_CLIENT: 'Choose Client',
    },
    ACTIVITY_LOG: {
      ASSET: {
        ASSET_EDITED: 'Asset edited',
        ASSET_CREATED: 'Asset created',
        WERE_NOTIFIED: '+{{NAMES}} were notified on asset',
        WAS_NOTIFIED: '{{ACTION}} notified on asset',
        WAS: 'was',
        WERE: 'were',
        TITLE: 'Title',
        DESCRIPTION: 'Description',
        ASSET_TYPE: 'Type',
        TAG: 'Tag',
        PROJECT: 'Project',
        DEPARTMENT: 'Department',
        BUSINESS_VALUE: 'Business value',
        FILES: 'File',
        CONTRIBUTOR: 'Contributor',
        OTHERS1: 'Others 1',
        OTHERS2: 'Others 2',
        IMPORTANCE: 'Categorization',
        FILE_LOCATION: 'File location',
        FILE_LABEL: 'File label',
        FILE_LOCATION_AND_LABEL: 'File label & location',
        COMMERCIAL_PRODUCT: 'Commercial product',
        BUSINESS_RELATED: 'Business related',
        COMMERCIAL_PRODUCT_AND_BUSINESS_RELATED: 'Commercial product & Business related',
        RECIPIENTS: 'notified on asset',
        RECIPIENT_REMOVED: 'notification was removed',
        ACKNOWLEDGED_ASSET: ' acknowledged asset',
        DISCLAIMER: 'Prior to this date, the activity log data details is partial',
      },
      CREATED: 'created',
      EDITED: 'edited',
      DELETED: 'deleted',
      ADDED: 'added',
      BY: 'By {{NAME}}',
      ERROR_STATE: 'Oops, we could not display asset’s activity at the moment',
      TRY_RELOAD: 'Try reloading the page',
      PREVIOUS_VALUE: 'Previous value',
      UPDATED_VALUE: 'Updated value',
    },
    INVENTION_DISCLOSURE: {
      MODAL: {
        TITLE: 'Invention Disclosure Text',
        DELETE_ITEM: 'Delete item?',
        DELETE_ITEM_CONTENT: 'By deleting this item you will not be able to restore or view its content, or see its association to other items. ',
        DONT_SHOW_AGAIN_DELETE: 'Don’t show me this again when deleting item',
        EDIT_ITEM: 'Edit item?',
        EDIT_ITEM_CONTENT: 'By editing an item you will not be able to see its association to other items.',
        DONT_SHOW_AGAIN_EDIT: 'Don’t show me this again when editing item',
        LEAVE_PAGE_TITLE: 'Leave page?',
        UNSAVED_CHANGES: 'You have unsaved changes that will be lost',
        LEAVE_PAGE_BUTTON: 'Leave Page',
      },
      TOOLTIP: {
        ASSOCIATIONS: '{{NUMBER}} associations',
        ASSOCIATION: '{{NUMBER}} association',
        DRAG_TO_REORDER_CLAIMS: 'Drag to reorder claims',
      },
      TITLE: 'Claims and potential trade secrets associations from Invention Disclosure',
      SEE_ORIGINAL_TEXT: 'See original text',
      PATENT_CLAIMS: 'Patent Claims',
      POTENTIAL_TRADE_SECRETS: 'Potential Trade Secrets',
      SHORT_INPUT: 'Short input text might affect the results. Try searching for at least {{OPTIMAL_WORDS}} words text.',
      WRITE_YOUR_CLAIM_HERE: 'Write your claim here',
      TABS: {
        CLAIMS: 'Claims',
        SPEC: 'Spec',
        COMING_SOON: 'COMING SOON',
        ADD_CLAIM: 'Add Claim',
      },
      PLACEHOLDER: {
        WRITE_YOUR_CLAIM_HERE: 'Write your claim here',
      },
    },
    UNDER_CONSTRUCTION_PAGE: {
      TITLE: 'Security updates in progress',
      SUBTITLE: 'Service is temporarily unavailable',
    },
    IP_AUDIT: {
      HEADER: 'Quick Start',
      BUTTON_TEXT: {
        START_NOW: 'Start now',
        UPGRADE_TO_AUDIT: 'Upgrade to unlock',
        SEE_INFO: 'See info',
        COMPLETED: 'completed',
      },
      TOAST: {
        ERROR: 'Failed, try again',
      },
      COMING_SOON: 'COMING SOON',
      CONTACT_US: 'Contact Us',
      BANNER_TEXT: 'Take these {{TOTAL}} steps to protect your trade secrets and quickly establish you have take reasonable measures.',
      IP_AUDIT_STEPS: {
        STEP: 'Step {{INDEX}}',
        STEP_OF: 'Step {{INDEX}} of {{TOTAL}}',
        COMPLETED: 'Completed',
        STANDARD_VERSION: 'our standard version',
        OVERVIEW: {
          NAME: 'Overview',
          TITLE: '{{TOTAL}} steps to take you into the Reasonable Measures category',
          DESCRIPTION:
            'Identify, evaluate, and manage intellectual property rights (IPR) to ensure that they are effectively protected, properly utilized, and aligned with the company’s business strategy.',
          COMPLETE_FLOW_BTN: 'Complete flow',
          HR_PROCESS_DESCRIPTION: 'HR is your first and last line of defense for protecting trade secrets.',
          SYSTEM_SECURITY_DESCRIPTION: 'These systems are critical in protecting against digital breaches.',
          LLM_POLICY_DESCRIPTION: 'LLMs and co-pilots carry great risk to keeping your valuable information confidential. Upload your LLM Policy below.',
          TRADE_SECRET_POLICY_DESCRIPTION: 'Trade secret protection starts here - without the right policy its all left to chance.',
          PROGRESS_BAR_TEXT: '{{COMPLETED}} completed',
        },
        TRADE_SECRET_POLICY: {
          NAME: 'Trade Secret Policy',
          DESCRIPTION: 'Trade secret protection starts here - without the right policy its all left to chance. Upload your trade secret policy below.',
          DOWNLOAD_POLICY: 'Don’t have your Trade Secret Policy?',
        },
        SYSTEM_SECURITY: {
          NAME: 'System Security',
          SELECTION: 'Select with systems your organization is working with:',
          NONE_OF_THE_ABOVE: 'None of the above',
          DESCRIPTION: 'These systems are critical in protecting against digital breaches.',
        },
        HR_PROCESS: {
          NAME: 'HR Process',
          DESCRIPTION: 'These processes are your first and last line of defense in protecting trade secrets.',
          PROTOCOL: 'Does your organization incorporate protocols for confidential data handling into its employee onboarding and offboarding processes?',
          TRAINING: 'Do your employees go through training on confidential data handling?',
        },
        LLM_POLICY: {
          NAME: 'LLM Policy',
          DESCRIPTION: 'LLMs and co-pilots carry great risk to keeping your valuable information confidential. Upload your LLM Policy below.',
          DOWNLOAD_POLICY: 'Don’t have your LLM Policy?',
        },
        CONTRACTS: {
          NAME: 'Contracts',
        },
        KEY_ASSETS_SYSTEMS: {
          NAME: 'Key Assets from Systems',
        },
        KEY_ASSETS_PATENT_X_RAY: {
          NAME: 'Key Assets from Patent X-Ray',
        },
      },
    },
  },
};
