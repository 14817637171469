import { IpAuditUserStepsKey, IpAuditStepKey } from '_components/IpAudit/types';

export type CardsDescriptionMap = Record<IpAuditUserStepsKey, string>;

export const cardsDescriptionMap: CardsDescriptionMap = {
  [IpAuditStepKey.TRADE_SECRET_POLICY]: 'IP_AUDIT.IP_AUDIT_STEPS.OVERVIEW.TRADE_SECRET_POLICY_DESCRIPTION',
  [IpAuditStepKey.SYSTEM_SECURITY]: 'IP_AUDIT.IP_AUDIT_STEPS.OVERVIEW.SYSTEM_SECURITY_DESCRIPTION',
  [IpAuditStepKey.HR_PROCESS]: 'IP_AUDIT.IP_AUDIT_STEPS.OVERVIEW.HR_PROCESS_DESCRIPTION',
  [IpAuditStepKey.LLM_POLICY]: 'IP_AUDIT.IP_AUDIT_STEPS.OVERVIEW.LLM_POLICY_DESCRIPTION',
  [IpAuditStepKey.CONTRACTS]: '',
  [IpAuditStepKey.KEY_ASSETS_SYSTEMS]: '',
  [IpAuditStepKey.KEY_ASSETS_PATENT_X_RAY]: '',
};
