import TangiModal from '_components/TangiLibrary/TangiModal/TangiModal';
import { TangiStepper } from '_components/TangiLibrary/TangiStepper/TangiStepper';
import { Header } from './components/Header/Header';
import { ipAuditModalBodyStyle, ipAuditModalContainerStyle, ipAuditStepperViewStyle } from './styles';
import { useIpAudit } from './useIpAudit';
import { TangiToast } from '_components/TangiLibrary/TangiToast';

interface Props {
  isOpenModal: boolean;
  onCloseModal: () => void;
}

export const IpAudit = ({ isOpenModal = false, onCloseModal }: Props) => {
  const { ipAuditSteps, ipAuditToastProps } = useIpAudit();
  return (
    <>
      <TangiModal
        show={isOpenModal}
        header={<Header />}
        onHide={onCloseModal}
        body={<TangiStepper steps={ipAuditSteps} slotsStyle={{ view: ipAuditStepperViewStyle }} />}
        slotsStyles={{ bodyContainer: { style: ipAuditModalBodyStyle } }}
        containerStyle={ipAuditModalContainerStyle}
      />
      <TangiToast {...ipAuditToastProps} />
    </>
  );
};
