/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: Fix any and Object types
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  acknowledgeGetAsset,
  acknowledgePostAsset,
  acknowledgePreviewAsset,
  acknowledgePreviewAssetPOST,
  createAssets,
  deleteAsset,
  deleteAssetFile,
  deleteRecipient,
  getAssetById,
  getAssets,
  getAssetsClient,
  getFilePathUrl,
  getRefNumber,
  shareAsset,
  validateAssets,
  getPrivilegeLogData,
  pendingAcknowledgement,
  getAssetAuditLog,
  downloadAllAssetsFiles,
  checkDownloadAssetsFilesStatus,
} from 'redux-toolkit/thunks/assetsThunks';
import { AllAssetsFilesResponse, Asset, Assets, IshareContributorsStatus, Pagination } from 'utils/types/assets/assets';
import { responseStatus } from '_constants';
import { mergeActivityLogsByMonth, normalizeActivityLogData } from '_components/ActivityLog/utils';
import { ActivityLogs, ActivityMetaData, INITIAL_LIMIT } from 'utils/types/activityLog/asset/types';
import { FilePayload, ToastProps } from 'utils/types/types';
import { RESULT_STATUS } from 'utils/enums';
import { XRAY_JOB_STATUS } from 'utils/types/xRay/xRay';

interface assetsState {
  assets: Assets;
  batchAssets: any[];
  batchErrors: any[];
  uploadedBatchAssets: any[];
  validatedBatch: boolean;
  assetsClientDashboard: any[];
  filePath: FilePayload;
  deleteFile: any;
  deleteAssetSuccess: boolean;
  deleteAssetError: boolean;
  deleteAssetStatus: any;
  asset: Asset | Object;
  error: Object;
  shareAsset: string;
  getAcknowledge: any[];
  postAcknowledge: any[];
  previewAsset: any[];
  assetStatus: any;
  previewPostAsset: any[];
  deleteRecipientData: any;
  fullReferenceId: Object;
  assetCreationToastProps: ToastProps;
  createdAssetId: string;
  shareContributorsStatus: IshareContributorsStatus;
  isTableView: boolean;
  pagination: Pagination;
  loading: boolean;
  privilegeLogLoading: boolean;
  batchLoading: boolean;
  recipientLoading: boolean;
  createRefLoading: boolean;
  assetLoading: boolean;
  createUpdateAssetLoading: boolean;
  pendingAcknowledgementLoading: boolean;
  assetAuditLog: ActivityLogs;
  assetAuditLogMetaData: ActivityMetaData;
  assetAuditLogInfiniteLoader: boolean;
  isDownloadAssetFilesLoading: boolean;
  downloadAssetFilesUrl: null | string;
  downloadAssetsFilesJobStatus: null | AllAssetsFilesResponse;
}

const initialState: assetsState = {
  assets: { metadata: [], data: [] },
  batchAssets: [],
  batchErrors: [],
  uploadedBatchAssets: [],
  validatedBatch: false,
  assetsClientDashboard: [],
  filePath: { base64: '', mimeType: '', file: '' },
  deleteFile: null,
  deleteAssetSuccess: false,
  deleteAssetError: false,
  deleteAssetStatus: null,
  asset: {},
  error: {},
  shareAsset: '',
  getAcknowledge: [],
  postAcknowledge: [],
  previewAsset: [],
  assetStatus: null,
  previewPostAsset: [],
  deleteRecipientData: null,
  fullReferenceId: {},
  assetCreationToastProps: { show: false, text: '', type: RESULT_STATUS.BLANK },
  createdAssetId: '',
  shareContributorsStatus: { status: '', message: '' },
  isTableView: false,
  pagination: { limit: 15, page: 1 },
  // loaders
  loading: false,
  privilegeLogLoading: false,
  batchLoading: false,
  recipientLoading: false,
  createRefLoading: false,
  assetLoading: false,
  createUpdateAssetLoading: false,
  pendingAcknowledgementLoading: false,
  assetAuditLog: {},
  assetAuditLogMetaData: {
    limit: INITIAL_LIMIT,
    page: 0,
    hasMore: false,
    total: -1,
  },
  assetAuditLogInfiniteLoader: false,
  isDownloadAssetFilesLoading: false,
  downloadAssetFilesUrl: null,
  downloadAssetsFilesJobStatus: null,
};

const assetsSlice = createSlice({
  name: 'assetsData',
  initialState,
  reducers: {
    setAssets(state, action) {
      state.assets = action.payload;
    },
    setAssetsLoading(state, action) {
      state.assetLoading = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.loading = false;
      state.assetStatus = responseStatus.assetFailed;
      state.error = action.payload;
    },
    setAsset(state, action) {
      state.asset = action.payload;
    },
    setCreateUpdateAssetLoading(state, action) {
      state.createUpdateAssetLoading = action.payload;
    },
    setShareContributorsStatus(state, action) {
      state.shareContributorsStatus.message = action.payload;
      state.shareContributorsStatus.status = 'success';
    },
    setAssetStatus(state, action) {
      state.assetStatus = action.payload;
    },
    setCreatedAssetId(state, action) {
      state.createdAssetId = action.payload;
    },
    setUploadFileError(state, action) {
      state.error = action.payload;
      state.createUpdateAssetLoading = false;
    },
    setResetBatchAssets(state) {
      state.validatedBatch = false;
      state.batchAssets = [];
      state.batchErrors = [];
      state.uploadedBatchAssets = [];
    },
    setClearFilePathUrl(state) {
      state.filePath = { base64: '', mimeType: '', file: '' };
    },
    setClearShareSuccess(state) {
      state.shareAsset = '';
    },
    setAcknowledgePostClear(state) {
      state.postAcknowledge = [];
    },
    setAcknowledgeGetClear(state) {
      state.getAcknowledge = [];
    },
    setClearAssetStatus(state) {
      state.loading = false;
      state.assetStatus = null;
      state.shareContributorsStatus = { status: '', message: '' };
    },
    setClearAsset(state) {
      state.asset = {};
      state.createdAssetId = '';
    },
    setGetAssetsClear(state) {
      state.assets = { metadata: [], data: [] };
      state.asset = {};

      state.assetsClientDashboard = [];
    },
    setClearRecipient(state) {
      state.deleteRecipientData = null;
    },
    setClearDeleteStatus(state) {
      state.deleteAssetStatus = null;
      state.loading = false;
      state.deleteAssetSuccess = false;
      state.deleteAssetError = false;
    },
    setAssetCreationToastProps(state, action: PayloadAction<ToastProps>) {
      state.assetCreationToastProps = action.payload;
    },
    setIsTableView(state, action: PayloadAction<boolean>) {
      state.isTableView = action.payload;
    },
    setPagination(state, action: PayloadAction<Pagination>) {
      state.pagination = action.payload;
    },
    setInitialPagination(state) {
      state.pagination = { limit: 15, page: 1 };
    },
    setAuditLogPage(state, action: PayloadAction<number>) {
      state.assetAuditLogMetaData.page = action.payload;
    },
    setInitialAuditLog(state) {
      state.assetAuditLog = {};
    },
    setAssetAuditLogLoader(state) {
      state.loading = false;
      state.assetAuditLogInfiniteLoader = true;
    },
    resetAssetAuditLogLoader(state) {
      state.assetAuditLogInfiniteLoader = false;
    },
  },
  extraReducers: (builder) => {
    // getAssetById
    builder.addCase(getAssetById.pending, (state) => {
      state.assetLoading = true;
    });
    builder.addCase(getAssetById.fulfilled, (state, action: any) => {
      state.asset = action?.payload?.data[0];
      state.assetLoading = false;
    });
    builder.addCase(getAssetById.rejected, (state) => {
      state.assetLoading = false;
    });

    // getAssets
    builder.addCase(getAssets.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAssets.fulfilled, (state, action: PayloadAction<Assets>) => {
      state.assets = action.payload;
      state.loading = false;
    });
    builder.addCase(getAssets.rejected, (state, action: any) => {
      state.loading = false;
      state.assetStatus = responseStatus.assetFailed;
      state.error = action.payload;
    });

    // getPrivilegeLogData
    builder.addCase(getPrivilegeLogData.pending, (state) => {
      state.privilegeLogLoading = true;
    });
    builder.addCase(getPrivilegeLogData.fulfilled, (state) => {
      state.privilegeLogLoading = false;
    });
    builder.addCase(getPrivilegeLogData.rejected, (state, action: any) => {
      state.privilegeLogLoading = false;
      state.error = action.error.message;
    });

    // validateAssets
    builder.addCase(validateAssets.pending, (state) => {
      state.batchAssets = [];
      state.batchErrors = [];
      state.validatedBatch = false;
      state.batchLoading = true;
    });
    builder.addCase(validateAssets.fulfilled, (state, action: any) => {
      state.batchAssets = action.payload.assets;

      state.batchErrors = action.payload.errors;
      state.validatedBatch = true;
      state.batchLoading = false;
    });
    builder.addCase(validateAssets.rejected, (state, action: any) => {
      state.batchAssets = [];
      state.batchErrors = [];
      state.validatedBatch = false;
      state.error = action.error.message;
      state.batchLoading = false;
    });

    // Create Assets from Batch
    builder.addCase(createAssets.pending, (state) => {
      state.batchLoading = true;
      state.uploadedBatchAssets = [];
    });
    builder.addCase(createAssets.fulfilled, (state, action: any) => {
      state.batchLoading = false;
      state.uploadedBatchAssets = action.payload.data;
    });
    builder.addCase(createAssets.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.batchLoading = false;
      state.uploadedBatchAssets = [];
    });

    // getFilePathUrl
    builder.addCase(getFilePathUrl.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFilePathUrl.fulfilled, (state, action: any) => {
      state.filePath = action.payload;
      state.loading = false;
    });
    builder.addCase(getFilePathUrl.rejected, (state, action: any) => {
      state.error = action.payload.error;
      state.filePath = { base64: '', mimeType: '', file: '' };
      state.loading = false;
    });

    // deleteAssetFile
    builder.addCase(deleteAssetFile.pending, () => {});
    builder.addCase(deleteAssetFile.fulfilled, (state, action: any) => {
      state.deleteFile = action.payload;
    });
    builder.addCase(deleteAssetFile.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.deleteFile = null;
    });

    // shareAsset
    builder.addCase(shareAsset.pending, (state) => {
      state.shareAsset = '';
      state.recipientLoading = true;
    });
    builder.addCase(shareAsset.fulfilled, (state, action) => {
      const newAssetDataShare = action.payload[0].data.data[0];
      const assetIndex = state.assets?.data.findIndex((asset) => asset?.id === newAssetDataShare?.id);
      const dataDupe = [...state.assets?.data];
      if (assetIndex >= 0) {
        dataDupe[assetIndex] = newAssetDataShare;
      }
      const assets = { ...state.assets, data: dataDupe };
      state.shareAsset = action.payload;
      state.recipientLoading = false;
      state.assets = assets;
      state.asset = newAssetDataShare;
    });

    builder.addCase(shareAsset.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.shareAsset = '';
      state.recipientLoading = false;
    });

    // getRefNumber
    builder.addCase(getRefNumber.pending, (state) => {
      state.createRefLoading = true;
      state.fullReferenceId = {};
    });
    builder.addCase(getRefNumber.fulfilled, (state, action: any) => {
      state.createRefLoading = false;
      state.fullReferenceId = action.payload;
    });
    builder.addCase(getRefNumber.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.createRefLoading = false;
      state.fullReferenceId = {};
    });

    // deleteAsset
    builder.addCase(deleteAsset.pending, (state) => {
      state.deleteAssetStatus = null;
      state.loading = true;
      state.deleteAssetSuccess = false;
      state.deleteAssetError = false;
    });
    builder.addCase(deleteAsset.fulfilled, (state, action: any) => {
      state.deleteAssetSuccess = true;
      state.deleteAssetStatus = action.payload;
      state.loading = false;
    });
    builder.addCase(deleteAsset.rejected, (state, action: any) => {
      state.deleteAssetError = true;
      state.deleteAssetStatus = action.error.message;
      state.loading = false;
    });

    // getAssetsClient
    builder.addCase(getAssetsClient.pending, (state) => {
      state.assetsClientDashboard = [];
      state.loading = true;
    });
    builder.addCase(getAssetsClient.fulfilled, (state, action: any) => {
      state.assetsClientDashboard = action.payload;
      state.loading = false;
    });
    builder.addCase(getAssetsClient.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.loading = false;
    });

    // deleteRecipient
    builder.addCase(deleteRecipient.pending, (state) => {
      state.deleteRecipientData = null;
      state.recipientLoading = true;
    });
    builder.addCase(deleteRecipient.fulfilled, (state, action: any) => {
      const newAssetDataDelete = action.payload.res[0].data.data[0] || {};
      let filteredAssets: Assets = { metadata: [], data: [] };
      if (state?.assets?.data.length > 0) {
        const updatedAssetIndex = state.assets.data.findIndex((asset) => asset.id === action.payload.assetId);
        const dataDupe: any = [...state.assets.data];
        const filteredRecipients = dataDupe[updatedAssetIndex].recipients.filter((recipient: any) => recipient._id !== action.payload.recipientId);
        dataDupe[updatedAssetIndex].recipients = filteredRecipients;
        filteredAssets = { ...state.assets, data: dataDupe };
      }
      state.deleteRecipientData = action.payload.res;
      state.asset = newAssetDataDelete;
      state.assets = filteredAssets;
      state.recipientLoading = false;
    });
    builder.addCase(deleteRecipient.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.deleteRecipientData = null;
      state.recipientLoading = false;
    });

    // acknowledgeGetAsset
    builder.addCase(acknowledgeGetAsset.pending, (state) => {
      state.getAcknowledge = [];
    });
    builder.addCase(acknowledgeGetAsset.fulfilled, (state, action: any) => {
      state.getAcknowledge = action.payload;
    });
    builder.addCase(acknowledgeGetAsset.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.getAcknowledge = [];
    });

    // acknowledgePostAsset
    builder.addCase(acknowledgePostAsset.pending, (state) => {
      state.postAcknowledge = [];
    });
    builder.addCase(acknowledgePostAsset.fulfilled, (state, action: any) => {
      state.postAcknowledge = action.payload;
    });
    builder.addCase(acknowledgePostAsset.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.postAcknowledge = [];
    });

    // acknowledgePreviewAsset
    builder.addCase(acknowledgePreviewAsset.pending, (state) => {
      state.previewAsset = [];
    });
    builder.addCase(acknowledgePreviewAsset.fulfilled, (state, action: any) => {
      state.previewAsset = action.payload;
    });
    builder.addCase(acknowledgePreviewAsset.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.previewAsset = [];
    });

    // acknowledgePreviewAssetPOST
    builder.addCase(acknowledgePreviewAssetPOST.pending, (state) => {
      state.previewPostAsset = [];
    });
    builder.addCase(acknowledgePreviewAssetPOST.fulfilled, (state, action: any) => {
      state.previewPostAsset = action.payload;
    });
    builder.addCase(acknowledgePreviewAssetPOST.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.previewPostAsset = [];
    });

    // pendingAcknowledgement
    builder.addCase(pendingAcknowledgement.pending, (state) => {
      state.pendingAcknowledgementLoading = true;
    });
    builder.addCase(pendingAcknowledgement.fulfilled, (state) => {
      state.pendingAcknowledgementLoading = false;
    });
    builder.addCase(pendingAcknowledgement.rejected, (state, action: any) => {
      state.pendingAcknowledgementLoading = false;
      state.error = action?.payload?.message ?? '';
    });

    // getAuditLog
    builder.addCase(getAssetAuditLog.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getAssetAuditLog.fulfilled, (state, action) => {
      state.error = false;
      state.loading = false;

      const combinedData = mergeActivityLogsByMonth(state.assetAuditLog, normalizeActivityLogData(action.payload.data));

      state.assetAuditLog = combinedData;
      state.assetAuditLogMetaData = action.payload.metadata;
    });
    builder.addCase(getAssetAuditLog.rejected, (state, action: any) => {
      state.error = action?.error?.message ?? '';
      state.loading = false;
    });

    // downloadAllAssetsFiles
    builder.addCase(downloadAllAssetsFiles.pending, (state) => {
      state.isDownloadAssetFilesLoading = true;
    });
    builder.addCase(downloadAllAssetsFiles.fulfilled, (state, action: PayloadAction<AllAssetsFilesResponse>) => {
      state.downloadAssetsFilesJobStatus = action.payload;
    });
    builder.addCase(downloadAllAssetsFiles.rejected, (state, action: any) => {
      state.isDownloadAssetFilesLoading = false;
      state.error = action.payload;
    });

    // checkDownloadAssetsFilesStatus
    builder.addCase(checkDownloadAssetsFilesStatus.pending, (state) => {
      state.isDownloadAssetFilesLoading = true;
    });

    builder.addCase(checkDownloadAssetsFilesStatus.fulfilled, (state, action: PayloadAction<Blob | AllAssetsFilesResponse>) => {
      if (action.payload instanceof Blob) {
        const blob: Blob = action.payload;
        const url = window.URL.createObjectURL(blob);
        state.downloadAssetFilesUrl = url;
        state.isDownloadAssetFilesLoading = false;
        state.downloadAssetsFilesJobStatus = null;
      } else {
        action.payload.jobStatus === XRAY_JOB_STATUS.SUCCEEDED || action.payload.jobStatus === XRAY_JOB_STATUS.FAILED
          ? (state.isDownloadAssetFilesLoading = false)
          : (state.isDownloadAssetFilesLoading = true);
        state.downloadAssetsFilesJobStatus = { ...state.downloadAssetsFilesJobStatus, jobStatus: action.payload.jobStatus, downloadUrl: action.payload.downloadUrl };
      }
    });
    builder.addCase(checkDownloadAssetsFilesStatus.rejected, (state, action: any) => {
      state.isDownloadAssetFilesLoading = false;
      state.downloadAssetsFilesJobStatus = null;
      state.error = action.payload;
    });
  },
});

export const assetsActions = assetsSlice.actions;

export default assetsSlice.reducer;
