import { isEmpty } from 'lodash';

import { Client, ClientIpPermissionsPartial } from './types/client/client';

export const hasIPPortfolioPermission = (client: Client | ClientIpPermissionsPartial) => {
  const ipPortfolioPermission = client?.permissions?.ipPortfolio;
  return (ipPortfolioPermission?.hasPermission && !isEmpty(ipPortfolioPermission?.portfolioId)) ?? false;
};

export const hasIPCompetitivePermission = (client: Client | ClientIpPermissionsPartial) => {
  const ipPortfolioPermission = client?.permissions?.ipCompetitive;
  return (ipPortfolioPermission?.hasPermission && !isEmpty(ipPortfolioPermission?.portfolioId)) ?? false;
};
