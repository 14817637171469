import styled from 'styled-components';
import { SPACING, THEME_COLORS } from 'utils/theme';

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

export const StepInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[4]};
  flex: 1;
  overflow-y: auto;
  padding: ${SPACING[4]} ${SPACING[4]} 0 ${SPACING[4]};
`;

export const StepTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[3]};
`;

export const DownloadText = styled.span`
  color: ${THEME_COLORS.PRIMARY_DEFAULT};
  cursor: pointer;
`;
