import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import AssetsSkeleton from './components/AssetsSkeleton/AssetsSkeleton';
import AssetCardActive from './components/AssetCardActive/AssetCardActive';
import AssetCardLocked from './components/AssetCardLocked/AssetCardLocked';
import AssetCardsEmptyState from './components/AssetsEmptyState/AssetCardsEmptyState';
import { SPACING } from 'utils/theme';

export const AssetCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  grid-auto-rows: minmax(232px, auto);
  column-gap: ${SPACING[5]};
  row-gap: ${SPACING[4]};
  a {
    color: inherit;
  }
`;

export const ClientAssetCards = ({ assets, handleDistributeAsset, loading, assetOptions, handleUnlockAsset, isAdmin }) => {
  const permissions = useSelector((state) => state.authentication.permissions);

  if (loading) {
    return <AssetsSkeleton />;
  }

  if (!assets?.data?.length) {
    return <AssetCardsEmptyState />;
  }

  const isAssetLocked = (asset) => asset?.needsToBeAcknowledgedByMe || (asset?.isThirdParty && !asset?.hasAccessToThirdPartyAsset);

  return (
    <AssetCardsContainer data-testid="assets">
      {assets.data.map((asset) => (
        <React.Fragment key={asset.id}>
          {isAssetLocked(asset) ? (
            <AssetCardLocked asset={asset} handleUnlockAsset={handleUnlockAsset} />
          ) : (
            <>
              {permissions?.Assetview ? (
                <NavLink to={{ pathname: `/client/${asset.client._id}/asset/${asset.id}` }}>
                  <AssetCardActive asset={asset} isAdmin={isAdmin} permissions={permissions} handleDistributeAsset={handleDistributeAsset} options={assetOptions} />
                </NavLink>
              ) : (
                <AssetCardActive asset={asset} isAdmin={isAdmin} permissions={permissions} handleDistributeAsset={handleDistributeAsset} options={assetOptions} />
              )}
            </>
          )}
        </React.Fragment>
      ))}
    </AssetCardsContainer>
  );
};
