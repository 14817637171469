import { ReactNode } from 'react';
import { PolicyFileDetails } from 'utils/types/client/client';
import { SystemSecuritySelection } from './components/Steps/SystemSecurity/types';

export enum IpAuditStepKey {
  OVERVIEW = 'overview',
  TRADE_SECRET_POLICY = 'tradeSecretPolicy',
  SYSTEM_SECURITY = 'systemSecurity',
  HR_PROCESS = 'hrProcess',
  LLM_POLICY = 'llmPolicy',
  CONTRACTS = 'contracts',
  KEY_ASSETS_SYSTEMS = 'keyAssetsFromSystems',
  KEY_ASSETS_PATENT_X_RAY = 'keyAssetsFromPatentXray',
}

export type IpAuditStepsInfoMap = Record<IpAuditStepKey, IpAuditStepData>;

export interface IpAuditStepData {
  name: string;
  component: ReactNode;
  isCompleted?: boolean;
}

export type IpAuditUserStepsKey = Exclude<IpAuditStepKey, IpAuditStepKey.OVERVIEW>;

export interface IpAuditData {
  hrProcess: HrProcessData | null;
  systemSecurity: SystemSecurityData | null;
}

export interface IpAuditExtendedData extends IpAuditData {
  policyFile: PolicyFileDetails | null;
  llmFile: PolicyFileDetails | null;
}

export type StepDataTypeMap = {
  [IpAuditStepKey.HR_PROCESS]: IpAuditExtendedData['hrProcess'] | null;
  [IpAuditStepKey.SYSTEM_SECURITY]: IpAuditExtendedData['systemSecurity'] | null;
  [IpAuditStepKey.LLM_POLICY]: IpAuditExtendedData['llmFile'] | null;
  [IpAuditStepKey.TRADE_SECRET_POLICY]: IpAuditExtendedData['policyFile'] | null;
};

export type SystemSecurityData = SystemSecuritySelection[];

export interface HrProcessData {
  isIncorporateProtocolsConfidential: boolean;
  employeeTraining: boolean;
}
