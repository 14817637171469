import React, { useEffect } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { TangiTypography } from '_components/TangiLibrary';
import { ContentLoader } from '_components/Loader/ContentLoader';
import { getLawyerClients, setActiveClientAndResetPartnerships } from 'redux-toolkit/thunks/lawFirmThunks';
import { RootState } from '_helpers';
import { Client } from 'utils/types/client/client';
import { generateRoute } from 'utils/routing/generateRoute';
import { AppRoutes } from 'utils/routing/consts';
import { NEUTRAL_SHADES, PRIMARY_SHADES } from 'utils/theme';
import { defaultImageSidemenu } from '../../assets/img';
import { ClientCard, LogoNameContainer, SubtitleContainer, TitleWrapper } from './style';
import './LawyerHomepage.scss';

const LawyerHomepage = () => {
  const activeAccount = useSelector((state: RootState) => state.authentication.activeAccount);
  const { lawyerClients, loading, activeClient } = useSelector((state: RootState) => state.lawfirm);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSwitchActiveClient = (client: Client) => {
    history.push(generateRoute(AppRoutes.HOME_LOBBY, { clientId: client?.id }));
    dispatch(setActiveClientAndResetPartnerships(client));
  };

  useEffect(() => {
    dispatch(setActiveClientAndResetPartnerships(null));
  }, []);

  useEffect(() => {
    if (activeAccount) {
      dispatch(getLawyerClients({ accountId: activeAccount._id }));
    }
  }, [activeAccount]);

  return (
    <>
      <Container fluid="md" className="mt-5">
        {loading ? (
          <ContentLoader isLoading={loading} />
        ) : (
          <Row className="widget-gap px-md-4">
            <TitleWrapper>
              <TangiTypography type="heading-lg" weight="semibold">
                {t('LAWYER_CLIENTS.TITLE')}
              </TangiTypography>
              <TangiTypography color={NEUTRAL_SHADES[900]}>{t('LAWYER_CLIENTS.SUBTITLE')}</TangiTypography>
            </TitleWrapper>
            {!!lawyerClients?.data?.length
              ? lawyerClients.data.map((client: Client) => (
                  <Col key={client.id} sm={12} md={12} lg={6} xl={4}>
                    <ClientCard
                      isActive={activeClient?.id === client?.id}
                      className="mb-4 shadow border-none"
                      bsPrefix="lawyer-client-card"
                      data-testid="lawyer-client-card"
                      onClick={() => handleSwitchActiveClient(client)}
                    >
                      <Card.Body className="p-4">
                        <Row>
                          <Col>
                            {client && client.assetCount && client.assetCount > 0 ? (
                              <span className="h2 ff-din-regular-2 mb-0">
                                {' '}
                                {client?.assetCount ?? '0'} {t('LAWYER_CLIENTS.ASSETS')}{' '}
                              </span>
                            ) : (
                              <span className="h2 ff-din-regular-2 mb-0">{t('LAWYER_CLIENTS.NO_ASSETS')}</span>
                            )}
                          </Col>
                          <LogoNameContainer>
                            <img
                              alt="Client Logo"
                              className="frontity-lazy-image img-thumbnail-client-single rounded-circle m-0 shadow-sm"
                              src={client && client.logo && client.logo !== ' ' ? client.logo : defaultImageSidemenu}
                            />
                            <TangiTypography weight="bold" type="subheading">
                              {client?.name || ''}
                            </TangiTypography>
                          </LogoNameContainer>
                        </Row>
                        <SubtitleContainer>
                          {client && client.assetCount && client.assetCount > 0 ? (
                            <TangiTypography weight="bold" type="subheading" color={PRIMARY_SHADES[600]}>
                              {t('LAWYER_CLIENTS.VIEW_ALL_ASSETS_OF', { CLIENT_NAME: client?.name || '' })}
                            </TangiTypography>
                          ) : (
                            <TangiTypography weight="bold" type="subheading" color={PRIMARY_SHADES[600]}>
                              {t('LAWYER_CLIENTS.CREATE_FIRST_ASSETS_WITH', { CLIENT_NAME: client?.name || '' })}
                            </TangiTypography>
                          )}
                        </SubtitleContainer>
                      </Card.Body>
                    </ClientCard>
                  </Col>
                ))
              : t('LAWYER_CLIENTS.NO_CLIENTS_AT_THE_MOMENT')}
          </Row>
        )}
      </Container>
    </>
  );
};

export default LawyerHomepage;
