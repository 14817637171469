import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { useSelector } from 'react-redux';
import i18next from 'i18next';

import { Container, Section, Headline, AccountDataContainer, ViewItemContainer, AccountDetails, AccountDataSection, ButtonsContainer } from './styles';
import { useViewEmployee } from './useViewEmployee';
import { getUrl } from '../CoursesTooltip/CourseItem/CourseItem';

import { customTableStyles } from '_components/ClientAssetsTable/style';
import { getColumns, tableTypes, getSettingRoleName, shouldAddComma } from './utils';
import { formatDate, getFormattedStringDate } from '../../utils/dateUtils';
import { adminsExceptLearn } from '../../utils/roles';
import { TangiAccountStatus, TangiButton } from '../TangiLibrary';
import { PeopleOptions } from '../PeopleOptions/PeopleOptions';
import successIcon from '../../assets/img/check-circle-solid.png';
import pendingIcon from '../../assets/img/exclamation-circle-solid.png';
import editIcon from '../../assets/icons/Edit-white.svg';

export const ViewEmployee = ({ userData, handleClose, setIsEditMode }) => {
  const { user, Role, permissions } = useSelector((state) => state.authentication);
  const { departments, courseProgression, agreements, role, assets, displayName, title, dateOfJoining, businessUnits, email, status, dateOfLeaving } = userData;
  const { ownedSortState, sharedSortState, handleSharedSort, handleOwnedSort, createdAssets, sharedAssets, settings, isUserLearnType, contributorsAssets } = useViewEmployee(userData);
  const isRegularAdmin = useMemo(() => adminsExceptLearn.includes(Role), [Role]);
  return (
    <Container>
      {(permissions.Employeeedit || permissions.Contractoredit || permissions.Learnemployeeedit) && (
        <ButtonsContainer>
          {user._id !== userData.user && <PeopleOptions accountId={userData.id} onFinish={handleClose} accountStatus={status} dropdownStyles={{ right: '110px' }} />}
          <TangiButton text={i18next.t('GENERAL.BUTTONS_TEXT.EDIT')} icon={editIcon} size="small" onClick={() => setIsEditMode(true)} />
        </ButtonsContainer>
      )}
      <Section>
        <AccountDataContainer>
          <div>{i18next.t('PEOPLE.VIEW_EMPLOYEE.ACCOUNT_DETAILS')}</div>
          <AccountDetails>
            <AccountDataSection>
              <span>
                {i18next.t('PEOPLE.VIEW_EMPLOYEE.ACCOUNT_DATA.NAME')}
                <b> {displayName}</b>
              </span>
              <span>
                {i18next.t('PEOPLE.VIEW_EMPLOYEE.ACCOUNT_DATA.EMAIL')}
                <b> {email}</b>
              </span>
              <span>
                {i18next.t('PEOPLE.VIEW_EMPLOYEE.ACCOUNT_DATA.TITLE')}
                <b> {title?.length ? title : '-'}</b>
              </span>
              <span>
                {i18next.t('PEOPLE.VIEW_EMPLOYEE.ACCOUNT_DATA.DEPARTMENTS')}
                <b> {departments?.length > 0 ? departments?.map((department, index) => `${shouldAddComma(departments.length, index) ? ', ' : ''}${department.name}`) : '-'}</b>
              </span>
              <span>
                {i18next.t('PEOPLE.VIEW_EMPLOYEE.ACCOUNT_DATA.BUSINESS_UNITS')}
                <b> {businessUnits?.length > 0 ? businessUnits?.map((unit, index) => `${shouldAddComma(businessUnits.length, index) ? ', ' : ''}${unit.name}`) : '-'}</b>
              </span>
            </AccountDataSection>
            <AccountDataSection>
              <span className="view-account-status">
                <div>{i18next.t('PEOPLE.VIEW_EMPLOYEE.ACCOUNT_DATA.STATUS')}</div>
                <TangiAccountStatus status={status} />
              </span>
              <span>
                {i18next.t('PEOPLE.VIEW_EMPLOYEE.ACCOUNT_DATA.ROLE')}
                <b> {role.name}</b>
              </span>
              <span>
                {i18next.t('PEOPLE.VIEW_EMPLOYEE.ACCOUNT_DATA.HIRE_DATE')} <b> {dateOfJoining ? formatDate(dateOfJoining) : '-'}</b>
              </span>
              <span data-testid="exit-date">
                {i18next.t('PEOPLE.VIEW_EMPLOYEE.ACCOUNT_DATA.EXIT_DATE')} <b> {dateOfLeaving ? formatDate(dateOfLeaving) : '-'}</b>
              </span>
            </AccountDataSection>
          </AccountDetails>
        </AccountDataContainer>
      </Section>
      {!isUserLearnType && (
        <>
          <Section>
            <Headline>{i18next.t('PEOPLE.VIEW_EMPLOYEE.HEADER.AGREEMENTS')}</Headline>
            <AgreementsSection agreements={agreements} settings={settings} role={role} />
          </Section>
          {isRegularAdmin && (
            <>
              <Section data-testid="assets-created-table">
                <Headline>{i18next.t('PEOPLE.VIEW_EMPLOYEE.HEADER.ASSETS_CREATED', { ASSET_LENGTH: assets.created.length })}</Headline>
                <DataTable
                  columns={getColumns(tableTypes.ASSETS_CREATED, ownedSortState)}
                  data={createdAssets}
                  onSort={handleOwnedSort}
                  sortIcon={<i className="" />}
                  customStyles={customTableStyles}
                />
              </Section>
              <Section data-testid="assets-contributors-table">
                <Headline>{i18next.t('PEOPLE.VIEW_EMPLOYEE.HEADER.ASSETS_CONTRIBUTED', { ASSET_LENGTH: assets.assetsContributors.length })}</Headline>
                <DataTable
                  columns={getColumns(tableTypes.ASSETS_CONTRIBUTORS, sharedSortState)}
                  data={contributorsAssets}
                  onSort={handleSharedSort}
                  sortIcon={<i className="" />}
                  customStyles={customTableStyles}
                />
              </Section>
              <Section data-testid="assets-shared-table">
                <Headline>{i18next.t('PEOPLE.VIEW_EMPLOYEE.HEADER.ASSETS_SHARED', { ASSET_SHARED_LENGTH: assets.sharedWith.length })}</Headline>
                <DataTable
                  columns={getColumns(tableTypes.ASSETS_SHARED, sharedSortState)}
                  data={sharedAssets}
                  onSort={handleSharedSort}
                  sortIcon={<i className="" />}
                  customStyles={customTableStyles}
                />
              </Section>
            </>
          )}
        </>
      )}
      <Section>
        <Headline>{i18next.t('PEOPLE.VIEW_EMPLOYEE.HEADER.TRAINING')}</Headline>
        {courseProgression?.map((course, index) => (
          <ViewItem key={index} imgUrl={course.featuredVideoUrl} title={course.title} status={course.progression} showStatus />
        ))}
      </Section>
    </Container>
  );
};

const ViewItem = ({ title, status, statusText, imgUrl, showStatus }) => {
  return (
    <ViewItemContainer>
      {imgUrl && <img height="42" src={getUrl(imgUrl)} alt="course-img" />}
      <div className="item-name">{title}</div>
      {showStatus && (
        <div className="item-status-data">
          {statusText}
          <img className="m-auto" width="16" height="16" alt="..." src={status ? successIcon : pendingIcon} />
        </div>
      )}
    </ViewItemContainer>
  );
};

const AgreementsSection = ({ agreements, settings, role }) => {
  const roleTypeName = getSettingRoleName(role.name);
  const agreementsData = agreements;
  switch (agreementsData.length) {
    case 2:
      return agreementsData.map((agreement) => {
        const statusText = agreement.signed ? i18next.t('PEOPLE.VIEW_EMPLOYEE.EXECUTED_ON', { SIGNED_DATE: getFormattedStringDate(agreement.signed_date) }) : i18next.t('PEOPLE.VIEW_EMPLOYEE.PENDING');
        // eslint-disable-next-line react/jsx-key
        return <ViewItem showStatus title={agreement.agreementType.name} statusText={statusText} status={agreement.signed} />;
      });
    case 1: {
      // --- first agreement --- //
      const agreement = agreementsData[0];
      const statusText = agreement.signed ? i18next.t('PEOPLE.VIEW_EMPLOYEE.EXECUTED_ON', { SIGNED_DATE: getFormattedStringDate(agreement.signed_date) }) : i18next.t('PEOPLE.VIEW_EMPLOYEE.PENDING');

      // --- second agreement --- //
      const settingsAgreements = settings?.agreements[roleTypeName].sort((a, b) => (a.type > b.type ? 1 : b.type > a.type ? -1 : 0));
      const agreementNotSigned = settingsAgreements.find((agreement) => agreement.id !== agreement.agreementType?._id);
      return (
        <>
          <ViewItem showStatus title={agreement.agreementType.name} statusText={statusText} status={true} />
          <ViewItem showStatus title={agreementNotSigned.name} statusText="pending" status={false} />
        </>
      );
    }
    case 0: {
      const settingsAgreements = settings?.agreements[getSettingRoleName(role.name)].sort((a, b) => (a.type > b.type ? 1 : b.type > a.type ? -1 : 0));
      return settingsAgreements.map((agreement, index) => {
        const statusText = 'pending';
        return <ViewItem key={index} showStatus title={agreement.name} statusText={statusText} status={false} />;
      });
    }
    default:
      return <div> - </div>;
  }
};
