import { useRef } from 'react';

const useTableScrollToTop = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  const scrollToTopOfTable = () => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return [containerRef, scrollToTopOfTable];
};

export default useTableScrollToTop;
