import styled from 'styled-components';

export const MFAContainer = styled.div`
  display: flex;
  width: 100%;
  font-size: 13px;
  font-family: 'DIN2014-Regular';
  height: 100%;
`;
export const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  padding: 16px 16px 8px 16px;
  .tangi-typography:nth-of-type(2) {
    width: 110%;
    margin-right: 32px;
  }
`;

export const ContactUs = styled.div`
  margin-top: 10px;
  color: #9b9b9b;
  text-align: center;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .error-alert-login {
    margin-top: auto;
  }
  .submit-button {
    margin-top: 8px;
  }
  gap: ${(props) => (props.showQR ? '0px' : '16px')};
`;

export const TextWithImageContainer = styled.div`
  display: flex;
  width: 120%;
  gap: 2px;
`;

export const QrStyling = { width: '115px', height: '115px', marginTop: '16px' };

export const ThirdStepContainer = styled.div`
  margin-top: 16px;
  width: 120%;
`;

export const OtpTitleContainer = styled.div`
  align-items: center;
  width: 110%;
  margin-top: 24px;
  padding-right: 16px;
  padding-bottom: 8px;
  text-align: center;
`;
