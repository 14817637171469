import { adminsExceptLearn, IRoles } from 'utils/roles';
import { BUTTON_VARIANTS, PRIMARY_SHADES, SECONDARY_SHADES, SUCCESS_SHADES } from 'utils/theme';
import { ButtonConfig, IpAuditQuickStartStyle } from './types';

const getStyleByRole = (isAdmin: boolean, completedStepsPercentage: number): IpAuditQuickStartStyle => {
  let colors = null;

  if (!isAdmin) {
    // Non-admin user
    colors = {
      backgroundColor: SECONDARY_SHADES[100],
      icon: 'activateWithGradientPurple',
      borderColor: SECONDARY_SHADES[300],
    };
  } else if (completedStepsPercentage === 100) {
    // Admin user with completed
    colors = {
      backgroundColor: SUCCESS_SHADES[50],
      icon: 'activateWithGradientGreen',
      borderColor: SUCCESS_SHADES[200],
    };
  } else {
    // Admin user without completed
    colors = {
      backgroundColor: PRIMARY_SHADES[200],
      icon: 'activateWithGradient',
      borderColor: PRIMARY_SHADES[300],
    };
  }

  return colors as IpAuditQuickStartStyle;
};

const getButtonConfig = (isAdmin: boolean, completedStepsPercentage: number): ButtonConfig => {
  const compliedSteps = completedStepsPercentage === 100;
  let buttonConfig = null;
  if (!isAdmin) {
    // Non-admin user
    buttonConfig = {
      text: 'IP_AUDIT.CONTACT_US',
      variant: BUTTON_VARIANTS.PRIMARY_PURPLE,
      icon: '',
      endIcon: false,
    };
  } else if (compliedSteps) {
    // Admin user with completed
    buttonConfig = {
      text: 'IP_AUDIT.BUTTON_TEXT.SEE_INFO',
      variant: BUTTON_VARIANTS.OUTLINE_DARK,
      icon: '',
      endIcon: false,
    };
  } else if (isAdmin && !compliedSteps) {
    // Admin user who has not started the audit process
    buttonConfig = {
      text: 'IP_AUDIT.IP_AUDIT_STEPS.OVERVIEW.COMPLETE_FLOW_BTN',
      variant: BUTTON_VARIANTS.PRIMARY,
      icon: 'arrowRight',
      endIcon: true,
    };
  } else {
    // Admin user without completed
    buttonConfig = {
      text: 'IP_AUDIT.BUTTON_TEXT.START_NOW',
      variant: BUTTON_VARIANTS.PRIMARY,
      icon: 'arrowRight',
      endIcon: true,
    };
  }

  return buttonConfig;
};

const isAdminCheck = (role: IRoles): boolean => {
  return adminsExceptLearn.includes(role);
};

const quickStartAuditUtil = {
  getStyleByRole,
  isAdminCheck,
  getButtonConfig,
};

export default quickStartAuditUtil;
