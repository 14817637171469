import React from 'react';
import { Modal } from 'react-bootstrap';
import './SessionIsOverModal.scss';
import { ModalButtonWrapper } from './style';
import { TangiButton } from '_components/TangiLibrary';

function SessionIsOverModal({ open, handleClose, modalBody }) {
  return (
    <Modal className="disclaimer-wrapper" size="sm" centered show={open} backdrop="static" dialogClassName="modal-fit-cont">
      <Modal.Header>
        <h5 className="modal-title">
          <span className="h2 font-weight-normal text-dark mb-4">Session ended</span>
        </h5>
      </Modal.Header>
      <div className="modal-body" id="session-over-body">
        <div className="disclaimer-text">{modalBody}</div>
        <ModalButtonWrapper>
          <TangiButton size="small" text="OK" onClick={handleClose} />
        </ModalButtonWrapper>
      </div>
    </Modal>
  );
}

export { SessionIsOverModal };
