import { useState, useEffect, useRef } from 'react';
import { Form, Button, Spinner, Modal } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import Dropzone from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';

import { TangiButton, TangiIconButton, TangiAlert, TangiTypography } from '../TangiLibrary';
import { Star } from '../Star';
import AssetItem from './AssetItem';
import { useCreateEditAsset, acceptedFilesList, isValidFileName, getOptions, transformToApiData } from './useCreateEditAsset';
import { BUTTON_VARIANTS } from '../../utils/componentUtils';
import { RESULT_STATUS } from '../../utils/enums';
import { getFileExtensionCard, icons } from '../../utils/fileUtils';
import { formatLongFileName } from 'utils/fileNameUtils';
import { MAX_FILE_SIZE, LARGE_FILE_CODE_ERROR, ONE_MB } from '_constants/fileConstants';
import { Container, FormContainer, FooterContainer, TitleContainer, DuoContainer, SubmitContainer, ModalHeader, ModalBody, ModalFooter } from './styles';
import './CreateEditAsset.scss';
import { assetMetaDataEnums } from '_constants';
import BreadcrumbProgress from './BreadcrumbProgress';

export const CreateEditAsset = ({ handleCloseAsset, asset, isEditMode = false, onCreate, onEdit, filesToAttach = [], isPatentMode = false }) => {
  const {
    metaDataLoading,
    setValue,
    setError,
    clearErrors,
    register,
    handleSubmit,
    getValues,
    errors,
    handleCreateOption,
    control,
    checkPermissions,
    contributors,
    fullReferenceId,
    permissions,
    tags,
    departments,
    projects,
    assetTypes,
    businessValues,
    createAsset,
    updateAsset,
    assetLoading,
    editFiles,
    setEditFiles,
    editUrls,
    setEditUrls,
    files,
    setFiles,
    urls,
    setUrls,
    createRefLoading,
    statusMessage,
    createUpdateAssetLoading,
    creatableLoading,
    invitedMultipleAccounts,
    importanceFields,
    businessRelated,
    commercialProduct,
    settings,
  } = useCreateEditAsset(isEditMode, asset, handleCloseAsset, onCreate, onEdit, isPatentMode);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [AssetToDelete, setAssetToDelete] = useState('');
  const [currentPage, setcurrentPage] = useState(1);

  const modalContentRef = useRef(null);

  const { t } = useTranslation();

  // ----------- Form Handlers ---------- //

  const handleAddURL = () => {
    clearErrors('urlLink');
    const { urlLink, urlLabel } = getValues();
    if (urlLink && urlLabel) {
      setUrls((prevValue) => [...prevValue, { label: urlLabel, url: urlLink }]);
      setValue('urlLink', '');
      setValue('urlLabel', '');
    } else {
      setError('urlLink', { type: 'custom', message: t('ASSET_PAGE.CREATE_EDIT_ASSET.ERROR_MESSAGE.LOCATION_AND_LABEL_REQUIRED') });
    }
  };

  // handler in case somone moves a page without adding the url as file
  useEffect(() => {
    if (currentPage === 2) {
      const { urlLink, urlLabel } = getValues();
      if (urlLink && urlLabel) {
        handleAddURL();
      }
    }
  }, [currentPage]);

  useEffect(() => {
    if (modalContentRef.current) {
      modalContentRef.current.scrollTop = 0;
    }
  }, [currentPage]);

  const onSubmit = (data) => {
    // if (typeof data.isPublished === 'undefined') {
    //   data.isPublished = false;
    // }TODO: Uncomment if we want this field to be relevant

    if (currentPage === 1) {
      if (getValues().urlLink !== '' && getValues().urlLabel === '') {
        setError('urlLabel', { type: 'custom', message: t('ASSET_PAGE.CREATE_EDIT_ASSET.ERROR_MESSAGE.FILE_LOCATION_LINK_EXISTS') });
        return;
      }
      if (getValues().urlLink === '' && getValues().urlLabel !== '') {
        setError('urlLink', { type: 'custom', message: t('ASSET_PAGE.CREATE_EDIT_ASSET.ERROR_MESSAGE.FILE_LABEL_EXISTS') });
        return;
      }
      setcurrentPage((prev) => prev + 1);
      return;
    }

    const transformedData = transformToApiData(data);

    if (isEditMode) {
      if (!data.refNumber) {
        transformedData.refNumber = getValues().refNumber;
      }
      if (!data.description) {
        transformedData.description = '';
      }
      transformedData.files = [...files, ...editFiles];
      transformedData.urls = [...urls, ...editUrls];
      updateAsset(transformedData);
    } else {
      transformedData.urls = urls;
      transformedData.files = files;
      createAsset(transformedData);
    }
  };

  // ----------- Delete File & Asset Modal Handlers ---------- //
  const DeleteConfirmationModalBody = () => {
    let fileName;
    let className;
    if (AssetToDelete.type === 'File') {
      fileName = editFiles.find((item) => item._id === AssetToDelete.id)?.name;
      className = icons[getFileExtensionCard(fileName)];
    } else {
      fileName = editUrls.find((item) => item._id === AssetToDelete.id)?.label;
      className = `${icons.link} text-primary`;
    }
    return (
      <div className="d-flex justify-content-center content-center flex-column p-5 gap-1">
        <div style={{ fontSize: '50px' }} className={className} />
        <div className="fs-5">{t('ASSET_PAGE.MODAL.ARE_YOU_SURE')}</div>
        <div className="ff-din-regular-2">
          {t('ASSET_PAGE.MODAL.DELETE_FILE')} {fileName}
        </div>
      </div>
    );
  };

  const removeCurrentAsset = (assetName, assetType) => {
    if (assetType === 'File') {
      setFiles((prevState) => prevState.filter((item) => item.name !== assetName));
    } else {
      setUrls((prevState) => prevState.filter((item) => item.label !== assetName));
    }
  };

  const removeAssetToDeleteFunc = (id, deleteAsset) => {
    setAssetToDelete({ type: deleteAsset, id });
    setShowDeleteModal(true);
  };

  const handleCloseModal = () => {
    setAssetToDelete('');
    setShowDeleteModal(false);
  };

  const handleEditAssetToDelete = () => {
    if (AssetToDelete.type === 'File') {
      const updatedEdit = editFiles.filter((item) => item._id !== AssetToDelete.id);
      if (updatedEdit && updatedEdit.length === 0) {
        setValue('files', files, { shouldValidate: true });
      }
      setEditFiles(updatedEdit);
    } else {
      const updatedEdit = editUrls.filter((item) => item._id !== AssetToDelete.id);
      if (updatedEdit && updatedEdit.length === 0) {
        setValue('urls', urls, { shouldValidate: true });
      }
      setEditUrls(updatedEdit);
    }
    setShowDeleteModal(false);
  };

  const titlePerPage = () => {
    return (
      <div style={{ marginBottom: '23px' }}>
        <TangiTypography type="heading-md" weight="semibold">
          {currentPage === 1 ? t('ASSET_PAGE.CREATE_EDIT_ASSET.TITLE.FILL_DETAILS') : t('ASSET_PAGE.CREATE_EDIT_ASSET.TITLE.METADATA')}
        </TangiTypography>
      </div>
    );
  };

  return (
    <Container data-testid="create-edit-asset">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>
          <TitleContainer>
            <TangiTypography type="heading-lg">
              {isEditMode ? `${t('ASSET_PAGE.CREATE_EDIT_ASSET.TITLE.EDIT')} ${asset?.name ?? '-'}` : t('ASSET_PAGE.CREATE_EDIT_ASSET.TITLE.CREATE_ASSET')}
            </TangiTypography>
            <Tippy content={<div className="font-bold text-xs">{t('ASSET_PAGE.CREATE_EDIT_ASSET.TOOLTIP.CLOSE_ASSET')}</div>}>
              <span>
                <TangiIconButton icon="close" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={handleCloseAsset} />
              </span>
            </Tippy>
          </TitleContainer>
          <BreadcrumbProgress currentPage={currentPage} />
        </ModalHeader>
        <ModalBody ref={modalContentRef}>
          {currentPage === 1 ? (
            <fieldset disabled={isEditMode && (assetLoading || metaDataLoading)}>
              {titlePerPage()}

              <FormContainer>
                <Form.Group controlId="assetTitle">
                  <Form.Label>{t('ASSET_PAGE.CREATE_EDIT_ASSET.LABEL.TITLE')}</Form.Label>
                  <Star />
                  <Form.Control
                    className="ff-din-DemiBold"
                    type="text"
                    placeholder={t('ASSET_PAGE.CREATE_EDIT_ASSET.PLACEHOLDERS.ENTER_TITLE')}
                    name="name"
                    {...register('name', { required: { value: true, message: t('ASSET_PAGE.CREATE_EDIT_ASSET.ERROR_MESSAGE.TITLE_FIELD_REQUIRED') } })}
                  />
                  <div className="d-block invalid-feedback">{errors.name && errors.name.message}</div>
                </Form.Group>
                <Form.Group controlId="assetDescription">
                  <Form.Label>{t('ASSET_PAGE.CREATE_EDIT_ASSET.LABEL.DESCRIPTION')}</Form.Label>
                  <Form.Control
                    className="ff-din-DemiBold"
                    type="text"
                    placeholder={t('ASSET_PAGE.CREATE_EDIT_ASSET.PLACEHOLDERS.ENTER_DESCRIPTION')}
                    name="description"
                    {...register('description')}
                  />
                  <div className="d-block invalid-feedback">{errors.description && errors.description.message}</div>
                </Form.Group>
                <Form.Group controlId="assetAuthor">
                  <Form.Label>{t('ASSET_PAGE.CREATE_EDIT_ASSET.LABEL.CONTRIBUTORS')}</Form.Label>
                  <Star />
                  <Controller
                    name="contributor"
                    render={({ field }) => {
                      const isInvitedMultipleAccounts = !!invitedMultipleAccounts?.length;
                      return (
                        <Select
                          classNamePrefix="contributors-list"
                          className="ff-din-DemiBold"
                          isMulti
                          {...field}
                          options={
                            isInvitedMultipleAccounts
                              ? getOptions(contributors, '_id', 'displayName')
                                  .filter((option) => !invitedMultipleAccounts.includes(option))
                                  .map((option) => ({ value: option.value, label: option.label }))
                              : getOptions(contributors, '_id', 'displayName')
                          }
                          defaultValue={
                            isInvitedMultipleAccounts
                              ? invitedMultipleAccounts.map((contributor) => ({
                                  value: contributor.id,
                                  label: contributor?.displayName || contributor?.user?.fullName || contributor?.user?.email || '',
                                }))
                              : undefined
                          }
                        />
                      );
                    }}
                    control={control}
                    rules={{
                      validate: (value) => (value && value.length > 0) || invitedMultipleAccounts.length > 0,
                      message: t('ASSET_PAGE.CREATE_EDIT_ASSET.ERROR_MESSAGE.CONTRIBUTORS_FIELD_IS_REQUIRED'),
                    }}
                    defaultValue={invitedMultipleAccounts.length > 0 ? invitedMultipleAccounts.map((contributor) => ({ value: contributor.id, label: contributor.fullName })) : undefined}
                  />

                  <div className="d-block invalid-feedback">{errors.contributor && errors.contributor.message}</div>
                </Form.Group>
                {isEditMode && (
                  <Form.Group controlId="existingFiles">
                    <section>
                      <ul className="p-0">
                        {editFiles?.length > 0 &&
                          editFiles.map((acceptedFile, index) => <AssetItem key={index} action="edit" asset={acceptedFile} assetType="File" removeAssetFunc={removeAssetToDeleteFunc} />)}
                      </ul>
                    </section>
                  </Form.Group>
                )}
                <FilesDropZone
                  files={files}
                  setFiles={setFiles}
                  control={control}
                  removeCurrentAsset={removeCurrentAsset}
                  checkPermissions={checkPermissions}
                  errors={errors}
                  filesToAttach={filesToAttach}
                />
                {isEditMode && (
                  <Form.Group controlId="existingUrls">
                    <section>
                      <ul className="p-0">
                        {editUrls &&
                          editUrls.length > 0 &&
                          editUrls.map((acceptedUrl, index) => <AssetItem key={index} action="edit" asset={acceptedUrl} assetType="Url" removeAssetFunc={removeAssetToDeleteFunc} />)}
                      </ul>
                    </section>
                  </Form.Group>
                )}
                {urls?.length > 0 && (
                  <section>
                    <ul className="p-0">
                      {urls.map((acceptedUrl) => (
                        <div key={uuidv4}>
                          <AssetItem action="create" asset={acceptedUrl} assetType="Url" removeAssetFunc={removeCurrentAsset} />
                        </div>
                      ))}
                    </ul>
                  </section>
                )}
                <div className="url-container">
                  <div className="add-url-fields">
                    <Form.Group controlId="urlLink" className="url-field">
                      <Form.Label>{t('ASSET_PAGE.CREATE_EDIT_ASSET.LABEL.FILE_LOCATION')}</Form.Label>
                      <Form.Control
                        className="ff-din-DemiBold"
                        type="text"
                        placeholder={t('ASSET_PAGE.CREATE_EDIT_ASSET.PLACEHOLDERS.ENTER_FILE_LOCATION')}
                        name="urlLink"
                        {...register('urlLink', { required: { value: !!getValues().urlLabel, message: t('ASSET_PAGE.CREATE_EDIT_ASSET.ERROR_MESSAGE.FILE_LABEL_EXISTS') } })}
                      />
                      {errors.urlLink && <div className="d-block invalid-feedback">{errors.urlLink && errors.urlLink.message}</div>}
                    </Form.Group>
                    <Form.Group controlId="urlLabel" className="url-field">
                      <Form.Label>{t('ASSET_PAGE.CREATE_EDIT_ASSET.LABEL.FILE_LABEL')}</Form.Label>

                      <Form.Control
                        className="ff-din-DemiBold"
                        type="text"
                        placeholder={t('ASSET_PAGE.CREATE_EDIT_ASSET.PLACEHOLDERS.ENTER_FILE_LABEL')}
                        name="urlLabel"
                        {...register('urlLabel', { required: { value: getValues().urlLink, message: t('ASSET_PAGE.CREATE_EDIT_ASSET.ERROR_MESSAGE.FILE_LOCATION_LINK_EXISTS') } })}
                      />
                      {errors.urlLabel && <div className="d-block invalid-feedback">{errors.urlLabel && errors.urlLabel.message}</div>}
                    </Form.Group>
                  </div>
                  <div className="add-url-button">
                    <Button type="button" onClick={handleAddURL} className="btn fw-lighter add-btn shadow-none btn btn-secondary ff-din-regular add-url-button">
                      <i aria-hidden="true" className="fas fa-plus-circle mr-2" /> {t('GENERAL.BUTTONS_TEXT.ADD')}
                    </Button>
                  </div>
                </div>
                <div className="ref-container">
                  <Form.Group controlId="assetsPrefix" className="ref-field">
                    <Form.Label>{t('ASSET_PAGE.CREATE_EDIT_ASSET.LABEL.ASSETS_PREFIX')}</Form.Label>
                    <Form.Control
                      disabled
                      className="ff-din-DemiBold"
                      type="text"
                      placeholder={fullReferenceId.assetsPrefix ?? 'xxxxxx'}
                      name="assetsPrefix"
                      {...register('client.assetsPrefix', {})}
                    />
                  </Form.Group>
                  <Form.Group controlId="refNumber" className="ref-field">
                    <Form.Label>{t('ASSET_PAGE.CREATE_EDIT_ASSET.LABEL.REFERENCE_NUMBER')}</Form.Label>
                    <Star />
                    <Form.Control
                      className="ff-din-DemiBold"
                      type="text"
                      maxLength="6"
                      placeholder={t('ASSET_PAGE.CREATE_EDIT_ASSET.PLACEHOLDERS.EDIT_REFERENCE_NUMBER')}
                      disabled={isEditMode ? false : !fullReferenceId.refNumber}
                      name="refNumber"
                      {...register('refNumber', {
                        maxLength: { value: 6, message: t('ASSET_PAGE.CREATE_EDIT_ASSET.ERROR_MESSAGE.REFERENCE_NUMBER_MUST_BE_EXACTLY') },
                        minLength: { value: 6, message: t('ASSET_PAGE.CREATE_EDIT_ASSET.ERROR_MESSAGE.REFERENCE_NUMBER_MUST_BE_EXACTLY') },
                        required: { value: true, message: t('ASSET_PAGE.CREATE_EDIT_ASSET.ERROR_MESSAGE.REFERENCE_NUMBER_IS_REQUIRED') },
                        pattern: {
                          value: /^[0-9]*$/,
                          message: t('ASSET_PAGE.CREATE_EDIT_ASSET.ERROR_MESSAGE.ONLY_DIGITS'),
                        },
                      })}
                    />
                    <div className="d-block invalid-feedback">{errors.refNumber && errors.refNumber.message}</div>
                  </Form.Group>
                </div>
              </FormContainer>
            </fieldset>
          ) : (
            <>
              {titlePerPage()}
              <FormContainer>
                <Form.Group controlId="assetType">
                  <Form.Label>{t('ASSET_PAGE.CREATE_EDIT_ASSET.LABEL.SELECT_ASSET_TYPE')}</Form.Label>
                  <Star />
                  <Controller
                    name="assetType"
                    control={control}
                    rules={{ required: { value: true, message: t('ASSET_PAGE.CREATE_EDIT_ASSET.ERROR_MESSAGE.TYPE_ASSET_FIELD_IS_REQUIRED') } }}
                    render={({ field }) =>
                      // same permission as for tag creation
                      checkPermissions('SET_ASSET_TYPE') ? (
                        <CreatableSelect
                          isLoading={creatableLoading}
                          className="ff-din-DemiBold"
                          {...field}
                          options={getOptions(assetTypes, 'id', 'formattedName')}
                          onCreateOption={(value) => handleCreateOption(value, 'assetType')}
                        />
                      ) : (
                        <Select
                          className="ff-din-DemiBold"
                          {...field}
                          options={checkPermissions('SET_ASSET_TYPE') ? getOptions(assetTypes.filter((data) => data.name.toLowerCase() === 'unclassified')) : getOptions(assetTypes)}
                        />
                      )
                    }
                  />
                  <div className="d-block invalid-feedback">{errors.assetType && errors.assetType.message}</div>
                </Form.Group>
                <Form.Group controlId="assetTags">
                  <Form.Label>{t('ASSET_PAGE.CREATE_EDIT_ASSET.LABEL.TAGS')}</Form.Label>
                  <Star />
                  <Controller
                    name="tags"
                    render={({ field }) =>
                      permissions.Tagcreate ? (
                        <CreatableSelect
                          isLoading={creatableLoading}
                          className="ff-din-DemiBold"
                          isMulti
                          {...field}
                          options={getOptions(tags)}
                          onCreateOption={(value) => handleCreateOption(value, 'tag')}
                        />
                      ) : (
                        <Controller name="tags" render={({ field }) => <Select className="ff-din-DemiBold" isMulti {...field} options={getOptions(tags)} />} control={control} />
                      )
                    }
                    control={control}
                    rules={{ required: { value: true, message: t('ASSET_PAGE.CREATE_EDIT_ASSET.ERROR_MESSAGE.TAGS_FIELD_IS_REQUIRED') } }}
                  />
                  <div className="d-block invalid-feedback">{errors.tag && errors.tag.message}</div>
                </Form.Group>
                <Form.Group controlId="importance">
                  <Form.Label>{t('ASSET_PAGE.CREATE_EDIT_ASSET.LABEL.IMPORTANCE')}</Form.Label>
                  <Controller
                    name="importance"
                    render={({ field }) => (
                      <Select
                        isLoading={creatableLoading}
                        className="ff-din-DemiBold"
                        {...field}
                        options={importanceFields}
                        isDisabled={!settings?.importanceFields || settings?.importanceFields?.length === 0}
                      />
                    )}
                    control={control}
                  />
                </Form.Group>
                <DuoContainer>
                  <Form.Group controlId="departments">
                    <Form.Label>{t('ASSET_PAGE.CREATE_EDIT_ASSET.LABEL.DEPARTMENTS')}</Form.Label>
                    <Controller
                      name="departments"
                      render={({ field }) =>
                        permissions.Projectcreate ? (
                          <CreatableSelect
                            isLoading={creatableLoading}
                            onCreateOption={(value) => handleCreateOption(value, 'department')}
                            className="ff-din-DemiBold"
                            isMulti
                            {...field}
                            options={getOptions(departments)}
                          />
                        ) : (
                          <Controller name="departments" render={({ field }) => <Select className="ff-din-DemiBold" isMulti {...field} options={getOptions(departments)} />} control={control} />
                        )
                      }
                      control={control}
                      defaultValue={invitedMultipleAccounts.length > 0 ? invitedMultipleAccounts.map((contributor) => ({ value: contributor.id, label: contributor.fullName })) : undefined}
                    />
                    <div className="d-block invalid-feedback">{errors.departments && errors.departments.message}</div>
                  </Form.Group>
                  <Form.Group controlId="projects">
                    <Form.Label>{t('ASSET_PAGE.CREATE_EDIT_ASSET.LABEL.PROJECTS')}</Form.Label>
                    <Controller
                      name="projects"
                      render={({ field }) =>
                        permissions.Projectcreate ? (
                          <CreatableSelect
                            isLoading={creatableLoading}
                            onCreateOption={(value) => handleCreateOption(value, 'project')}
                            className="ff-din-DemiBold"
                            isMulti
                            {...field}
                            options={getOptions(projects)}
                          />
                        ) : (
                          <Controller name="projects" render={({ field }) => <Select className="ff-din-DemiBold" isMulti {...field} options={getOptions(projects)} />} control={control} />
                        )
                      }
                      control={control}
                    />
                    <div className="d-block invalid-feedback">{errors.projects && errors.projects.message}</div>
                  </Form.Group>
                </DuoContainer>
                <DuoContainer>
                  <Form.Group controlId="others1">
                    <Form.Label>{t('ASSET_PAGE.CREATE_EDIT_ASSET.LABEL.OTHERS_1')}</Form.Label>
                    <Form.Control
                      className="ff-din-DemiBold"
                      type="text"
                      placeholder={t('ASSET_PAGE.CREATE_EDIT_ASSET.PLACEHOLDERS.ENTER_ADDITIONAL_INFORMATION')}
                      name="others1"
                      {...register('others1')}
                    />
                    <div className="d-block invalid-feedback">{errors.others1 && errors.others1.message}</div>
                  </Form.Group>
                  <Form.Group controlId="others2">
                    <Form.Label>{t('ASSET_PAGE.CREATE_EDIT_ASSET.LABEL.OTHERS_2')}</Form.Label>
                    <Form.Control
                      className="ff-din-DemiBold"
                      type="text"
                      placeholder={t('ASSET_PAGE.CREATE_EDIT_ASSET.PLACEHOLDERS.ENTER_ADDITIONAL_INFORMATION')}
                      name="others2"
                      {...register('others2')}
                    />
                    <div className="d-block invalid-feedback">{errors.others2 && errors.others2.message}</div>
                  </Form.Group>
                </DuoContainer>
                <Form.Group controlId="businessValue">
                  <Form.Label>{t('ASSET_PAGE.CREATE_EDIT_ASSET.LABEL.BUSINESS_VALUE')}</Form.Label>
                  <Controller
                    name="businessValues"
                    render={({ field }) =>
                      permissions.Projectcreate ? (
                        <CreatableSelect
                          isLoading={creatableLoading}
                          className="ff-din-DemiBold"
                          isMulti
                          {...field}
                          options={getOptions(businessValues)}
                          onCreateOption={(value) => handleCreateOption(value, 'businessValue')}
                        />
                      ) : (
                        <Controller name="businessValues" render={({ field }) => <Select className="ff-din-DemiBold" isMulti {...field} options={getOptions(businessValues)} />} control={control} />
                      )
                    }
                    control={control}
                  />
                  <div className="d-block invalid-feedback">{errors.businessValues && errors.businessValues.message}</div>
                </Form.Group>
                <Form.Group controlId={assetMetaDataEnums.businessRelated}>
                  <Form.Label>{t('ASSET_PAGE.CREATE_EDIT_ASSET.LABEL.BUSINESS_RELATED')}</Form.Label>
                  <Controller
                    name={assetMetaDataEnums.businessRelated}
                    render={({ field }) => (
                      <CreatableSelect
                        isLoading={creatableLoading}
                        className="ff-din-DemiBold"
                        {...field}
                        options={businessRelated ? getOptions(businessRelated, '_id', 'value') : []}
                        onCreateOption={(value) => handleCreateOption(value, assetMetaDataEnums.businessRelated)}
                      />
                    )}
                    control={control}
                  />
                </Form.Group>
                <Form.Group controlId={assetMetaDataEnums.commercialProduct}>
                  <Form.Label>{t('ASSET_PAGE.CREATE_EDIT_ASSET.LABEL.COMMERCIAL_PRODUCT')}</Form.Label>
                  <Controller
                    name={assetMetaDataEnums.commercialProduct}
                    render={({ field }) => (
                      <CreatableSelect
                        isLoading={creatableLoading}
                        className="ff-din-DemiBold"
                        {...field}
                        options={commercialProduct ? getOptions(commercialProduct, '_id', 'value') : []}
                        onCreateOption={(value) => handleCreateOption(value, assetMetaDataEnums.commercialProduct)}
                      />
                    )}
                    control={control}
                  />
                </Form.Group>
                {/* <Form.Group controlId="isPublished">
                  <TangiCheckbox label={t('ASSET_PAGE.CREATE_EDIT_ASSET.LABEL.PUBLISHED')} register={register} name="isPublished" />
                </Form.Group> */
                /*TODO: Uncomment if we want this field to be relevant */}
              </FormContainer>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {/* Footer */}
          <FooterContainer>
            {currentPage !== 1 && (
              <div>
                <TangiButton variant={BUTTON_VARIANTS.TERTIARY} text={t('GENERAL.BUTTONS_TEXT.BACK')} onClick={() => setcurrentPage((prev) => prev - 1)} />
              </div>
            )}
            {/* Dont show button if success status */}
            {statusMessage?.color !== '#008000' && (
              <SubmitContainer currentPage={currentPage}>
                {assetLoading || metaDataLoading || createUpdateAssetLoading || createRefLoading || creatableLoading ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <TangiButton
                    text={currentPage === 1 ? t('GENERAL.BUTTONS_TEXT.CONTINUE') : t('GENERAL.BUTTONS_TEXT.COMPLETE')}
                    type="submit"
                    data-testId={currentPage === 1 ? t('GENERAL.BUTTONS_TEXT.CONTINUE') : t('GENERAL.BUTTONS_TEXT.COMPLETE')}
                  />
                )}
              </SubmitContainer>
            )}
          </FooterContainer>
          {statusMessage && (
            <div style={{ backgroundColor: statusMessage.color }} className="alert fade show rounded py-2 text-white text-center">
              <span style={{ fontSize: '13px' }} className="mx-3">
                {statusMessage.message}
              </span>
            </div>
          )}
        </ModalFooter>
        {/* modals */}
        <div className="delete-confirm">
          <Modal show={showDeleteModal} onHide={handleCloseAsset} className="delete-confirm">
            <Modal.Body>
              <DeleteConfirmationModalBody name="test.jpg" />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" className="asset-delete-cancel" onClick={handleCloseModal}>
                {t('GENERAL.BUTTONS_TEXT.CANCEL')}
              </Button>
              <Button className="asset-delete-confirm" onClick={handleEditAssetToDelete}>
                {t('GENERAL.BUTTONS_TEXT.DELETE')}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Form>
    </Container>
  );
};

const FilesDropZone = ({ files, setFiles, removeCurrentAsset, control, checkPermissions, errors, filesToAttach }) => {
  const [inValidFileName, setInValidFileName] = useState(false);
  const [alertProps, setAlertProps] = useState({
    show: false,
    text: '',
    type: RESULT_STATUS.BLANK,
  });
  const { t } = useTranslation();

  const resetAlertProps = () => {
    setAlertProps({ show: false, text: '', type: RESULT_STATUS.BLANK });
  };

  /**
   * Check if the name of accepted files is valid, and update state accordingly.
   *
   * @param {Array<File>} acceptedFiles - An array of File objects representing the accepted files.
   * @returns {void} - This function does not return a value.
   */
  const checkIsValidFileName = (acceptedFiles) => {
    if (isValidFileName(acceptedFiles[0].name)) {
      const mergeResult = [...files, ...acceptedFiles];
      setFiles(mergeResult);
    } else {
      setInValidFileName(true);
    }
  };

  /**
   * Check the size of rejected files and display an alert if a file is too large.
   *
   * @param {Array<Object>} fileRejections - An array of objects representing files that were rejected.
   * @returns {void} - This function does not return a value.
   */
  const checkFileSize = (fileRejections) => {
    const MAX_REJECTED_FILENAME_LENGTH = 97;
    if (fileRejections[0]?.errors[0]?.code === LARGE_FILE_CODE_ERROR) {
      const rejectedFileName = formatLongFileName(fileRejections[0].file.name, MAX_REJECTED_FILENAME_LENGTH);
      const maxAcceptedFileSize = MAX_FILE_SIZE / ONE_MB;
      setAlertProps({ show: true, text: t('GENERAL.UPLOAD_FILES_MODAL.FILE_IS_TOO_LARGE', { FILE_NAME: rejectedFileName, FILE_SIZE: maxAcceptedFileSize }), type: RESULT_STATUS.ERROR });
    }
  };

  /**
   * Handle the event when files are dropped or selected, along with any rejected files.
   *
   * @param {Array<File>} acceptedFiles - An array of File objects representing the dropped or selected files.
   * @param {Array<Object>} fileRejections - An array of objects representing files that were rejected.
   * @returns {void} - This function does not return a value.
   */
  const onDrop = (acceptedFiles, fileRejections) => {
    setInValidFileName(false);
    resetAlertProps();
    acceptedFiles?.length && checkIsValidFileName(acceptedFiles);
    fileRejections?.length && checkFileSize(fileRejections);
  };

  useEffect(() => {
    if (filesToAttach?.length) {
      const existingFileNames = new Set(files.map((file) => file.name));
      const newFilesArray = filesToAttach.filter((file) => !existingFileNames.has(file.name));
      if (newFilesArray.length) {
        onDrop(newFilesArray);
      }
    }
  }, [filesToAttach]);

  return (
    <Form.Group controlId="assetFiles">
      {files && files.length > 0 && (
        <section>
          <ul className="p-0">
            {files.map((acceptedFile) => (
              <div key={uuidv4}>
                <AssetItem action="create" asset={acceptedFile} assetType="File" removeAssetFunc={removeCurrentAsset} />
              </div>
            ))}
          </ul>
        </section>
      )}
      <Form.Label>{t('ASSET_PAGE.CREATE_EDIT_ASSET.LABEL.ADD_FILES')}</Form.Label>
      <Controller
        render={({ field }) => (
          <Dropzone multiple {...field} accept={acceptedFilesList} disabled={!checkPermissions('UPLOAD_FILES')} onDrop={onDrop} maxSize={MAX_FILE_SIZE}>
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
              <div>
                {!isDragActive && (
                  <div
                    {...getRootProps()}
                    className="container w-100 bg-secondary p-5 text-center rounded text-muted mt-0 dropZone"
                    style={{ cursor: !checkPermissions('UPLOAD_FILES') ? 'no-drop' : 'pointer' }}
                  >
                    <input {...getInputProps()} />
                    <span>
                      <i aria-hidden="true" className="fas fa-upload me-2" />
                      {t('ASSET_PAGE.CREATE_EDIT_ASSET.ADD_FILES.DRAG_AND_DROP')}
                    </span>
                  </div>
                )}
                {isDragActive && !isDragReject && (
                  <div
                    {...getRootProps()}
                    className="container w-100 bg-secondary p-5 text-center rounded text-muted dropZone"
                    style={{ cursor: !checkPermissions('UPLOAD_FILES') ? 'no-drop' : 'pointer' }}
                  >
                    <input {...getInputProps()} />
                    <span>
                      <i aria-hidden="true" className="fas fa-upload me-2" />
                      {t('ASSET_PAGE.CREATE_EDIT_ASSET.ADD_FILES.DROP_FILES')}
                    </span>
                  </div>
                )}
                {isDragActive && isDragReject && (
                  <div
                    {...getRootProps()}
                    className="container w-100 bg-danger p-5 text-center rounded text-white dropZone"
                    style={{ cursor: !checkPermissions('UPLOAD_FILES') ? 'no-drop' : 'pointer' }}
                  >
                    <input {...getInputProps()} />
                    <p style={{ margin: 0 }}>{t('ASSET_PAGE.CREATE_EDIT_ASSET.ADD_FILES.REJECT_PLEASE_UPLOAD_ONLY_EXCEPTED')}</p>
                  </div>
                )}
              </div>
            )}
          </Dropzone>
        )}
        name="files"
        control={control}
      />
      <p className="text-muted fs-7">{t('ASSET_PAGE.CREATE_EDIT_ASSET.ADD_FILES.UPLOAD_ONLY_ACCEPTED_FORMATS')}</p>
      {inValidFileName && <p className="d-block invalid-feedback">{t('ASSET_PAGE.CREATE_EDIT_ASSET.ADD_FILES.FILE_CANNOT_CONTAIN_SPECIAL_CHARACTERS')}</p>}
      {errors.files && <div className="d-block invalid-feedback">{errors.files && errors.files.message}</div>}
      <TangiAlert {...alertProps} />
    </Form.Group>
  );
};
