import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { authenticate } from 'redux-toolkit/thunks/authThunks';
import { useDispatch } from 'react-redux';

import { AuthContext } from '../../_hocs/Cognito/Auth';
import { Loader } from '../Loader';
import { useLoggedInRedirect } from '../../utils/customHooks';
import { AppRoutes } from 'utils/routing/consts';

function getCodeFromQuery(url) {
  return url.split('=')[1];
}

export const OktaCallback = () => {
  const { getTokensFromCode, authenticateOkta } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const code = getCodeFromQuery(location.search);
  useLoggedInRedirect(true);

  useEffect(() => {
    (async () => {
      if (code) {
        try {
          const tokens = await getTokensFromCode(code);
          const idToken = await authenticateOkta(tokens);
          dispatch(authenticate({ idToken }));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          history.push(AppRoutes.LOGIN);
        }
      } else {
        history.push(AppRoutes.LOGIN);
      }
    })();
  }, [code]);

  return <Loader isLoading />;
};
