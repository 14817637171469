import { TangiButton } from '_components/TangiLibrary/TangiButton/TangiButton';
import { FileDisplayContainer, FileNameContainer } from './styles';
import { useTranslation } from 'react-i18next';
import { TangiTypography } from '_components/TangiLibrary/TangiTypography/TangiTypography';
import { formatLongFileName } from 'utils/fileNameUtils';
import { MAX_ACCEPTED_FILENAME_LENGTH } from '_constants/fileConstants';
import { PolicyFileDetails } from 'utils/types/client/client';

interface FileDisplayProps {
  details: PolicyFileDetails;
  onRemove: () => void;
}

export const FileDisplay = ({ details, onRemove }: FileDisplayProps) => {
  const { t } = useTranslation();
  return (
    <FileDisplayContainer>
      <FileNameContainer>
        <TangiTypography type="subheading" weight="semibold">
          {formatLongFileName(details.fileName, MAX_ACCEPTED_FILENAME_LENGTH)}
        </TangiTypography>
      </FileNameContainer>
      <TangiButton variant="secondary" text={t('GENERAL.BUTTONS_TEXT.DELETE')} onClick={onRemove} svgIcon="delete" smallbtn />
    </FileDisplayContainer>
  );
};
