import { IpAuditStepKey } from '_components/IpAudit/types';
import { StepTitle } from '../components/StepTitle/StepTitle';
import { TangiTypography } from '_components/TangiLibrary/TangiTypography/TangiTypography';
import { NEUTRAL_SHADES } from 'utils/theme';
import { useTranslation } from 'react-i18next';
import { StepContainer, StepInnerContainer, StepTextContainer } from '../styles';
import { TangiDragAndDropFile } from '_components/TangiLibrary/TangiDragAndDropFile/TangiDragAndDropFile';
import { TangiAlert } from '_components/TangiLibrary';
import { useHandlePolicy } from '../hooks/useHandlePolicy';
import { RESULT_STATUS } from 'utils/enums';
import { StepFooter } from '../components/StepFooter/StepFooter';
import { FileDisplay } from './FileDisplay';
import { useEffect, useMemo } from 'react';
import { POLICIES } from 'utils/types/client/client';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '_helpers';
import { clientActions } from 'redux-toolkit/slices/clientSlice';
import { useHandleSteps } from '_components/TangiLibrary/TangiStepper/useHandleSteps';

export const TradeSecretPolicy = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const tradeSecretPolicyFile: { hasData: boolean; isLoading: boolean } = useSelector((state: RootState) => state.client.ipAudit[IpAuditStepKey.TRADE_SECRET_POLICY]);

  const { handleStepChange } = useHandleSteps();
  const { uploadDroppedFile, removeDroppedFile, onDropFile, acceptedFilesList, dropFailData, fileDetails, droppedFile, removeFileDetails, isUserHasPolicy } = useHandlePolicy(POLICIES.TRADE_SECRET);

  const onSubmit = () => {
    uploadDroppedFile();
    removeDroppedFile();
  };

  useEffect(() => {
    if (tradeSecretPolicyFile.hasData) {
      dispatch(clientActions.clearIpAuditAction(IpAuditStepKey.TRADE_SECRET_POLICY));
      handleStepChange(IpAuditStepKey.SYSTEM_SECURITY);
    }
  }, [tradeSecretPolicyFile.hasData]);

  // The continue button is enabled when:
  // 1. The user had an existing policy but decided to delete it.
  //    This condition is met when the user has a policy but no file details:
  //    (isUserHasPolicy && !fileDetails)
  // 2. The user didn't have an existing policy, but they dropped a new file.
  //    In this case, the continue button is enabled if there's a dropped file and file details exist:
  //    (droppedFile && fileDetails)
  const isContinueBtnEnabled = useMemo(() => (isUserHasPolicy && !fileDetails) || (droppedFile && fileDetails), [isUserHasPolicy, fileDetails, droppedFile]);

  return (
    <StepContainer>
      <StepInnerContainer>
        <StepTextContainer>
          <StepTitle stepKey={IpAuditStepKey.TRADE_SECRET_POLICY} />
          <TangiTypography type="subheading" color={NEUTRAL_SHADES[1100]}>
            {t('IP_AUDIT.IP_AUDIT_STEPS.TRADE_SECRET_POLICY.DESCRIPTION')}
          </TangiTypography>
        </StepTextContainer>

        {!fileDetails ? <TangiDragAndDropFile onDrop={onDropFile} acceptedFilesList={acceptedFilesList} height="240px" /> : <FileDisplay details={fileDetails} onRemove={removeFileDetails} />}

        {/* temporary disabled */}

        {/* <TangiTypography type="subheading" color={NEUTRAL_SHADES[1100]}>
          {t('IP_AUDIT.IP_AUDIT_STEPS.TRADE_SECRET_POLICY.DOWNLOAD_POLICY')}
          <DownloadText>{` ${t('POLICY.OPTIONS.DOWNLOAD')} `}</DownloadText>
          {t('IP_AUDIT.IP_AUDIT_STEPS.STANDARD_VERSION')}
        </TangiTypography> */}

        <TangiAlert show={dropFailData?.isFail} text={dropFailData?.failDescription} type={RESULT_STATUS.ERROR} />
      </StepInnerContainer>
      <StepFooter onSubmit={onSubmit} isContinueBtnLoading={tradeSecretPolicyFile.isLoading} isContinueBtnDisabled={!isContinueBtnEnabled} />
    </StepContainer>
  );
};
