export enum FILTERS_PEOPLE_OPTIONS_LABELS {
  ROLE = 'Role',
  COUNTRY = 'Country',
  BUSINESS_UNIT = 'Business Unit',
  HIRE_DATE = 'Hire Date',
  TRAINING = 'Training',
}

export const filtersMapping = {
  ROLE: 'ROLE',
  COUNTRY: 'COUNTRY',
  BUSINESS_UNIT: 'BUSINESS_UNIT',
  HIRE_DATE: 'HIRE_DATE',
  TRAINING: 'TRAINING',
};

export enum FILTERS_ID {
  TRAINING = 'trainingProgress',
  HIRE_DATE = 'dateOfJoining',
  ROLE = 'roleId',
  COUNTRY = 'country',
  BUSINESS_UNIT = 'businessUnit',
}

export interface RenderSelectedComponentProps {
  selectedFilter: Option | null;
  filter: FilterData;
  onChange: (selectedItems: SelectedFilter[], id: string) => void;
  setShowFilterOptions: React.Dispatch<React.SetStateAction<boolean>>;
  handleSelectedOptions?: (selectedItems: SelectedFilter[], id: FILTERS_ID) => void;
}

interface DropdownItemConfig {
  title: string;
  itemList: FilterDataItem[];
}

export type DropdownConfig = Record<FILTERS_ID, DropdownItemConfig>;

export interface Option {
  filterId: string;
  id: FILTERS_ID;
  displayName: string;
  selected: boolean;
}

export interface SelectedFilter {
  id: string;
  name: string;
  displayName?: string;
  formattedName?: string;
}
export interface DateRangeFilter {
  from: string;
  to: string;
}

export type FilterItem = SelectedFilter[] | DateRangeFilter;

export type SelectedOptions = Record<string, (string | DateRangeFilter)[]>;

export interface FilterDataItem {
  id: string;
  name: string;
  displayName?: string;
  formattedName?: string;
  email?: string;
}

export type FilterData = {
  [key in FILTERS_ID]: FilterDataItem[] | boolean;
} & {
  department: FilterDataItem[];
};
