import { createAsyncThunk } from '@reduxjs/toolkit';

import { saveStateToLocalStorage } from 'utils/localStorage';
import inventionDisclosureAdapter from 'redux-toolkit/adapters/inventionDisclosureAdapter';
import { inventionDisclosureActions } from 'redux-toolkit/slices/inventionDisclosureSlice';
import { IDFRequestInfo, IDFTextFinderResponse, InventionDisclosureData, InventionDisclosureTextFinderParams } from 'utils/types/inventionDisclosure/inventionDisclosure';
import { inventionDisclosureConstants } from '_constants/inventionDisclosureConstants';
import { addIsAssetCreatedToPotentialTS } from 'utils/xrayUtils';
import { trackMixpanelEvent } from '_services/utils/MixPanel/mixpanelConfig';
import { MixpanelEventNames } from '_services/utils/MixPanel/const';
import { countWordsInText } from '_components/InventionDisclosureSearch/utils';

// POST
export const inventionDisclosureTextFinder = createAsyncThunk<
  IDFRequestInfo | null, //Thunk return type
  InventionDisclosureTextFinderParams // This is the type of the argument that thunk receives
>('/inventionDisclosure/inventionDisclosureTextFinder', async ({ inventionDisclosureText, clientId }: InventionDisclosureTextFinderParams, { dispatch }) => {
  // Reset the inventionDisclosureData
  dispatch(inventionDisclosureActions.setInventionDisclosureDataInitState());

  const res: IDFTextFinderResponse = await inventionDisclosureAdapter.inventionDisclosureTextFinder(inventionDisclosureText);
  if (res) {
    const { inventionDisclosureSearchId } = res;
    saveStateToLocalStorage(inventionDisclosureConstants.IDF_REQUEST_INFO, { ...res, clientId });
    dispatch(getInventionDisclosureResults(inventionDisclosureSearchId));
    dispatch(
      trackMixpanelEvent(MixpanelEventNames.InventionSearch, {
        inventionDisclosureTextLength: countWordsInText(inventionDisclosureText), // Additional property specific to this event
      }),
    );
  }
  return res ? { ...res, clientId } : null;
});

// GET
export const getInventionDisclosureResults = createAsyncThunk<
  InventionDisclosureData | null, //Thunk return type
  string // This is the type of the argument that thunk receives
>('/inventionDisclosure/getInventionDisclosureResults', async (inventionDisclosureSearchId: string) => {
  const res = await inventionDisclosureAdapter.getInventionDisclosureResults(inventionDisclosureSearchId);
  if (res && res?.response?.trade_secrets?.length) {
    const tradeSecretsWithIsAssetCreated = addIsAssetCreatedToPotentialTS(res.response.trade_secrets);
    res.response.trade_secrets = tradeSecretsWithIsAssetCreated;
  }
  return res ?? null;
});
