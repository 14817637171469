import { TangiButton, TangiTypography } from '_components/TangiLibrary';
import quickStartAuditUtil from '../util';
import { useTranslation } from 'react-i18next';
import { NEUTRAL_SHADES, SUCCESS_SHADES } from 'utils/theme';
import { Container, ProgressBarContainer } from './style';
import { TangiProgressBar } from '_components/TangiLibrary/TangiProgressBar/TangiProgressBar';
import { useIpAudit } from '_components/IpAudit/useIpAudit';

interface Props {
  isAdmin: boolean;
  percentage?: number;
  onQuickStartAudit: (isAdmin: boolean) => void;
}
const RightSide = ({ isAdmin, onQuickStartAudit }: Props) => {
  const { t } = useTranslation();
  const { numberOfSteps, numberOfCompletedSteps } = useIpAudit();
  const completedStepsPercentage = (numberOfCompletedSteps / numberOfSteps) * 100;
  const buttonConfig = quickStartAuditUtil.getButtonConfig(isAdmin, completedStepsPercentage);
  const isFullyCompleted = completedStepsPercentage === 100;

  const completedText = isFullyCompleted
    ? t('IP_AUDIT.IP_AUDIT_STEPS.COMPLETED')
    : t('IP_AUDIT.IP_AUDIT_STEPS.OVERVIEW.PROGRESS_BAR_TEXT', { COMPLETED: `${numberOfCompletedSteps}/${numberOfSteps}` });

  const completeTextColor = isFullyCompleted ? SUCCESS_SHADES[600] : NEUTRAL_SHADES[800];

  return (
    <Container>
      <ProgressBarContainer>
        {!isFullyCompleted && (
          <div style={{ flex: 1 }}>
            <TangiProgressBar trackColor={NEUTRAL_SHADES['WHITE']} percentage={completedStepsPercentage} indicatorColor={isFullyCompleted ? SUCCESS_SHADES[600] : undefined} />
          </div>
        )}
        <TangiTypography color={completeTextColor} weight={isFullyCompleted ? 'bold' : 'regular'}>
          {completedText}
        </TangiTypography>
      </ProgressBarContainer>

      <TangiButton
        text={t(buttonConfig.text)}
        svgIcon={buttonConfig.icon}
        endIcon={buttonConfig.endIcon}
        variant={buttonConfig.variant}
        data-cy={`ip-audit-button-${isAdmin ? 'admin' : 'no-admin'}`}
        onClick={() => onQuickStartAudit(isAdmin)}
      />
    </Container>
  );
};

export default RightSide;
