import { RootState } from '_helpers';
import { DEMO_ACCOUNT_NAMES, Environment } from './const';
import { lawyers } from 'utils/roles';

// Update the shouldTrackEvent function
export const shouldTrackEvent = (clientName = '') => {
  const isDemoAccount = DEMO_ACCOUNT_NAMES.includes(clientName);
  const currentEnv = process.env.REACT_APP_ENVIRONMENT;

  return currentEnv === Environment.PRODUCTION && !isDemoAccount;
};

export const getCommonProperties = (state: RootState) => {
  const { Role, user, activeAccount } = state.authentication;
  const { activeClient } = state.lawfirm;

  const isLawyer = lawyers.includes(Role);
  const clientName = isLawyer ? activeClient?.name : activeAccount?.client?.name;
  const clientId = isLawyer ? activeClient?.id || '' : activeAccount?.client?._id || '';

  const commonProperties = {
    Role,
    userEmail: user?.email || '',
    clientName,
    clientId,
  };

  const lawyerProperties = { lawFirmId: activeAccount?.lawFirm?._id || '', lawFirmName: activeAccount?.lawFirm?.name || '' };

  return { ...commonProperties, ...(isLawyer ? lawyerProperties : {}) };
};

export interface CommonEventProps {
  clientName: string;
  userEmail: string;
  role?: string;
  clientId?: string;
  accountId?: string;
  assetId?: string;
  selectedAssets?: string[];
  createdFrom?: string;
  createdFromTool?: string;
  isMappingTool?: boolean;
  extraData?: unknown;
  patentModel?: string;
  typeOfAction?: string;
}
export interface InviteAccountProps extends CommonEventProps {
  accountData: { id: string; email?: string; role?: string };
}
export interface ExtractTradeSecretProps extends CommonEventProps {
  clientId: string;
  patentModel: string;
  typeOfAction: string;
  extraData?: unknown;
}
export interface XraySearchProps extends CommonEventProps {
  clientId: string;
  extraData?: unknown;
}
export interface AcknowledgementAssetProps extends CommonEventProps {
  accountId: string;
  selectedAssets: string[];
}
