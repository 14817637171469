import styled, { css } from 'styled-components';

import { Props as TangiTypographyProps } from './TangiTypography';
import { NEUTRAL_SHADES } from '../../../utils/theme';

export enum TYPOGRAPHY_TYPES {
  HEADING_XL = 'heading-xl',
  HEADING_LG = 'heading-lg',
  HEADING_MD = 'heading-md',
  SUBHEADING = 'subheading',
  BODY = 'body',
  SUB_BODY = 'sub-body',
  CAPTION = 'caption',
  X_SMALL = 'x-small',
  BTN_MEDIUM_BIG = 'btn-medium-big',
  BTN_MEDIUM_BIG_SEMI = 'btn-medium-big-semi',
  BUTTON_SM = 'button-sm',
}

export const weightStyles = {
  regular: 400,
  semibold: 600,
  bold: 700,
};

// for now - until we change font
export const weightDinFontStyles = {
  regular: 'DIN2014-Regular',
  semibold: 'DIN2014-DemiBold',
  bold: 'DIN2014-Bold',
};

export const typeDictionary = {
  [TYPOGRAPHY_TYPES.HEADING_XL]: {
    size: '28px',
    line: '38px',
  },
  [TYPOGRAPHY_TYPES.HEADING_LG]: {
    size: '24px',
    line: '34px',
  },
  [TYPOGRAPHY_TYPES.HEADING_MD]: {
    size: '18px',
    line: '28px',
  },
  [TYPOGRAPHY_TYPES.SUBHEADING]: {
    size: '16px',
    line: '24px',
  },
  [TYPOGRAPHY_TYPES.BODY]: {
    size: '14px',
    line: '22px',
  },
  [TYPOGRAPHY_TYPES.SUB_BODY]: {
    size: '12px',
    line: '18px',
  },
  [TYPOGRAPHY_TYPES.CAPTION]: {
    size: '12px',
    line: '20px',
  },
  [TYPOGRAPHY_TYPES.X_SMALL]: {
    size: '12px',
    line: '16px',
  },
  [TYPOGRAPHY_TYPES.BTN_MEDIUM_BIG]: {
    size: '16px',
    line: '20px',
  },
  [TYPOGRAPHY_TYPES.BTN_MEDIUM_BIG_SEMI]: {
    size: '14px',
    line: '20px',
  },
  [TYPOGRAPHY_TYPES.BUTTON_SM]: {
    size: '14px',
    line: '16px',
  },
};

type StyledTypographyProps = Pick<TangiTypographyProps, 'color' | 'type' | 'weight' | 'customStyles'>;

export const StyledTypography = styled.div<StyledTypographyProps>`
  ${({ weight = 'regular', type = 'body', color = NEUTRAL_SHADES.BLACK, customStyles }) => css`
    // TODO we will need this when we change font
    // font-weight: ${weightStyles[weight]};
    font-size: ${typeDictionary[type].size};
    line-height: ${typeDictionary[type].line};
    color: ${color};
    // TODO remove when we change font
    font-family: ${weightDinFontStyles[weight]};
    margin-top: 2px;
    text-transform: ${type === 'caption' && 'uppercase'};
    ${customStyles && customStyles}
  `}
`;
