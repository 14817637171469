import React from 'react';

import { StyledChip } from './style';

export type ChipType = 'filled' | 'outline' | 'primary' | 'secondary' | 'tag';
export interface ITangiChipProps {
  text: string | React.ReactNode;
  maxWidth?: number;
  variant?: ChipType;
  className?: string;
}

export const TangiChip = ({ text, maxWidth, variant = 'filled', className }: ITangiChipProps) => {
  return (
    <StyledChip data-testid="tangi-chip" maxWidth={maxWidth} variant={variant} className={className}>
      {text}
    </StyledChip>
  );
};
