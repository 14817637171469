import { SpecialAdminPermissions } from 'utils/types/account/account';
import { Client, ClientIpPermissionsPartial } from 'utils/types/client/client';
import { IconTypes } from '_components/TangiLibrary/TangiSvgIcon/types';
import { IRoles } from 'utils/roles';
import { AppRoutes } from 'utils/routing/consts';

export interface AccessCheckParams {
  accountPermissions: SpecialAdminPermissions;
  client: ClientIpPermissionsPartial | Client;
}

export interface GetAccessibleMenuItemsParams extends AccessCheckParams {
  role: IRoles;
  clientId: string;
}

export type AccessCheck = boolean | ((accessParams: AccessCheckParams) => boolean);
export interface AccessChecks extends Record<string, AccessCheck> {}

export enum NavItemType {
  ITEM = 'item',
  GROUP = 'group',
  PARTNERSHIP_DRAWER = 'partnershipDrawer',
}
export interface ConfigurableMenuItem {
  id: string;
  translationKey: string;
  path?: AppRoutes;
  roles: string[];
  access: AccessCheck;
  subItems?: ConfigurableMenuItem[];
  type: NavItemType;
  icon: IconTypes;
}

export interface AccessibleMenuItem extends Omit<ConfigurableMenuItem, 'path' | 'access' | 'subItems'> {
  path?: string;
  access: boolean;
  subItems?: AccessibleMenuItem[];
}
