import React, { createContext, useMemo } from 'react';
import { CognitoUser, AuthenticationDetails, CognitoUserPool, CognitoIdToken } from 'amazon-cognito-identity-js';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { config } from '../../config/config';
import { loadStateFromLocalStorage, saveStateToLocalStorage } from 'utils/localStorage';
import { authConstants } from '_constants';
import { mixpanelEvents } from '_services/utils/MixPanel/mixpanelConfig';
import { ERROR_MESSAGES, LOCAL_STORAGE_KEYS } from 'utils/token/localStorage';
import { CognitoIdentityProviderClient, InitiateAuthCommand } from '@aws-sdk/client-cognito-identity-provider';
import { AWS_SDK } from './constants';

const poolData = {
  UserPoolId: config.userPoolId,
  ClientId: config.cognitoClientId,
};
const Pool = new CognitoUserPool(poolData);
export default Pool;

const AuthContext = createContext();

const Auth = ({ children }) => {
  let sessionUserAttributes = null; /// global variables to handle completeNewPasswordChallenge flow
  let currentUser = null; /// global variables to handle completeNewPasswordChallenge flow
  const activeAccount = useSelector((state) => state.authentication.activeAccount);
  const activeClient = useSelector((state) => state.lawfirm.activeClient);
  const dispatch = useDispatch();

  const clientName = useMemo(() => {
    return activeAccount?.client ? activeAccount.client.name : activeClient?.name;
  }, [activeAccount, activeClient]);

  const authCB = (resolve, reject) => {
    return {
      onSuccess: async (data) => {
        saveStateToLocalStorage(LOCAL_STORAGE_KEYS.JWT_TOKEN, data.accessToken.jwtToken);
        resolve(data);
      },
      onFailure: (err) => {
        reject(err);
      },
      newPasswordRequired: (data) => {
        resolve({ code: 'NEW_PASSWORD_REQUIRED', payload: data });
      },
      totpRequired: (secretCode) => {
        resolve({ code: secretCode });
      },
      mfaSetup: (challengeName) => {
        resolve({ code: challengeName });
      },
      associateSecretCode: (secret) => {
        resolve(secret);
      },
    };
  };

  const getSession = async () => {
    return new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser() || currentUser;
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject(err);
          } else {
            const attributes = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err);
                } else {
                  const results = {};
                  for (const attribute of attributes) {
                    const { Name, Value } = attribute;
                    results[Name] = Value;
                  }
                  sessionUserAttributes = results;
                  resolve(results);
                }
              });
            });

            resolve({ user, ...session, ...attributes });
          }
        });
      } else {
        reject('user not found');
      }
    });
  };

  const authenticate = async (Username, Password) => {
    return new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });
      currentUser = user;
      user.authenticateUser(authDetails, authCB(resolve, reject));
    });
  };

  const senfMfa = async (otpValue) => {
    return new Promise((resolve, reject) => {
      currentUser.sendMFACode(otpValue, authCB(resolve, reject), 'SOFTWARE_TOKEN_MFA');
    });
  };

  const associateToken = async () => {
    return new Promise((resolve, reject) => {
      currentUser.associateSoftwareToken(authCB(resolve, reject));
    });
  };

  const verifyToken = async (otpValue) => {
    return new Promise((resolve, reject) => {
      currentUser.verifySoftwareToken(otpValue, 'My TOTP device', authCB(resolve, reject));
    });
  };

  const forceResetPassword = async (newPassword) => {
    return new Promise((resolve, reject) => {
      currentUser.completeNewPasswordChallenge(newPassword, {}, authCB(resolve, reject));
    });
  };

  const forgotPassword = async (Username) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({ Username, Pool });
      currentUser = cognitoUser;
      cognitoUser.forgotPassword({
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (err) => {
          reject(err.message);
        },
      });
    });
  };

  const confirmForgotPassword = (newPassword, verificationCode, Username) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({ Username, Pool });
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess() {
          resolve('Password confirmed!');
        },
        onFailure(err) {
          reject(err);
        },
      });
    });
  };

  //TODO: need to wrap this logout function with dispatch , usage: dispatch(logout())
  const logout = () => {
    return (dispatch) => {
      const cognitoUser = Pool.getCurrentUser();
      const firstTimeLoginFlag = loadStateFromLocalStorage(authConstants.FIRST_TIME_LOGIN);
      localStorage.clear();
      saveStateToLocalStorage(authConstants.FIRST_TIME_LOGIN, firstTimeLoginFlag);
      dispatch(mixpanelEvents.logoutActive({ clientName, message: 'User intentionally logged out' }));
      if (cognitoUser) {
        cognitoUser.signOut();
      }
    };
  };

  const getTokensFromCode = async (code) => {
    const requestOptions = {
      url: `${config.cognitoUserPoolDomain}/oauth2/token?grant_type=authorization_code&code=${code}&client_id=${config.cognitoClientId}&redirect_uri=${config.cognitoRedirectUri}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: {},
    };
    return new Promise((resolve, reject) => {
      axios(requestOptions).then(
        (result) => {
          saveStateToLocalStorage(LOCAL_STORAGE_KEYS.JWT_TOKEN, result.data.access_token);
          saveStateToLocalStorage(LOCAL_STORAGE_KEYS.OKTA_USER_TOKENS, result.data);
          resolve(result.data);
        },
        (error) => {
          reject(error);
        },
      );
    });
  };

  const authenticateOkta = async (tokens) => {
    return new Promise((resolve) => {
      const IdToken = tokens.id_token;
      const userData = new CognitoIdToken({ IdToken });
      const Username = userData.payload['cognito:username'];
      const user = new CognitoUser({ Username, Pool });
      currentUser = user;

      saveStateToLocalStorage(LOCAL_STORAGE_KEYS.JWT_TOKEN, tokens.access_token);
      saveStateToLocalStorage();
      resolve(IdToken);
    });
  };

  async function refreshOktaToken(refreshToken) {
    const client = new CognitoIdentityProviderClient({ region: config.region });

    const authParameters = {
      REFRESH_TOKEN: refreshToken,
    };
    const input = {
      AuthFlow: AWS_SDK.AUTH_FLOW_REFRESH_TOKEN,
      AuthParameters: authParameters,
      ClientId: config.cognitoClientId,
    };

    const command = new InitiateAuthCommand(input);
    try {
      const response = await client.send(command);
      const accessToken = response.AuthenticationResult.AccessToken;
      const oktaTokens = loadStateFromLocalStorage(LOCAL_STORAGE_KEYS.OKTA_USER_TOKENS);

      saveStateToLocalStorage(LOCAL_STORAGE_KEYS.JWT_TOKEN, accessToken);
      saveStateToLocalStorage(LOCAL_STORAGE_KEYS.OKTA_USER_TOKENS, { ...oktaTokens, access_token: accessToken });
    } catch (err) {
      console.error(err.message ?? ERROR_MESSAGES.UNAUTHORIZED_ACCESS);
      return dispatch(logout());
    }
  }

  const refreshCognitoToken = () => {
    const cognitoUser = Pool.getCurrentUser();
    if (!cognitoUser) return dispatch(logout());
    cognitoUser.getSession((err, session) => {
      if (err) {
        console.error(err.message ?? ERROR_MESSAGES.UNAUTHORIZED_ACCESS);
        return dispatch(logout());
      }
      const refreshToken = session.getRefreshToken();

      cognitoUser.refreshSession(refreshToken, (err, authResponse) => {
        saveStateToLocalStorage(LOCAL_STORAGE_KEYS.JWT_TOKEN, authResponse.accessToken.jwtToken);
        if (err) {
          console.error(err.message ?? ERROR_MESSAGES.UNAUTHORIZED_ACCESS);
          return dispatch(logout());
        }
      });
    });
  };

  return (
    <AuthContext.Provider
      value={{
        authenticate,
        logout,
        getSession,
        senfMfa,
        associateToken,
        verifyToken,
        forceResetPassword,
        getTokensFromCode,
        authenticateOkta,
        forgotPassword,
        confirmForgotPassword,
        refreshCognitoToken,
        refreshOktaToken,
        sessionUserAttributes,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export { Auth, AuthContext };
