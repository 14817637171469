import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TANGI_BUTTON_SIZES, TangiButton } from '_components/TangiLibrary';
import { StyledTextArea, FormFooter, Container } from './style';
import { BUTTON_VARIANTS } from 'utils/theme';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
  name: string;
  handleSave: (newText: string) => void;
  handleCancel: () => void;
  value?: string;
  isEditMode?: boolean;
  isCreateMode?: boolean;
  isAssociated?: boolean;
  isCurrentAssociation?: boolean;
}

const EditableTextArea = ({ name, value = '', handleSave, handleCancel, isEditMode = false, isCreateMode = false, isAssociated = false, isCurrentAssociation = false }: Props) => {
  const [localValue, setLocalValue] = useState<string>(value);

  const isReadMode = !isEditMode && !isCreateMode;

  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    adjustTextAreaHeight();
    if (!isReadMode) {
      textAreaRef.current?.focus();
    }
  }, [isReadMode, localValue]);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const adjustTextAreaHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  };

  const onSave = () => {
    if (localValue.trim().length) {
      handleSave(localValue.trim());
    }
  };

  const onCancel = () => {
    setLocalValue(value);
    setValue(name, value);
    handleCancel();
  };

  return (
    <Container>
      <Controller
        name={name}
        control={control}
        defaultValue={value}
        render={({ field }) => (
          <StyledTextArea
            {...field}
            value={localValue}
            onChange={(e) => setLocalValue(e.target.value)}
            data-testid="editable-textarea"
            placeholder={t('INVENTION_DISCLOSURE.PLACEHOLDER.WRITE_YOUR_CLAIM_HERE')}
            readOnly={!isEditMode && !isCreateMode}
            isReadMode={!isEditMode && !isCreateMode}
            isAssociated={isAssociated}
            isCurrentAssociation={isCurrentAssociation}
            ref={textAreaRef}
          />
        )}
      />
      {!isReadMode && (
        <FormFooter>
          <TangiButton
            onClick={onCancel}
            smallbtn={true}
            size={TANGI_BUTTON_SIZES.SMALL}
            text={t('GENERAL.BUTTONS_TEXT.CANCEL')}
            type="button"
            data-testid="editable-textarea-cancel"
            variant={BUTTON_VARIANTS.TERTIARY}
            disabled={false}
          />
          <TangiButton
            onClick={onSave}
            smallbtn={true}
            size={TANGI_BUTTON_SIZES.SMALL}
            text={t('GENERAL.BUTTONS_TEXT.SAVE')}
            type="submit"
            data-testid="editable-textarea-save"
            variant={BUTTON_VARIANTS.PRIMARY}
            disabled={false}
          />
        </FormFooter>
      )}
    </Container>
  );
};

export default EditableTextArea;
