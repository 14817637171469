import { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Dashboard } from 'pages/Dashboard';
import { TangiIconButton, TangiTypography } from '_components/TangiLibrary';
import ScoreProgressBar from './components/ScoreProgressBar';
import { ContractParameters } from './components/ContractParameters';
import { ContractHeader } from './components/ContractHeader';
import { RootState } from '_helpers';
import { getProgressBarColor } from './utils';
import { XRAY_JOB_STATUS } from 'utils/types/xRay/xRay';
import { generateRoute } from 'utils/routing/generateRoute';
import { AppRoutes } from 'utils/routing/consts';
import { BUTTON_VARIANTS } from 'utils/theme';
import {
  NavigationColumn,
  ContentColumn,
  PageContainer,
  ScoreColumn,
  BackToContainer,
  ScoreContainer,
  scoreTextStyles,
  PercentageTextContainer,
  percentageTextStyles,
  ContractContainer,
} from './style';

const ContractToolPage = () => {
  const contractData = useSelector((state: RootState) => state.contract).contractData;
  const { getResultLoader } = useSelector((state: RootState) => state.contract.contractLoaders);

  const { t } = useTranslation();
  const history = useHistory();
  const { clientId } = useParams<{ clientId: string }>();

  const progressBarColor = useMemo(() => getProgressBarColor(contractData?.score), [contractData?.score]);

  useEffect(() => {
    if (!getResultLoader && contractData?.status !== XRAY_JOB_STATUS.SUCCEEDED) {
      history.push(generateRoute(AppRoutes.CONTRACT_ANALYZER_MAIN_PAGE, { clientId }));
    }
  }, [getResultLoader, clientId, contractData.status]);

  const renderMatchScore = () => {
    return (
      <ScoreContainer>
        <PercentageTextContainer>
          <TangiTypography weight="bold" type="heading-xl" customStyles={scoreTextStyles}>
            {contractData?.score}
          </TangiTypography>
          <TangiTypography type="heading-xl" customStyles={percentageTextStyles}>
            %
          </TangiTypography>
        </PercentageTextContainer>
        <ScoreProgressBar now={contractData?.score} customBackgroundColor={progressBarColor} />
        <TangiTypography type="subheading">{t('DISCOVERY_LOBBY.UPLOAD_CONTRACT_APPLICATION.TRADE_SECRET_PROTECTION_SCORE')}</TangiTypography>
      </ScoreContainer>
    );
  };

  const renderContractContent = () => {
    return (
      <ContractContainer>
        <ContractHeader score={contractData.score} filename={contractData.filename} />
        <ContractParameters />
      </ContractContainer>
    );
  };

  const renderNavigationContent = () => {
    return (
      <BackToContainer>
        <TangiIconButton icon="arrowLeft" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={() => history.push(generateRoute(AppRoutes.CONTRACT_ANALYZER_MAIN_PAGE, { clientId }))} />
        <span>{t('PATENT_TOOL.CONTENT.BACK_TO_CONTRACT_ANALYZER_HOME')}</span>
      </BackToContainer>
    );
  };

  return (
    <Dashboard title="">
      <PageContainer>
        <NavigationColumn>{renderNavigationContent()}</NavigationColumn>
        <ContentColumn>{renderContractContent()}</ContentColumn>
        <ScoreColumn>{renderMatchScore()}</ScoreColumn>
      </PageContainer>
    </Dashboard>
  );
};

export default ContractToolPage;
