import { useTranslation } from 'react-i18next';
import { StepContainer, StepInnerContainer, StepTextContainer } from '../styles';
import { StepTitle } from '../components/StepTitle/StepTitle';
import { IpAuditStepKey } from '_components/IpAudit/types';
import { TangiTypography } from '_components/TangiLibrary/TangiTypography/TangiTypography';
import { NEUTRAL_SHADES } from 'utils/theme';
import { TangiDragAndDropFile } from '_components/TangiLibrary/TangiDragAndDropFile/TangiDragAndDropFile';
import { TangiAlert } from '_components/TangiLibrary/TangiAlert/TangiAlert';
import { StepFooter } from '../components/StepFooter/StepFooter';
import { useHandlePolicy } from '../hooks/useHandlePolicy';
import { RESULT_STATUS } from 'utils/enums';
import { useEffect, useMemo } from 'react';
import { FileDisplay } from '../TradeSecretPolcy/FileDisplay';
import { POLICIES } from 'utils/types/client/client';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '_helpers';
import { clientActions } from 'redux-toolkit/slices/clientSlice';

export const LlmPolicy = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { uploadDroppedFile, removeDroppedFile, onDropFile, acceptedFilesList, dropFailData, fileDetails, droppedFile, isUserHasPolicy, removeFileDetails } = useHandlePolicy(POLICIES.LLM);

  const llmFile: { hasData: boolean; isLoading: boolean } = useSelector((state: RootState) => state.client.ipAudit[IpAuditStepKey.LLM_POLICY]);
  const onSubmit = () => {
    uploadDroppedFile();
    removeDroppedFile();
  };

  useEffect(() => {
    if (llmFile.hasData) {
      dispatch(clientActions.clearIpAuditAction(IpAuditStepKey.LLM_POLICY));
    }
  }, [llmFile.hasData]);

  // The continue button is enabled when:
  // 1. The user had an existing policy but decided to delete it.
  //    This condition is met when the user has a policy but no file details:
  //    (isUserHasPolicy && !fileDetails)
  // 2. The user didn't have an existing policy, but they dropped a new file.
  //    In this case, the continue button is enabled if there's a dropped file and file details exist:
  //    (droppedFile && fileDetails)
  const isContinueBtnEnabled = useMemo(() => (isUserHasPolicy && !fileDetails) || (droppedFile && fileDetails), [isUserHasPolicy, fileDetails, droppedFile]);

  return (
    <StepContainer>
      <StepInnerContainer>
        <StepTextContainer>
          <StepTitle stepKey={IpAuditStepKey.LLM_POLICY} />
          <TangiTypography type="subheading" color={NEUTRAL_SHADES[1100]}>
            {t('IP_AUDIT.IP_AUDIT_STEPS.LLM_POLICY.DESCRIPTION')}
          </TangiTypography>
        </StepTextContainer>

        {!fileDetails ? <TangiDragAndDropFile onDrop={onDropFile} acceptedFilesList={acceptedFilesList} height="240px" /> : <FileDisplay details={fileDetails} onRemove={removeFileDetails} />}

        {/* temporary disabled */}

        {/* <TangiTypography type="subheading" color={NEUTRAL_SHADES[1100]}>
          {t('IP_AUDIT.IP_AUDIT_STEPS.LLM_POLICY.DOWNLOAD_POLICY')}
          <DownloadText>{` ${t('POLICY.OPTIONS.DOWNLOAD')} `}</DownloadText>
          {t('IP_AUDIT.IP_AUDIT_STEPS.STANDARD_VERSION')}
        </TangiTypography> */}

        <TangiAlert show={dropFailData?.isFail} text={dropFailData?.failDescription} type={RESULT_STATUS.ERROR} />
      </StepInnerContainer>
      <StepFooter onSubmit={onSubmit} isContinueBtnLoading={llmFile.isLoading} isContinueBtnDisabled={!isContinueBtnEnabled} onContinueText={t('GENERAL.BUTTONS_TEXT.SAVE')} />
    </StepContainer>
  );
};
