import React from 'react';
import { useTranslation } from 'react-i18next';

import { TangiSvgIcon } from '../TangiLibrary';
import { StyledSnackbar, SnackbarContentContainer, SnackbarMessage, SnackbarButton } from './style';
import { NEUTRAL_SHADES } from 'utils/theme';

const AcknowledgementSnackbar = ({ handleClick, needToBeAcknowledgedNum }) => {
  const { t } = useTranslation();

  return (
    <StyledSnackbar data-testid="acknowledgement-snackbar">
      <SnackbarContentContainer>
        <TangiSvgIcon component="info" color={NEUTRAL_SHADES.WHITE} />
        <SnackbarMessage>{t('ASSET_PAGE.TOOLTIP.ASSETS_AWAITING_YOUR_SIGNATURE', { NEEDTOBEACKNOWLEDGEDNUM: needToBeAcknowledgedNum })}</SnackbarMessage>
        <SnackbarButton type="button" onClick={handleClick} data-testid="acknowledgement-snackbar-button">
          {t('ASSET_PAGE.TOOLTIP.NAVIGATE_TO_ALL_SIGNATURE_REQUESTS')}
        </SnackbarButton>
      </SnackbarContentContainer>
    </StyledSnackbar>
  );
};

export default AcknowledgementSnackbar;
