import { useTranslation } from 'react-i18next';
import { batch, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { setActiveClientAndResetPartnerships } from 'redux-toolkit/thunks/lawFirmThunks';
import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { inventionDisclosureActions } from 'redux-toolkit/slices/inventionDisclosureSlice';
import { patentActions } from 'redux-toolkit/slices/patentSlice';
import { partnershipActions } from 'redux-toolkit/slices/partnershipSlice';
import { AppRoutes } from 'utils/routing/consts';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { SideBarNavItem } from '../style';

const SwitchClientButtonContainer = styled(SideBarNavItem)`
  position: fixed;
  bottom: 0px;
  left: 8px;
  height: ${SPACING[7]};
  border-radius: 0;
  display: flex;
  width: 225px;
  margin-left: -${SPACING[2]};
  margin-right: -${SPACING[2]};
  box-shadow: 0px -1px 4px 0px #0000000f;
  padding-left: ${SPACING[3]};
  cursor: pointer;
  background: ${NEUTRAL_SHADES.WHITE};
`;

const SwitchClientButton = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const dispatch = useDispatch();

  const handleSwitchClients = () => {
    batch(() => {
      dispatch(setActiveClientAndResetPartnerships(null));
      dispatch(patentActions.resetStateExceptRequestInfo());
      dispatch(inventionDisclosureActions.resetStateExceptRequestInfo());
      dispatch(partnershipActions.toggleDrawer(false));
    });
    push(AppRoutes.DASHBOARD);
  };

  return (
    <SwitchClientButtonContainer onClick={handleSwitchClients}>
      <TangiSvgIcon component="switch" />
      <TangiTypography>{t('SIDE_BAR.SWITCH_CLIENTS')}</TangiTypography>
    </SwitchClientButtonContainer>
  );
};

export default SwitchClientButton;
