import styled from 'styled-components';
import { SPACING } from 'utils/theme';

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[2]};
  margin: ${SPACING[2]} 0;
`;

export const RadioInput = styled.input`
  margin-right: ${SPACING[1]};
`;

export const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: ${SPACING[1]};
`;

export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[4]};
`;