import { ProgressBar, ProgressBarProps } from 'react-bootstrap';
import styled from 'styled-components';

import { NEUTRAL_SHADES, SPACING } from 'utils/theme';

const StyledProgressBar = styled(ProgressBar)<MyProgressBarProps>`
  width: 100%;
  height: 20px !important;
  background-color: ${(props) => (props.hideBackground ? 'transparent' : NEUTRAL_SHADES[200])};
  box-shadow: none !important;
  .progress-bar {
    border-radius: ${SPACING[1]} ${SPACING[0]} ${SPACING[0]} ${SPACING[1]};
    background: ${(props) => props.customBackgroundColor};
  }
`;

type MyProgressBarProps = ProgressBarProps & {
  hideBackground?: boolean;
  customBackgroundColor?: string;
};

const ScoreProgressBar = ({ hideBackground, customBackgroundColor, ...props }: MyProgressBarProps) => {
  return <StyledProgressBar {...props} hideBackground={hideBackground} customBackgroundColor={customBackgroundColor} />;
};

export default ScoreProgressBar;
