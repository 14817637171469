import axios from 'axios';
import { history } from '../../../_helpers';
import { ERROR_MESSAGES} from 'utils/token/localStorage';


export function apiCall(requestOptions) {
  return new Promise(function (resolve, reject) {
    axios(requestOptions).then(
      (result) => {
        resolve(result.data);
      },
      (error) => {
        if (error?.response) {
          const { status, data } = error.response;
          if (status === 401 && data.message === ERROR_MESSAGES.API_KEY_EXPIRED) {
            localStorage.clear();
            history.push('/login');
            window.location.reload();
          }
          reject(data);
        } else {
          reject(error);
        }
      },
    );
  });
}
