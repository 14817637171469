import { IpAuditExtendedData, HrProcessData, IpAuditStepKey, SystemSecurityData, StepDataTypeMap } from './types';
import { useClient } from './components/Steps/hooks/useClient';
import { POLICIES } from 'utils/types/client/client';
import { ipAuditStepsData } from './consts';
import { TangiStepperStep } from '_components/TangiLibrary/TangiStepper/TangiStepper';
import { useDispatch, useSelector } from 'react-redux';
import { updateHrProcessIpAudit, updateSystemSecurityIpAudit } from 'redux-toolkit/thunks/clientThunks';
import { RootState } from '_helpers';

export const useIpAudit = () => {
  const dispatch = useDispatch();

  const { ipAuditToastProps } = useSelector((state: RootState) => state.client);
  const { activeAccount, getClientPolicyFile, clientId, isLawyer, getClientIpAudit, getClientPermissions } = useClient();

  const isIpAuditEnabled = getClientPermissions()?.ipAudit?.hasPermission;

  //------------------------------------------------------------------ Data ----------------------------------------------------------------------------------------//

  const tradePolicyFiles = getClientPolicyFile(POLICIES.TRADE_SECRET);
  const llmPolicyFiles = getClientPolicyFile(POLICIES.LLM);
  const ipAuditData = getClientIpAudit();

  const ipAuditExtendedData: IpAuditExtendedData = {
    hrProcess: ipAuditData?.hrProcess ?? null,
    systemSecurity: ipAuditData?.systemSecurity ?? null,
    policyFile: tradePolicyFiles?.[0] ?? null,
    llmFile: llmPolicyFiles?.[0] ?? null,
  };

  //---------------------------------------------------------- Steps Submission Handling ----------------------------------------------------------------------------//

  const submitSystemSecurity = (data: SystemSecurityData) => {
    const updatedIpAuditData = { ...ipAuditData, [IpAuditStepKey.SYSTEM_SECURITY]: data };
    const params = {
      clientId,
      accountId: activeAccount._id,
      isLawyer,
      ipAudit: updatedIpAuditData,
    };
    dispatch(updateSystemSecurityIpAudit(params));
  };

  const submitHrProcess = (data: HrProcessData) => {
    const updatedIpAuditData = { ...ipAuditData, [IpAuditStepKey.HR_PROCESS]: data };
    const params = {
      clientId,
      accountId: activeAccount._id,
      isLawyer,
      ipAudit: updatedIpAuditData,
    };
    dispatch(updateHrProcessIpAudit(params));
  };

  //---------------------------------------------------------------- Steps Helpers ------------------------------------------------------------------------------------//
  const getStepCurrentData = <K extends keyof StepDataTypeMap>(stepKey: K): StepDataTypeMap[K] => {
    let data: StepDataTypeMap[K];
    switch (stepKey) {
      case IpAuditStepKey.HR_PROCESS:
        data = ipAuditExtendedData.hrProcess as StepDataTypeMap[K];
        break;
      case IpAuditStepKey.SYSTEM_SECURITY:
        data = ipAuditExtendedData.systemSecurity as StepDataTypeMap[K];
        break;
      case IpAuditStepKey.LLM_POLICY:
        data = ipAuditExtendedData.llmFile as StepDataTypeMap[K];
        break;
      case IpAuditStepKey.TRADE_SECRET_POLICY:
        data = ipAuditExtendedData.policyFile as StepDataTypeMap[K];
        break;
      default:
        throw new Error(`Unhandled step key: ${stepKey}`);
    }
    return data;
  };

  const isStepCompleted = (stepKey: IpAuditStepKey) => {
    switch (stepKey) {
      case IpAuditStepKey.TRADE_SECRET_POLICY:
        return !!ipAuditExtendedData.policyFile;
      case IpAuditStepKey.LLM_POLICY:
        return !!ipAuditExtendedData.llmFile;
      case IpAuditStepKey.HR_PROCESS:
        return ipAuditExtendedData.hrProcess?.employeeTraining != null && ipAuditExtendedData.hrProcess?.isIncorporateProtocolsConfidential != null;
      case IpAuditStepKey.SYSTEM_SECURITY:
        return !!ipAuditExtendedData.systemSecurity?.length;
      default:
        break;
    }
  };

  const getNextStepKey = (currentStep: string, fallbackToFirst: boolean = false): IpAuditStepKey | null => {
    const currentStepIdx = userSteps.findIndex(({ key }) => key === currentStep);
    if (currentStepIdx < 0 || currentStepIdx === userSteps.length - 1) {
      return fallbackToFirst ? ipAuditSteps[0].key : null;
    }
    return userSteps[currentStepIdx + 1].key;
  };

  const isIpAuditStepKey = (key: string | null): key is IpAuditStepKey => Object.values(IpAuditStepKey).includes(key as IpAuditStepKey);

  const getNextStepForCompleteFlow = (): IpAuditStepKey | null => {
    const step = userSteps.find(({ isCompleted }) => !isCompleted);
    if (step) return step.key;
    return null;
  };

  //---------------------------------------------------------------- Steps Data ----------------------------------------------------------------------------------------//

  const ipAuditSteps: TangiStepperStep<IpAuditStepKey>[] = Object.entries(ipAuditStepsData).map(([key, value], idx) => ({
    key: key as IpAuditStepKey,
    name: value.name,
    component: value.component,
    index: idx,
    isCompleted: isStepCompleted(key as IpAuditStepKey),
    isComingSoon: !value.component,
  }));

  // steps which the user can fill
  const userSteps = ipAuditSteps.filter(({ key, isComingSoon }) => key !== IpAuditStepKey.OVERVIEW && !isComingSoon);

  const numberOfSteps = userSteps.length;

  const numberOfCompletedSteps = userSteps.filter(({ isCompleted }) => isCompleted).length;

  return {
    isIpAuditEnabled,
    ipAuditSteps,
    isStepCompleted,
    getStepCurrentData,
    getNextStepKey,
    isIpAuditStepKey,
    getNextStepForCompleteFlow,
    numberOfSteps,
    numberOfCompletedSteps,
    ipAuditToastProps,
    userSteps,
    submitSystemSecurity,
    submitHrProcess,
  };
};
