import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import styled from 'styled-components';

import AssetTypeColorBlock from '_components/AssetTypeColorBlock/AssetTypeColorBlock';
import { TangiTypography } from '_components/TangiLibrary';
import { RecipientAsset } from 'utils/types/dashboard/dashboard';
import { isCustomAssetType, CUSTOM_ASSET_TYPE } from 'utils/assetTypes';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { InsightContainer } from './Insights.styled';

interface Props {
  assets: RecipientAsset[];
}

const TypeNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING[1]};
  width: 150px;
  flex: 1;

  .tangi-typography {
    white-space: nowrap;
    width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .asset-type-color-block {
    margin-right: ${SPACING[1]};
  }
`;

const MostNotifiedAsset = ({ assets }: Props) => {
  const { t } = useTranslation();
  const isEmpty = !assets.length;

  const mostNotifiedAsset = useMemo(() => (isEmpty ? null : assets[0]), [assets]);

  return (
    <InsightContainer isEmpty={isEmpty} data-testid="mostNotifiedAsset">
      <TangiTypography color={isEmpty ? NEUTRAL_SHADES[800] : NEUTRAL_SHADES.BLACK}>{t('DASHBOARD_PAGE.WIDGETS.INSIGHTS.MOST_NOTIFIED_ASSET')}</TangiTypography>
      <TypeNameContainer>
        {isEmpty ? (
          <TangiTypography color={isEmpty ? NEUTRAL_SHADES[800] : NEUTRAL_SHADES.BLACK}>{t('DASHBOARD_PAGE.WIDGETS.INSIGHTS.EMPTY')}</TangiTypography>
        ) : (
          mostNotifiedAsset && (
            <>
              <AssetTypeColorBlock assetType={isCustomAssetType(mostNotifiedAsset?.assetType?.name || '') ? CUSTOM_ASSET_TYPE : mostNotifiedAsset?.assetType?.name || ''} />
              <TangiTypography>{mostNotifiedAsset.name}</TangiTypography>
            </>
          )
        )}
      </TypeNameContainer>
    </InsightContainer>
  );
};

export default MostNotifiedAsset;
