import styled from 'styled-components';
import { PRIMARY_SHADES, SPACING } from 'utils/theme';

export const FileDisplayContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING[2]};
`;

export const FileNameContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${PRIMARY_SHADES[50]};
  height: 48px;
  flex: 1;
  gap: ${SPACING[1]};
  padding-left: ${SPACING[2]}; ;
`;
