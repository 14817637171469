import styled from 'styled-components';
import { FlexContainer } from 'utils/globalStyles';
import { NEUTRAL_SHADES, PRIMARY_SHADES, SPACING } from 'utils/theme';

export const PageContainer = styled.div`
  display: flex;
  padding: 32px 0px;
`;

export const Column = styled(FlexContainer)`
  padding: ${SPACING[5]};
`;

export const NavigationColumn = styled(Column)`
  width: 20%;
`;

export const ContentColumn = styled(Column)`
  width: 60%;
  gap: ${SPACING[3]}; /* Adjust the gap as needed */
`;

export const ScoreColumn = styled(Column)`
  width: 20%;
  top: 0;
`;
export const PercentageTextContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const BackToContainer = styled.div`
  font-size: 14px;
  font-family: 'DIN2014-Regular';
  display: flex;
  align-items: baseline;
  gap: ${SPACING[2]}; /* Adjust the gap as needed */
  margin-bottom: ${SPACING[1]};
`;

export const ScoreContainer = styled(FlexContainer)`
  width: 220px;
  height: 180px;
  padding: ${SPACING[3]} ${SPACING[3]};
  flex-direction: column;
  align-items: flex-start;
  gap: ${SPACING[2]};
  border-radius: ${SPACING[2]};
  border: 1px solid ${NEUTRAL_SHADES[300]};
  background: ${NEUTRAL_SHADES.WHITE};
`;

export const ContractContainer = styled(FlexContainer)`
  width: 100%;
  flex-direction: column;
  gap: ${SPACING[3]};
`;
export const ParameterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const scoreTextStyles = `
  font-size: 40px;
  margin-right: 2px;
`;

export const percentageTextStyles = `
  font-size: 30px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[2]};
`;

export const ContentSummaryContainer = styled(FlexContainer)`
  display: flex;
  padding: ${SPACING[4]};
  flex-direction: column;
  align-items: flex-start;
  gap: ${SPACING[3]};
  align-self: stretch;
  border-radius: ${SPACING[2]};
  border: 1px solid ${NEUTRAL_SHADES[300]};
  background: ${NEUTRAL_SHADES.WHITE};
`;

export const CollapseDivider = styled.hr`
  width: 100%;
  border-bottom: 1px solid ${NEUTRAL_SHADES[600]};
  margin: ${SPACING[0]};
`;

export const CollapseParameterContainer = styled.div`
  padding: ${SPACING[1]};
`;

export const CollapseContainer = styled.div`
  display: flex;
  padding: ${SPACING[2]} ${SPACING[0]};
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
`;

export const RecommendationContainer = styled.div`
  margin: ${SPACING[2]} ${SPACING[0]};
  display: flex;
  padding: ${SPACING[2]};
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
  border-radius: ${SPACING[1]};
  border: 1px solid ${PRIMARY_SHADES[300]};
  background: ${PRIMARY_SHADES[100]};
`;

export const CollapseKeyContainer = styled.div`
  display: flex;
  gap: ${SPACING[2]};
`;

export const CollapseContentContainer = styled.div`
  padding: ${SPACING[0]} 50px ${SPACING[0]} 20px;
  margin-bottom: 4px;
`;
