export enum ACCOUNT_STATUS {
  ACTIVE = 'Active',
  PENDING = 'Pending',
  DISABLED = 'Disabled',
}

export enum CREATED_FROM {
  EMAIL_FINDER = 'email-finder',
  EMAIL_TRACKER = 'email-tracker',
  PATENT_TOOL = 'patent-tool',
  PARTNERSHIP = 'partnership',
  INVENTION_DISCLOSURE = 'invention-disclosure',
}

export enum PARTNERSHIP_STATUS {
  ACTIVE = 'Active',
  DISABLED = 'Disabled',
}

export enum INTERVAL_IN_MILLISECONDS {
  ThirtySeconds = 30000,
  FortySeconds = 40000,
  TwoAndHalfSeconds = 2500,
  OneAndHalfSeconds = 1500,
  TenSeconds = 10000,
}

export enum RESULT_STATUS {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  BLANK = '',
}

export enum MIME_TYPES {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  PDF = 'application/pdf',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  CSV = 'text/csv',
}

export enum UPLOAD_FILE_TYPES {
  POLICY = 'policy',
  AGREEMENTS = 'agreements',
  ASSETS = 'assets',
  LLM = 'llm',
}

export enum CLIENT_TYPE {
  LAW_FIRM = 'lawFirm',
  CLIENT = 'client',
}

export enum ENTITY_TYPES {
  ASSET = 'assets',
  ACCOUNTS = 'accounts',
  AUTH = 'auth',
  PARTNERSHIPS = 'partnerships',
}

export enum REQUEST_STATUS {
  PENDING = 'pending',
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}

export enum RESPONSE_STATUS_CODES {
  OK = 200,
  PARTIAL_CONTENT = 206,
}
