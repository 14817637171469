import { createAsyncThunk } from '@reduxjs/toolkit';

import { ACCOUNT_STATUS } from '../../utils/enums';
import { CURRENT_APP_VERSION, authConstants } from '../../_constants';
import authAdapter from '../adapters/authAdapter';
import { Authenticate, AuthenticateResponse } from '../../utils/types/auth/auth';
import { UpdateSeenXrayModal, UpdateUserLanguage } from 'utils/types/auth/auth';
import { Account } from 'utils/types/account/account';
import { User } from 'utils/types/users/users';
import { saveStateToLocalStorage } from 'utils/localStorage';

export const confirmDisclaimer = createAsyncThunk('/accounts/confirmDisclaimer', async () => {
  try {
    // Change Active Account in localstorage
    const account = JSON.parse(localStorage?.getItem(authConstants.ACTIVATE_ACCOUNT) || 'null');
    account.seenDisclaimer = true;
    account.status = ACCOUNT_STATUS.ACTIVE;
    localStorage.setItem('activeAccount', JSON.stringify(account));

    // Change user in localstorage
    const user = JSON.parse(localStorage?.getItem(authConstants.USER) || 'null');
    const accountIndex = user.accounts.findIndex((account: Account) => account._id === account._id);
    if (accountIndex === -1) {
      user.accounts.push(account);
    } else {
      user.accounts[accountIndex] = { ...user.accounts[accountIndex], status: ACCOUNT_STATUS.ACTIVE, seenDisclaimer: true };
    }
    localStorage.setItem(authConstants.USER, JSON.stringify(user));

    await authAdapter.confirmDisclaimer();

    // Change user & activeAccount in redux
    return { account, user };
  } catch (error) {
    console.log('There was a problem saving the confirmation of the disclaimer');
  }
});

export const authenticate = createAsyncThunk<AuthenticateResponse | null, Authenticate>('/auth/authenticate', async ({ idToken, accountId }: Authenticate) => {
  const user = (await authAdapter.authenticate(idToken)) as User;
  const account = accountId ? user?.accounts?.find((acc: Account) => acc._id === accountId) : user?.accounts ? user.accounts[0] : null;

  if (!account || !user) return null;

  saveStateToLocalStorage(authConstants.ACTIVATE_ACCOUNT, account);
  saveStateToLocalStorage(authConstants.USER, user);
  saveStateToLocalStorage(authConstants.APP_VERSION, CURRENT_APP_VERSION);

  return { user, accountId, account };
});

export const updateUserLanguage = createAsyncThunk('user/updateUserLanguage', async (data: UpdateUserLanguage) => {
  const response = await authAdapter.updateUserLanguage(data);
  return response;
});

export const updateSeenXrayModal = createAsyncThunk('user/updateSeenXrayModal', async ({ userId, seenXray }: UpdateSeenXrayModal) => {
  const { seenXrayMapping, seenXrayExtraction } = seenXray;

  const dataObj = {
    ...(seenXrayMapping !== undefined && { seenXrayMapping }),
    ...(seenXrayExtraction !== undefined && { seenXrayExtraction }),
  };

  const res = await authAdapter.updateSeenXrayModal(userId, dataObj);
  return res;
});
