import { useTranslation } from 'react-i18next';

import { TangiChip, TangiSvgIcon } from '_components/TangiLibrary';
import { PRIMARY_SHADES } from 'utils/theme';
import { ChipContentContainer } from './styles';

const NewChip = ({ className }: { className?: string }) => {
  const { t } = useTranslation();

  return (
    <TangiChip
      variant="primary"
      className={className}
      text={
        <ChipContentContainer>
          <TangiSvgIcon component="discovery" size="15px" color={PRIMARY_SHADES[800]} />
          <span>{t('LOBBY_PAGE.CHIP.NEW')}</span>
        </ChipContentContainer>
      }
      maxWidth={130}
    />
  );
};

export default NewChip;
