import styled from 'styled-components';
import { SPACING } from 'utils/theme';

export const OptionCardsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${SPACING[3]};
  max-width: 1000px;
`;

export const CheckboxWithTextCard = styled.div`
  display: flex;
  align-items: center;
  border: none;
  padding-left: ${SPACING[3]};
  margin-top: ${SPACING[3.5]};
`;

export const nonOfTheAboveTextStyle = 'margin-top: 5px';
