import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CURRENT_APP_VERSION, authConstants } from '_constants/authConstants';
import { RootState } from '_helpers';
import { loadStateFromLocalStorage, saveStateToLocalStorage } from 'utils/localStorage';
import { HttpClientService } from '_services/HttpClientService';
import { mixpanelEvents } from '_services/utils/MixPanel/mixpanelConfig';
import useTokenRefresh from '_hocs/Cognito/useTokenRefresh';

interface Props {
  children: React.ReactNode;
}

const VersionControlHandler = (props: Props) => {
  const { loggedIn } = useSelector((state: RootState) => state.authentication);
  const dispatch = useDispatch();

  useTokenRefresh();

  useEffect(() => {
    const checkAppVersion = () => {
      const storedVersion = loadStateFromLocalStorage(authConstants.APP_VERSION);

      if (loggedIn && storedVersion !== CURRENT_APP_VERSION) {
        console.log('Version mismatch or not set. Logging out...');
        dispatch(mixpanelEvents.logoutAuto({ message: 'automatic logout' }));
        HttpClientService.logout();
        window.location.reload();
      }

      saveStateToLocalStorage(authConstants.APP_VERSION, CURRENT_APP_VERSION);
    };

    checkAppVersion();
  }, []);

  return props.children;
};

export default VersionControlHandler;
