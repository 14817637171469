import { CountObject, PredictedPortfolioTableData, ServerFilterItem, ServerFilters } from 'utils/types/predictedPortfolio/predictedPortfolio';
import { TRANSLATION_KEY } from '../types';

export const dateFormat = 'DD.MMM.YYYY HH:MM';

export const getWidgetsArr = (countObject: CountObject) => {
  return [
    { title: TRANSLATION_KEY.WIDGET_PATENT, number: countObject?.total_patents || 0 },
    { title: TRANSLATION_KEY.WIDGET_POTENTIAL_TREDE_SECRETS, number: countObject?.total_ts || 0 },
    { title: TRANSLATION_KEY.WIDGET_SUBJECTS, number: countObject?.total_subjects || 0 },
  ];
};

export const calculateGraphSize = (countObject: CountObject) => {
  if (!countObject) {
    return { height: 800, width: 800 };
  }

  const subjectTextHeight = 20;
  const patentsTextWidth = 30;
  const height = countObject.total_subjects * subjectTextHeight;
  const width = countObject.total_patents * patentsTextWidth;
  return { height, width };
};

export const transformData = (predictedPortfolioTable: PredictedPortfolioTableData) => {
  return predictedPortfolioTable.data.map((item) => ({
    'Potential trade secrets': item.trade_secret,
    Patent: item.patent.value,
    Year: item.patent_year,
    Subject: item.subject.value,
  }));
};

export const isFilterApplied = (filter: Record<string, unknown[]>, searchValue: string): boolean => {
  const isAnyArrayNonEmpty = Object.values(filter).some((array) => array.length > 0);
  const isSearchValueNonEmpty = searchValue !== '';
  return isAnyArrayNonEmpty || isSearchValueNonEmpty;
};

export const createFiltersForServer = (filter: ServerFilters, enteredSearchValue: string) => {
  return {
    patents: filter.patents?.map((patent: ServerFilterItem) => patent.id) || [],
    years: filter.years?.map((year: ServerFilterItem) => year.id) || [],
    subjects: filter.subjects?.map((subject: ServerFilterItem) => subject.id) || [],
    companies: filter.companies?.map((company: ServerFilterItem) => company.id) || [],
    search: enteredSearchValue,
  };
};

export const csvConfig = {
  useKeysAsHeaders: true,
  filename: 'predicted_portfolio_data',
};
