import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { usersActions } from 'redux-toolkit/slices/usersSlice';
import { getAgreementVersions, getUsers } from 'redux-toolkit/thunks/clientThunks';
import { getAssetMetaData } from 'redux-toolkit/thunks/assetMetaDataThunks';
import { getRoleNameContractor, getRoleNameEmployee, isUserIsRole, lawyers } from 'utils/roles';
import { AvatarEmail } from '../../../_components/Avatar/AvatarEmail';
import { formatDate } from '../../../utils/dateUtils';
import CoursesProgress from '../../../_components/CoursesProgress/CoursesProgress';
import Compliant from '../../../_components/Compliant/Compliant';
import { clientSettingsActions } from '../../../_actions/clientSettingsActions';
import { getColumns } from '../../../utils/peoplePartnerUtils';
import { dispatchGetUsersEmployee } from '../../../utils/customHooks';
import { excelExporters } from '../../../utils/excelExporters';
import { PeopleOptions } from '../../../_components/PeopleOptions/PeopleOptions';
import { ActionsCell, StartExitDates, MagicIconContainer, AvatarNameIconContainer } from '../style';
import { TangiAccountStatus } from '../../../_components/TangiLibrary';
import { AVATAR_SIZES } from '../../../utils/componentUtils';
import { SPACING } from '../../../utils/theme';
import { CREATED_FROM, RESULT_STATUS } from '../../../utils/enums';
import { ReactComponent as MagicIcon } from '../../../assets/tangiIcons/discovery.svg';
import { userConstants } from '_constants';
import AcknowledgmentPeopleTable from '_components/Acknowledgment/AcknowledgmentPeopleTable';
import AgreementPeopleTable from '_components/Agreement/AgreementPeopleTable';
import { getRoles, getUniqueCountries } from 'redux-toolkit/thunks/usersThunks';

export const usePeople = () => {
  const { clientId } = useParams();
  const { tablesLoaders } = useSelector((state) => state.client);
  const {
    tablesData: { employee, contractor },
  } = useSelector((state) => state.client);
  const { permissions, user, Role, activeAccount } = useSelector((state) => state.authentication);
  const { peopleToastProps, status: userUpdateStatus } = useSelector((state) => state.users);
  const metaDataState = useSelector((state) => state.assetMetaData);
  const agreementVersions = useSelector((state) => state.client.agreementVersions);
  const clientLoading = useSelector((state) => state.client.loading);
  const activeClient = useSelector((state) => state.lawfirm.activeClient);
  const { settings, loading: clientSettingsLoading } = useSelector((state) => state.clientSettings);
  const filter = useSelector((state) => state.users.peoplePage.filters.selectedOptions);
  const searchValue = useSelector((state) => state.users.peoplePage.filters.searchValue);

  const initialPagination = { page: 1, limit: 10 };
  const initialFilter = {
    status: [],
    compliantProgress: [],
    trainingProgress: [],
    dateOfJoining: [],
    roleId: [],
    country: [],
    businessUnit: [],
    department: [],
  };

  const [employeeData, setEmployeeData] = useState([]);
  const [contractorData, setContractorData] = useState([]);
  const [activeSelectorEmployee, setActiveSelectorEmployee] = useState({ selector: '', direction: '' });
  const [activeSelectorContractor, setActiveSelectorContractor] = useState({ selector: '', direction: '' });
  const [empPagination, setEmpPagination] = useState(initialPagination);
  const [cntrPagination, setCntrPagination] = useState(initialPagination);
  const [view, setView] = useState({ show: false, data: {}, type: '', mode: 'add' });
  const [, setBatchOptions] = useState(false);
  const [showBatchValidation, setShowBatchValidation] = useState(false);
  const [showInviteAccountForm, setShowInviteAccountForm] = useState(false);

  const isLawyer = useMemo(() => lawyers.includes(Role), [Role]);
  const isUserIsLearnType = useMemo(() => isUserIsRole(activeAccount, 'Learn'), [activeAccount]);
  const roleNameContractor = useMemo(() => getRoleNameContractor(isUserIsLearnType), [isUserIsLearnType]);
  const roleNameEmployee = useMemo(() => getRoleNameEmployee(isUserIsLearnType), [isUserIsLearnType]);

  const isFilterApplied = useMemo(() => {
    const anyFilterApplied = Object.values(filter).some((valuesArray) => valuesArray.length > 0);
    return anyFilterApplied || searchValue !== '';
  }, [filter, searchValue]);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const columns2 = getColumns(activeSelectorEmployee, false, true, Role);
  const columns3 = getColumns(activeSelectorContractor, false, true, Role);

  const getFiltersOptions = () => {
    batch(() => {
      dispatch(getRoles());
      dispatch(getUniqueCountries());
    });
  };

  const renderMagicIcon = (value) => {
    return value?.createdFrom === CREATED_FROM.EMAIL_FINDER ? (
      <MagicIconContainer>
        <Tippy content={<div className="text-xs">{t('PEOPLE.TOOLTIP.CREATED_BY_FINDER', { createdAt: formatDate(value?.createdAt) })}</div>}>
          <MagicIcon />
        </Tippy>
      </MagicIconContainer>
    ) : (
      value?.createdFrom === CREATED_FROM.EMAIL_TRACKER && (
        <MagicIconContainer>
          <Tippy content={<div className="text-xs">{t('PEOPLE.TOOLTIP.CREATED_BY_TRACKER', { createdAt: formatDate(value?.createdAt) })}</div>}>
            <MagicIcon />
          </Tippy>
        </MagicIconContainer>
      )
    );
  };

  const Actions = ({ account }) => {
    return (
      <ActionsCell>
        {user._id !== account.user && (
          <PeopleOptions accountStatus={account.status} accountId={account.id} handleEdit={() => handleViewData(account, 'Add Contractors', 'edit')} dropdownStyles={{ right: SPACING[5] }} />
        )}
      </ActionsCell>
    );
  };

  useEffect(() => {
    dispatch(assetsActions.setGetAssetsClear());
    dispatchGetUsersEmployee(dispatch, roleNameContractor, clientId, 'Contractor', { ...cntrPagination, search: searchValue }, filter);
  }, [cntrPagination, searchValue, filter, dispatch]);

  useEffect(() => {
    dispatch(getUsers({ userRoleName: roleNameEmployee, client: clientId, type: 'Employee', pagination: { ...empPagination, search: searchValue }, filter }));
  }, [empPagination, searchValue, filter, dispatch]);

  useEffect(() => {
    if (userUpdateStatus === userConstants.USER_UPDATE_SUCCESSFULLY) {
      getFiltersOptions();
    }
  }, [userUpdateStatus, dispatch]);

  useEffect(() => {
    if (contractor && contractor.data && contractor.data.length > 0) {
      const tabledata = [];
      contractor.data.forEach((value) => {
        if (user?.username !== value.username) {
          tabledata.push({
            id: value.id,
            onClick: () => handleViewData(value, 'Add Contractors', 'view'),
            name: (
              <AvatarNameIconContainer>
                <div>
                  <AvatarEmail bordered={false} size={AVATAR_SIZES.XS} isLinkDisabled name={value.displayName ? value.displayName : '-'} email={value.email} />{' '}
                  <Tippy content={<div className="font-bold text-xs">{value.displayName ? value.displayName : '-'}</div>}>
                    <span
                      role="presentation"
                      onClick={() => handleViewData(value, 'Add Contractors', 'view')}
                      className="hand-cursor table-cell-ff h4 text-dark font-weight-normal text-capitalize text-ellipsis ff-din-DemiBold"
                    >
                      {value.displayName ? value.displayName : '-'}
                    </span>
                  </Tippy>
                </div>
                {renderMagicIcon(value)}
              </AvatarNameIconContainer>
            ),
            startdate: (
              <StartExitDates>
                <div data-testid="contractor-hire-date">{value?.dateOfJoining ? formatDate(value.dateOfJoining) : ''}</div>
                <div data-testid="contractor-exit-date">{value?.dateOfLeaving ? formatDate(value.dateOfLeaving) : ''}</div>
              </StartExitDates>
            ),
            acknowledgment: <AcknowledgmentPeopleTable acknowledgments={value.assets.sharedWith} />,
            agreements: <AgreementPeopleTable agreements={value.agreements} />,
            training: <CoursesProgress coursesArray={value.courseProgression} />,
            ...(!isUserIsLearnType && { status: <Compliant agreements={value.agreements} tradeSecrets={value.assets?.tradeSecrets} userName="Contractor" clientId={clientId} /> }),
            editdelete: <Actions account={value} />,
            accountStatus: <TangiAccountStatus status={value.status} />,
          });
        }
      });
      setContractorData(tabledata);
    } else {
      setContractorData([]);
    }
    if (employee && employee.data && employee.data.length > 0) {
      const tabledata = [];
      employee.data.forEach((value) => {
        if (user) {
          tabledata.push({
            id: value.id,
            onClick: () => handleViewData(value, 'Add Employees', 'view'),
            name: (
              <AvatarNameIconContainer>
                <div>
                  <AvatarEmail size={AVATAR_SIZES.XS} isLinkDisabled name={value.displayName ? value.displayName : '-'} email={value.email} />{' '}
                  <Tippy content={<div className="font-bold text-xs">{value.displayName ? value.displayName : '-'}</div>}>
                    <span
                      role="presentation"
                      onClick={() => handleViewData(value, 'Add Employees', 'view')}
                      className="hand-cursor text-ellipsis table-cell-ff h4 text-dark font-weight-normal text-capitalize ff-din-DemiBold ms-2"
                    >
                      {value.displayName ? value.displayName : '-'}
                    </span>
                  </Tippy>
                </div>
                {renderMagicIcon(value)}
              </AvatarNameIconContainer>
            ),
            startdate: (
              <StartExitDates>
                <div data-testid="employee-hire-date">{value?.dateOfJoining ? formatDate(value.dateOfJoining) : ''}</div>
                <div data-testid="employee-exit-date">{value?.dateOfLeaving ? formatDate(value.dateOfLeaving) : ''}</div>
              </StartExitDates>
            ),
            acknowledgment: <AcknowledgmentPeopleTable acknowledgments={value.assets.sharedWith} />,
            agreements: <AgreementPeopleTable agreements={value.agreements} />,
            training: <CoursesProgress coursesArray={value.courseProgression} />,
            editdelete: <Actions account={value} />,
            accountStatus: <TangiAccountStatus status={value.status} />,
          });
        }
      });
      setEmployeeData(tabledata);
    } else {
      setEmployeeData([]);
    }
  }, [employee, contractor, t, filter]);

  const handleSortEmployee = (column, sortDirection) => {
    setActiveSelectorEmployee({
      selector: column.fieldName,
      direction: sortDirection,
    });
    let field;
    if (column.fieldName === 'startdate') {
      field = 'dateOfJoining';
    } else if (column.fieldName === 'name') {
      field = 'displayName';
    } else if (column.fieldName === 'accountStatus') {
      field = 'status';
    } else {
      field = column.fieldName;
    }
    const sort = [field, ':', sortDirection].join('');
    setEmpPagination({ ...empPagination, sortBy: sort });
  };
  const handleSortContractor = (column, sortDirection) => {
    setActiveSelectorContractor({
      selector: column.fieldName,
      direction: sortDirection,
    });
    let field;
    if (column.fieldName === 'startdate') {
      field = 'dateOfJoining';
    } else if (column.fieldName === 'name') {
      field = 'displayName';
    } else if (column.fieldName === 'accountStatus') {
      field = 'status';
    } else {
      field = column.fieldName;
    }
    const sort = [field, ':', sortDirection].join('');
    setCntrPagination({ ...cntrPagination, sortBy: sort });
  };

  const handleViewData = (data, type, mode) => {
    const newdata = { ...data };
    setView({ show: true, data: newdata, type, mode });
  };

  const exportExcelEmployees = () => {
    excelExporters.exportEmployeeTemplate(metaDataState, settings.agreements, isUserIsLearnType, agreementVersions);
    setBatchOptions(false);
  };
  const handleOpenBatchValidation = () => {
    setShowBatchValidation(true);
    setBatchOptions(false);
    dispatch(usersActions.setResetBatchEmployees());
  };

  const initPeople = () => {
    batch(() => {
      dispatch(getAssetMetaData(clientId));
      dispatch(clientSettingsActions.getClientSettings(clientId));
      dispatch(getAgreementVersions({ client: clientId }));
    });
  };

  const handleCloseBatchValidation = () => {
    dispatch(getUsers({ userRoleName: roleNameEmployee, client: clientId, type: 'Employee', pagination: empPagination }));
    setShowBatchValidation(false);
    getFiltersOptions();
  };

  useEffect(() => {
    initPeople();
    getFiltersOptions();
  }, []);

  const fetchAccounts = () => {
    dispatchGetUsersEmployee(dispatch, roleNameEmployee, clientId, 'Employee', empPagination);
    dispatchGetUsersEmployee(dispatch, roleNameContractor, clientId, 'Contractor', cntrPagination);
    batch(() => {
      dispatch(usersActions.setClearUser());
      dispatch(usersActions.setPeopleToastProps({ show: false, type: RESULT_STATUS.BLANK, text: '' }));
    });
  };

  const handleClearSearch = useCallback(() => {
    dispatch(usersActions.clearSearchQuery());
  }, [dispatch]);

  return {
    handleSortContractor,
    setEmpPagination,
    setCntrPagination,
    handleSortEmployee,
    exportExcelEmployees,
    handleOpenBatchValidation,
    handleCloseBatchValidation,
    setShowInviteAccountForm,
    fetchAccounts,
    handleClearSearch,
    searchValue,
    initialFilter,
    filter,
    isLearn: isUserIsLearnType,
    view,
    permissions,
    contractor,
    columns2,
    columns3,
    empPagination,
    cntrPagination,
    employeeData,
    contractorData,
    tablesLoaders,
    Role,
    showBatchValidation,
    showInviteAccountForm,
    activeAccount,
    activeClient,
    isLawyer,
    peopleToastProps,
    isFilterApplied,
    clientSettingsLoading,
    clientLoading,
    metaDataLoading: metaDataState.loading,
  };
};
